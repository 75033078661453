import React, { useState } from "react";
import "./aboutUs.css";
import { useEffect } from "react";

import Background from "../../assets/backgrounds/Background-grid.svg";
import kartik from "../../assets/images/about-us/KartikS.svg";
import Prashat from "../../assets/images/about-us/PrashantT.svg";
import globe from "../../assets/icons/global.svg";
import taliscop from "../../assets/icons/microscope.svg";
import microphone from "../../assets/icons/microphone.svg";
import building from "../../assets/icons/buliding.svg";

import HeroTitleAndDesc from "../../components/hero-title-description/HeroTitleAndDesc";
import HobbieCard from "../../components/cards/hobbies-card/HobbieCard";
import Stats from "../../components/stats/Stats";
import OurTeam from "../../components/our-team/OurTeam.js";
import ContactFrom from "../../components/ContactForm/ContactFrom.js";
import { ToastContainer } from "react-toastify";

const AboutUs = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const kartikHobbies = [
    {
      title: "Globetrotter",
      description:
        "I like to explore the hearts of random cities and countries, interacting with their local cultures, cuisines, and parties!",
      icon: globe,
    },
    {
      title: "Nerd",
      description:
        "Sci-fi movies? Comics? Ancient history? Fantasy books? Greek Gods? Board games? League of Legends?",
      icon: taliscop,
    },

    {
      title: "Hobbyist Creator",
      description: `Check out my sci-fi novel "The Unconventional Winner: Spectre's Rise" or my podcast “Moodpickings”.`,
      icon: microphone,
    },
    {
      title: "Startup Enthusiast",
      description: "Evallo is my third ed-tech startup, and 5th overall.",
      icon: building,
    },
  ];

  const heroSection = {
    title: "About Us",
    description:
      "Evallo is a product of collaborative efforts by multiple tutors and tutoring companies in sharing their pain points with us.",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container-fluid px-0 overflow-hidden">
      <ToastContainer />
      {/* <div className='row'>Navbar</div>  */}
      <div className="row">
        {/* <div className='col-12'>
                    <Stats />
                </div> */}
        <div className="w-100 px-4">
          <HeroTitleAndDesc
            title={heroSection.title}
            desc={heroSection.description}
          />
          <div className=" row position-relative ">
            <div className="w-100 mt-5 z-2">
              <Stats />
              <div className="row gap-4 justify-content-center about-container">
                {/* <div className='row p-3 border p-lg-5 gap-4 justify-content-center about-container'> */}

                <div className="evallo-info">
                  <h1>Our Mission</h1>
                  <div>
                    <p>
                      Evallo's mission is to democratize learning globally,
                      starting with empowering educators to focus purely on
                      teaching. By automating the non-instructional aspects of
                      their work, we aim to make high-quality education
                      accessible everywhere. Our vision is to create a world
                      where educators and students connect effortlessly,
                      fostering a community that transcends geographical
                      boundaries and revolutionizes educational experiences for
                      all.
                    </p>
                  </div>
                </div>

                <div className="founder-info py-lg-5 py-2">
                  <div className="row py-3 gap-4 gap-lg-0 justify-content-between align-items-center">
                    <div className="col-12 col-lg-4 order-lg-2">
                      <img className="w-100" src={kartik} alt="" />
                    </div>
                    <div className="col-12 col-lg-7 px-lg-4 order-lg-1 ">
                      <div className="text-center text-lg-start mb-4">
                        <h1>Kartik Sarda</h1>
                        <h4>CEO & Founder, Evallo</h4>
                      </div>
                      <div className="about-founder">
                        <p>
                          As the founder of Evallo, I blend a passion for
                          education with tech innovation to revolutionize
                          tutoring. My expertise in product management and UI/UX
                          design has propelled Evallo's development, creating a
                          platform that streamlines educator workflows and
                          enhances student learning through data-driven insights
                          and a comprehensive content marketplace.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row gap-3 my-4 justify-content-center hobbies-container">
                    {kartikHobbies.map((el, i) => {
                      return (
                        <HobbieCard
                          key={i}
                          title={el.title}
                          desc={el.description}
                          icon={el.icon}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="founder-info py-lg-5 py-2">
                  <div className="row py-3 gap-4 gap-lg-0 justify-content-between align-items-center">
                    <div className="col-12 col-lg-4 order-lg-2">
                      <img className="w-100" src={Prashat} alt="" />
                    </div>
                    <div className="col-12 col-lg-7 px-lg-4 order-lg-1 ">
                      <div className="text-center text-lg-start mb-4">
                        <h1>Prashant Kumar Tiwari</h1>
                        <h4>CBO & Co-founder, Evallo</h4>
                      </div>
                      <div className="about-founder">
                        <p>
                          As a second-time founder, I have always been deeply
                          invested in the education space in one way or another.
                          At Evallo, I am dedicated to ensuring client
                          satisfaction and my focus is on maximizing results for
                          our clients through a combination of great product and
                          quality service. When I'm not working, I love trekking
                          and exploring new places, always seeking new
                          adventures and inspirations.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <OurTeam />
            </div>
            <div className="w-100 position-absolute top-0 background-grid">
              <img src={Background} alt="" />
            </div>
            <div className="position-absolute top-0 start-50 translate-middle-x color-spread"></div>
          </div>
        </div>
      </div>
      <div className="row p-0 m-0 mt-4">
        <div className="col-12 p-0 m-0">
        <ContactFrom  {...{
                firstName,
                lastName,
                email,
                phone,
                setFirstName,
                setLastName,
                setPhone,
                message,
                setMessage,
                setEmail,
                queryFor:"sales",
              }} />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
