import React from 'react'
import "./heroTitleDesc.css"

import TitileAndDiscGrid from './TitileAndDiscGrid'

const HeroTitleAndDesc = ({ title, desc, grid }) => {
    return (
        <div className='row hero-title-desc'>
            <div className='text-center d-flex flex-column align-items-center feature-title'>
                <h1 className=''>{title}</h1>
                <div className='position-relative '>
                    <p className='z-2 position-relative'>{desc}</p>
                    {/* //{grid && <TitileAndDiscGrid />} */}
                </div>
            </div>
        </div>
    )
}

export default HeroTitleAndDesc