import './Card.css'

const Card = (props) =>{


    return (
        <div className='ev-card d-flex flex-column justify-content-center align-items-start p-3 p-lg-4'>
            <div className='ev-card-heading'>
                {props.heading}
            </div>
            <div className='ev-card-desc'>
                {props.desc}
            </div>
        </div>
    )

}

export default Card