import React from 'react'

import "./bannerV2.css"
import useScrollAnimation from '../../Hooks/useScrollAnimation'

function ReverseBanner({ title, desc, image, button, featureUrl,note }) {

    useScrollAnimation("banner-init", "popIn", 0.5)

    return (
        <div className='col-12'>
            <div className='d-flex flex-column banner-init flex-lg-row justify-content-between align-items-center banner-card'>
                <div className='banner-cxard-img'>
                    <img className='w-100' src={image} alt="" />
                </div>
                <div className='banner-card-info'>
                    <h1>{title}</h1>
                    <p>{desc}</p>
                    {/* <NormalButton btnName={button} featureUrl={featureUrl} /> */}
                    {
                        note ? 
                        <div className="note-part">{note}</div>
                        : null
                    }
                    <div className='d-flex justify-content-start align-items-center'>{button}</div>
                </div>
            </div>
        </div>
    )
}

export default ReverseBanner