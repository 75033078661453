import DocsSearchBar from '../DocsSearchBar/DocsSearchBar'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import styles from './MirachTopbar.module.css'
import SearchIcon from '@mui/icons-material/Search';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PublishIcon from '@mui/icons-material/Publish';
import PreviewIcon from '@mui/icons-material/Preview';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useNavigate } from 'react-router-dom';

const MirachTopbar = (props) => {
    const navigate = useNavigate()
    const { isHome, onPublishClicked, onSaveClicked, backLink, onSearchKeywordChanged } = props
    if (isHome) {
        return (
            <div className={`container-fluid h-100 d-flex justify-content-between align-items-center p-4`}>
                <div class="input-group w-50">
                    <span class="input-group-text" id="basic-addon1"><SearchIcon /></span>
                    <input type="text" class="form-control" placeholder="Search keywords" onChange={(e)=>{onSearchKeywordChanged(e)}}/>
                </div>
                <Link to="/mirach/account"><AccountCircleIcon /></Link>
                {/* <button className='btn'></button> */}
            </div>
        )
    } else {
        return (
            <div className={`container-fluid h-100 d-flex justify-content-between align-items-center p-2`}>
                <button className='btn d-flex justify-content-center gap-3 align-items-center' onClick={(e)=>navigate(backLink)}><ArrowBackIcon /> Back</button>
                <div className='d-flex justify-content-end align-items-center gap-4'>
                    <button className={styles.saveButton} onClick={(e)=>onSaveClicked(e)}><SaveIcon />Save</button>
                    <button className={styles.publishButton} onClick={(e)=>onPublishClicked(e)}><PublishIcon/>Publish</button>
                </div>
            </div>
        )
    }
}

export default MirachTopbar