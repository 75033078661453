import React from "react";
import "./aBanner.css";
import art_image from "../../../assets/images/blog-page-images/article_img.png";
import clock from "../../../assets/images/blog-page-images/clock.png";
import { Link } from "react-router-dom";

const ArticleBanner = ({ BlogsBannerData, scrollToRead }) => {
  const DateModified = (date) => {
    const modDate = new Date(date);
    const month = modDate.toLocaleString("default", { month: "short" });
    const day = modDate.getDate();
    const year = modDate.getFullYear();
    return `${month} ${day} ${year}`;
  };

  return (
    <div className="banner2 z-2 "  style={{backdropFilter:"blur(4px)"}} >
      <div className="artice_banner2">
        <div className="article_image2">
          <img src={BlogsBannerData?.img} alt="artice-image" />
        </div>
        <section className="art_desc">
          <section className="art_desc2">
            <section className="d-flex gap-2">
              {BlogsBannerData?.categories.map((data, index) => (
                <button key={index} className="art_button">
                  {data}
                </button>
              ))}
            </section>
            <h2>{BlogsBannerData.title}</h2>

            <div className="art_time">
              {DateModified(BlogsBannerData.date)} |{" "}
              <img src={clock} alt="clock" /> {BlogsBannerData.readTime}
            </div>
          </section>

          <p>{BlogsBannerData.seoDescription}</p>

          <div style={{ width: "fit-content", cursor: "default" }}>
            <button className="art_button_2 " onClick={scrollToRead}  >
              Read more
              <svg
                width="15"
                height="17"
                viewBox="0 0 15 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.79289 16.7071C7.18342 17.0976 7.81658 17.0976 8.20711 16.7071L14.5711 10.3431C14.9616 9.95262 14.9616 9.31946 14.5711 8.92893C14.1805 8.53841 13.5474 8.53841 13.1569 8.92893L7.5 14.5858L1.84315 8.92893C1.45262 8.53841 0.819457 8.53841 0.428932 8.92893C0.0384078 9.31946 0.0384078 9.95262 0.428932 10.3431L6.79289 16.7071ZM6.5 0L6.5 16H8.5V0L6.5 0Z"
                  fill="#0671E0"
                />
              </svg>
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ArticleBanner;
