import React, { useContext } from "react";
import styles from "./DocsCard.module.css";
import { ButtonFilled } from "../Buttons";
import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NavLinkContext from "../../contexts/NavLinkContext";

const DocsCard = ({ img, title, desc, button, navTab }) => {

  const {setNavLink} = useContext(NavLinkContext)

  return (
    <div
      className={` d-flex flex-column gap-3 justify-content-between align-items-start ${styles.container}`}
    >
      <div className={styles.imgContainer}>
        <img src={img} className={styles.img} alt="abc" />
      </div>
      <div>
        <div className={styles.heading}>{title}</div>
        <div className={styles.description}>{desc}</div>
        <div className="mt-2">
            <ButtonFilled onClick={(e)=>setNavLink(navTab)}>{button}</ButtonFilled>
        </div>
      </div>
    </div>
  );
};

export default DocsCard;
