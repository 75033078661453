import './PackageFeaturesList.css'
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import GridViewIcon from '@mui/icons-material/GridView';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import selected from './selected.svg'
import book from './book.svg'
import analytics from './analytics.svg'
import apps from './apps.svg'
import cal from './cal.svg'

import bookS from './book copy.svg'
import analyticsS from './analytics copy.svg'
import appsS from './apps copy.svg'
import calS from './cal copy.svg'


const PackageFeaturesList = (props) => {

    const handePackageSelected = (e) => {
        console.log(e.target)
        props.setSelected(e.target.id)
    }

    return (
        <ul className="package-features-list">
            <li className={'position-relative d-flex justify-content-center justify-content-lg-start align-items-center '}  id="General" onClick={(e)=>handePackageSelected(e)}>
                <div className={'d-flex flex-row justify-content-start align-items-center gap-3 '} style={{zIndex : 2, pointerEvents : "none", color :  props.selected === "General" ? "white" : ""}}>
                    <img src={props.selected === "General" ? bookS : book} style={{pointerEvents : "none"}}/><span>General</span>
                </div>
                {
                    (props.selected === "General" ?  <img src={selected} className='position-absolute pe-lg-4 ps-lg-0' style={{pointerEvents : "none"}}/>: null)
                }
            </li>
            <li className='position-relative d-flex justify-content-center justify-content-lg-start align-items-center'  id="User Management" onClick={(e)=>handePackageSelected(e)}>
                <div className='d-flex flex-row justify-content-start align-items-center gap-3' style={{zIndex : 2, pointerEvents : "none",color :  props.selected === "User Management" ? "white" : ""}}>
                    <img src={props.selected === "User Management" ? appsS : apps} style={{pointerEvents : "none"}}/><span>User Management</span>
                </div>
                {
                    (props.selected === "User Management" ?  <img src={selected} className='position-absolute pe-lg-4 ps-lg-0' style={{pointerEvents : "none"}}/>: null)
                }
            </li>
            <li className='position-relative d-flex justify-content-center justify-content-lg-start align-items-center'  id="Calendar & Scheduling" onClick={(e)=>handePackageSelected(e)}>
                <div className='d-flex flex-row justify-content-start align-items-center gap-3' style={{zIndex : 2, pointerEvents : "none", color :  props.selected === "Calendar & Scheduling" ? "white" : ""}}>
                    <img src={props.selected === "Calendar & Scheduling"?  calS : cal} style={{pointerEvents : "none"}}/><span>Calendar & Scheduling</span>
                </div>
                {
                    (props.selected === "Calendar & Scheduling" ?  <img src={selected} className='position-absolute pe-lg-4 ps-lg-0' style={{pointerEvents : "none"}}/>: null)
                }
            </li>
            <li className='position-relative d-flex justify-content-center justify-content-lg-start align-items-center'  id="Analytics" onClick={(e)=>handePackageSelected(e)}>
                <div className='d-flex flex-row justify-content-start align-items-center gap-3'style={{zIndex : 2, pointerEvents : "none", color :  props.selected === "Analytics" ? "white" : ""}} >
                    <img src={props.selected === "Analytics" ? analyticsS : analytics} style={{pointerEvents : "none"}}/><span>Analytics</span>
                </div>
                {
                    (props.selected === "Analytics" ?  <img src={selected} className='position-absolute pe-lg-4 ps-lg-0' style={{pointerEvents : "none"}}/>: null)
                }
            </li>
            <li className='position-relative d-flex justify-content-center justify-content-lg-start align-items-center'  id="Invoicing" onClick={(e)=>handePackageSelected(e)}>
                <div className='d-flex w-100 flex-row justify-content-start align-items-center gap-3' style={{zIndex : 2, pointerEvents : "none", color :  props.selected === "Invoicing" ? "white" : ""}}>
                    <MonetizationOnIcon style={{color : props.selected === "Invoicing" ? "white" : "#07151F", pointerEvents : "none"}}/><span>Invoicing</span>
                </div>
                {
                    (props.selected === "Invoicing" ? <img src={selected} className='position-absolute pe-lg-4 ps-lg-0' style={{pointerEvents : "none"}}/>: <></>)
                }
                
            </li>

        </ul>
    )

}

export default PackageFeaturesList