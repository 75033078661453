import React, { useState } from "react";
import "./dsat.css";
import FeaturesHeroSection from "../../../components/FeaturesHeroSection/FeaturesHeroSection";
import Banner from "../../../components/banner/Banner";
import NormalButton from "../../../components/Buttons/NormalButton/NormalButton";
import ReverseBanner from "../../../components/banner/ReverseBanner";
import VideoButton from "../../../components/Buttons/VideoButton/VideoButton";
import CardWithPadding from "../../../components/cards/cards-with-padding/CardWithPadding";
import heroBgImage from "../../../assets/backgrounds/FeaturesHeroSectionImage.svg";
import practiceTest from "../../../assets/images/dsat-page-images/practicTest.png";
import createTest from "../../../assets/images/dsat-page-images/createYourTest.svg";
import performance from "../../../assets/images/dsat-page-images/performanceRep.svg";
import timer from "../../../assets/images/dsat-page-images/timer.svg";
import partialTest from "../../../assets/images/dsat-page-images/partialTest.svg";
import sharedRepo from "../../../assets/images/dsat-page-images/sharedRep.svg";
import Meteorites from "../../../components/metorites/meteorites";
import ContactFrom from "../../../components/ContactForm/ContactFrom";
import { useEffect } from "react";

import VideoPopUp from "../../../components/videoPopUp/videoPopUp";
import PopUp from "../../../components/PopUp/PopUp";
import { ToastContainer } from "react-toastify";

const videos = [
  <iframe
    width="80%"
    height="80%"
    src="https://www.youtube.com/embed/YEmJMPyWgIo?si=Iyx_mMJlmXc8R3dE"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowfullscreen
  ></iframe>,
];

const Dsat = () => {
  const [vidTrigger, setVidTrigger] = useState(false);
  const [vidIdx, setVidIdx] = useState(0);
  const [formTrigger, setFormTrigger] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const dsatInfo = {
    image: heroBgImage,
    title: "Assignments & Grading",
    description:
      "Realistic test simulation, full-length practice DSAT®s, and instant score reports with insights - you get a full package!",
    buttonText: "Try Now",
    buttonUrl: "https://app.evallo.org/signin",
  };

  const dsatItemInfo = [
    {
      image: practiceTest,
      title: "Exclusive Content & Practice Tests",
      description:
        "Searching for reliable practice tests for your students? When you purchase the Assignments extension, you get upto 10 Digital SATs® for free!",
    },
    {
      image: createTest,
      title: "Create Your Own Tests",
      description:
        "Want to create your own Digital SAT® practice tests or drills? Yes, you can do that too! Just upload the metadata, and Evallo will take care of the grading, scoring, and results tracking automatically.",
    },
    {
      image: performance,
      title: "Comprehensive Performance Reports",
      description:
        "Track student performance and time management with automatic reports that contain breakdowns by question type and strategy type for each test. Student progress over time is graphed automatically.",
    },
  ];

  const dsatCardInfo = [
    {
      image: timer,
      title: "Timer Control",
      description:
        "Choose how much time you want to give the student per section–including unlimited time.",
    },
    {
      image: partialTest,
      title: "Partial Tests",
      description:
        "Don’t want your students to take full-tests? Mark tests as “Completed” whenever you want.",
    },
    {
      image: sharedRepo,
      title: "Shared Reports",
      description:
        "Increase data transparency by sharing score reports with students and parents.",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className="container-fluid position-relative px-0"
      style={{ overflow: "clip" }}
    >
      <ToastContainer />
      <PopUp trigger={formTrigger} setTrigger={setFormTrigger}>
        <ContactFrom
          {...{
            firstName,
            lastName,
            email,
            phone,
            setFirstName,
            setLastName,
            setPhone,
            message,
            setMessage,
            setEmail,
            queryFor: "sales",
            cases:"popup",
            setFormTrigger: setFormTrigger,
          }}
        />
      </PopUp>
      <Meteorites />
      <VideoPopUp
        element={videos[vidIdx]}
        trigger={vidTrigger}
        setTrigger={setVidTrigger}
      />
      <FeaturesHeroSection
        image={dsatInfo.image}
        title={dsatInfo.title}
        desc={dsatInfo.description}
        buttonText={dsatInfo.buttonText}
        buttonUrl={dsatInfo.buttonUrl}
        page="dsat"
      />
      <div className="row row-gap-4 gap-lg-5 featured-items">
        <div className="col-12">
          <Banner
            title={dsatItemInfo[0].title}
            desc={dsatItemInfo[0].description}
            image={dsatItemInfo[0].image}
            button={
              <button
                onClick={(e) => setFormTrigger(true)}
                className="normal-btn"
              >
                Inquire Details
              </button>
              //   <NormalButton
              //     btnUrl={"mailto:support@evallo.org"}
              //     btnName={"Inquire Details"}
              //   />
            }
          />
        </div>
        <div className="col-12">
          <ReverseBanner
            title={dsatItemInfo[1].title}
            desc={dsatItemInfo[1].description}
            image={dsatItemInfo[1].image}
            button={
              <VideoButton
                onClick={(e) => {
                  if (setVidTrigger) {
                    setVidTrigger(!vidTrigger);
                    setVidIdx(0);
                  }
                }}
                btnName={"Watch Tutorial"}
              />
            }
          />
        </div>
        <div className="col-12">
          <Banner
            title={dsatItemInfo[2].title}
            desc={dsatItemInfo[2].description}
            image={dsatItemInfo[2].image}
            button={
              <a href="https://app.evallo.org/signup" target="_blank">
                <button className="normal-btn">Try for free</button>
              </a>
              // <NormalButton btnUrl={"https://app.evallo.org/signin"} btnName={"Try For Free"} />
            }
          />
        </div>
      </div>
      <div className="row gap-4 dsat-bottom-card">
        {dsatCardInfo.map((el, i) => {
          return (
            <CardWithPadding
              key={i}
              title={el.title}
              desc={el.description}
              image={el.image}
            />
          );
        })}
      </div>
      <div className="row p-0 m-0 mt-4">
        <div className="col-12 p-0 m-0">
          <ContactFrom
            {...{
              firstName,
              lastName,
              email,
              phone,
              setFirstName,
              setLastName,
              setPhone,
              message,
              setMessage,
              setEmail,
              queryFor: "sales",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Dsat;
