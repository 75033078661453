import { Link } from 'react-router-dom'
import { ButtonFilled } from '../Buttons'
import styles from './FeaturesHero.module.css'

/**
 * 
 * @param {{img : object,heading : string ,description : string, buttonText : string}} props props for the object
 * @returns 
 */
export default function FeaturesHero({ img, heading, description, buttonText, btnUrl }) {
    return (
        <div className={`d-flex flex-column align-items-center justify-content-start gap-0 gap-lg-0 ${styles.container}`}>
            <img className={styles.image} src={img} />
            <div className='d-flex flex-column gap-2 gap-lg-3 justify-content-start align-items-center' style={{ marginTop: "-18px" }}>
                <div className={styles.heading}>
                    {heading}
                </div>
                <div className={styles.description}>
                    {description}
                </div>
                <Link to={btnUrl} target='_blank'><ButtonFilled>{buttonText}</ButtonFilled></Link>
            </div>
        </div>
    )
}