import React, { useEffect, useState } from "react";
import HeroTitleAndDesc from "../../components/hero-title-description/HeroTitleAndDesc";
import BannerTutor10x from "../../components/Blogs/BannerTutor10x/BannerTutor10x";
import ArticleBanner from "../../components/Blogs/ArticleBanner/ArticleBanner";
import Background from "../../assets/backgrounds/Background-grid.svg";
import search from "../../assets/images/blog-page-images/search.png";
import BlogSection from "../../components/Blogs/BlogsSection/BlogSection";
import PinSection from "../../components/Blogs/PinSection/PinSection";
import {
  fetchBlogsPublished,
  getCategories,
  getPinBlog,
  searchBlogsForKeyword,
} from "../../Utils/utils";

import "./blog.css";
import PaginationComp from "../../components/Blogs/Pagination/Pagination";

const Blogs = () => {
  const [category, setCategory] = useState(1);
  const [blogs, setAllBlogs] = useState([]);
  const [pinBlogs, setPinBlogs] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [HeaderBlog, setHeaderBlog] = useState();
  const [CategoricalData, setCategoricalData] = useState(null);
  const [page, setPage] = useState(1);

  const heroSection = {
    title: "Blogs",
    description:
      "Discover the latest in ed-tech, tutoring & education - we cover it all. ",
  };

  const categories = [
    {
      id: 1,
      name: "All",
    },
    {
      id: 2,
      name: "Education",
    },
    {
      id: 3,
      name: "Ed-tech",
    },
    {
      id: 4,
      name: "Case Studies",
    },
    {
      id: 5,
      name: "Tips for Tutors",
    },
  ];



  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    (async () => {
      try {
        const result = await fetchBlogsPublished();
        setHeaderBlog(result[0]);
        setAllBlogs(result);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const result = await getPinBlog();
        setPinBlogs(result);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);



  const triggerSearch = async (e) => {
    if (e.key == "Enter") {
      const results = await searchBlogsForKeyword(searchText);
      setCategory(1);
      setCategoricalData(null);


      setAllBlogs(results);
    }
  };

  const clearSearch = async () => {
   
    setSearchText("");
    const result = await fetchBlogsPublished();
    setCategoricalData(null);
    setCategory(1);
    setAllBlogs(result);

  };


  const searchCategory = (id, name) => {
    setCategory(id);

    if (id == 1) {
      setCategoricalData(null);
    } else {
      const data = blogs.filter((blog) => {
        return (
          Array.isArray(blog.categories) &&
          blog.categories.some(
            (category) => category.toLowerCase() === name.toLowerCase()
          )
        );
      });

      setCategoricalData(data);
    }
  };

  

  return (
    <div className="container-fluid px-0 overflow-hidden">
      <div className=" evallo-blog row">
        <div className="w-100 px-4 head_block ">
          <HeroTitleAndDesc
            title={heroSection.title}
            desc={heroSection.description}
          />
        </div>

        <div  className="start_div   position-relative">
          <div className="w-100 position-absolute top-0 background-grid  ">
            <img src={Background} alt="" />
          </div>
          <div className="position-absolute top-0 start-50 translate-middle-x color-spread "></div>

          {HeaderBlog?._id && <ArticleBanner BlogsBannerData={HeaderBlog} />}

          <div className="cont_blog_outer">
            <div className="cont_blog ">
              <section className="search_box d-flex z-2">
                <img src={search} alt="search" />
                <input
                  type="text"
                  value={searchText}
                  placeholder="What do you want to read about?"
                  onKeyDown={triggerSearch}
                  onChange={(e) => setSearchText(e.target.value)}
                />

                {searchText.length > 0 && (
                  <svg
                    onClick={clearSearch}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.8002 5.19995L5.2002 14.8M5.2002 5.19995L14.8002 14.8"
                      stroke="#6B9CBB"
                      stroke-width="1.6"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                )}
              </section>

              {/* Category Section */}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <section className="categories">
                  <h2 className="z-2 ">Browse through our categories</h2>

                  <div className="d-flex cat_list flex-wrap">
                    {categories.map((topics) => (
                      <button
                        key={topics.id}
                        className={`topic_btn  ${
                          topics.id === category ? "btn_selected" : undefined
                        } `}
                        onClick={() => searchCategory(topics.id, topics.name)}
                      >
                        {topics.name}
                      </button>
                    ))}
                  </div>
                </section>

                {/* Blogs Section */}

                <div
                  className="d-flex blog_pins z-2 "
                  style={{ paddingBottom: "30px" }}
                >
                  <BlogSection
                    Blogs={CategoricalData ? CategoricalData : blogs}
                    page={page}
                  />
                  <PinSection PinPostData={pinBlogs} />
                </div>

                {/* Pagination component */}

                <PaginationComp
                  total={
                    CategoricalData ? CategoricalData.length : blogs.length
                  }
                  handleChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <BannerTutor10x />
      </div>
    </div>
  );
};

export default Blogs;
