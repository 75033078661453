import React from 'react'
import styles from './testomonialCard.module.css'
import StarIcon from '../../assets/images/CalendarV2/StarIcon'
const TestomonialCard = ({ image, name, position, review, stars }) => {

    return (
        <div className={styles.testomonialCard}>
            <div className={styles.reviewerInfo}>
                <div className={styles.reviewerImage} style={{ background: image }}>
                    {/* <img src="https://images.pexels.com/photos/28859194/pexels-photo-28859194/free-photo-of-bright-yellow-bird-on-a-wire-with-lush-green-background.jpeg?auto=compress&cs=tinysrgb&w=800" alt="" /> */}
                    <div>
                        {name.split(" ")[0][0]}{name.split(" ")[1] && name.split(" ")[1][0]}
                    </div>
                </div>
                <div className={styles.reviewerName}>
                    <div>{name}</div>
                    <div>{position}</div>
                </div>
            </div>
            {/* <div className={styles.starsContainer}>
                {
                    ["", "", "", "", ""].map((el, idx) => {
                        console.log("jdj")
                        return <div style={{ color: idx < stars ? "#F2CB00" : "#E9EBEF" }}><StarIcon /></div>
                    })
                }
            </div> */}
            <div className={styles.review}>
                {review}
            </div>
        </div>
    )
}

export default TestomonialCard