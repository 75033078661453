import React from 'react'
import styles from './brandCarousel.module.css'
import nextPrep from '../../assets/images/CalendarV2/NextPrep.svg'
import ntpa from '../../assets/images/CalendarV2/NTPA.svg'
import reevesTutor from '../../assets/images/CalendarV2/ReevesTutor.svg'
import sevenSquare from '../../assets/images/CalendarV2/SeveSquare.svg'
import testPrep from '../../assets/images/CalendarV2/TestPrep.svg'


const BrandCarousel = () => {
    return (
        <div className={styles.brandCarouselContainer}>
            <h1 className={styles.titleHeading}>TRUSTED BY LEADING TUTORING BUSINESSES OF ALL SIZES</h1>
            <div className={styles.logosContainer}>
                <div className={styles.logos}>
                    <div className={styles.logos_slide}>
                        <div className={styles.slide}>
                            <img src={testPrep} alt="" />
                        </div>
                        <div className={styles.slide}>
                            <img src={ntpa} alt="" />
                        </div>
                        <div className={styles.slide}>
                            <img src={nextPrep} alt="" />
                        </div>
                        <div className={styles.slide}>
                            <img src={reevesTutor} alt="" />
                        </div>
                        <div className={styles.slide}>
                            <img src={sevenSquare} alt="" />
                        </div>
                    </div>
                    <div className={styles.logos_slide}>
                        <div className={styles.slide}>
                            <img src={testPrep} alt="" />
                        </div>
                        <div className={styles.slide}>
                            <img src={ntpa} alt="" />
                        </div>
                        <div className={styles.slide}>
                            <img src={nextPrep} alt="" />
                        </div>
                        <div className={styles.slide}>
                            <img src={reevesTutor} alt="" />
                        </div>
                        <div className={styles.slide}>
                            <img src={sevenSquare} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.middleBorder}></div>
            <div className={styles.brandStatsCardContainer}>
                <div className={styles.brandStatsCard}>
                    <div>200+</div>
                    <div>Businesses Onboarded</div>
                </div>
                <div className={styles.brandStatsCard}>
                    <div>1000+</div>
                    <div>Service Providers</div>
                </div>
                <div className={styles.brandStatsCard}>
                    <div>4.8
                        <svg className={styles.bigStar} width="29" height="27" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5 1L18.6715 9.22818L28 10.5557L21.25 16.9569L22.843 26L14.5 21.7282L6.157 26L7.75 16.9569L1 10.5557L10.3285 9.22818L14.5 1Z" fill="#25335A" stroke="#25335A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <svg className={styles.smallStar} width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 1L7.545 3.96215L11 4.44006L8.5 6.74448L9.09 10L6 8.46215L2.91 10L3.5 6.74448L1 4.44006L4.455 3.96215L6 1Z" fill="#25335A" stroke="#25335A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>


                    </div>
                    <div>RATING</div>
                </div>
            </div>
        </div>
    )
}

export default BrandCarousel