import React from "react";
import BlogBanner from "../BlogBanner/BlogBanner";
import "./blogsection.css";

const BlogSection = ({ Blogs, page }) => {



  return (
    <div className="blog_section d-flex gap-4 z-2 flex-column ">
      <h4>Latest Posts</h4>
       {Blogs.length > 0 && Blogs?.slice(page * 4 - 4, page * 4).map((blog) => (
        <BlogBanner key={blog._id} category={blog.categories} heading={blog.title} date={blog.date} Reading_Time={blog.readTime} Summary={blog.seoDescription} id={blog._id} link={blog.customURL} image={blog.img} />
      ))}
    </div>
  );
};

export default BlogSection;
