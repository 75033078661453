import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { CreateUser, GetAllUsers, DeleteUser } from "./WhitelistAction";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { whiteListSchema } from "../../../validations/whitelistValidation";
import "./WhitelistUser.css";

const WhitelistUser = () => {
  const [whiteList, setWhiteList] = useState([]);
  const [Error, setError] = useState();

  const getAllUsers = async () => {
    let response = await GetAllUsers();
    if (response) {
      setWhiteList(response.WhitelistEmails);
    }
  };


  useEffect(() => {
    try {
      getAllUsers();
    } catch (error) {
      toast.error(error.message, "Error fetching users");
    }
  }, []);
  console.log(whiteList);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(whiteListSchema),
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      if (data) {
        let response = await CreateUser(data);
        if (response) {
          toast.success("User Created successfully.");
          await getAllUsers();
        }
      }
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError(err.message);
      }
    }
  };
  const handleDeleteUser = async (id) => {
    try {
      console.log(id);
      let response = await DeleteUser(id);
      if (response) {
        toast.success("User Deleted successfully.");
        await getAllUsers();
      }
    } catch (error) {
      setError(error);
    }
  };

  return (
    <div className="">
      <div className="create-user-container">
        <div className="py-4">
          <form className="" onSubmit={handleSubmit(onSubmit)}>
            <div className="m-2 ">
              <h2 className="py-3">Create New User</h2>
            </div>
            <div className="d-flex  justify-content-evenly align-items-center mt-5 ">
              <div>
                <label className="d-flex flex-column" htmlFor="email">
                  Email
                  <input
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email"
                    {...register("email")}
                  />
                </label>
                <div
                  className="invalid-feedback  text-output-error text-xs px-2 pt-1"
                  style={errors.email ? { display: "block" } : {}}
                >
                  {errors.email?.message}
                </div>
              </div>
              <div>
                <label className="d-flex flex-column" htmlFor="role">
                  Role
                  <select name="role" {...register("role")} className="">
                    <option value="admin">Admin</option>
                    <option value="contributor">Contributor</option>
                  </select>
                  <div
                    className="invalid-feedback  text-output-error text-xs px-2 pt-1"
                    style={errors.role ? { display: "block" } : {}}
                  >
                    {errors.role?.message}
                  </div>
                </label>
              </div>

              <div>
                <button className="submit-btn" type="submit">
                  Create
                </button>
              </div>
            </div>
          </form>
          <div
            className="invalid-feedback text-center text-output-error text-xs px-2 py-2  pt-1 "
            style={Error ? { display: "block" } : {}}
          >
            {Error ? Error : null}
          </div>
        </div>
      </div>
      <div className="users-table-container">
        <div className=" users-table-block w-100 max-h-112 d-flex flex-column justify-content-start align-items-start mb-0 pb-0">
          <table className=" table w-100 h-100 table-light text-justify mb-0 pb-0 rounded overflow-auto">
            <thead>
              <tr className="table-secondary">
                <td>User Email</td> <td>Role</td> <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {whiteList.map((item, index) => (
                <tr key={index}>
                  <td>{item.email}</td>
                  <td>{item.role}</td>
                  <td>
                    <button
                      key={item._id}
                      className="btn btn-danger btn-sm rounded"
                      onClick={() => {
                        handleDeleteUser(item._id);
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default WhitelistUser;
