import { useEffect } from 'react'
import styles from './ContentTerms.module.css'
import './ContentTermsGlobal.css'

const htmlString = `<div id="root_div">
<p class="p3"><span class="s2">These terms and conditions govern the licensing of Creator Content by the Creators to Teaching Organisations <b>("Content Terms"</b>).</span></p>
<p class="p3"><span class="s2">Any capitalised terms used herein and not defined explicitly shall have the same meaning as defined in the Terms of Use, as amended from time to time.</span></p>
<p class="p3"><span class="s2">The Users understand that Sevenimagine Education Private Limited (“<b>Evallo</b>”) provides an intermediary platform and shall only facilitate licensing of Creator Content on the Platform by the Creator to the Teaching Organisation, in accordance with these Content Terms. For the purposes of the Content Terms ‘you’, ‘your’ shall refer to the Creator as a User of the Platform.</span></p>
<h1 style="margin: 0.0px 0.0px 0.0px 24.0px; text-indent: -24.0px; font: 14.7px 'Times New Roman'; color: #000000; color: rgba(0, 0, 0, 0.85); -webkit-text-stroke: rgba(0, 0, 0, 0.85)"><span class="s2"><b>1.</b></span><span class="s4">     </span><span class="s2"><b>LICENSING CREATOR CONTENT</b></span></h1>
<p class="p5"><span class="s2"> </span></p>
<p class="p6"><span class="s2">1.1.</span><span class="s4">   </span><span class="s2">In order to use the Platform, you must create an account, as required by Evallo (“<b>Creator Account</b>”). You must provide accurate and complete information and keep your Creator Account information updated. The Creator may thereafter publish their Creator Content and set the Content Consideration in accordance with Section 2.</span></p>
<p class="p6"><span class="s2"> </span></p>
<p class="p6"><span class="s2">1.2.</span><span class="s4">   </span><span class="s2">For publishing the Creator Content, you may share it in appropriate format with Evallo via email at </span><span class="s3"><b><a>support@evallo.org</a></b></span><span class="s2">.</span></p>
<p class="p6"><span class="s2"> </span></p>
<p class="p6"><span class="s2">1.3.</span><span class="s4">   </span><span class="s2">Teaching Organisations can browse and select Creator Content for licensing from available options.</span></p>
<p class="p7"><span class="s2"> </span></p>
<p class="p6"><span class="s2">1.4.</span><span class="s4">   </span><span class="s2">Teaching Organisations may purchase licenses to the Creator Content by paying the Content Consideration determined on the number of licenses purchased and the validity of the license.</span></p>
<p class="p7"><span class="s2"> </span></p>
<p class="p6"><span class="s2">1.5.</span><span class="s4">   </span><span class="s2">Teaching Organisation can use the licensed Creator Content exclusively for their students served on the Evallo Platform.</span></p>
<h1 style="margin: 0.0px 0.0px 0.0px 24.0px; text-indent: -24.0px; font: 14.7px 'Times New Roman'; color: #000000; color: rgba(0, 0, 0, 0.85); -webkit-text-stroke: rgba(0, 0, 0, 0.85)"><span class="s2"><b>2.</b></span><span class="s4">     </span><span class="s2"><b>CONSIDERATION</b></span></h1>
<p class="p8"><span class="s2"> </span></p>
<p class="p6"><span class="s2">2.1.</span><span class="s4">   </span><span class="s2">You are solely responsible for determining the price (“<b>Content Consideration</b>”) you charge the Teaching Organisations for use and/or access to your Creator Content that you offer through the Platform, and for any refund policy you set regarding the licensing or use of your Creator Content.</span></p>
<p class="p6"><span class="s2"> </span></p>
<p class="p6"><span class="s2">2.2.</span><span class="s4">   </span><span class="s2">The Content Consideration charged by the Creator for a Creator Content shall not be higher than the consideration charged for the same/similar content on any other platform.</span></p>
<p class="p7"><span class="s2"> </span></p>
<p class="p6"><span class="s2">2.3.</span><span class="s4">   </span><span class="s2">Evallo shall charge a platform fee, equal to 20% of the Content Consideration for each transaction performed through the Platform.</span></p>
<p class="p7"><span class="s2"> </span></p>
<p class="p6"><span class="s2">2.4.</span><span class="s4">   </span><span class="s2">Evallo may, from time to time, prescribe additional guidelines regarding the manner in which Content Consideration may be determined by the Creator.</span></p>
<p class="p6"><span class="s2"> </span></p>
<h1 style="margin: 0.0px 0.0px 0.0px 24.0px; text-indent: -24.0px; font: 14.7px 'Times New Roman'; color: #000000; color: rgba(0, 0, 0, 0.85); -webkit-text-stroke: rgba(0, 0, 0, 0.85)"><span class="s2"><b>3.</b></span><span class="s4">     </span><span class="s5"><b>‍</b></span><span class="s2"><b> RIGHTS AND RESPONSIBILITIES FOR CREATOR CONTENT‍</b></span></h1>
<p class="p9"><span class="s2"> </span></p>
<p class="p6"><span class="s2">3.1.</span><span class="s4">   </span><span class="s2">You acknowledge and agree that any contract of sale/license made through the Platform is directly between you and the Teaching Organisation. You are the seller/licensor of record for all courses and other offerings you sell through the Platform. You are responsible for creating and providing your Creator Content through the Platform, and all aspects of the transactions between you and the Teaching Organisation, other than as expressly set out in the Content Terms. This includes, without limitation, refunds, fulfilling any sales or customer service obligations, fraudulent transactions, required legal disclosures, regulatory compliance, alleged or actual violation of applicable laws (including but not limited to consumer protection laws in any jurisdiction where you offer products or services for sale), or your breach of the Terms of Use.</span></p>
<p class="p9"><span class="s2"> </span></p>
<p class="p6"><span class="s2">3.2.</span><span class="s4">   </span><span class="s2">You may not use the Platform for any illegal or unauthorized purpose, nor may you, in the use of the Platform, violate any applicable laws. You will comply with all applicable laws, rules, and regulations (including but not limited to obtaining and complying with requirements of any license or permit that may be necessary to sell/license the Creator Content on the Platform) in your use of the Platform and your performance of the obligations under the Terms of Use. </span></p>
<p class="p6"><span class="s2"> </span></p>
<p class="p6"><span class="s2">3.3.</span><span class="s4">   </span><span class="s2">Evallo is not responsible for any actions you take concerning your Creator Content, including sharing it publicly, and we assume no liability for your interactions with the Teaching Organisations who are your customers on the Platform. Evallo reserves the right but has no obligation to monitor disputes between you and such customer Teaching Organisations. Evallo is not obligated to monitor or review any Creator Content submitted to the Platform, nor do we mandatorily pre-screen any Creator Content.</span></p>
<h1 style="margin: 0.0px 0.0px 0.0px 24.0px; text-indent: -24.0px; font: 14.7px 'Times New Roman'; color: #000000; color: rgba(0, 0, 0, 0.85); -webkit-text-stroke: rgba(0, 0, 0, 0.85)"><span class="s2"><b>4.</b></span><span class="s4">     </span><span class="s2"><b>INTELLECTUAL PROPERTY RIGHTS</b></span></h1>
<p class="p6"><span class="s2"> </span></p>
<p class="p6"><span class="s2">4.1.</span><span class="s4">   </span><span class="s2">You shall retain ownership of all the intellectual property rights in your Creator Content. Evallo does not claim ownership over any of your Creator Content. You are responsible for your Creator Content and the consequences of posting or publishing it. The Terms of Use do not grant Evallo any licenses or rights to your Creator Content except for the limited rights needed for Evallo to provide the Platform to you, and as otherwise described in the Terms of Use. Should any of your Creator Content involve third-party content, you agree that Evallo is not responsible in any way for such content and makes no representations or warranties as to such third-party content.</span></p>
<p class="p6"><span class="s2"> </span></p>
<p class="p6"><span class="s2">4.2.</span><span class="s4">   </span><span class="s2">While Evallo does not claim any intellectual property rights over your Creator Content, by uploading the Creator Content on the Platform, you grant Evallo a worldwide, royalty-free license to host, display, distribute, make publicly available, store, to operate, provide, and improve the Platform, to promote Evallo and the Platform, and to otherwise perform our obligations and exercise our rights under the Terms of Use and as otherwise permitted by the <a href="https://evallo.org/privacy-policy"><span class="s6">Privacy Policy</span></a>. This license continues even after you stop using our Platform for aggregate and anonymised data derived from your Creator Content and any residual backup copies of your Creator Content made in the ordinary course of our business. This license also extends to any third parties providers we work with to the extent necessary to provide the Platform to you. You understand that your Content may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to the technical requirements of connecting networks or devices.</span></p>
<p class="p7"><span class="s2"> </span></p>
<p class="p6"><span class="s2">4.3.</span><span class="s4">   </span><span class="s2">You grant Evallo a non-exclusive, transferable, sub-licensable, royalty-free, worldwide right and license to use the names, trademarks, service marks, and logos associated with your Creator Content to operate, provide, market, and promote the Platform and to perform our obligations and exercise our rights under the Terms of Use. This license will survive any termination of the Terms of Use solely to the extent that Evallo requires the license to exercise any rights or perform any obligations that arise under the Terms of Use.</span></p>
<p class="p6"><span class="s2"> </span></p>
<p class="p6"><span class="s2">4.4.</span><span class="s4">   </span><span class="s2">‍Evallo fully respects the intellectual property rights of others, and we expect our users to do the same. If we receive a complaint that your Creator Content infringes on a third party’s intellectual property rights, we shall remove and delist it from the Platform.</span></p>
<h1 style="margin: 0.0px 0.0px 0.0px 24.0px; text-indent: -24.0px; font: 14.7px 'Times New Roman'; color: #000000; color: rgba(0, 0, 0, 0.85); -webkit-text-stroke: rgba(0, 0, 0, 0.85)"><span class="s2"><b>5.</b></span><span class="s4">     </span><span class="s2"><b>CREATOR REPRESENTATIONS AND WARRANTIES</b></span></h1>
<p class="p5"><span class="s2"> </span></p>
<p class="p5"><span class="s2">You represent and warrant to Evallo that:</span></p>
<p class="p5"><span class="s2"> </span></p>
<p class="p6"><span class="s2">5.1.</span><span class="s4">   </span><span class="s2">you own or have the necessary licenses, rights, consents, or permissions to use, license or publish the Creator Content that you include, submit, through the Platform;</span></p>
<p class="p6"><span class="s2"> </span></p>
<p class="p6"><span class="s2">5.2.</span><span class="s4">   </span><span class="s2">none of your Creator Content or a Teaching Organisations use of your Creator Content will violate any applicable laws, any third-party intellectual property, privacy, publicity, or other rights, or the <a href="https://evallo.org/tou"><span class="s6">Terms</span></a> of Use;</span></p>
<p class="p10"><span class="s2"> </span></p>
<p class="p6"><span class="s2">5.3.</span><span class="s4">   </span><span class="s2">you shall not imply to third-parties that your Creator Content is in any way sponsored, provided, or endorsed by Evallo;</span></p>
<p class="p10"><span class="s2"> </span></p>
<p class="p6"><span class="s2">5.4.</span><span class="s4">   </span><span class="s2">Your Creator Content will be true, accurate, and complete. For the avoidance of doubt, Evallo will not be the seller, licensor or merchant of record and will have no responsibility for your Creator Content offered through the Platform.</span></p>
<p class="p11"><span class="s2"><b> </b></span></p>
<h1 style="margin: 0.0px 0.0px 0.0px 24.0px; text-indent: -24.0px; font: 14.7px 'Times New Roman'; color: #000000; color: rgba(0, 0, 0, 0.85); -webkit-text-stroke: rgba(0, 0, 0, 0.85)"><span class="s2"><b>6.</b></span><span class="s4">     </span><span class="s2"><b>DISCLAIMER</b></span></h1>
<p class="p11"><span class="s2"><b> </b></span></p>
<p class="p6"><span class="s2">6.1.</span><span class="s4">   </span><span class="s2">The Platform and Services are provided by Evallo on an "as is" and "as available" basis. Evallo herein expressly disclaims all warranties of any kind, express or implied, as to the Creator Content being made available by Creators, or the information, content or materials included therein, including, but not limited to the implied warranties of merchantability, fitness for a particular purpose and non-infringement. Teaching Organisations expressly agree that their use of the Platform, their content, and any services or items obtained from Evallo is at their sole risk.</span></p>
<h1 style="margin: 0.0px 0.0px 0.0px 24.0px; text-indent: -24.0px; font: 14.7px 'Times New Roman'; color: #000000; color: rgba(0, 0, 0, 0.85); -webkit-text-stroke: rgba(0, 0, 0, 0.85)"><span class="s2"><b>7.</b></span><span class="s4">     </span><span class="s2"><b>TERMINATION</b></span></h1>
<p class="p8"><span class="s2"> </span></p>
<p class="p6"><span class="s2">7.1.</span><span class="s4">   </span><span class="s2">You can terminate, remove, or delist any of your Creator Content from the Platform at any time by providing a prior written notice of [</span><span class="s3"><b><i>30</i></b></span><span class="s2">] days, provided however that any Creator Content that is under an active valid license as on the date of termination shall be continued to be made available till the end of the concerned license term,</span></p>
<p class="p6"><span class="s2"> </span></p>
<p class="p6"><span class="s2">7.2.</span><span class="s4">   </span><span class="s2">You understand and agree that removing your Creator Content does not terminate any rights or licenses granted to the Creator Content that Evallo requires to exercise any rights or perform any obligations under these Terms. You agree that Evallo can, at any time, review and delete any or all of your Creator Content.</span></p>
<p class="p9"><span class="s2"> </span></p>
<p class="p6"><span class="s2">7.3.</span><span class="s4">   </span><span class="s2">Evallo may in its own discretion remove or delist any of the Creator Content if it is found to be in violation of applicable laws and/or the Terms of Use.</span></p>
<p class="p5"><span class="s2"> </span></p>
<h1 style="margin: 0.0px 0.0px 0.0px 24.0px; text-indent: -24.0px; font: 14.7px 'Times New Roman'; color: #000000; color: rgba(0, 0, 0, 0.85); -webkit-text-stroke: rgba(0, 0, 0, 0.85)"><span class="s2"><b>8.</b></span><span class="s4">     </span><span class="s2"><b>MODIFICATION OF CONTENT TERMS</b></span></h1>
<p class="p5"><span class="s2"> </span></p>
<p class="p5"><span class="s2">Evallo may modify these Content Terms, from time to time, and any such changes will be reflected on the Platform and be effective immediately upon the changes being reflected on the Platform. Users agree to be bound to any such changes or modifications and understand and accept the importance of regularly reviewing the Content Terms as updated on the Platform from time to time.</span></p>
<p class="p5"><span class="s2"> </span></p>
<h1 style="margin: 0.0px 0.0px 0.0px 24.0px; text-indent: -24.0px; font: 14.7px 'Times New Roman'; color: #000000; color: rgba(0, 0, 0, 0.85); -webkit-text-stroke: rgba(0, 0, 0, 0.85)"><span class="s2"><b>9.</b></span><span class="s4">     </span><span class="s2"><b>CONFLICT</b></span></h1>
<p class="p5"><span class="s2"> </span></p>
<p class="p5"><span class="s2">In the event of a conflict between these Content Terms and the Terms of Use, in relation to the licensing and use of the Creator Content, unless expressly provided otherwise herein, these Content Terms shall prevail.</span></p></div>`

const ContentTerms = (props) => {

    useEffect(() => {
        const parser = new DOMParser()
        const newDoc = parser.parseFromString(htmlString, "text/html")
        document.getElementById("inner_container_ct").innerHTML = newDoc.getElementById("root_div").innerHTML
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div id="content_terms_container" className='container-fluid mt-5 p-4 d-flex flex-column justify-content-center align-items-center'>
            <div className={styles.title}>Content Terms</div>
            <div id="inner_container_ct"></div>
        </div>
    )
}


export default ContentTerms