import React, { useEffect, useState } from 'react'
// import "./landing.css"
import "../Home/Home.css"
import styles from "./landing.module.css"

import bgGrid from "../../assets/backgrounds/reverse_grid.svg"
import LeadForm from '../../components/LeadForm/LeadForm'
import { ButtonFilled, ButtonHollow } from '../../components/Buttons'
import Card from '../../components/Card/Card'
import { redirectEvallo } from '../../Utils/utils'
import TitleTag from '../../components/titleTagBox/TitleTag'
import FAQSec from '../../components/FAQSec/FAQSec'
import SlideShow from '../../components/SlideShow/SlideShow'
import triangle from "../../assets/triangle.svg"
import triangleR from "../../assets/trianglRight.svg"


const Landing = () => {
    const [leadSubmited, setLeadSubmited] = useState(false)
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("NA")
    const [phone, setPhone] = useState("NA")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [isActive, setIsActive] = useState([true, false, false, false, false, false])

    useEffect(() => {
        setIsActive([true, false, false, false, false, false])
    }, [])

    const handleFaqClick = (e) => {
        const id = parseInt(e.target.id)
        let _isActive = isActive.map((val, idx) => false)
        console.log('Before', _isActive)
        _isActive[id] = !isActive[id]
        console.log("after", _isActive)
        setIsActive(_isActive)
    }

    const handleOutsideClick = (e) => {
        if (e.target.id === "cal_outer") {
            setLeadSubmited(false)
        }
    }



    return (
        <div className={styles.landing_container}>

            {/* <div className={`${styles.popup_calendly} ${!leadSubmited ? styles.hide_cal : ""}`} onClick={(e) => handleOutsideClick(e)} id={"cal_outer"}>
                <iframe src={`https://calendly.com/sales-p0mo/evallo-product-demo?hide_gdpr_banner=1&month=2024-04&date=2024-04-26&name=${firstName + "%20" + lastName}&email=${email}&a1=${phone != "NA" ? "1" + phone : ""}&a2=${message}`} frameborder="0"></iframe>
            </div> */}
            <div className={styles.page_container}>
                <div className={styles.landing_header}>
                    <h1>Your tutoring business, simplified. <br />
                        <span>Contact sales.</span> </h1>
                </div>
                <div className={styles.slide_form_container} style={{zIndex:"1"}} >
                    <div className={styles.slides}  > 
                        <SlideShow />
                        <div className={styles.tri_r}>
                            <img src={triangleR} alt="" />
                        </div>
                        <div className={styles.tri_l}>
                            <img src={triangle} alt="" />
                        </div>
                    </div>
                    <div className={styles.form_container} >

                        <LeadForm setLeadSubmited={setLeadSubmited} firstName={firstName} setFirstName={setFirstName} lastName={lastName} setLastName={setLastName} email={email} setEmail={setEmail} phone={phone} setPhone={setPhone} message={message} setMessage={setMessage} />
                        <div className={styles.tri_form}>
                            <img src={triangleR} alt="" />
                        </div>
                    </div>
                </div>
                <div className={styles.top_border}></div>
                <div className={styles.section_seven}>
                    <div className='row home-section-seven px-3 px-lg-2 pb-1 mx-lg-5 pe-lg-5 '>
                        <div className='col-12'>
                            <div className='d-flex justify-content-center align-items-center w-100'>
                                <span className='line'></span>
                                <span className='subheading-container-home'>measure your success</span>
                                <span className='line' style={{ transform: "rotate(180deg)" }}>   </span>
                            </div>
                        </div>
                        <div className='col-12 col-md-4 col-lg-5 d-flex justify-content-center ps-md-5 price-cont' >
                            <div className='home-container-success d-flex flex-column justify-content-start align-items-center align-items-md-start align-items-lg-start' style={{ gap: "12px" }}>
                                <div className='home-title-container-success'>
                                    <div className='home-title-success text-center text-md-start text-lg-start'>We Promise Better Business Outcomes</div>
                                </div>
                                <div className='home-description-container-success '>
                                    <div className='home-description-success text-center d-md-none'>After conducting hundreds of hours of customer interviews, we have realized that the value added by Evallo goes far beyond money.</div>
                                    <div className='home-description-success-lg d-md-block text-start'>After conducting hundreds of hours of customer interviews, we have realized that the value added by Evallo goes far beyond money.</div>
                                </div>
                                <ButtonFilled className="bh-lg" onClick={(e) => { redirectEvallo() }}>Try For Free</ButtonFilled>
                            </div>
                        </div>
                        <div className='col-md-2 col-lg-1 '></div>
                        <div className='col-12 col-md-6 col-lg-6 d-md-flex justify-content-md-center align-items-md-center d-lg-block cards-prices'>
                            <div className='row row-gap-2 price-cont' >
                                <div className='col-12 d-flex justify-content-start align-items-center ps-4 ps-lg-5 ms-lg-5 mb-md-3' style={{ gap: "20px" }}>
                                    <Card heading="72 Hours" desc="Saved Every Month" />
                                    <Card heading="3x" desc="Referral Tracking" />
                                </div>
                                <div className='col-12 d-flex justify-content-end align-items-center pe-4' style={{ gap: "20px" }}>
                                    <Card heading="$45,000" desc="Annual Savings" />
                                    <Card heading="100%" desc="Accurate Reports" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.lead_faq}>
                    <div className={styles.lead_faq_child}>
                        <TitleTag title={"FAQs"} />
                        <div className='position-relative Z-2 mb-5'>
                            <div className='home-faq-title-container col-12 d-flex flex-column justfy-content-between flex-lg-row justify-content-lg-center align-items-center '>
                                <div className='faq-title-1'>Still Curious?</div>
                                <div className='faq-title-2'>&nbsp;These might help...</div>
                            </div>

                            <div className='col-12 w-100 mt-5'>
                                <FAQSec margin={"mx-0"} question={"Do you offer unlimited student and parent accounts?"}
                                    answer={"Yes, we do! Nothing too complicated here - just go to the CRM and start adding students and parents via the “Bulk Upload” feature or the “Add New User” button. You can also directly share with your clients the unique sign-up link that comes with your account. To access that, simply visit your admin account Settings and click on the “Client Sign-Up” tab, under which you will find a link that students and parents can use to join your organization."}
                                    isActive={isActive} buttonId={0} handleFaqClick={handleFaqClick} />
                            </div>
                            <div className='col-12 mt-4'>
                                <FAQSec margin={"mx-0"} question={"Do you have curated content like practice DSAT® or Math drills?"}
                                    answer={"Yes, we do! After signing up as a tutoring business administrator, you will have the option to select your subscription type and to add any extensions, which include “Assignments”. You can choose to start your free trial and gain access to hundreds of question banks, worksheets, practice tests, and official answer keys - all available to directly assign to your students."}
                                    isActive={isActive} buttonId={1} handleFaqClick={handleFaqClick} />
                            </div>
                            <div className='col-12 mt-4'>
                                <FAQSec margin={"mx-0"} question={"What can I expect when I start using Evallo?"}
                                    answer=
                                    {<>
                                        When you start using Evallo to run your tutoring business, you can expect the following:
                                        <ul>
                                            <li>About 3-4 hours saved daily due to automations in score reporting and invoicing, along with advanced filters to quickly manage your users (students, parents, and tutors) and their schedules.</li>
                                            <li>Flexibility in managing your tutoring business policies through the “Organization Defaults” settings.</li>
                                            <li>About $45,000 saved yearly (for businesses in the United States) by reducing your Ops team size.</li>
                                            <li>Better referral tracking through our Referral Codes feature.</li>
                                            <li>100% accurate instant score reports that enhance your client’s service experience.</li>
                                            <li>Top-notch customer support with guaranteed response within 24 hours of raising a query</li>
                                        </ul>
                                    </>}
                                    isActive={isActive} buttonId={2} handleFaqClick={handleFaqClick} />
                            </div>
                            <div className='col-12 mt-4'>
                                <FAQSec margin={"mx-0"} question={"What advantage do Evallo’s dashboards offer?"}
                                    answer={"Evallo’s CRM functionality allows for detailed customer profiles, aiding in understanding clients better. This, in turn, facilitates personalized tutoring experiences, ultimately enhancing customer interactions."}
                                    isActive={isActive} buttonId={3} handleFaqClick={handleFaqClick} />
                            </div>
                            <div className='col-12 mt-4'>
                                <FAQSec margin={"mx-0"} question={"Can Evallo serve individual tutors as well as large-scale tutoring companies?"}
                                    answer={"Yes, we can service tutoring businesses of all sizes. We understand that enterprises carry a lot of momentum, so we provide additional support to large businesses in transferring to Evallo. For individual tutors and SMBs, we provide a faster onboarding experience and a complete step-by-step process on how they can use Evallo to scale up."}
                                    isActive={isActive} buttonId={4} handleFaqClick={handleFaqClick} />
                            </div>
                            <div className='col-12 mt-4'>
                                <FAQSec margin={"mx-0"} question={"Why is Evallo better than anything else out there?"}
                                    answer={<>
                                        Evallo is the best tutoring management platform for the following reasons:
                                        <ul>
                                            <li>Any tutoring business requires three key verticals to run efficiently - Operations, Content, and Marketing. While most platforms are limited to one, Evallo is a comprehensive solution that addresses all three verticals efficiently. </li>
                                            <li>Our user interface is friendly, easy to learn, and scalable. </li>
                                            <li>Our customer support and onboarding tutorials is top-notch.</li>
                                            <li>Our platform is fast, secure, and simple.</li>
                                            <li>Our pricing is competitive, especially if you consider content.</li>
                                        </ul>
                                    </>}
                                    isActive={isActive} buttonId={5} handleFaqClick={handleFaqClick} />
                            </div>
                            <div className='col-12 mt-4'>
                                <FAQSec margin={"mx-0"} question={"How can I trust my business with Evallo?"}
                                    answer={`Evallo was built by tutors, for tutors. If you are facing a pain point right now in running your business, there is a good chance that the founders of Evallo have gone through something similar as well. Thousands of hours of research, hundreds of customer interviews, multiple rounds of peer review, and months of careful development has led to Evallo’s robust offerings. With us, you are guaranteed to receive top-class service, support, and pampering. We are collaborative by nature.`}
                                    isActive={isActive} buttonId={6} handleFaqClick={handleFaqClick} />
                            </div>

                        </div>
                    </div>
                </div>


            </div>

        </div>
    )
}

export default Landing