import styles from './FeaturesCardSmall.module.css'

/**
 * 
 * @param {{heading : string, description : string, img : object}} param0 
 * @returns 
 */
export default function FeatureCardSmall({heading, description, img}) {
    return (
        <div className={'d-flex flex-column align-items-center align-items-start '+ styles.container}>
            <div className={`d-flex flex-column align-items-start justify-content-start gap-2`}>
                <div className={styles.heading}>
                    {heading}
                </div>
                <div className={styles.description}>
                    {description}
                </div>
            </div>
            <img src={img} className={styles.image} />
        </div>
    )
}