import logo from './logo.svg';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import Stage from './pages/Staging/Stage';
import Pricing from './pages/Pricing/Pricing';
import AboutUs from './pages/AboutUs/AboutUs';
import Support from './pages/support/Support';
import NotFound from './pages/NotFound/NotFound';
import AllFeatures from './pages/AllFeatures';
import Docs from './pages/Docs';
import Mirach from './pages/Mirach';
import TermsOfUse from './pages/TermsOfUse/TermsOfUse';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import { useEffect } from 'react';
import ContentTerms from './pages/ContentTerms/ContentTerms';
import { ToastContainer } from 'react-toastify';
import Landing from './pages/LandingScheduling/Landing';
import PricingV2 from './pages/Pricing/PricingV2';
import Intercom from '@intercom/messenger-js-sdk';
import Blogs from './pages/Blogs/Blogs';
import PageDescription from './pages/Page Description/PageDescription';
import ContactUs from './pages/ContactUs/ContactUs';

function App() {



  console.log("first")
  useEffect(()=>{
    Intercom({
      app_id: 'fnycoofm',
    });
  },[])


  return (
    <>
      <ToastContainer style={{zIndex:"200"}} />
      <BrowserRouter>
        <Routes>
          {/*Paths for the marketing website */}
          <Route path='/' element={<Stage />}>
            <Route index element={<Home />}></Route>
            <Route path='home' element={<Home />}></Route>
            {/* <Route path='pricing' element={<Pricing />}></Route> */}
            <Route path='pricing' element={<PricingV2 />}></Route>
            <Route path='about' element={<AboutUs />}></Route>
            <Route path='features/*' element={<AllFeatures />}></Route>
            <Route path='support' element={<Support />}></Route>
            <Route path='contact-us' element={<ContactUs />}></Route>
            <Route path="book-demo/*" element={<Landing />}></Route>
            <Route path='tou' element={<TermsOfUse />}></Route>
            <Route path="privacy-policy" element={<PrivacyPolicy />}></Route>
            <Route path="content-terms" element={<ContentTerms />}></Route>
            <Route path="blogs" element={<Blogs/>}> </Route>
            <Route path="blogs/:id?" element={<PageDescription/>}> </Route>
          </Route>
          {/*Paths for the documentation section */}
          <Route path='docs/:preview?/:module?/:section?/:docId?' element={<Docs />}></Route>
          {/*Paths for the documenatation upload section */}
          <Route path='mirach/*' element={<Mirach />}></Route>
          <Route path='*' element={<NotFound />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
