import React from 'react'
import styles from "./priceTableCard.module.css"
import { colors } from '@mui/material'

const PriceTableCard = ({ bundle, isYearly, idx, currentSlide }) => {
    return (
        <div className={styles.bundle_card} style={{ display: `${idx === currentSlide ? "block" : "none"}` }}>
            <div className={styles.bundle_title}>
                <div>
                    {bundle.icon}
                    <h2>{bundle.title}</h2>
                </div>
                <div>
                    {bundle.desc}
                </div>
            </div>
            <div className={styles.bundle_table}>
                <div className={styles.table_head}>
                    <div>{bundle.limitTitle}</div>
                    <div>Amt</div>
                </div>
                {
                    bundle.limits.map((el, i) => {
                        return <div>
                            <div>{el.quantity}</div>
                            <div>
                                {
                                    isYearly ?
                                        <div> <strike className='' >${el.cost}</strike> ${Math.round(el.cost * .85).toFixed(0)}</div>
                                        :
                                        <div>{el.cost != 0 ? "$" + el.cost : "Free"}</div>
                                }
                            </div>
                        </div>
                    })
                }
                {/* <div>
                    <div>100</div>
                    <div>Free</div>
                </div>
                <div>
                    <div>250</div>
                    <div>$4</div>
                </div>
                <div>
                    <div>999</div>
                    <div>$9</div>
                </div>
                <div>
                    <div>Unlimited</div>
                    <div>$29</div>
                </div> */}
            </div>
            <div className={styles.additional}>
                {bundle.additional}
            </div>
        </div>
    )
}

export default PriceTableCard