import React from 'react'

const leftArrowIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0094 11.1539L13.3844 14.5289C13.543 14.6875 13.6224 14.8894 13.6224 15.1346C13.6224 15.3798 13.543 15.5817 13.3844 15.7404C13.2257 15.8991 13.0238 15.9784 12.7786 15.9784C12.5334 15.9784 12.3315 15.8991 12.1729 15.7404L8.19208 11.7596C8.10554 11.6731 8.04439 11.5793 8.00862 11.4784C7.97285 11.3774 7.95468 11.2692 7.9541 11.1539C7.9541 11.0385 7.97227 10.9303 8.00862 10.8293C8.04497 10.7284 8.10612 10.6346 8.19208 10.5481L12.1729 6.56733C12.3315 6.40867 12.5334 6.32935 12.7786 6.32935C13.0238 6.32935 13.2257 6.40867 13.3844 6.56733C13.543 6.72598 13.6224 6.9279 13.6224 7.1731C13.6224 7.41829 13.543 7.62021 13.3844 7.77887L10.0094 11.1539Z" fill="black" />
        </svg>
    )
}

export default leftArrowIcon