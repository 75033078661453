import { useEffect } from 'react'
import styles from './meteorites.module.css'


export default function Meteorites(props) {
    return (
        <>
            <div class={`d-flex flex-row justify-content-start align-items-center ${styles["meteorite-1"]}`}>
                <div class={styles.meteor}></div><span class={styles.trail}></span>
            </div>

            <div class={`d-flex flex-row justify-content-start align-items-center ${styles["meteorite-2"]}`}>
                <div class={styles.meteor}></div><span class={styles.trail}></span>
            </div>

            <div class={`d-flex flex-row justify-content-start align-items-center ${styles["meteorite-3"]}`}>
                <div class={styles.meteor}></div><span class={styles.trail}></span>
            </div>

            <div class={`d-flex flex-row justify-content-start align-items-center  ${styles["meteorite-4"]}`}>
                <div class={styles.meteor}></div><span class={styles.trail}></span>
            </div>

            <div class={`d-flex flex-row justify-content-start align-items-center ${styles["meteorite-5"]}`}>
                <div class={styles.meteor}></div><span class={styles.trail}></span>
            </div>

            <div class={`d-flex flex-row justify-content-start align-items-center ${styles["meteorite-6"]}`}>
                <div class={styles.meteor}></div><span class={styles.trail}></span>
            </div>

            <div class={`d-flex flex-row justify-content-start align-items-center  ${styles["meteorite-7"]}`}>
                <div class={styles.meteor}></div><span class={styles.trail}></span>
            </div>

            <div class={`d-flex flex-row justify-content-start align-items-center  ${styles["meteorite-8"]}`}>
                <div class={styles.meteor}></div><span class={styles.trail}></span>
            </div>
            <div class={`d-flex flex-row justify-content-start align-items-center  ${styles["meteorite-9"]}`}>
                <div class={styles.meteor}></div><span class={styles.trail}></span>
            </div>

            <div class={`d-flex flex-row justify-content-start align-items-center  ${styles["meteorite-10"]}`}>
                <div class={styles.meteor}></div><span class={styles.trail}></span>
            </div>
            <div class={`d-flex flex-row justify-content-start align-items-center  ${styles["meteorite-11"]}`}>
                <div class={styles.meteor}></div><span class={styles.trail}></span>
            </div>

            <div class={`d-flex flex-row justify-content-start align-items-center  ${styles["meteorite-12"]}`}>
                <div class={styles.meteor}></div><span class={styles.trail}></span>
            </div></>
    )
}