import React, { useEffect, useState, useRef } from "react";
import "./feature.css";
import { fetchBlogsPublished } from "../../../../Utils/utils";
import { FeatureBlog } from "../FeatureBlogs/FeatureBlog";

const FeaturedComponent = ({ id }) => {
  const [blogs, setAllBlogs] = useState([]);
  const featureBlogsRef = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        const result = await fetchBlogsPublished();
        setAllBlogs(result);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const scrollLeft = () => {
    featureBlogsRef.current.scrollBy({ left: -200, behavior: "smooth" });
  };

  const scrollRight = () => {
    featureBlogsRef.current.scrollBy({ left: 200, behavior: "smooth" });
  };

  return (
    <div className="feature_main">
      <div className="feature_main2">
        <section className="feature_heading">
          <h2>Related posts you can read</h2>
          <div className="feature_move">
            <button onClick={scrollLeft}>
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.80078 5.75664L6.70078 9.65664C6.88411 9.83997 6.97578 10.0733 6.97578 10.3566C6.97578 10.64 6.88411 10.8733 6.70078 11.0566C6.51745 11.24 6.28411 11.3316 6.00078 11.3316C5.71745 11.3316 5.48411 11.24 5.30078 11.0566L0.700781 6.45664C0.600781 6.35664 0.530114 6.24831 0.488781 6.13164C0.447448 6.01497 0.426448 5.88997 0.425781 5.75664C0.425781 5.62331 0.446781 5.49831 0.488781 5.38164C0.530781 5.26497 0.601448 5.15664 0.700781 5.05664L5.30078 0.45664C5.48411 0.273307 5.71745 0.181641 6.00078 0.181641C6.28411 0.181641 6.51745 0.273307 6.70078 0.45664C6.88411 0.639974 6.97578 0.873307 6.97578 1.15664C6.97578 1.43997 6.88411 1.67331 6.70078 1.85664L2.80078 5.75664Z"
                  fill="black"
                />
              </svg>
            </button>
            <button onClick={scrollRight}>
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.19922 5.75664L0.299219 9.65664C0.115886 9.83997 0.0242186 10.0733 0.0242186 10.3566C0.0242186 10.64 0.115886 10.8733 0.299219 11.0566C0.482553 11.24 0.715886 11.3316 0.999219 11.3316C1.28255 11.3316 1.51589 11.24 1.69922 11.0566L6.29922 6.45664C6.39922 6.35664 6.46989 6.24831 6.51122 6.13164C6.55255 6.01497 6.57355 5.88997 6.57422 5.75664C6.57422 5.62331 6.55322 5.49831 6.51122 5.38164C6.46922 5.26497 6.39855 5.15664 6.29922 5.05664L1.69922 0.45664C1.51589 0.273307 1.28255 0.181641 0.999219 0.181641C0.715886 0.181641 0.482553 0.273307 0.299219 0.45664C0.115886 0.639974 0.0242186 0.873307 0.0242186 1.15664C0.0242186 1.43997 0.115886 1.67331 0.299219 1.85664L4.19922 5.75664Z"
                  fill="black"
                />
              </svg>
            </button>
          </div>
        </section>

        <section className="feature_blogs2" ref={featureBlogsRef}>
          {blogs &&
            blogs.map((blog) => {
              if (blog._id != id) {
                return (
                  <FeatureBlog
                    key={blog._id}
                    category={blog.categories}
                    heading={blog.title}
                    date={blog.date}
                    Reading_Time={blog.readTime}
                    Summary={blog.seoDescription}
                    id={blog._id}
                    link={blog.customURL}
                    image={blog.img}
                  />
                );
              }
            })}
        </section>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="feature_move2">
            <button onClick={scrollLeft}>
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.80078 5.75664L6.70078 9.65664C6.88411 9.83997 6.97578 10.0733 6.97578 10.3566C6.97578 10.64 6.88411 10.8733 6.70078 11.0566C6.51745 11.24 6.28411 11.3316 6.00078 11.3316C5.71745 11.3316 5.48411 11.24 5.30078 11.0566L0.700781 6.45664C0.600781 6.35664 0.530114 6.24831 0.488781 6.13164C0.447448 6.01497 0.426448 5.88997 0.425781 5.75664C0.425781 5.62331 0.446781 5.49831 0.488781 5.38164C0.530781 5.26497 0.601448 5.15664 0.700781 5.05664L5.30078 0.45664C5.48411 0.273307 5.71745 0.181641 6.00078 0.181641C6.28411 0.181641 6.51745 0.273307 6.70078 0.45664C6.88411 0.639974 6.97578 0.873307 6.97578 1.15664C6.97578 1.43997 6.88411 1.67331 6.70078 1.85664L2.80078 5.75664Z"
                  fill="black"
                />
              </svg>
            </button>
            <button onClick={scrollRight}>
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.19922 5.75664L0.299219 9.65664C0.115886 9.83997 0.0242186 10.0733 0.0242186 10.3566C0.0242186 10.64 0.115886 10.8733 0.299219 11.0566C0.482553 11.24 0.715886 11.3316 0.999219 11.3316C1.28255 11.3316 1.51589 11.24 1.69922 11.0566L6.29922 6.45664C6.39922 6.35664 6.46989 6.24831 6.51122 6.13164C6.55255 6.01497 6.57355 5.88997 6.57422 5.75664C6.57422 5.62331 6.55322 5.49831 6.51122 5.38164C6.46922 5.26497 6.39855 5.15664 6.29922 5.05664L1.69922 0.45664C1.51589 0.273307 1.28255 0.181641 0.999219 0.181641C0.715886 0.181641 0.482553 0.273307 0.299219 0.45664C0.115886 0.639974 0.0242186 0.873307 0.0242186 1.15664C0.0242186 1.43997 0.115886 1.67331 0.299219 1.85664L4.19922 5.75664Z"
                  fill="black"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedComponent;
