import React from 'react'
import "./cardWithPadding.css"
import useScrollAnimation from '../../../Hooks/useScrollAnimation'

const CardWithPadding = ({ title, desc, image }) => {

    useScrollAnimation("padding-card-init", "popIn", 0.5)

    return (
        <div className='col-lg d-flex flex-column justify-content-between padding-card-init padding-cards'>
            <div>
                <h2>{title}</h2>
                <p>{desc}</p>
            </div>
            <div><img src={image} alt="" /></div>
        </div>
    )
}

export default CardWithPadding