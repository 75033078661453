import { useEffect } from "react";


export default function useScrollAnimation(elementSelector, elementAnimationSelector, threshold = 0.3) {
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting === true) {
                    entry.target.classList.add(elementAnimationSelector)
                    entry.target.classList.remove(elementSelector)
                }

            })

        }, {
            threshold: threshold,
        })
        const fCards = document.querySelectorAll(`.${elementSelector}`)
        fCards.forEach(fCard => observer.observe(fCard))
        console.log(fCards)

        return () => {
            fCards.forEach((fCard) => observer.unobserve(fCard))
        }
    }, [])
}
