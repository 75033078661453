import { axiosInstance } from "../../../Utils/axiosHelper";

import {
  getTokenCookie,
  getAuthHeader,
  removeTokenCookie,
} from "../../../Utils/authTools";

export const SignInUser = async (values) => {
  const signInInfo = await axiosInstance.post("/api/auth/signin", {
    email: values.email,
    password: values.password,
  });

  return signInInfo.data;
};

export const userIsAuth = async () => {
  if (!getTokenCookie()) {
    return false;
  } else {
    const user = await axiosInstance.get("/api/auth/isauth", getAuthHeader());

    return user;
  }
};

export const userSignOut = async () => {
  await removeTokenCookie();
  return true;
};
