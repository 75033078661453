import { useContext, useEffect, useState } from 'react'
import styles from './DocsDropdown.module.css'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link, NavLink, useParams } from 'react-router-dom';
import NavLinkContext from '../../contexts/NavLinkContext';

const navLinkMapping = {
    home : "home",
    admins : "admin",
    tutors : "tutor",
    parents : "parent",
    students : "student"
}

const DocsDropdown = ({ header, links, isSubMenu, latestTriggered, setLatestTriggered,contentHelp,setShowMenu }) => {

    const [trigger, setTrigger] = useState(false)
    const {navLink,setContentHelp, setNavLink} = useContext(NavLinkContext)
    const {module,preview,docId} = useParams()

    useEffect(()=>{
        setTrigger(latestTriggered === header)
        setContentHelp(contentHelp)
    },[latestTriggered])

    return (
        <div className={`${styles.container} ${isSubMenu ? styles.containerSubMenu : ""} ` } >
            <div className={`${styles.dropdownHeader} ${isSubMenu ? styles.dropdownHeaderSubMenu : ""} ${ (trigger && (isSubMenu === false || isSubMenu === undefined)) && latestTriggered === header ? styles.dropdownHeaderSelected : styles.dropdownHeader}`}>
                {header}
                <div className={``} onClick={(e) => {
                    setTrigger(!trigger);
                    if(isSubMenu === false || isSubMenu === undefined) setLatestTriggered(header);
                    }}>
                    {
                        trigger ? <ExpandMoreIcon  style={{ pointerEvents: "none" }}/> : <NavigateNextIcon  style={{ pointerEvents: "none" }}/>
                    }
                </div>
            </div>
            {
                trigger ?
                    <div className={`${styles.dropdownLinks} ${isSubMenu ? styles.dropdownLinksSubMenu : ""}`}>
                        {
                            links.map((val, idx) => {
                                return (
                                    val.children ? 
                                    <DocsDropdown header={val.name} links={val.children} isSubMenu={true} setShowMenu={setShowMenu}/>
                                    :
                                    <Link className={ val.publishId === docId ? styles.dropdownLinksSelected : ""} onClick={(e)=>setShowMenu ? setShowMenu(false) : null} to={`/docs/published/${navLink}/${header}/${val.publishId}`}>{val.title}</Link>
                                )
                                
                            })
                        }
                    </div> 
                    :
                    null
            }

        </div>
    )
}

export default DocsDropdown