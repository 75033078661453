import styles from './PopUp.module.css'


const PopUp = (props) => {
    const { trigger, setTrigger } = props

    const handleOutsideClick = (e) => {
        if(e.target.id === "popup-outer"){
            setTrigger(false)
        }
    }

    return (
        <>{
            trigger ?
                <div className={styles.outerContainer} onClick={(e)=>handleOutsideClick(e)} id={"popup-outer"}>
                    <div className={styles.innerContainer} onClick={(e)=>setTrigger(true)}>
                        {
                            props.children
                        }
                    </div>
                </div>
                :
                <></>
        }
        </>
    )
}


export default PopUp