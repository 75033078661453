import ReactQuill, { Quill } from 'react-quill';
import styles from './Creator.module.css'
import 'react-quill/dist/quill.snow.css';
import { ButtonFilled, ButtonHollow } from '../../../components/Buttons';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PublishIcon from '@mui/icons-material/Publish';
import PreviewIcon from '@mui/icons-material/Preview';
import SaveIcon from '@mui/icons-material/Save';
import { useEffect, useState } from 'react';
import { deleteArticle, editDoc, fetchArticle, generateLinksForHeadings, getImageBase64String, saveNewDoc, updateArticle, getYTVideoIdFromURL } from '../../../Utils/utils';
import { ToastContainer, toast } from 'react-toastify';
import { useParams, useSearchParams, Link } from 'react-router-dom';
import MirachTopbar from '../../../components/MirachTopbar/MirachTopbar';

const Creator = (props) => {

    //Valid modes are ["create","edit"]
    // const [moduleCategory, setModuleCategory] = useState("")
    const [mode, setMode] = useState("create")

    const preModule = ["admin", "tutor", "parent", "student"]
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
                {
                    background: ["red", "blue", "yellow"]
                }
            ],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean'],
        ],
    }

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote', 'background',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]


    const [articleId, setArticleid] = useState(null)
    const [articleYTVideoId, setArticleYTVideoId] = useState(null)
    const [editorValue, setEditorValue] = useState('')
    const [moduleName, setModuleName] = useState('')
    const [sectionName, setSectionName] = useState('')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [img, setImg] = useState(null)
    const [showMessage, setShowMessage] = useState(false)
    const { docId } = useParams()

    useEffect(() => {
        (async () => {
            if (docId) {
                try {
                    const doc = await fetchArticle("65e5af2cd653e7e14ae6ce47", docId)
                    setArticleid(docId)
                    setModuleName(doc.module)
                    setSectionName(doc.section)
                    setEditorValue(doc.html)
                    setTitle(doc.title)
                    setDescription(doc.description)
                    setImg(doc.img)
                    setArticleYTVideoId(doc.ytVideoId)
                    console.log(doc)
                } catch (err) {
                    toast.error("No article found with this id.")
                }
            }
        })()

        window.onbeforeunload = () => true
        props.setIsInCreator(true)

        return (() => {
            window.onbeforeunload = null
            props.setIsInCreator(false)
        })
    }, [])

    const onChangeValues = (e) => {
        const target = e.target
        const value = e.target.value
        switch (e.target.id) {
            case "module": setModuleName(value); break;
            case "section": setSectionName(value); break;
            case "title": setTitle(value); break;
            case "description": setDescription(value); break;
            case "ytVideoId": setArticleYTVideoId(getYTVideoIdFromURL(value)); break;
            case "image": getImageBase64String(e.target.files[0], (result) => { setImg(result) }); break;
        }


    }

    const onPreviewClicked = (e) => {

    }


    const onPublishClicked = async (e) => {
        if (!articleId) {
            toast.error("Please save your work first.")
            return
        } else {
            const params = {
                articleId: articleId,
                publish: true,
                module: moduleName.toLowerCase(),
                sectionTitle: sectionName
            }
            try {
                await updateArticle(params)
                toast.success("Article published successfully")
            } catch (err) {
                toast.error("Error publishing article " + err?.response?.data?.message)
            }
        }
    }

    const onSaveClicked = async (e) => {

        const editorHTMLWithdID = generateLinksForHeadings(editorValue).innerHTML

        if (moduleName === "" || sectionName === "" || title === "" || description === "" || editorHTMLWithdID === "") {
            toast.error("All feilds are required")
            return
        }
        const params = {
            module: moduleName.toLowerCase(),
            sectionTitle: sectionName,
            articleAuthor: "testAuthorName", //To be picked up from the auth or from the user object stored in local storage
            articleTitle: title,
            articleDescription: description,
            articleHTML: editorHTMLWithdID,
            ...(articleYTVideoId) && { articleYtVideoId: articleYTVideoId },
            ...(docId) && { articleId: docId }
        }

        if (title === "" || sectionName === "" || description === "" || moduleName === "" || editorHTMLWithdID === "") {
            toast.err("Title, module, section, decription & content is required.")
            return
        }

        //Check for enabling edit mode
        if (!docId && !articleId) {
            try {
                const savedDoc = await saveNewDoc(params)
                setArticleid(savedDoc._id)
                toast.success("Article saved successfully.")
            } catch (err) {
                toast.error(err.message, "Article not saved, some error occured.")
            }
        } else {
            try {
                await editDoc(null, {...params, ...(articleId) && {articleId : articleId} })
                toast.success("Article edited & saved successfully.")
            } catch (err) {
                toast.error("Error saving edited article.")
            }
        }

    }

    const onDeleteClicked = async (e) => {
        try {
            await deleteArticle("65e5af2cd653e7e14ae6ce47", articleId)
            toast.success("Document deleted successfully.")
        } catch (err) {
            toast.error("Error deleting document.")
        }
    }


    // return (
    //     <>
    //         <div className={`container h-100 p-3 ${styles.contentContainer}`}>
    //             <ToastContainer />
    //         </div >
    //     </>
    // )

    return (
        <>
            <div className={styles.topBarContainer}>
                <MirachTopbar isHome={false} onPublishClicked={onPublishClicked} onSaveClicked={onSaveClicked} backLink={"/mirach/docs"} />
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.columnOne}>
                    <div className='container-fluid h-100'>

                        <div className='row'>
                            <div className={`col-12 mb-3`}>
                                <input type="text" className={`form-control ${styles.titleInput}`} id="title" placeholder="Title for document here" onChange={(e) => onChangeValues(e)} defaultValue={title ? title : ""} />
                            </div>
                        </div>

                        <div className='row gap-4'>
                            <div className={`col-12`}>
                                <ReactQuill id="editor" theme="snow" modules={modules} formats={formats} className={`${styles.editor}`} value={editorValue} onChange={setEditorValue}></ReactQuill>
                            </div>
                            <div className={`col-12`} hidden={true}>
                                <div className={`${styles.infoMessage}`}>
                                    Save your work to preview the article
                                </div>
                            </div>
                            <div className={`col-12 d-flex justify-content-between align-items-center gap-3`}>
                                <div className='d-flex justify-content-start align-items-center gap-3'>
                                    <Link to={!articleId ? "" : `/docs/preview/${moduleName === "home" ? "home" : moduleName + "s"}/${sectionName}/${articleId}`} target='_blank' onMouseEnter={(e) => { if (!articleId) setShowMessage(true); }} onMouseLeave={(e) => { if (!articleId) setShowMessage(false); }}>
                                        <button className={styles.previewButton} disabled={!articleId} ><PreviewIcon />Preview</button>
                                    </Link>
                                    <div className={`${styles.infoMessage}`} hidden={!showMessage}>
                                        Save your work to preview the article.
                                    </div>
                                    {/* <ButtonHollow className='d-flex justify-content-start align-items-center gap-2' onClick={(e)=>onPublishClicked(e)}><PublishIcon/>Publish</ButtonHollow>
                        <ButtonFilled className='d-flex justify-content-start align-items-center gap-2' onClick={(e)=>onSaveClicked(e)}><SaveIcon />Save</ButtonFilled> */}
                                </div>
                                <div className='d-flex justify-content-start align-items-center gap-3'>
                                    {/* <ButtonFilled className='d-flex justify-content-start align-items-center gap-2 bg-danger' onClick={(e) => onDeleteClicked(e)} disabled={!articleId}><DeleteForeverIcon />Delete</ButtonFilled> */}
                                </div>
                            </div >
                        </div>
                    </div>
                </div>
                <div className={styles.columnTwo}>
                    <div className='container-fluid h-100'>
                        <div className={`row row-gap-4`}>
                            <div className={`col-12`}>
                                <label htmlFor='module' className={styles.labelCreator}>Module</label>
                                <select id='module' onChange={(e) => { onChangeValues(e); console.log(moduleName) }} defaultValue={moduleName ? moduleName : ""} style={{ textTransform: "capitalize" }}  >
                                    <option value="">Select Module</option>
                                    {
                                        preModule.map((el, i) => {
                                            return <option value={el} key={i} selected={moduleName.toLowerCase() === el.toLowerCase()} style={{ textTransform: "capitalize" }} >{el + "s"}</option>
                                        })
                                    }
                                </select>
                                {/* <input type="text" id="module" placeholder="Ex : Home, Admin, Tutors, Parents, Students " className='form-control' onChange={(e) => onChangeValues(e)} defaultValue={moduleName ? moduleName : ""} /> */}
                            </div >
                            <div className={`col-12`}>
                                <div className='d-flex flex-column justify-content-start align-items-start'>
                                    <label htmlFor='section' className={styles.labelCreator}>Section</label>
                                    <input type="text" id="section" placeholder="Ex : Getting Started, Overview" className='form-control' onChange={(e) => onChangeValues(e)} defaultValue={sectionName ? sectionName : ""} />
                                    {/* 
                    LATER
                        </div> 
                        <div className='dropdown'>
                    */}
                                </div>
                            </div >
                            <div className={`col-12`}>
                                <label htmlFor='description' className={styles.labelCreator}>Description</label>
                                <input type="text" id="description" placeholder="Give a brief idea about the document in 155 characters " className='form-control' onChange={(e) => onChangeValues(e)} defaultValue={description ? description : ""} />
                            </div>
                            <div className={`col-12`}>
                                <label htmlFor='ytVideoId' className={styles.labelCreator}>Video Link</label>
                                <input type="text" id="ytVideoId" placeholder="Enter the URL for the youtube video for this doc. Optional" className='form-control' onChange={(e) => onChangeValues(e)} defaultValue={articleYTVideoId ? `http://www.youtube.com/watch?v=${articleYTVideoId}` : ""} />
                            </div>
                            {/* <div className={`col-12`}>
                    <label htmlFor='image' >Select Image</label>
                    <input type="file" id="image" className='form-control' onChange={(e)=>onChangeValues(e)}/>
                </div> */}
                            {/* <div className={`col-12 ${img ? "d-block": "d-lg-none"}`}>
                    <img src={img} style={{objectFit : "contain", maxHeight : "300px", border : "1px solid white", borderRadius : "15pxf"}}/>
                </div> */}
                        </div >
                    </div>
                </div>
            </div>
        </>
    )
}

export default Creator