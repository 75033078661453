import React, { useState } from 'react'
import styles from './creditsEarnedPopup.module.css'
import YellowWallerIcon from '../../assets/images/CalendarV2/desktopIcons/YellowWallerIcon'
import { insertQueryInDynamoDb } from '../../Utils/utils'
import { toast } from 'react-toastify'

const CreditsEarnedPopup = ({ setSuccess }) => {

    const [fullName, setFullName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [organization, setOrgnization] = useState("")
    const [website, setWebsite] = useState("")
    const [city, setCity] = useState("")
    const [loaderIcon, setLoaderIcon] = useState(false);

    const isEmail = (val) => {
        let regEmail =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEmail.test(val)) {
            return false;
        } else {
            return true;
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const firstName = fullName.split(" ")[0]
        let lastName = ""
        if (fullName.split(" ")[1]) {
            lastName = fullName.split(" ")[1]
        } else {
            lastName = "NA"
        }

        if (fullName === "" || email === "") {
            toast.warn("Please fill required fields.");
            return;
        }
        if (phone) {
            if (phone.length > 10) {
                toast.warn("Enter correct phone number");
                return;
            }
        }
        if (isEmail(email) == false) {
            toast.warn("Enter correct email.");
            return;
        }

        setLoaderIcon(true);
        try {
            const result = await insertQueryInDynamoDb({
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone: phone || "0000000000",
                message: `Orgnization: ${organization} | Website: ${website} | City: ${city} | Query for: Parincing and credits`,
                queryFor: "sales",
            });
            setSuccess(true);
            window.history.pushState({}, "", `book-demo/call-scheduling`);
            // if (setLeadSubmited) {
            //     setLeadSubmited(true);
            // }
            toast.success("Thank you for entering the query.");

            //   if (setFormPopUpTrigger) setFormPopUpTrigger(false);
            // setActivePopup(false)
            window.ApolloMeetings.submit({
                map: false,
                lead: { first_name: firstName, last_name: lastName, email: email, phone: phone || "0000000000", message: `Orgnization: ${organization} | Website: ${website} | City: ${city}` },
                onSuccess: () => {
                    toast.success(
                        "Meeting booked successfully. Looking forward to meet you."
                    );
                    setFullName('')
                    setPhone("")
                    setEmail("")
                    setCity("")
                    setOrgnization("")
                    setWebsite("")
                },
            });
        } catch (err) {
            setLoaderIcon(false);
            console.log(err);
            toast.error("Oops an error occured");
            //   if (setFormPopUpTrigger) setFormPopUpTrigger(false);
        }

        setLoaderIcon(false)
    };

    return (
        <div className={styles.creditsEarnedPopup}>
            <div>
                <h4 className={styles.popupHeading}>Congratulations!</h4>
                <div className={styles.bottomBorder}></div>
                <div className={styles.credits}>
                    <YellowWallerIcon />
                    <div>Credits Earned: <span>$100</span></div>
                </div>
                <div className={styles.creditsDesc}>Ready to redeem? Book a demo to explore our pricing options.</div>
            </div>
            <form action="" onSubmit={(e) => handleFormSubmit(e)}>
                <div className={styles.formContainer}>
                    <input type="text" placeholder='Full Name*' onChange={(e) => setFullName(e.target.value)} />
                    <input type="text" placeholder='Email*' onChange={(e) => setEmail(e.target.value)} />
                    <input type="text" placeholder='Phone Number' onChange={(e) => setPhone(e.target.value)} />
                </div>
                <button type='submit' disabled={fullName && email ? false : true} style={{ background: fullName && email ? "#0671E0" : "#0671E033" }} className={styles.submitBtn} >Unlock Pricing</button>
            </form>
        </div>
    )
}

export default CreditsEarnedPopup