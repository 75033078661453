import React from 'react'
import styles from './responsiveIcon.module.css'

const CalendarIcon = () => {
    return (
        <svg className={styles.calendarSectionIcons} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="40" height="40" rx="12" fill="#0671E0" />
            <rect x="1" y="1" width="40" height="40" rx="12" stroke="#0671E0" />
            <g clip-path="url(#clip0_499_3556)">
                <path d="M9.85547 18.4282V25.8485C9.85728 27.5172 10.521 29.1171 11.7009 30.297C12.8809 31.477 14.4808 32.1407 16.1495 32.1425H25.8472C27.5159 32.1407 29.1157 31.477 30.2957 30.297C31.4757 29.1171 32.1394 27.5172 32.1412 25.8485V18.4282H9.85547ZM20.9983 27.8568H15.8555C15.6281 27.8568 15.4101 27.7665 15.2494 27.6057C15.0886 27.445 14.9983 27.227 14.9983 26.9997C14.9983 26.7723 15.0886 26.5543 15.2494 26.3936C15.4101 26.2328 15.6281 26.1425 15.8555 26.1425H20.9983C21.2257 26.1425 21.4437 26.2328 21.6044 26.3936C21.7652 26.5543 21.8555 26.7723 21.8555 26.9997C21.8555 27.227 21.7652 27.445 21.6044 27.6057C21.4437 27.7665 21.2257 27.8568 20.9983 27.8568ZM26.1412 23.5711H15.8555C15.6281 23.5711 15.4101 23.4808 15.2494 23.32C15.0886 23.1593 14.9983 22.9413 14.9983 22.7139C14.9983 22.4866 15.0886 22.2686 15.2494 22.1078C15.4101 21.9471 15.6281 21.8568 15.8555 21.8568H26.1412C26.3685 21.8568 26.5865 21.9471 26.7473 22.1078C26.908 22.2686 26.9983 22.4866 26.9983 22.7139C26.9983 22.9413 26.908 23.1593 26.7473 23.32C26.5865 23.4808 26.3685 23.5711 26.1412 23.5711Z" fill="white" />
                <path d="M26.14 11.5841V10.7141C26.14 10.4867 26.0497 10.2687 25.8889 10.108C25.7282 9.94724 25.5102 9.85693 25.2828 9.85693C25.0555 9.85693 24.8375 9.94724 24.6768 10.108C24.516 10.2687 24.4257 10.4867 24.4257 10.7141V11.5712H17.5686V10.7141C17.5686 10.4867 17.4783 10.2687 17.3175 10.108C17.1568 9.94724 16.9387 9.85693 16.7114 9.85693C16.4841 9.85693 16.2661 9.94724 16.1053 10.108C15.9446 10.2687 15.8543 10.4867 15.8543 10.7141V11.5841C14.4386 11.6519 13.0872 12.1952 12.0185 13.1261C10.9498 14.057 10.2263 15.3211 9.96484 16.7141H32.0294C31.768 15.3211 31.0444 14.057 29.9757 13.1261C28.907 12.1952 27.5556 11.6519 26.14 11.5841Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_499_3556">
                    <rect width="24" height="24" fill="white" transform="translate(9 9)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default CalendarIcon