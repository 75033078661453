import React from 'react'
import "./normalButton.css"
import { Link } from 'react-router-dom'
import videoBtnIcon from "../../../assets/icons/video-circle.svg"

const NormalButton = ({ btnName, btnUrl }) => {
    return (
        <Link to={btnUrl || ""}>
            <button className='normal-btn'>
                {btnName}
            </button>
        </Link>
    )
}

export default NormalButton