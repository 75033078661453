import styles from './MirachNavbar.module.css'
import mirachLogo from './mirachLogo.svg'
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupIcon from '@mui/icons-material/Group';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import BookIcon from '@mui/icons-material/Book';
import { Link, NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { GetAllUsers } from '../../pages/Mirach/WhitelistUser/WhitelistAction';


const MirachNavbar = (props) => {

    const [isSuperuser, setIsSuperuser] = useState(false)

    useEffect(()=>{
        (async()=>{
            try{
                await GetAllUsers()
                setIsSuperuser(true)
            } catch(err){
                setIsSuperuser(false)
            }
        })()
    },[props.isLoggedIn])

    return (
        <div className={styles.navbar}>
            <img src={mirachLogo} className={styles.navbarLogo} alt="evallo mirach logo" />
            <NavLink to="blogs" className={({ isActive }) => { return (isActive ? styles.navbarActiveLinkWrapper : styles.navbarLinkWrapper) }}>
                <div className={styles.navbarLink}>
                    <RssFeedIcon />
                    Blogs
                </div>
            </NavLink>
            <NavLink to="docs" className={({ isActive }) => { return (isActive ? styles.navbarActiveLinkWrapper : styles.navbarLinkWrapper) }}>
                <div className={styles.navbarLink}>
                    <BookIcon />
                    Documentation
                </div>
            </NavLink>
            {
                isSuperuser ?
                    <NavLink to="whitelist" className={({ isActive }) => { return (isActive ? styles.navbarActiveLinkWrapper : styles.navbarLinkWrapper) }}>
                        <div className={styles.navbarLink}>
                            <GroupIcon />
                            Whitelist Users
                        </div>
                    </NavLink> : null
            }

{/* 
            <NavLink to="account" className={({ isActive }) => { return (isActive ? styles.navbarActiveLinkWrapper : styles.navbarLinkWrapper) }}>
                <div className={styles.navbarLink}>
                    <AccountCircleIcon />
                    Account
                </div>
            </NavLink> */}
        </div>
    )
}

export default MirachNavbar