import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { RegisterUser } from "./SignUpAction";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerSchema } from "../../../validations/registerValidations";
import "./SignUp.css";

const SignUp = () => {
  const navigate = useNavigate();
  const [registerError, setRegisterError] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(registerSchema),
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      if (data) {
        let response = await RegisterUser(data);
        if (response) {
          navigate("/mirach/signin");
        }
      }
    } catch (err) {
      if (err.response) {
        setRegisterError(err.response.data.message);
      } else {
        setRegisterError(err.message);
      }
    }
  };
  return (
    <div className="row m-auto p-0 z-2 w-100">
      <div className="">
        <form className="z-2 signup-from" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <h2>Evallo SignUp</h2>
          </div>

          <div className="d-flex flex-wrap flex-md-nowrap gap-lg-3">
            <label className="d-flex flex-column" htmlFor="first-name">
              First Name
              <input
                type="text"
                id="first-name"
                name="first-name"
                placeholder="First Name"
                {...register("firstname")}
              />
            </label>
            {
              <div
                className="invalid-feedback text-output-error text-xs px-2 pt-1"
                style={errors.firstname ? { display: "block" } : {}}
              >
                {errors.firstname?.message}
              </div>
            }
            <label className="d-flex flex-column" htmlFor="last-name">
              Last Name
              <input
                type="text"
                id="last-name"
                name="last-name"
                placeholder="Last Name"
                {...register("lastname")}
              />
            </label>{" "}
            {
              <div
                className="invalid-feedback text-output-error text-xs px-2 pt-1"
                style={errors.lastname ? { display: "block" } : {}}
              >
                {errors.lastname?.message}
              </div>
            }
          </div>
          <label className="d-flex flex-column" htmlFor="email">
            Email
            <input
              type="text"
              id="email"
              name="email"
              placeholder="Email"
              {...register("email")}
            />
          </label>
          {
            <div
              className="invalid-feedback  text-output-error text-xs px-2 pt-1"
              style={errors.email ? { display: "block" } : {}}
            >
              {errors.email?.message}
            </div>
          }

          <div className=" gap-lg-3">
            <div>
              <label className="d-flex flex-column" htmlFor="password">
                Password
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  {...register("password")}
                />
              </label>
              {
                <div
                  className="invalid-feedback  text-output-error text-xs px-2 pt-1"
                  style={errors.password ? { display: "block" } : {}}
                >
                  {errors.password?.message}
                </div>
              }
            </div>
            <div>
              <label className="d-flex flex-column" htmlFor="confirm password">
                Confirm Password
                <input
                  type="password"
                  id="confirm-password"
                  name="confirm-password"
                  placeholder="Confirm Password"
                  {...register("confirmpassword")}
                />
              </label>{" "}
              {
                <div
                  className="invalid-feedback  text-output-error text-xs px-2 pt-1"
                  style={errors.confirmpassword ? { display: "block" } : {}}
                >
                  {errors.confirmpassword?.message}
                </div>
              }
            </div>
          </div>
          <div
            className="invalid-feedback text-center text-output-error text-xs px-2 py-2  pt-1 "
            style={registerError ? { display: "block" } : {}}
          >
            {registerError ? registerError : null}
          </div>

          <button
            className="submit-btn"
            type="submit"
            onClick={() => console.log("btn click")}
          >
            SignUp
          </button>
        </form>
        <div className="formStrip"></div>
      </div>{" "}
    </div>
  );
};

export default SignUp;
