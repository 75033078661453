import React from 'react'
import styles from './responsiveIcon.module.css'

const AvatarIcon = () => {
    return (
        <svg className={styles.responsiveIconSize} viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_499_6708)">
                <path d="M29.071 8.929C26.365 6.253 22.819 4.75 19 4.75C15.181 4.75 11.635 6.22299 8.929 8.929C6.253 11.635 4.75 15.181 4.75 19C4.75 22.819 6.22299 26.365 8.929 29.071C11.635 31.777 15.181 33.25 19 33.25C22.819 33.25 26.365 31.777 29.071 29.071C31.747 26.395 33.25 22.819 33.25 19C33.25 15.181 31.777 11.635 29.071 8.929ZM27.5 26C26.657 22.994 24.472 21.226 21.616 20.233C23.029 19.36 23.992 17.767 23.992 15.994C23.992 13.228 21.766 11.002 19 11.002C16.234 11.002 14.008 13.228 14.008 15.994C14.008 17.797 14.941 19.36 16.384 20.233C13.498 21.196 10.813 22.994 10 26C8.017 23.987 7.90601 22.066 7.90601 19C7.90601 12.868 12.898 7.90599 19 7.90599C25.132 7.90599 30.094 12.898 30.094 19C30.094 22.036 29.483 23.987 27.5 26Z" fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_499_6708">
                    <rect width="30" height="30" fill="white" transform="translate(4 4)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default AvatarIcon