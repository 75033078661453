import { Outlet, useLocation } from 'react-router-dom'
import './Stage.css'
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'
import { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'



const Stage = () => {

    const location = useLocation()

    console.log("WLP", location)

    return (
        <div className='stage-container' style={{ background: location.pathname === "/features/calendar" ? "#f9fdff" : "" }}>
            <div className='navbar-container'>
                <Navbar />
            </div>
            <div className='content-container'>
                <Outlet />
            </div>
            <div className='footer-container'>
                <Footer />
            </div>
        </div>
    )
}

export default Stage
