import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './account.module.css'
import { myProfile } from '../../../Utils/utils'
import { removeTokenCookie } from '../../../Utils/authTools'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
import apiURL from '../../../apiURL'



const Account = ({ setIsLoggedIn }) => {
    const [user, setUser] = useState({})

    const navigate = useNavigate()

    async function logout() {
        try {
            await axios({
                method : "POST",
                url : apiURL + "/api/auth/logout",
                withCredentials : true
            })
            setIsLoggedIn(false)
            navigate("/mirach/signin")
        }catch(err){
            toast.error("Error logging out")
        }
    }

    useEffect(() => {
        myProfile()
            .then((res) => {
                setUser(res)
                console.log(res)
            })
            .catch((err)=>{
                if(err && err.response.status===401 || err.response.status===403){
                    navigate("/mirach/signin")
                } 
            })
    }, [])

    return (
        <div className='min-vh-100'>
            <div className={styles.account}>
                <h1 className='mb-3'>Account Details</h1>
                <div className='d-flex flex-column'>
                    <label htmlFor="first">First Name</label>
                    <input type="text" defaultValue={user?.firstName} name="first" id="firstName" placeholder='First Name' disabled />
                </div>
                <div className='d-flex flex-column'>
                    <label htmlFor="second">Last Name</label>
                    <input type="text" defaultValue={user?.lastName} name="second" id="secondName" placeholder='Last Name' disabled />
                </div>
                <div className='d-flex flex-column'>
                    <label htmlFor="email">Email</label>
                    <input type="text" defaultValue={user?.email} name="email" id="email" placeholder='Email' disabled />
                </div>
                <button class={styles.Btn} onClick={logout}>
                    <div class={styles.sign}><svg viewBox="0 0 512 512"><path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path></svg></div>
                    <div class={styles.text}>Logout</div>
                </button>



            </div>

        </div>
    )
}

export default Account