import React from 'react'
import styles from './responsiveIcon.module.css'
const HistoryIcon = () => {
    return (
        <svg className={styles.calendarSectionIcons} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="40" height="40" rx="12" fill="#0671E0" />
            <rect x="1" y="1" width="40" height="40" rx="12" stroke="#0671E0" />
            <path d="M31.2898 25.67C31.2645 25.4062 31.1354 25.1632 30.9311 24.9944C30.7267 24.8256 30.4637 24.7449 30.1998 24.77L26.1998 25.15C25.9346 25.1752 25.6902 25.3047 25.5205 25.5101C25.3508 25.7154 25.2696 25.9798 25.2948 26.245C25.32 26.5102 25.4495 26.7546 25.6549 26.9243C25.8602 27.094 26.1246 27.1752 26.3898 27.15L28.1298 27C27.2628 27.9398 26.2116 28.6911 25.0418 29.2072C23.8719 29.7233 22.6084 29.9932 21.3298 30C19.846 29.9996 18.3854 29.6323 17.0779 28.9309C15.7704 28.2294 14.6566 27.2157 13.8357 25.9797C13.0147 24.7438 12.5121 23.324 12.3724 21.8469C12.2328 20.3697 12.4606 18.8809 13.0354 17.5131C13.6103 16.1452 14.5144 14.9407 15.6674 14.0067C16.8203 13.0728 18.1862 12.4383 19.6436 12.1599C21.101 11.8815 22.6046 11.9677 24.0207 12.4108C25.4367 12.854 26.7212 13.6404 27.7598 14.7C27.9499 14.8653 28.1954 14.9527 28.4472 14.9448C28.699 14.9369 28.9385 14.8343 29.1179 14.6574C29.2973 14.4805 29.4034 14.2425 29.4148 13.9908C29.4263 13.7392 29.3424 13.4924 29.1798 13.3C27.9101 12.0047 26.3398 11.0434 24.6087 10.5018C22.8776 9.9602 21.0394 9.8551 19.2578 10.1958C17.4763 10.5366 15.8066 11.3126 14.3976 12.4548C12.9885 13.5969 11.8837 15.0698 11.1816 16.7423C10.4796 18.4148 10.202 20.2349 10.3736 22.0406C10.5451 23.8463 11.1606 25.5816 12.1651 27.0919C13.1696 28.6022 14.532 29.8406 16.131 30.6969C17.73 31.5533 19.5159 32.0009 21.3298 32C22.8704 31.9967 24.3939 31.6772 25.8061 31.0614C27.2182 30.4457 28.489 29.5466 29.5398 28.42L29.6698 29.84C29.6923 30.0893 29.8075 30.3211 29.9927 30.4896C30.1778 30.6581 30.4195 30.751 30.6698 30.75H30.7598C31.0217 30.7246 31.2631 30.597 31.4316 30.3948C31.6001 30.1927 31.6821 29.9322 31.6598 29.67L31.2898 25.67Z" fill="white" />
            <path d="M24.8383 16.92L20.7383 20.92L19.1583 19.37C18.9687 19.183 18.7126 19.079 18.4463 19.0809C18.18 19.0828 17.9253 19.1903 17.7383 19.38C17.5514 19.5696 17.4474 19.8257 17.4492 20.092C17.4511 20.3583 17.5587 20.613 17.7483 20.8L19.9983 23.08C20.1857 23.2662 20.4391 23.3708 20.7033 23.3708C20.9675 23.3708 21.221 23.2662 21.4083 23.08L26.2183 18.35C26.3122 18.2574 26.387 18.1472 26.4383 18.0258C26.4896 17.9043 26.5165 17.7739 26.5174 17.642C26.5184 17.5102 26.4933 17.3794 26.4437 17.2573C26.3941 17.1351 26.3209 17.0239 26.2283 16.93C26.1358 16.8361 26.0256 16.7613 25.9041 16.71C25.7827 16.6587 25.6523 16.6318 25.5204 16.6309C25.3885 16.63 25.2578 16.655 25.1356 16.7046C25.0134 16.7542 24.9022 16.8274 24.8083 16.92H24.8383Z" fill="white" />
        </svg>

    )
}

export default HistoryIcon