import React, { useState } from "react";
import "./FeaturesHeroSection.css";
import { Link } from "react-router-dom";
import VideoPopUp from "../videoPopUp/videoPopUp";

const FeaturesHeroSection = ({ image, title, desc, buttonText, buttonUrl, page }) => {


  console.log(page)
  const [vidTrigger, setVidTrigger] = useState(false);
  const [hoverTextVisible, setHoverTextVisible] = useState(false);

  const handleMouseEnter = () => {
    setHoverTextVisible(true);
  };

  const handleMouseLeave = () => {
    setHoverTextVisible(false);
  };

  console.log(hoverTextVisible);

  return (
    <div className="d-flex justify-content-center align-items-center relative">
      <VideoPopUp
        trigger={vidTrigger}
        setTrigger={setVidTrigger}
        element={
          <iframe
            class="sl-demo"
            height="80%"
            width="80%"
            title="YouTube video player"
            frameBorder="0"
            src= {page == "dsat" ? "https://app.supademo.com/embed/clz09eqk12ivcz9kdlcdvqorc" : page == "crm"? "https://app.supademo.com/embed/clyzoetrm2ao4z9kd9fpcz6ec"  : page == "calendar"? "https://app.supademo.com/embed/clz08hu9h2ikrz9kdijq6xxgv" :  "https://app.storylane.io/demo/iegsn5zdmcx6"}
            name="sl-embed"
            allow="fullscreen"
            allowFullScreen
          ></iframe>
        }
      />
      <div className="hero-image-container ">
        <div
          style={{ position: "relative" }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <img
            width={"100%"}
            src={image}
            alt=""
          />

          {hoverTextVisible && (
            <div className="hover-text">
             
              <button className="hover-button"  onClick={() => setVidTrigger(true)}>
                Interact with Prototype
              </button>
            </div>
          )}
        </div>

        <div className="text-center">
          <h1>{title}</h1>
          <p>{desc}</p>
          <Link to={buttonUrl}>
            <button>{buttonText}</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FeaturesHeroSection;
