import { useEffect } from 'react'
import styles from './TermsOfUse.module.css'
import './TermsOfUseGlobal.css'

const htmlString = `<div class="c12 doc-content" id="doc-content">
<p class="c10"><span class="c0"></span></p>
<p class="c11 c28"><span class="c0"></span></p>
<p class="c10"><span class="c0"></span></p>
<p class="c11 c22"><span class="c2">Your use of the website(s) hosted on the domain &ndash; evallo.org
        (&ldquo;</span><span class="c1">Platform</span><span class="c2">&rdquo;) is subject to all terms and
        policies posted on this Platform (including the Privacy Policy, Content Terms, collectively referred to as
        &ldquo;</span><span class="c1">Terms of Use</span><span class="c2">&rdquo; or &ldquo;</span><span
        class="c1">Terms</span><span class="c0">&rdquo;). The Terms of Use may be revised from time to time through
        an updated posting, and therefore you should check the Terms of Use periodically. Revisions are effective
        upon posting and your continued use of the Platform following the posting of revisions to the Terms of Use
        will indicate your acceptance of such revisions.</span></p>
<p class="c10"><span class="c0"></span></p>
<p class="c11 c22"><span class="c2">For the purpose of these Terms, and where the context so requires,
        &ldquo;</span><span class="c1">our</span><span class="c2">&rdquo;, &ldquo;</span><span
        class="c1">we</span><span class="c2">&rdquo; or &ldquo;</span><span class="c1">us</span><span
        class="c2">&rdquo; shall mean Evallo Digital Products Inc (&ldquo;</span><span class="c1">Evallo</span><span
        class="c2">&rdquo;), and wherever the context so requires, the &ldquo;</span><span
        class="c1">you</span><span class="c2">&rdquo; or &ldquo;</span><span class="c1">your</span><span
        class="c0">&rdquo; will refer to a User.</span></p>
<p class="c11 c22"><span class="c0"></span></p>
<p class="c11 c22"><span class="c0">It is important to us that the Platform provides a helpful and reliable
        experience to Users. To protect our rights and yours, we have prepared these Terms of Use that apply to all
        visitors to this Platform.</span></p>
<p class="c11"><span class="c0"></span></p>
<p class="c11 c22"><span class="c0">BY USING THE PLATFORM, YOU REPRESENT THAT YOU HAVE READ AND UNDERSTOOD THESE
        TERMS OF USE AND THE PRIVACY POLICY AND AGREE TO BE BOUND BY THEM. IF YOU DO NOT AGREE WITH ANY PART OF
        THESE TERMS OF USE OR THE PRIVACY POLICY GOVERNING THE PLATFORM, YOU SHOULD NOT USE THE PLATFORM. BY USING
        THE PLATFORM, YOU AGREE TO USE OUR SERVICES IN A MANNER CONSISTENT WITH ALL APPLICABLE LAWS AND REGULATIONS
        AND IN ACCORDANCE WITH THESE TERMS OF USE.</span></p>
<p class="c11 c22"><span class="c0"></span></p>
<p class="c11"><span class="c0"></span></p>
<p class="c10"><span class="c1">1.</span><span
        class="c2 c4"></span><span
        class="c3 c1">DEFINITIONS</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">1.1.</span><span class="c2 c4"> </span><span
        class="c2">&ldquo;</span><span class="c1">Admin</span><span class="c0">&rdquo; means the authorised
        personnel/employees/agents of the Teaching Organisation who have been authorised to manage and run the
        account on Teaching Organisation&rsquo;s behalf.</span></p>
<p class="c7"><span class="c2">1.2.</span><span class="c2 c4"> </span><span
        class="c2 c13">&ldquo;</span><span class="c1 c13">Confidential Information</span><span
        class="c0 c13">&rdquo; means any information that whether it being written or oral and whether it being
        designated as confidential or not, by its nature, and/or the circumstances of its disclosure, makes it
        reasonably likely that it is confidential. Confidential Information may include, but is not limited to,
        trade secrets, discoveries, ideas, concepts, know-how, techniques, future product plans, designs,
        specifications, data, computer programs, business activities and operations, customer lists, reports,
        studies, personnel information, financial information, information disclosed by demonstration of any product
        and other technical and business information.</span></p>
<p class="c7"><span class="c2">1.3.</span><span class="c2 c4"> </span><span
        class="c2">&ldquo;</span><span class="c1">Content Terms</span><span class="c2">&rdquo; shall mean the terms
        and conditions available&nbsp;</span><span class="c2"><a class="c14"
            href="https://www.google.com/url?q=https://evallo.org/content-terms&amp;sa=D&amp;source=editors&amp;ust=1715590830235112&amp;usg=AOvVaw1Z65CBOdaxvrNNddhhSxrQ"></a></span><span
        class="c23 c2"><a class="c14"
            href="https://www.google.com/url?q=https://evallo.org/content-terms&amp;sa=D&amp;source=editors&amp;ust=1715590830235843&amp;usg=AOvVaw2Sk-TpsdNL9m3QxTHAJ6jh">here</a></span><span
        class="c0">.</span></p>
<p class="c7"><span class="c2">1.4.</span><span class="c2 c4"> </span><span
        class="c2">&ldquo;</span><span class="c1">Creator</span><span class="c0">&rdquo; shall mean individuals,
        organizations, or entities who own or other have the right to provide Creator Content for licensing on the
        Platform.</span></p>
<p class="c7"><span class="c2">1.5.</span><span class="c2 c4"> </span><span
        class="c2">&ldquo;</span><span class="c1">Creator Content</span><span class="c0">&rdquo; shall mean the
        content made available by a Creator on the Platform for licensing it, in accordance with the Content Terms
        and includes without limitation, information, data, text, photographs, videos, audio clips, written posts
        and comments, software, scripts, graphics, and any other form of academic content, explanations, question
        banks, practice material. This will exclude Teaching Organisation Content.</span></p>
<p class="c7"><span class="c2">1.6.</span><span class="c2 c4"> </span><span
        class="c2">&ldquo;</span><span class="c1">Org Contributor</span><span class="c0">&rdquo; means an individual
        added by the Admin on the Platform who shall have special access in relation to uploading Teaching
        Organisation Content on the Platform.</span></p>
<p class="c7"><span class="c2">1.7.</span><span class="c2 c4"> </span><span
        class="c2">&ldquo;</span><span class="c1">Evallo Data</span><span class="c0">&rdquo; shall have the same
        meaning as set out in section 12.1.</span></p>
<p class="c7"><span class="c2">1.8.</span><span class="c2 c4"> </span><span
        class="c2">&ldquo;</span><span class="c1">Intellectual Property</span><span class="c0">&rdquo; shall mean
        all work product, improvements, developments, discoveries, proprietary information, trademarks, trade names,
        logos, art work, slogans, know-how, client presentation decks, processes, systems methods, trade secrets,
        source code, application development, ideas, designs, slogans, art works, drawings, plans, business plans or
        models, blue prints (whether or not registrable and whether or not design rights subsist in them), utility
        models, other works in which copyright may subsist (including computer software and preparatory and design
        materials thereof), inventions (whether patentable or not, and whether or not patent protection has been
        applied for or granted), all other intellectual property throughout the world, in and for all languages,
        including but not limited to computer and human languages.</span></p>
<p class="c7"><span class="c2">1.9.</span><span class="c2 c4"> </span><span
        class="c2">&ldquo;</span><span class="c1">Intellectual Property Rights</span><span class="c0">&rdquo; shall
        mean all current and future worldwide rights in the Intellectual Property.</span></p>
<p class="c9"><span class="c2">1.10.&ldquo;</span><span class="c1">Parent</span><span class="c0">&rdquo; means a
        parent or legal guardian of a Student.</span></p>
<p class="c9"><span class="c2">1.11.&ldquo;</span><span class="c1">Personal Data</span><span class="c0">&rdquo;
        means any information that relates to a natural person, which, either directly or indirectly, in combination
        with other information available or likely to be available with a body corporate, can identify such
        person.</span></p>
<p class="c9"><span class="c2">1.12.&ldquo;</span><span class="c1">Privacy Policy</span><span class="c2">&rdquo;
        means the privacy policy of Evallo as set out &nbsp;</span><span class="c2"><a class="c14"
            href="https://www.google.com/url?q=https://evallo.org/privacy-policy&amp;sa=D&amp;source=editors&amp;ust=1715590830244395&amp;usg=AOvVaw1f5vqy9eYeph80BwtUpluR"></a></span><span
        class="c2 c23"><a class="c14"
            href="https://www.google.com/url?q=https://evallo.org/privacy-policy&amp;sa=D&amp;source=editors&amp;ust=1715590830245166&amp;usg=AOvVaw1uKZJL4AjwrEIsfWLUc8As">here</a></span><span
        class="c0">.</span></p>
<p class="c9"><span class="c2">1.13.&ldquo;</span><span class="c1">Services</span><span class="c0">&rdquo; means
        services offered by Evallo, which includes, CRM tools, assignment management tools, class scheduling
        tools.</span></p>
<p class="c9"><span class="c2">1.14.&ldquo;</span><span class="c1">Student</span><span class="c0">&rdquo; means a
        student who avails services from a Teaching Organisation using the Platform.</span></p>
<p class="c9"><span class="c2">1.15.&ldquo;</span><span class="c1">Teaching Organisation</span><span
        class="c0">&rdquo; means a customer of Evallo who avails the Services.</span></p>
<p class="c9"><span class="c2">1.16.&ldquo;</span><span class="c1">Tutors</span><span class="c0">&rdquo; means
        teachers appointed by the Teaching Organisation to provide tutoring services to their Students.</span></p>
<p class="c9"><span class="c2">1.17.&ldquo;</span><span class="c1">Tutoring Fees</span><span class="c0">&rdquo;
        means the fees charged by the Teaching Organisation to the Parent for their children availing the Teaching
        Organisation&rsquo;s services on the Platform.</span></p>
<p class="c9"><span class="c2">1.18.&ldquo;</span><span class="c1">Teaching Organisation Content</span><span
        class="c0">&rdquo; shall mean any material or content uploaded by a User on the Platform.</span></p>
<p class="c9"><span class="c2">1.19.&ldquo;</span><span class="c1">User</span><span class="c0">&rdquo; as the
        context requires, refers to either the Parent, Student, Admin, Tutor, Teaching Organisation, Creator, or the
        Org Contributor (as the case may be).</span></p>
<p class="c11 c22"><span class="c0"></span></p>
<p class="c10"><span class="c1">2.</span><span
        class="c2 c4"></span><span class="c3 c1">USE OF
        PLATFORM AND THE SERVICES</span></p>
<p class="c11"><span class="c3 c1"></span></p>
<p class="c7"><span class="c2">2.1.</span><span class="c2 c4"> </span><span class="c0">You may use the
        Platform and the Services only for lawful purposes and in accordance with these Terms.</span></p>
<p class="c19"><span class="c0"></span></p>
<p class="c18"><span class="c2">2.2.</span><span class="c2 c4"> </span><span class="c0">Any action
        triggered on your Account on the Platform will be deemed to have been authorised by you and initiated with
        your express consent. You shall be solely responsible for maintaining the confidentiality and security of
        your Account, and for all activities that occur under your Account. You agree that all activities that occur
        under your Account (including without limitation, posting any information, clicking to accept any terms
        &amp; conditions) will be deemed to have been authorized by you.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">2.3.</span><span class="c2 c4"> </span><span class="c0">You hereby agree
        not to use the Platform and the Services:</span></p>
<p class="c6"><span class="c0">2.3.1.in any way that violates any applicable national or international laws or
        regulations;</span></p>
<p class="c6"><span class="c0">2.3.2.for the purpose of exploiting, harming, or attempting to exploit any person or
        harm minors in any way by exposing them to inappropriate content or otherwise;</span></p>
<p class="c6"><span class="c0">2.3.3.to transmit, or procure the sending of, any advertising or promotional
        material, including any &quot;junk mail&quot;, &quot;chain letter&quot;, &quot;spam,&quot; or any other
        similar solicitation;</span></p>
<p class="c6"><span class="c0">2.3.4.to impersonate or attempt to impersonate Evallo, an Evallo employee or
        personnel, another User, or any other person or entity;</span></p>
<p class="c6"><span class="c0">2.3.5.in any way that infringes upon the rights of others, or in any way is illegal,
        threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful
        purpose or activity; or</span></p>
<p class="c6"><span class="c0">2.3.6.to engage in any other conduct that restricts or inhibits anyone&#39;s use or
        enjoyment of Platform and the Service, or which, as determined by us, may harm, or offend Evallo or other
        Users or expose them to liability;</span></p>
<p class="c6"><span class="c0">2.3.7.in any manner that could disable, overburden, damage, or impair the Platform or
        interfere with any other party&#39;s use of the Platform;</span></p>
<p class="c6"><span class="c0">2.3.8.use any &quot;deep-link&quot;, &quot;page-scrape&quot;, &quot;robot&quot;,
        &quot;spider&quot;, or other automatic device, process, program or means to access the Platform and/or the
        Service for any purpose, including monitoring or copying any of the material on the Platform;</span></p>
<p class="c6"><span class="c0">2.3.9.use any manual process to monitor or copy any of the material on Service or for
        any other unauthorized purpose without our prior written consent;</span></p>
<p class="c6"><span class="c2">2.3.10.</span><span class="c2 c4"> </span><span class="c0">use for any
        purpose that is unlawful or otherwise prohibited by these Terms s, or for other activity which infringes the
        rights of Evallo or others;</span></p>
<p class="c6"><span class="c2">2.3.11.</span><span class="c2 c4"> </span><span class="c0">use any
        device, software, or routine that interferes with the proper working of the Platform;</span></p>
<p class="c6"><span class="c2">2.3.12.</span><span class="c2 c4"> </span><span class="c0">introduce any
        viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically
        harmful;</span></p>
<p class="c6"><span class="c2">2.3.13.</span><span class="c2 c4"> </span><span class="c0">attempt to
        gain unauthorized access to, interfere with, damage, or disrupt any parts of the Platform, the server on
        which the Platform is stored, or any server, computer, or database connected to the Platform;</span></p>
<p class="c6"><span class="c2">2.3.14.</span><span class="c2 c4"> </span><span class="c0">attack the
        Platform via a denial-of-service attack or a distributed denial-of-service attack;</span></p>
<p class="c6"><span class="c2">2.3.15.</span><span class="c2 c4"> </span><span class="c0">in any way
        decompile, reverse engineer, or disassemble the Platform or any material or content on the Platform;
        or</span></p>
<p class="c6"><span class="c2">2.3.16.</span><span class="c2 c4"> </span><span class="c0">otherwise
        attempt to interfere with the proper working of the Platform and the Service.</span></p>
<p class="c19"><span class="c0"></span></p>
<p class="c18"><span class="c2">2.4.</span><span class="c2 c4"> </span><span class="c0">You shall not
        make any defamatory or denigrating statement(s) about Evallo, or its brand name or domain name used by
        Evallo, or otherwise act in any manner that might tarnish the reputation or standing, of Evallo or other
        Users of the Platform or otherwise tarnish or dilute any of Evallo&rsquo;s trademarks, service marks,
        trading name, or the goodwill associated with them.</span></p>
<p class="c19"><span class="c0"></span></p>
<p class="c18"><span class="c2">2.5.</span><span class="c2 c4"> </span><span class="c0">You shall not
        probe, scan, or test the vulnerability of the Platform or any network connected to the Platform or breach
        the security or authentication measures on the Platform, or any network connected to the Platform. You shall
        not reverse look-up, trace or seek to trace any information on any other User or visitor of the Platform, to
        its source, or exploit the Platform or any service or information made available or offered by or through
        the Platform, in any way where the purpose is to reveal any information, including but not limited to
        personal identification or information, other than your own information, as provided for by the
        Platform.</span></p>
<p class="c19"><span class="c0"></span></p>
<p class="c10"><span class="c1">3.</span><span
        class="c2 c4"></span><span class="c3 c1">TERMS OF USE
        FOR THE TEACHING ORGANISATION</span></p>
<p class="c11 c16"><span class="c0"></span></p>
<p class="c18"><span class="c2">3.1.</span><span class="c2 c4"> </span><span class="c0">You will be
        required to sign up for accessing the Platform and availing the Services.</span></p>
<p class="c19"><span class="c0"></span></p>
<p class="c18"><span class="c2">3.2.</span><span class="c2 c4"> </span><span class="c0">You understand
        that COPPA (Child Online Privacy Protection Act; for schools in the USA) and GDPR (General Data Protection
        Regulation; for schools in the EU) require parental consent for the collection of Personal Data from
        underage children. As a third-party operator Evallo relies on you obtaining consent from the respective
        Parents for all underage children under COPPA. The consent can be obtained by requiring the Parents to
        approve their child&rsquo;s use of the Platform via the link received by them, on being initiated by you, on
        the Platform.</span></p>
<p class="c17"><span class="c0"></span></p>
<p class="c18"><span class="c2">3.3.</span><span class="c2 c4"> </span><span class="c0">You agree to
        handle and manage all matters/disputes arising out of or in connection with any refund of Tutoring Fees to
        the Parents and/or Students. All such refunds shall be at your account, and you agree to indemnify Evallo
        against all disputes raised by Parents, Students and/or any other third party in this regard against
        Evallo.</span></p>
<p class="c15"><span class="c0"></span></p>
<p class="c18"><span class="c2">3.4.</span><span class="c2 c4"> </span><span class="c0">You will use the
        Platform and the Services responsibly, and for academic purposes only and will take reasonable steps to
        ensure that your Students use the Platform and the Services responsibly.</span></p>
<p class="c19"><span class="c0"></span></p>
<p class="c18"><span class="c2">3.5.</span><span class="c2 c4"> </span><span class="c0">You will respect
        the privacy of the children and only share the class journal content with relevant Parents.</span></p>
<p class="c19"><span class="c0"></span></p>
<p class="c18"><span class="c2">3.6.</span><span class="c2 c4"> </span><span class="c0">In respect of
        your licensing and use of the Creator Content, you agree to the terms set out in relation thereto under the
        Content Terms.</span></p>
<p class="c11 c16"><span class="c0"></span></p>
<p class="c10"><span class="c1">4.</span><span
        class="c2 c4"></span><span class="c3 c1">TERMS OF USE
        FOR TUTOR, ADMIN AND ORG CONTRIBUTOR</span></p>
<p class="c17"><span class="c3 c1"></span></p>
<p class="c7"><span class="c2">4.1.</span><span class="c2 c4"> </span><span class="c0">You understand
        that you are accessing the Platform on behalf of your Teaching Organisation.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">4.2.</span><span class="c2 c4"> </span><span class="c0">You undertake
        that you are the owner of or have all required rights, licenses, consents, and permissions to use any
        copyrighted work, including literary works, visual works, musical works, sound recordings, audiovisual
        works, or other work or any combination of the foregoing, of any content that you upload on the Platform or
        use while providing your services or discharging your obligations using the Platform.</span></p>
<p class="c17"><span class="c0"></span></p>
<p class="c10"><span class="c1">5.</span><span
        class="c2 c4"></span><span class="c3 c1">TERMS OF USE
        FOR THE PARENTS</span></p>
<p class="c11"><span class="c3 c1"></span></p>
<p class="c7"><span class="c2">5.1.</span><span class="c2 c4"> </span><span class="c0">You consent to
        the usage of the Platform and Services by your child who is a Student. You understand that Evallo may
        collect Personal Data of the child for this.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">5.2.</span><span class="c2 c4"> </span><span class="c0">If your child is
        below the legal age (13 years in the USA, 18 years in India or such age as applicable within your
        jurisdiction) and is using the Platform and the Service without your consent, then please send an email to
        Evallo at support@evallo.org with relevant details.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">5.3.</span><span class="c2 c4"> </span><span class="c0">You understand
        and agree that the Evallo, Admin, and/or Tutor using the Platform may send you messages, alerts, and other
        communications through the Platform, including via email, web alert and other similar means, in accordance
        with the preferences you set through the Services, and you agree to accept such messages.</span></p>
<p class="c15"><span class="c0"></span></p>
<p class="c7"><span class="c2">5.4.</span><span class="c2 c4"> </span><span class="c0">You agree to pay
        the Tutoring Fees charged by the concerned Teaching Organisation for your child availing the services of the
        Teaching Organisation on the Platform. The Tutoring Fees may vary depending on the duration of the service,
        the nature of the service, and/or the Tutor opted for or provided by the Teaching Organisation. The Tutoring
        Fees shall be communicated by the Teaching Organisation to you in the manner they deem suitable, including
        but not limited to, on their website, over call,</span></p>
<p class="c15"><span class="c0"></span></p>
<p class="c7"><span class="c2">5.5.</span><span class="c2 c4"> </span><span class="c0">You agree to pay
        the Tutoring Fees shall be paid via the payment options available on the Platform.</span></p>
<p class="c15"><span class="c0"></span></p>
<p class="c7"><span class="c2">5.6.</span><span class="c2 c4"> </span><span class="c0">You may choose to
        avail services of a Teaching Organisation on the Platform, by paying the Tutoring Fees, in any of the manner
        provided for, by the Teaching Organisation. This could include advance payments, purchasing prepaid tutoring
        hours, pre-payment per session, post-payment per session, scheduled payments on a
        bi-weekly/bi-monthly/monthly basis, or such other manner as enabled by the concerned Tutoring Organisation
        on the Platform.</span></p>
<p class="c30"><span class="c3 c26"></span></p>
<p class="c7"><span class="c2">5.7.</span><span class="c2 c4"> </span><span class="c0">You agree that,
        unless provided otherwise by the Teaching Organisation in writing, no refunds of the Tutoring Fees shall be
        made. Further, any and all matters/disputes connected with such refunds shall be exclusively handled by the
        Teaching Organisation directly.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">5.8.</span><span class="c2 c4"> </span><span class="c0">You consent to
        Evallo storing and accessing the educational records of your child.</span></p>
<p class="c17"><span class="c3 c1"></span></p>
<p class="c10"><span class="c1">6.</span><span
        class="c2 c4"></span><span class="c3 c1">TERMS OF USE
        FOR THE STUDENTS</span></p>
<p class="c11"><span class="c0"></span></p>
<p class="c7"><span class="c2">6.1.</span><span class="c2 c4"> </span><span class="c0">You will be
        required to sign up for an account after your Teaching Organisation initiates the process and where
        relevant, after your Parent consents to the usage of the Platform.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">6.2.</span><span class="c2 c4"> </span><span class="c0">If you are a
        minor (under 13 years of age in the USA, 18 years in India or such age as applicable within your
        jurisdiction), you must have the consent of your Parent to use the Services.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">6.3.</span><span class="c2 c4"> </span><span class="c0">You will use the
        Services responsibly and respect the rights and privacy of other Users.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">6.4.</span><span class="c2 c4"> </span><span class="c0">You may not
        transfer your account to anyone else without Evallo&rsquo;s prior written permission.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">6.5.</span><span class="c2 c4"> </span><span class="c0">Evallo does not
        encourage you to share your Personal Data (name, phone number, email, photos, videos) publicly and advises
        you to refrain from sharing it publicly.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">6.6.</span><span class="c2 c4"> </span><span class="c0">You consent to
        Evallo storing and accessing your educational records.</span></p>
<p class="c19"><span class="c0"></span></p>
<p class="c10"><span class="c1">7.</span><span
        class="c2 c4"></span><span class="c3 c1">FEES AND
        PAYMENT</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">7.1.</span><span class="c2 c4"> </span><span class="c2">The Teaching
        Organisation&rsquo;s access to and use of the Platform and Services is subject to payment of the applicable
        subscription fees (&quot;</span><span class="c1">Fees</span><span class="c0">&rdquo;), as notified by
        Evallo, at the time of signing up for the Services.</span></p>
<p class="c7"><span class="c2">7.2.</span><span class="c2 c4"> </span><span class="c2">The Teaching
        Organisation may opt from different subscription plans offered by Evallo. Evallo may also offer certain
    </span><span class="c2 c20">add-on</span><span class="c0">services for additional charges which may be
        availed by the Teaching Organisation.</span></p>
<p class="c7"><span class="c2">7.3.</span><span class="c2 c4"> </span><span class="c2">Evallo may, at
        its own discretion, on purchase of a subscription plan by the Teaching Organisation, offer the Services
        under such subscription plan for an additional duration without any cost (&ldquo;</span><span
        class="c1">Free Trial</span><span class="c0">&rdquo;). Once a Teaching Organisation has been offered a Free
        Trial under any of the subscription plan, it shall not be entitled to Free Trial again under the same or any
        other subscription plan.</span></p>
<p class="c7"><span class="c2">7.4.</span><span class="c2 c4"> </span><span class="c0">The Fees for the
        Platform and the Services are subject to review from time to time, and Evallo reserves the right to propose
        changes to the Fees by providing an advance notice to the Teaching Organisation.</span></p>
<p class="c7"><span class="c2">7.5.</span><span class="c2 c4"> </span><span class="c0">The Fees shall be
        subject to taxes, under applicable laws.</span></p>
<p class="c11 c16"><span class="c3 c1"></span></p>
<p class="c10"><span class="c1">8.</span><span
        class="c2 c4"></span><span class="c3 c1">TEACHING
        ORGANISATION CONTENT</span></p>
<p class="c11 c22"><span class="c0"></span></p>
<p class="c11 c22"><span class="c0">You shall not, where permitted host, display, upload, modify, publish, transmit,
        store, update or share Teaching Organisation Content which:</span></p>
<p class="c11 c16"><span class="c0"></span></p>
<p class="c7"><span class="c2">8.1.</span><span class="c2 c4"> </span><span class="c0">belongs to
        another person and to which you do not have any right to;</span></p>
<p class="c7"><span class="c2">8.2.</span><span class="c2 c4"> </span><span class="c0">is grossly
        harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of
        another&#39;s privacy including bodily privacy, hateful, racially, or ethnically objectionable, disparaging,
        relating, or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or
        unlawfully threatening or unlawfully harassing or otherwise inconsistent with or contrary to the laws in
        force;</span></p>
<p class="c7"><span class="c2">8.3.</span><span class="c2 c4"> </span><span class="c0">is misleading in
        any way;</span></p>
<p class="c7"><span class="c2">8.4.</span><span class="c2 c4"> </span><span class="c0">is patently
        offensive to the online community, such as sexually explicit content, or content that promotes obscenity,
        paedophilia, racism, bigotry, hatred, or physical harm of any kind against any group or individual;</span>
</p>
<p class="c7"><span class="c2">8.5.</span><span class="c2 c4"> </span><span class="c0">harasses or
        advocates harassment of another person;</span></p>
<p class="c7"><span class="c2">8.6.</span><span class="c2 c4"> </span><span class="c0">promotes illegal
        activities or conduct that is abusive, threatening, obscene, defamatory or libellous;</span></p>
<p class="c7"><span class="c2">8.7.</span><span class="c2 c4"> </span><span class="c0">infringes upon or
        violates any third party&#39;s rights including, but not limited to, Intellectual Property Rights, rights of
        privacy (including without limitation unauthorized disclosure of a person&#39;s name, email address,
        physical address, or phone number) or rights of publicity;</span></p>
<p class="c7"><span class="c2">8.8.</span><span class="c2 c4"> </span><span class="c0">promotes an
        illegal or unauthorized copy of another person&#39;s copyrighted work, such as providing pirated computer
        programs or links to them, providing information to circumvent manufacture-installed copy-protect devices,
        or providing pirated music or links to pirated music files;</span></p>
<p class="c7"><span class="c2">8.9.</span><span class="c2 c4"> </span><span class="c0">contains
        restricted or password-only access pages, or hidden pages or images (those not linked to or from another
        accessible page);</span></p>
<p class="c9"><span class="c0">8.10.provides material that exploits people in a sexual, violent, or otherwise
        inappropriate manner or solicits Personal Data from anyone;</span></p>
<p class="c9"><span class="c0">8.11.provides instructional information about illegal activities such as making or
        buying illegal weapons, violating someone&#39;s privacy, or providing or creating computer viruses;</span>
</p>
<p class="c9"><span class="c0">8.12.contains video, photographs, or images of another person (with a minor or an
        adult), except with the express prior written consent of such person;</span></p>
<p class="c9"><span class="c0">8.13.tries to gain unauthorized access or exceeds the scope of authorized access to
        the Platform or solicits personal identifying information for commercial or unlawful purposes from other
        Users;</span></p>
<p class="c9"><span class="c0">8.14.solicits gambling or engages in any gambling activity which, in sole discretion,
        believes is or could be construed as being illegal;</span></p>
<p class="c9"><span class="c0">8.15.interferes with another User&rsquo;s use and enjoyment of the Platform or
        enjoyment of any Service(s);</span></p>
<p class="c9"><span class="c0">8.16.refers to any website or URL that, in sole discretion, contains material that is
        inappropriate for the Platform or any other website, contains content that would be prohibited or violates
        the letter or spirit of these Terms;</span></p>
<p class="c9"><span class="c0">8.17.harms minors in any way;</span></p>
<p class="c9"><span class="c0">8.18.infringes any patent, trademark, copyright, or other proprietary rights or third
        party&#39;s trade secrets or rights of publicity or privacy or shall not be fraudulent or involve the sale
        of counterfeit or stolen products;</span></p>
<p class="c9"><span class="c0">8.19.violates any law for the time being in force;</span></p>
<p class="c9"><span class="c0">8.20.deceives or misleads the addressee about the origin of such messages or
        communicates any information which is grossly offensive or menacing in nature or knowingly and intentionally
        communicates any information which is patently false or misleading in nature but may reasonably be perceived
        as a fact;</span></p>
<p class="c9"><span class="c0">8.21.impersonate another person;</span></p>
<p class="c9"><span class="c0">8.22.contains software viruses or any other computer code, files or programs designed
        to interrupt, destroy, or limit the functionality of any computer resource; or contains any trojan horses,
        worms, time bombs, cancel-bots, easter eggs or other computer programming routines that may damage,
        detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data
        or Personal Data;</span></p>
<p class="c9"><span class="c0">8.23.threatens the unity, integrity, defence, security or sovereignty of a nation,
        friendly relations with foreign states, or public order or causes incitement to the commission of any
        cognizable offence or prevents investigation of any offence or is insulting to a nation.</span></p>
<p class="c9"><span class="c0">8.24.shall not be false, inaccurate or misleading;</span></p>
<p class="c9"><span class="c0">8.25.is patently false and untrue, and is written or published in any form, with the
        intent to mislead or harass a person, entity, or agency for financial gain or to cause any injury to any
        person.</span></p>
<p class="c11 c16"><span class="c0"></span></p>
<p class="c10"><span class="c1">9.</span><span
        class="c2 c4"></span><span class="c3 c1">CREATOR
        CONTENT</span></p>
<p class="c7"><span class="c2">9.1.</span><span class="c2 c4"> </span><span class="c0">The licensing of
        Creator Content on the Platform shall be governed by the Content Terms.</span></p>
<p class="c11 c16"><span class="c3 c1"></span></p>
<p class="c10"><span class="c1">10.</span><span class="c2 c4"> </span><span class="c3 c1">REPRESENTATION AND
        WARRANTIES</span></p>
<p class="c11 c16"><span class="c0"></span></p>
<p class="c7"><span class="c2">10.1.</span><span class="c2 c4">     
        </span><span class="c0">Evallo represents and warrants that
        -</span></p>
<p class="c6"><span class="c2">10.1.1.</span><span class="c2 c4"> </span><span class="c0">it is a
        validly incorporated business entity;</span></p>
<p class="c6"><span class="c2">10.1.2.</span><span class="c2 c4"> </span><span class="c0">it has all
        necessary rights, powers, and authority to perform its obligations under these Terms and the execution,
        delivery, and performance of the Terms; and</span></p>
<p class="c6"><span class="c2">10.1.3.</span><span class="c2 c4"> </span><span class="c0">it shall at
        all times and at its own expense strictly comply with all applicable laws and maintain in full force and
        effect all licenses, permits, and authorization from all governmental departments and agencies to the extent
        necessary to perform its obligations under these Terms.</span></p>
<p class="c11 c29"><span class="c0"></span></p>
<p class="c7"><span class="c2">10.2.</span><span class="c2 c4">     
        </span><span class="c0">Users represent, warrant, and
        undertake that &ndash;</span></p>
<p class="c6"><span class="c2">10.2.1.</span><span class="c2 c4"> </span><span class="c0">you are an
        individual of legal age to form a binding contract. If you&rsquo;re agreeing to these Terms on behalf
        of an organization or entity, you represent and warrant that you are authorized to agree to these Terms on
        that organization or entity&rsquo;s behalf and have all necessary rights, powers, and authority to bind them
        to these Terms;</span></p>
<p class="c6"><span class="c2">10.2.2.</span><span class="c2 c4"> </span><span class="c0">by agreeing to
        these Terms, you are not violating or infringing upon or otherwise violating the rights of any third
        party;</span></p>
<p class="c6"><span class="c2">10.2.3.</span><span class="c2 c4"> </span><span class="c0">you will use
        the Platform and avail the Services in compliance with these Terms and with all applicable laws; and</span>
</p>
<p class="c6"><span class="c2">10.2.4.</span><span class="c2 c4"> </span><span class="c0">you will not
        use the Platform for any purpose that is unlawful or prohibited by the Terms. You agree not to use this
        Platform in any manner that could damage, disable, overburden, or impair the Platform or interfere with any
        other User&rsquo;s use and enjoyment of the Platform. You agree not to obtain or attempt to obtain through
        the Platform any materials or information not intentionally made available to you through the
        Platform.</span></p>
<p class="c11 c29"><span class="c0"></span></p>
<p class="c7"><span class="c2">10.3.</span><span class="c2 c4">     
        </span><span class="c0">In addition to the representations
        and warranties under Section 10.2, Teaching Organisation additionally represent and warrant that
        &ndash;</span></p>
<p class="c11 c29"><span class="c0"></span></p>
<p class="c6"><span class="c2">10.3.1.</span><span class="c2 c4"> </span><span class="c0">It shall
        ensure that only authorised personnel of the Teaching Organisation are onboarded as Admin and Org
        Contributor on the Platform;</span></p>
<p class="c6"><span class="c2">10.3.2.</span><span class="c2 c4"> </span><span class="c0">it shall
        ensure compliance with any laws, rules, regulations, and contracts with third parties that applies to its
        usage of Platform to provide its Services to the Students;</span></p>
<p class="c6"><span class="c2">10.3.3.</span><span class="c2 c4"> </span><span class="c0">it
        shall, at all times, be responsible for all licenses, registrations, authorisations required to provide
        services to Students using the Platform;</span></p>
<p class="c6"><span class="c2">10.3.4.</span><span class="c2 c4"> </span><span class="c0">it shall
        &ndash; (a) obtain adequate and appropriate consent from all Users from whom it collects data for provision
        of services using the Platform; and (b) handle and use personal data of such Users in compliance with
        applicable privacy laws and these Terms; and</span></p>
<p class="c6"><span class="c2">10.3.5.</span><span class="c2 c4"> </span><span class="c0">it has all
        necessary rights in and to all your Teaching Organisation Content and all material they contain; that such
        Teaching Organisation Content shall not infringe any proprietary or other rights of third parties;
        that such Teaching Organisation Content shall not contain any viruses or other contaminating or destructive
        devices or features; that such Teaching Organisation Content will not contain any defamatory, indecent,
        offensive, tortious, or otherwise unlawful material or content; and that your such Teaching Organisation
        Content will not be used to carry out or solicit any unlawful activity and/or be used to make
        commercial solicitations.</span></p>
<p class="c11 c29"><span class="c0"></span></p>
<p class="c7"><span class="c2">10.4.</span><span class="c2 c4">     
        </span><span class="c0">In addition to the representations
        and warranties under Section 10.2, Parents additionally represent, warrant, and undertake that
        &ndash;</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c6"><span class="c2">10.4.1.</span><span class="c2 c4"> </span><span class="c0">they have duly
        authorised their child/ward&rsquo;s use of the Platform as a Student; and</span></p>
<p class="c6"><span class="c2">10.4.2.</span><span class="c2 c4"> </span><span class="c0">they shall
        remain responsible for their child/ward&rsquo;s actions and behaviour as a Student on the Platform.</span>
</p>
<p class="c11"><span class="c0"></span></p>
<p class="c10"><span class="c1">11.</span><span class="c2 c4"> </span><span class="c3 c1">DISCLAIMER OF
        WARRANTIES</span></p>
<p class="c11 c16"><span class="c0"></span></p>
<p class="c7"><span class="c2">11.1.</span><span class="c2 c4">     
        </span><span class="c0">The Platform and Services are
        provided by Evallo on an &quot;as is&quot; and &quot;as available&quot; basis. Evallo herein expressly
        disclaims all warranties of any kind, express or implied, as to the operation of the Platform and the
        Services, or the information, content or materials included therein, including, but not limited to the
        implied warranties of merchantability, fitness for a particular purpose and non-infringement. You expressly
        agree that your use of the Platform, their content, and any Services or items obtained from us is at your
        sole risk.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">11.2.</span><span class="c2 c4">     
        </span><span class="c0">Evallo makes no warranty that - (i)
        the Platform and the Services will meet your requirements, (ii) the Platform and the Services will be
        uninterrupted, timely, secure, or error-free, (iii) the results that may be obtained from the use of the
        Platform and the Services will be complete, accurate or reliable, (iv) the quality of any products,
        services, information, or other material obtained by you through the Platform will meet your expectations,
        and (v) any errors or defects in the Platform will be corrected. Further, Evallo shall not be responsible
        for the conduct of User towards each other who, in their own discretion determine to interact with each
        other, in any manner whatsoever, using the Platform.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">11.3.</span><span class="c2 c4">     
        </span><span class="c0">You expressly understand and agree
        that any material uploaded/downloaded or otherwise obtained, from the Platform is done at your own
        discretion and risk; Evallo shall not be liable, and you will be solely responsible, for any and all loss,
        or corruption, of data uploaded or inputted by you through the use of the Platform, and all servicing,
        repair, or correction and any damage to your hardware and software that may result from the use of the
        Platform and the Services. Evallo will not be liable for any losses, damages or claims by you or any
        third-party in this regard.</span></p>
<p class="c17"><span class="c0"></span></p>
<p class="c7"><span class="c2">11.4.</span><span class="c2 c4">     
        </span><span class="c0">Evallo is not responsible for
        outages or disruptions, including those caused on account of disruption of the internet and
        telecommunications infrastructure which are beyond Evallo&rsquo;s control, outages caused on account of
        surge on the Platform, etc. that can lead to interruptions in the availability of the Platform. Evallo may,
        temporarily and under consideration of your legitimate interests (e.g., by providing prior notice), restrict
        the availability of the Platform or certain features thereof, if this is necessary in view of capacity
        limits, the security or integrity of Evallo&rsquo;s servers, or to carry out maintenance measures that
        ensure the proper or improved functioning of the Platform.</span></p>
<p class="c11 c22"><span class="c0"></span></p>
<p class="c10"><span class="c1">12.</span><span class="c2 c4"> </span><span class="c3 c1">INTELLECTUAL
        PROPERTY RIGHTS</span></p>
<p class="c11 c16"><span class="c0"></span></p>
<p class="c7"><span class="c2">12.1.</span><span class="c2 c4">     
        </span><span class="c2">The Platform and its original
        content (excluding Teaching Organisation Content and Creator Content), features and functionality,
        structure, expression, &quot;look and feel&quot;, all graphics, user interfaces, visual interfaces,
        photographs, trademarks, logos, sounds, music, artwork, and computer code (&quot;</span><span
        class="c1">Evallo Data</span><span class="c0">&quot;) are and will remain the exclusive property of Evallo
        and its licensors. The Platform is protected by copyright, trademark, and other applicable laws in the
        jurisdiction applicable to the operations of Evallo. Our trademarks may not be used in connection with any
        product or service without the prior written consent of Evallo.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">12.2.</span><span class="c2 c4">     
        </span><span class="c0">Except as expressly provided in
        these Terms, no part of the Platform and no Evallo Data may be copied, reproduced, republished, uploaded,
        posted, publicly displayed, encoded, translated, transmitted, or distributed in any way (including
        &quot;mirroring&quot;) to any other computer, server, website or other medium for publication or
        distribution or for any commercial use, without Evallo&#39;s express prior written consent. Evallo Data on
        the Platform is solely for your personal, limited, and non-exclusive use. Use of the Evallo Data on any
        other web site or networked computer environment or use of the Evallo Data for any purpose other than
        personal, non-commercial use is a violation of the copyrights, trademarks, and other proprietary rights, and
        is prohibited.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">12.3.</span><span class="c2 c4">     
        </span><span class="c0">You acknowledge and understand that
        any intellectual property you may have access to by way of availing the Services are the sole and exclusive
        property of Evallo and its licensors and undertake not to use the same without the prior written consent of
        Evallo.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">12.4.</span><span class="c2 c4">     
        </span><span class="c2">You may provide us with information
        and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters
        related to our Platform and Services (&quot;</span><span class="c1">Feedback</span><span class="c0">&quot;).
        You acknowledge and agree that: (i) you shall not retain, acquire or assert any Intellectual Property Right
        or other right, title or interest in or to the Feedback; (ii) Evallo may have development ideas similar to
        the Feedback; (iii) Feedback shall not contain Confidential Information or proprietary information from you
        or any third party; and (iv) Evallo is not under any obligation of confidentiality with respect to the
        Feedback. In the event the transfer of the ownership to the Feedback is not possible due to applicable
        mandatory laws, you grant Evallo and its affiliates an exclusive, transferable, irrevocable, royalty-free,
        sub-licensable, unlimited, and perpetual right to use (including copy, modify, create derivative works,
        publish, distribute, and commercialize) Feedback in any manner and for any purpose.</span></p>
<p class="c15"><span class="c0"></span></p>
<p class="c7"><span class="c2">12.5.</span><span class="c2 c4">     
        </span><span class="c2">All Intellectual Property Rights in
        the Teaching Organisation Content shall be the exclusive property of the respective Users who uploads/posts
        the Teaching Organisation Content on the Platform.</span><span class="c2 c21"></span><span
        class="c0">By posting Teaching Organisation Content on or through the Platform, you represent and warrant
        that: (i) Teaching Organisation Content is yours (you own it) and/or you have the right to use, distribute
        it and the right to grant us the rights and license as provided in these Terms, and (ii) that the posting of
        your Teaching Organisation Content on or through the Platform does not violate the privacy rights, publicity
        rights, copyrights, contract rights or any other rights of any person or entity.</span></p>
<p class="c11 c22"><span class="c0"></span></p>
<p class="c10"><span class="c1">13.</span><span class="c2 c4"> </span><span
        class="c3 c1">CONFIDENTIALITY</span></p>
<p class="c11 c16"><span class="c0"></span></p>
<p class="c7"><span class="c2">13.1.</span><span class="c2 c4">     
        </span><span class="c0">Any and all Confidential Information
        exchanged between or amongst Evallo and any of the Users in the course of their usage of the Platform and
        the Services shall be kept strictly confidential and not disclosed to any third party without prior written
        consent of the discloser of the Confidential Information. At all times, Confidential Information shall be
        treated and stored carefully and appropriately so that the Confidential Information is not inadvertently
        made available to any third party or otherwise disclosed in breach of these Terms.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">13.2.</span><span class="c2 c4">     
        </span><span class="c0">Notwithstanding the aforesaid, these
        Terms shall not prohibit the disclosure of Confidential Information to the extent that such disclosure is
        permitted or required by law, regulation or order of a court or other governmental authority. Furthermore,
        Evallo may disclose Confidential Information on a need-to-know basis to its contractors and service
        providers whom it requires to maintain such information in strict confidence and use it only to facilitate
        the performance of their services in connection with the Services.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">13.3.</span><span class="c2 c4">     
        </span><span class="c0">Users may disclose the other
        User&rsquo;s/Evallo&rsquo;s Confidential Information when required by law but only after it, if legally
        permissible: (a) uses commercially reasonable efforts to notify the disclosing party of such disclosure
        requirement; and (b) gives the disclosing party the chance to challenge the disclosure.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">13.4.</span><span class="c2 c4">     
        </span><span class="c0">All the data that a User enters in
        the Platform belongs to the User, however, the User may not copy, make transcriptions or recordings or in
        any other way reproduce or duplicate any document or other medium containing Confidential Information or
        Intellectual Property of Evallo or other User, without Evallo or such other User&rsquo;s (as the case may
        be) written consent.</span></p>
<p class="c7"><span class="c2">13.5.</span><span class="c2 c4">     
        </span><span class="c0">Evallo will comply with applicable
        data protection laws as well as with its own policies/processes, as applicable, and Privacy Policy in
        relation to any User&rsquo;s personal data that it may collect and process for the purposes of supplying the
        Service. Evallo will process any such personal data in accordance with the Privacy Policy.</span></p>
<p class="c11 c16"><span class="c0"></span></p>
<p class="c10"><span class="c1">14.</span><span class="c2 c4"> </span><span class="c3 c1">TERMINATION AND
        SUSPENSION</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">14.1.</span><span class="c2 c4">     
        </span><span class="c0">You may discontinue availing the
        Services by simply discontinuing usage of the Platform. You may write to Evallo at support@evallo.org for
        deletion of your account. In the event you are a Teaching Organisation, any payments accrued till the date
        of usage of the Services and the Platform shall be due and payable to Evallo as per the applicable payment
        terms.</span></p>
<p class="c17"><span class="c0"></span></p>
<p class="c7"><span class="c2">14.2.</span><span class="c2 c4">     
        </span><span class="c0">If a Teaching Organisation deletes
        its account, accounts of its associated Tutors, Parents and Students shall also be deleted. Further,
        Students shall also have the ability to delete accounts of its associated Tutors, Students, and/or
        Parents.</span></p>
<p class="c15"><span class="c0"></span></p>
<p class="c7"><span class="c2">14.3.</span><span class="c2 c4">     
        </span><span class="c0">Evallo may either suspend or
        terminate your account immediately, without any liability and without any notice for any of the following
        reasons: (a) Evallo reasonably believes that you are in violation of the Terms; (b) Evallo is required by
        applicable law, or a regulatory or government body to terminate/suspend your account or discontinue
        provision of Platform/Services; or (c) on occurrence of any other event where Evallo reasonably believes
        that the suspension or termination of your account is necessary to protect Evallo, the Platform or other
        Users.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">14.4.</span><span class="c2 c4">     
        </span><span class="c0">Evallo may terminate your account or
        discontinue provision of the Platform and Services, at its discretion for any reason whatsoever, by giving
        you [15] days&rsquo; advance notice. You are required to take appropriate backup of all your data on
        receiving notice of termination by Evallo, in the absence of which your data and content may be
        destroyed.</span></p>
<p class="c17"><span class="c0"></span></p>
<p class="c7"><span class="c2">14.5.</span><span class="c2 c4">     
        </span><span class="c0">Evallo will generally, in good
        faith, try to provide advance notice to you prior to terminating your account so that you are able to
        retrieve any important User Submissions you may have stored in your account (to the extent allowed by law
        and these Terms), but Evallo may not do so if it determines that it would be impractical, illegal, not in
        the interest of someone&rsquo;s safety or security, or otherwise harmful to the rights or property of
        Evallo.</span></p>
<p class="c11"><span class="c0"></span></p>
<p class="c7"><span class="c2">14.6.</span><span class="c2 c4">     
        </span><span class="c0">Evallo reserves the right at any
        time, and from time to time, to modify or discontinue, temporarily or permanently, the Platform and/or the
        Services, or any part thereof, with or without notice, in its own discretion. You agree that Evallo will not
        be liable to you or any third party for any modification, suspension, or discontinuation of this Platform
        and/or Services, or any part thereof.</span></p>
<p class="c17"><span class="c0"></span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c10"><span class="c1">15.</span><span class="c2 c4"> </span><span class="c3 c1">THIRD-PARTY
        SERVICES</span></p>
<p class="c11 c16"><span class="c0"></span></p>
<p class="c7"><span class="c2">15.1.</span><span class="c2 c4">     
        </span><span class="c0">Evallo may allow you access to
        content, products or services offered by third parties through hyperlinks (in the form of word link,
        banners, channels or otherwise), API or otherwise to such third parties&rsquo; websites. You are cautioned
        to read such third party&rsquo;s terms and conditions and/or privacy policies before using their web
        site/mobile application with respect to such content, products, or services that you may avail. You
        acknowledge that Evallo has no control over such third parties&rsquo; websites/mobile application and shall
        not be responsible or liable to anyone for such websites, or any content, products or services made
        available on such websites/mobile application.</span></p>
<p class="c11 c16"><span class="c0"></span></p>
<p class="c10"><span class="c1">16.</span><span class="c2 c4"> </span><span class="c1 c3">LIMITATION OF
        LIABILITY</span></p>
<p class="c11 c16"><span class="c0"></span></p>
<p class="c7"><span class="c2">16.1.</span><span class="c2 c4">     
        </span><span class="c0">Evallo shall not have any liability
        whatsoever for any claims arising out of or in relation to: (a) any of your acts or omissions; (b)
        compliance with the instructions given by you or any person acting on your behalf; (c) any dispute or claims
        between the Users; (d) the loss of data and/or the use or the inability to use this site; (e) the cost
        of procurement of substitute goods and services resulting from any goods, data, information or services
        purchased or obtained or transactions entered into through or from the Platform; (f) unauthorized access to
        or alteration of your transmissions or data; (g) statements or conduct of any third party on the Platform;
        or (v) any other matter relating to unauthorised and incorrect use of the Platform..</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">16.2.</span><span class="c2 c4">     
        </span><span class="c0">To the fullest extent permitted by
        applicable laws, under no circumstances and under no legal theory (including, without limitation, tort,
        contract, strict liability, or otherwise) shall Evallo (or its affiliates, subsidiaries, directors,
        officers, employees, agents, licensors, suppliers, sub-contractor or service providers) be liable to you or
        to any other person for (a) any indirect, special, incidental, consequential, exemplary, or punitive
        damages, losses, or expenses of any kind, including damages for lost profits, loss of goodwill, work
        stoppage, accuracy of results, or computer failure or malfunction, even if Evallo knew or should have known
        or was advised that such damages, losses, or expenses were possible and even if direct damages do not
        satisfy a remedy.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">16.3.</span><span class="c2 c4">     
        </span><span class="c0">Except as provided herein, to the
        fullest extent permissible by applicable law, the aggregate liability of Evallo for any claims that may
        arise in connection with these Terms and/or use of the Services shall not exceed the amounts paid by the
        concerned user(s) (if any) to Evallo in connection with the services in the six (06) month period preceding
        the applicable claim.</span></p>
<p class="c17"><span class="c0"></span></p>
<p class="c7"><span class="c2">16.4.</span><span class="c2 c4">     
        </span><span class="c0">Some jurisdictions do not allow the
        exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential
        damages. Accordingly, some of the above limitations may not apply to you.</span></p>
<p class="c11 c16"><span class="c0"></span></p>
<p class="c10"><span class="c1">17.</span><span class="c2 c4"> </span><span class="c3 c1">INDEMNITY</span></p>
<p class="c11 c16"><span class="c3 c1"></span></p>
<p class="c7"><span class="c2">17.1.</span><span class="c2 c4">     
        </span><span class="c2">To the maximum extent permissible by
        law, you agree to indemnify and hold Evallo, its affiliates, subsidiaries, directors, officers, agents,
        licensors, employees, and partners harmless from and against any and all claims, liabilities, damages
        (actual and consequential), losses and expenses (including attorneys&rsquo; fees), including those made by
        third parties, arising from or in any way related to - (a) your use of the Platform and/or the Services
        (including any actions taken by a third party using your account); (b) your violation of these Terms,
        including violation of third party&rsquo;s Intellectual Property Rights in respect of any material uploaded
        on the Platform; (c) your violation of any rights of another User; and (d) your violation of any applicable
        laws (&ldquo;</span><span class="c1">Claim</span><span class="c0">&rdquo;).</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">17.2.</span><span class="c2 c4">     
        </span><span class="c0">In the event of such a Claim, we
        will attempt to provide notice of the Claim to the contact information we have for your account (provided
        that failure to deliver such notice shall not eliminate or reduce your indemnification obligations
        hereunder).</span></p>
<p class="c11 c16"><span class="c0"></span></p>
<p class="c10"><span class="c1">18.</span><span class="c2 c4"> </span><span class="c3 c1">CHANGE OF
        CONTROL</span></p>
<p class="c11 c16"><span class="c0"></span></p>
<p class="c7"><span class="c2">18.1.</span><span class="c2 c4">     
        </span><span class="c0">Over time, Evallo may grow and
        reorganize. We may share your information, including Personal Data with affiliates such as a parent company,
        subsidiaries, joint venture partners or other companies that we control or that are under common control
        with us, in which case we will require those companies to agree to use your Personal Data in a way that is
        consistent with the Privacy Policy.</span></p>
<p class="c11 c16"><span class="c0"></span></p>
<p class="c7"><span class="c2">18.2.</span><span class="c2 c4">     
        </span><span class="c0">In the event of a change to our
        organizations such that all or a portion of Evallo or its assets are acquired by or merged with a
        third-party, we will provide you with notice of the acquisition or the corporate restructuring within five
        (05) days following the completion of such a transaction, by email to your email address that you provided
        to us. If you do not consent to the use of the Platform offered by such a successor company, you may
        discontinue using the Platform and the Services.</span></p>
<p class="c17"><span class="c0"></span></p>
<p class="c7"><span class="c2">18.3.</span><span class="c2 c4">     
        </span><span class="c0">In any other situation where
        Personal Data that we have collected from users would be one of the assets transferred to or acquired by
        that third-party, the Privacy Policy will continue to apply to your information, and any acquirer would only
        be able to handle your Personal Data as per the Privacy Policy (unless you give consent to a new policy). If
        you do not consent to the use of your Personal Data by such a successor company, subject to applicable law,
        you may request its deletion from the company.</span></p>
<p class="c17"><span class="c3 c1"></span></p>
<p class="c10"><span class="c1">19.</span><span class="c2 c4"> </span><span class="c3 c1">GOVERNING LAW AND
        DISPUTE RESOLUTION</span></p>
<p class="c11 c16"><span class="c0"></span></p>
<p class="c7"><span class="c2">19.1.</span><span class="c2 c4">     
        </span><span class="c0">These Terms shall be governed by the
        laws of Delaware, USA and the parties hereto agree to submit all matters and/or disputes arising thereof, to
        the exclusive jurisdiction of the courts of Delaware, USA.</span></p>
<p class="c11 c16"><span class="c0"></span></p>
<p class="c10"><span class="c1">20.</span><span class="c2 c4"> </span><span class="c3 c1">MISCELLANEOUS</span>
</p>
<p class="c11 c16"><span class="c3 c1"></span></p>
<p class="c7"><span class="c2">20.1.</span><span class="c2 c4">     
        </span><span class="c3 c1">Notices:</span></p>
<p class="c6"><span class="c2">20.1.1.</span><span class="c2 c4"> </span><span class="c0">All legal
        notices or demands to or upon Evallo shall be made in writing and sent by email to
        support@evallo.org.</span></p>
<p class="c6"><span class="c2">20.1.2.</span><span class="c2 c4"> </span><span class="c0">All legal
        notices or demands to or upon you shall be made by to the last-known email address provided by you to
        Evallo, or SMS, WhatsApp messages, or by posting such notice or demand on any part of the Platform that is
        publicly accessible without a charge or through such other mode of communication as Evallo may deem fit in
        its discretion.</span></p>
<p class="c11 c29"><span class="c0"></span></p>
<p class="c7"><span class="c2">20.2.</span><span class="c2 c4">     
        </span><span class="c1">Force Majeure:</span><span
        class="c0">Evallo shall not be liable for its non-performance and inadequate performance to the extent
        such non-performance and inadequate performance is caused by a condition (for instance, natural disaster,
        act of war or terrorism, riot, labour condition, epidemic, pandemic, governmental action, and internet
        disturbance) that was beyond Evallo&rsquo;s reasonable control.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">20.3.</span><span class="c2 c4">     
        </span><span class="c1">Assignment:</span><span
        class="c0">You may not assign, transfer, or delegate your rights, titles, benefits, and obligations
        under the Terms to a third party without the prior written consent of Evallo. Evallo may, at its sole
        discretion, assign, transfer or delegate its rights, titles, benefits, and obligations under the Terms to an
        affiliate or any other third-party.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">20.4.</span><span class="c2 c4">     
        </span><span class="c3 c1">Modifications:</span></p>
<p class="c6"><span class="c2">20.4.1.</span><span class="c2 c4"> </span><span class="c2">Evallo may
        amend these Terms at any time by posting the amended terms (</span><span class="c1">&quot;Revised
        Terms&quot;</span><span class="c0">) on the same or different webpage designated by us for the Terms on the
        Platform. It is your responsibility to review these Terms periodically. Any such amendment shall be deemed
        to be effective in respect of you, thirty (30) days from the date such change was posted on this webpage (or
        any other such webpage on the Platform) or otherwise communicated to you.</span></p>
<p class="c6"><span class="c2">20.4.2.</span><span class="c2 c4"> </span><span class="c0">Subject to the
        above, your continued use of the Platform following the posting of Revised Terms shall imply your
        acknowledgement of, and agreement with the Revised Terms, which shall be binding on you.</span></p>
<p class="c6"><span class="c2">20.4.3.</span><span class="c2 c4"> </span><span class="c0">If you do not
        agree to the Revised Terms, you are no longer authorized to use the Platform and the Service.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">20.5.</span><span class="c2 c4">     
        </span><span class="c1">Severability:</span><span
        class="c0">If any provision of the Terms is held by a court or other tribunal of competent
        jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or
        limited to the minimum extent such that the remaining provisions of these Terms will continue in full force
        and effect.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">20.6.</span><span class="c2 c4">     
        </span><span class="c1">Waiver:</span><span
        class="c0">Evallo&rsquo;s failure to enforce any terms of this Terms will not constitute a waiver of
        its right, nor will it act as a waiver of Evallo&rsquo;s right to act with respect to subsequent or similar
        breaches.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">20.7.</span><span class="c2 c4">     
        </span><span class="c1">Conflict:</span><span
        class="c0">Unless otherwise provided for, in the event of any conflict between these Terms and any
        other agreement executed between you and Evallo, the terms of this Terms shall prevail.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">20.8.</span><span class="c2 c4">     
        </span><span class="c1">Survival:</span><span
        class="c0">The provisions which by their nature, intend to survive the termination of these Terms,
        shall survive the termination of these Terms.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">20.9.</span><span class="c2 c4">     
        </span><span class="c1">Entire Agreement:</span><span
        class="c0">This Terms, along with the other policies hosted on the Platform, constitutes the entire
        understanding between you and Evallo and supersedes all other discussions and understanding between you and
        Evallo.</span></p>
<p class="c8"><span class="c0"></span></p>
<p class="c7"><span class="c2">20.10.</span><span class="c2 c4">   
        </span><span class="c1">Contact
        Information:</span><span class="c2">Please send your feedback, comments, requests by email at
    </span><span class="c2 c5">support@evallo.org</span><span class="c0">.</span></p>
<p class="c25"><span class="c3 c26"></span></p>
</div>`

const TermsOfUse = (props) => {

    useEffect(()=>{
        const parser = new DOMParser()
        const newDoc = parser.parseFromString(htmlString,"text/html")
        document.getElementById("inner_container_tou").innerHTML = newDoc.getElementById("doc-content").innerHTML
    },[])

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    return(
        <div id="terms_of_use_container" className='container-fluid mt-5 p-4 d-flex flex-column justify-content-center align-items-center'>
            <div className={styles.title}>Terms Of Use</div>
            <div id="inner_container_tou"></div>
        </div>
    )
}


export default TermsOfUse