import React from 'react'
import styles from './tabSelector.module.css'

const TabItem = ({ tabTitle, isSelected, idx, onClick, image }) => {
    return (
        <button className={isSelected ? styles.tabItemSelected : styles.tabItem} {...{ onClick: () => onClick(idx) }}>
            <div>{image}</div> {tabTitle}
        </button>
    )
}


const TabSelector = ({ items, selectedIdx, onClick }) => {
    return (
        <div className={`${styles.TabContainer}`}>
            {
                items?.map((tabItem, idx) => (
                    <>
                        <div className={""}>
                            <TabItem tabTitle={tabItem.heading} image={tabItem.image} {...{ isSelected: idx === selectedIdx, idx, onClick }} />
                        </div>

                    </>
                ))
            }
        </div>
    )
}

export default TabSelector