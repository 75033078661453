import React, { useState } from "react";
import "./enquiryForm.css";
import { toast } from "react-toastify";
import {
  insertQueryInDb,
  insertQueryInDynamoDb,
  sendEnquiry,
} from "../../Utils/utils";
import { ToastContainer } from "react-toastify";
import EnquiryBg from "./Enquiry-form-bg-vector/EnquiryBg";
import { ButtonFilled } from "../Buttons";

const EnquiryForm = (props) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const isEmail = (val) => {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      return false;
    } else {
      return true;
    }
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const first_name = e.target.first_name.value.trim();
    const last_name = e.target.last_name.value.trim();
    const email = e.target.email.value.trim();
    const message = e.target.message.value.trim();
    const phone = e.target.phone.value.trim();

    if (first_name === "" || email === "" || message === "" || last_name ==="" ||  phone === "" ) {
      toast.warn("All fields are required");
      return;
    }
    if(phone.length >10){
      toast.warn("Enter correct phone number");
      return;
    }
    if (isEmail(email) == false) {
      toast.warn("Enter correct email.");
      return;
    }

    setIsButtonDisabled(true);
    try {
      const result = await insertQueryInDb({
        firstName: first_name,
        lastName: last_name ? last_name : "NA",
        email: email,
        message: message,
        phone: phone ? phone : "NA",
        ...(props.queryFor && { queryFor: props.queryFor }),
      });
      console.log(result);

      e.target.first_name.value = "";
      e.target.last_name.value = "";
      e.target.email.value = "";
      e.target.message.value = "";
      e.target.phone.value = "";
      toast.success("Thank you for entering the query.");
      setIsButtonDisabled(false);
    } catch (err) {
      console.log(err);
      toast.error("Oops an error occured");
    }
    setIsButtonDisabled(false);
  };

  return (
    <div
      className={` ${
        props?.cases == "contact" ? "form-outer_e" : "form-outer"
      } _e w-100 d-flex justify-content-center align-items-center m-0 p-0`}
      style={{
        background: props.inPopUp ? "none" : "",
        width: props.inPopUp ? "fit-content" : "100%",
      }}
    >
      <form
        className="position-relative z-2"
        style={{
          width: props.inPopUp ? "fit-content" : "100%",
          margin: props.inPopUp ? "0" : "",
        }}
        onSubmit={(e) => handleFormSubmit(e)}
        method="post"
      >
        <div
          className="form-container"
          style={{
            backgroundColor: props.inPopUp ? "#F1F9FE" : "",
            margin: props.inPopUp ? "0" : "",
          }}
        >
          <div className="form-heading w-100  d-flex flex-column align-items-start justify-content-top text-center">
            <div className="col-12 line-1  mb-1 text-start">
              We are here to help.
            </div>
            <div className="col-12 line-2  text-start">
              Fill this out and we will get back to you within 24 hours.
            </div>
          </div>
          <div className="form-content-1 row mt-4">
            <div className="col-12 col-lg-6">
              <input
                type="text"
                id="first_name"
                placeholder="First Name*"
                className=" border-0"
                name="first_name"
              />
            </div>
            <div className="col-12 col-lg-6">
              <input
                type="text"
                id="last_name"
                placeholder="Last Name*"
                className=" border-0"
                name="last_name"
              />
            </div>
          </div>
          <div className="form-content-2 row mt-4">
            <div className="col-12">
              <input
                type="phone"
                id="phone"
                placeholder="Phone Number*"
                className=" border-0"
                name="phone"
              />
            </div>
          </div>
          <div className="form-content-2 row mt-4">
            <div className="col-12 ">
              <input
                type="email"
                id="email"
                placeholder="Email*"
                className="border-0"
                name="email"
              />
            </div>
          </div>
          <div className="form-content-2 row mt-4">
            <div className="col-12 ">
              <textarea
                id="message"
                placeholder="Enter your message here*"
                className=" border-0"
                name="message"
              />
            </div>
          </div>
          <div className="form-content-3 row mt-4 gap-3">
            <div className="col-12">
              <div className="d-flex d-none d-lg-flex justify-content-center align-items-center">
                <ButtonFilled
                  style={{ fontSize: "16px", padding: "12px 32px" }}
                  type="submit"
                  disabled={isButtonDisabled}
                >
                  {!isButtonDisabled ? "Submit" : "Sending ..."}
                </ButtonFilled>
              </div>
              <div className="d-flex w-100 d-lg-none justify-content-center align-items-center w-100">
                <ButtonFilled
                  type="submit"
                  style={{
                    width: "100%",
                    fontSize: "16px",
                    padding: "12px 24px",
                  }}
                  disabled={isButtonDisabled}
                >
                  {!isButtonDisabled ? "Submit" : "Sending ..."}
                </ButtonFilled>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EnquiryForm;
