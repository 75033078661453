import styles from "./MirachTable.module.css";
import { Link, useFetcher } from "react-router-dom";
import {
  deleteArticle,
  deleteBlog,
  setPinBlog,
  unPinBlog,
} from "../../Utils/utils";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
const _ = require("lodash");

//Type can be : [docs, blogs]
const headers = {
  blogs: ["Title", "Author", "Categories", "Actions"],
  docs: ["Title", "Description", "Module >> Section", "Actions"],
};

const pageSize = 10;

const MirachTable = (props) => {
  const { type } = props;

  const [data, setdata] = useState();
  const { blogsFilter } = props;
  const { fetchAndSetAllDocs } = props;
  const { fetchAndSetAllBlogs } = props;
  const [currentIdx, setCurrentIdx] = useState(0);
  const [seedArray, setSeedArray] = useState([1, 2, 3, 4, 5]);
  const [pageCounter, setPageCounter] = useState(1);
  const { selectedFilter } = props;

  console.log(data);

  useEffect(() => {
    setdata([]);
  }, [selectedFilter]);

  useEffect(() => {
    if (selectedFilter == "pin") {
      const update = props.data.sort((a, b) => b.priority - a.priority);
      setdata(update);
    } else {
      setdata(props.data);
    }
  }, [props.data]);

  const handleOnDeleteClick = async (e) => {
    if (type === "docs") {
      try {
        await deleteArticle(null, e.target.id);
        toast.success("Document deleted successfully.");
        fetchAndSetAllDocs(selectedFilter);
      } catch (err) {
        toast.error("Error deleting document.");
      }
    }
    if (type == "blogs") {
      try {
        await deleteBlog(e.target.id);
        toast.success("Blog deleted successfully.");
        fetchAndSetAllBlogs(selectedFilter);
      } catch (err) {
        toast.error("Error deleting blog");
      }
    }
  };

  const changePageControls = (e) => {
    if (e.target.id === "next") {
      //seedArray[pageSize-1]<=Math.ceil(data.length / pageSize)
      console.log(0 + pageCounter * (pageSize * 5));
      setPageCounter(pageCounter + 1);
      setCurrentIdx(0 + pageCounter * (pageSize * 5));
      const tempArray = seedArray;
      for (
        let i = pageCounter * pageSize + 1;
        i <= pageCounter * pageSize + pageSize;
        i++
      ) {
        tempArray[i - (pageCounter * pageSize + 1)] = i;
      }
      setSeedArray(tempArray);
    } else if (e.target.id === "prev") {
      if (pageCounter != 1) {
        setPageCounter(pageCounter - 1);
        setCurrentIdx(0 + (pageCounter - 1) * (pageSize * 5) - pageSize * 5);
        console.log(0 + (pageCounter - 1) * (pageSize * 5) - pageSize * 5);
        const tempArray = seedArray;
        for (
          let i = (pageCounter - 1) * pageSize - 4;
          i <= (pageCounter - 1) * pageSize;
          i++
        ) {
          tempArray[i - ((pageCounter - 1) * pageSize - 4)] = i;
        }
        setSeedArray(tempArray);
      }
    }
  };

  const setPin = async (e, id) => {
    if (e.target.checked) {
      try {
        const result = await setPinBlog({ id: id });

        if (result.success) {
          toast.success(result.message);
        } else {
          toast.error(result.message);
          e.target.checked = false;
        }
      } catch (error) {
        e.target.checked = false;
        toast.error("Error in pinning post !");
      }
    } else {
      try {
        const result = await unPinBlog({ id: id });

        if (result.success) {
          toast.success(result.message);
        } else {
          toast.error(result.message);
          e.target.checked = true;
        }
      } catch (error) {
        toast.error("Error in Unpinning post !");
        e.target.checked = true;
      }
    }
  };

  if (type === "docs") {
    return (
      <>
        <div className="table-responsive w-100 d-flex flex-column justify-content-start align-items-start mb-0 pb-0">
          <table
            className={`table w-100 table-light text-justify mb-0 pb-0 ${styles.tableHeader}`}
          >
            <thead>
              {
                <tr>
                  {headers[type].map((val, idx) => {
                    return <th>{val}</th>;
                  })}
                </tr>
              }
            </thead>
            <tbody>
              {data
                ?.slice(currentIdx, currentIdx + pageSize)
                .map((val, idx) => {
                  const tempObj = {
                    path: val.module + "s" + " >> " + val.section,
                    ...val,
                  };
                  const keys = ["title", "description", "path"];
                  const cells = [];

                  for (const key of keys) {
                    cells.push(
                      <td className={styles.docsCell}>{tempObj[key]}</td>
                    );
                  }
                  cells.push(
                    <td>
                      <div className="d-flex justify-content-end align-items-center">
                        <button
                          className="btn text-danger"
                          id={val._id}
                          onClick={(e) => handleOnDeleteClick(e)}
                        >
                          Delete
                        </button>
                        <Link
                          to={`/mirach/creator/${val._id}`}
                          className="btn text-primary"
                        >
                          Edit
                        </Link>
                      </div>
                    </td>
                  );
                  return (
                    <tr>
                      {cells.map((val) => {
                        return val;
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className={`${styles.paginationControls}`}>
          <button
            className={`btn ${styles.pageButton}`}
            id="prev"
            onClick={(e) => {
              changePageControls(e);
            }}
          >
            <ArrowLeftIcon style={{ pointerEvents: "none" }} />
          </button>
          {seedArray.map((val, idx) => {
            return (
              <button
                onClick={(e) => setCurrentIdx(0 + pageSize * (val - 1))}
                className={`btn ${styles.pageButton} ${
                  0 + pageSize * (val - 1) === currentIdx
                    ? styles.selectedPage
                    : ""
                }`}
              >
                {val}
              </button>
            );
          })}
          <button
            className={`btn ${styles.pageButton}`}
            id="next"
            onClick={(e) => {
              changePageControls(e);
            }}
          >
            <ArrowRightIcon style={{ pointerEvents: "none" }} />
          </button>
        </div>
      </>
    );
  }

  if (type === "blogs") {
    return (
      <>
        <div className="table-responsive w-100 mb-0 pb-0">
          <table className="table w-100 table-light text-justify mb-0 pb-0">
            <thead>
              {
                <tr>
                  {headers[type].map((val, idx) => {
                    return (
                      <th
                        className={`${styles.tableHeader} ${
                          idx === 3 ? "text-center" : "text-justify"
                        }`}
                      >
                        {val}
                      </th>
                    );
                  })}
                </tr>
              }
            </thead>
            <tbody>
              {data
                ?.slice(currentIdx, currentIdx + pageSize)
                .map((val, idx) => {
                  const tempObj = {
                    path: val.module + " >> " + val.section,
                    ...val,
                  };
                  const keys = ["title", "author", "categories"];
                  const cells = [];

                  for (const key of keys) {
                    if (key === "categories") {
                      cells.push(<td>{tempObj[key].join(",")}</td>);
                    } else {
                      cells.push(<td>{tempObj[key]}</td>);
                    }
                  }
                  cells.push(
                    selectedFilter == "pin" ? (
                      <td>
                        <div
                          className="d-flex  align-items-center"
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <label class={styles.switch}>
                            <input
                              type="checkbox"
                              value={val.priority}
                              defaultChecked={val && val?.priority}
                              onChange={(e) => setPin(e, val._id)}
                            />
                            <span
                              class={`${styles.slider} ${styles.round}`}
                            ></span>
                          </label>
                        </div>
                      </td>
                    ) : (
                      <td>
                        <div className="d-flex justify-content-between align-items-center">
                          <button
                            className="btn text-danger"
                            id={val._id}
                            onClick={(e) => handleOnDeleteClick(e)}
                          >
                            Delete
                          </button>
                          <Link
                            to={`/mirach/blogs-creator/${val._id}`}
                            className="btn text-primary"
                          >
                            Edit
                          </Link>
                        </div>
                      </td>
                    )
                  );
                  return (
                    <tr>
                      {cells.map((val) => {
                        return val;
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className={`${styles.paginationControls}`}>
          <button
            className={`btn ${styles.pageButton}`}
            id="prev"
            onClick={(e) => {
              changePageControls(e);
            }}
          >
            <ArrowLeftIcon style={{ pointerEvents: "none" }} />
          </button>
          {seedArray.map((val, idx) => {
            return (
              <button
                onClick={(e) => setCurrentIdx(0 + pageSize * (val - 1))}
                className={`btn ${styles.pageButton} ${
                  0 + pageSize * (val - 1) === currentIdx
                    ? styles.selectedPage
                    : ""
                }`}
              >
                {val}
              </button>
            );
          })}
          <button
            className={`btn ${styles.pageButton}`}
            id="next"
            onClick={(e) => {
              changePageControls(e);
            }}
          >
            <ArrowRightIcon style={{ pointerEvents: "none" }} />
          </button>
        </div>
      </>
    );
  }
};

export default MirachTable;
