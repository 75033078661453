import { useEffect, useState } from 'react'
import styles from './PriceComparator.module.css'
import { style } from '@mui/system'
import pricingFeatures from '../../Utils/pricing_features.json'
import pricingFeaturesProd from '../../Utils/pricing_features_prod.json'
import TickMarkIcon from '../TickMarkIcon/TickMarkIcon'
import AssignmentIcon from './AssigmentsIcon.svg'
import CalendarIcon from './CalendarIcon.svg'
import CustomTestIcon from './CustomTestIcon.svg'
import RoundCrossIcon from '../RoundCrossIcon/RoundCrossIcon'
import RoundTickIcon from '../RoundTickIcon/RoundTickIcon'
import Tooltip from '../Tooltip/Tooltip'
import InfoIcon from '../InfoIcon/InfoIcon'


const testData = [
    ["Pay Monthly *", "Free", "$10/mo", "$29/mo", "$99/mo"],
    ["Pay Yearly (up to 15%) *", "Free", "$8.5/mo", "$24.65/mo", "$84.15/mo"]
]

const tncData = [
    <div style={{ fontStyle: "italic" }}>*This is the base price for each plan. Product quantities can be increased separately as per your choice. See pricing for <a href='#prod_dep_feature' className='font-italic' style={{ color: "#0671E0", fontStyle: "italic" }}>Product Dependent Features</a> above.</div>
]

const productDependentFeatures = [
    [<div className='w-full flex items-center justify-start gap-[0.5rem]'><img src={CalendarIcon} style={{ height: "24px", width: "24px" }} /><div>Session Tags</div></div>, "1 Heading", "3 Headings", "10 Headings", "Unlimited"],
    [<div className='w-full flex items-center justify-start gap-[0.5rem]'><img src={AssignmentIcon} style={{ height: "24px", width: "24px" }} /><div>Included Test Material</div></div>, "2 DSAT®, All Drills, Official Answer Keys", "All Access", "All Access", "All Access"]
]

const iconMapping = {
    "Assignments": <img src={AssignmentIcon} style={{ height: "24px", width: "24px" }} />,
    "Calendar": <img src={CalendarIcon} style={{ height: "24px", width: "24px" }} />,
    "Content": <img src={CustomTestIcon} style={{ height: "24px", width: "24px" }} />,
}

const PriceHeader = ({ packageImage, packageName, buttonContent, customButtonClass, customClass, idx, onClick }) => {
    return (
        <div className={`${styles.wrapperDiv} ${customClass}`} >
            <div className={`d-flex flex-column justify-content-start align-items-center ${styles.priceCardHeader}`} style={{ gap: "36px" }}>
                <div className='d-flex justify-content-center align-items-center ' style={{ gap: "12px" }}>
                    <img src={packageImage} className='' style={{ height: "24px", width: "24px" }} />
                    <div className={styles.priceHeader}>{packageName}</div>
                </div>
                <button className={styles.buttonV2} onClick={(e) => onClick(e)} {...(idx === 0) && { style: { backgroundColor: "white", border: "2px solid #0671E0", color: "#0671E0" } }} id={idx}>{buttonContent}</button>
            </div>
        </div>
    )
}



const PriceComparator = ({ packageInfo, setAreCardsExpanded }) => {

    useEffect(() => {
        console.log(">>> TESTING", productDependentFeatures)
    }, [])

    const handleClick = (e) => {
        const idx = parseInt(e.target.id)
        if (idx !== 0) {
            const temp = [false, false, false, false]
            temp[idx] = true
            setAreCardsExpanded(temp)
        }
        window.scrollTo({ top: 400, left: null, behavior: "smooth" })
    }


    return (
        <div className={styles.container} id="price_comparator_table">
            {/* <h4 className=' pb-4 w-100 text-left' style={{ borderBottom: "1px solid #25335A" }} >Compare all plan features</h4> */}
            <div className={styles.priceComparatorHeader}>
                {
                    <div className={styles.dummy}>&nbsp;</div>
                }
                {
                    packageInfo?.map((aPackage, idx) => (<PriceHeader packageImage={aPackage.summaryImg} packageName={aPackage.basePackageName} buttonContent={aPackage.buttonContent} customButtonClass={aPackage.customButtonClass} onClick={handleClick} idx={idx} />))
                }
            </div>
            <div className={styles.sectionHeader}>
                <div className={styles.priceHeaderTwo}>Pricing</div>
            </div>
            <div className={styles.priceSection}>
                {
                    testData.map((row) => (
                        <div className={styles.priceRow}>
                            {
                                row.map((detail, idx) => (
                                    <div className={idx === 0 ? styles.priceDetailCellFirst : styles.priceDetailCell}>{detail}</div>
                                ))
                            }
                        </div>
                    ))
                }
                {
                    tncData.map((val) => (
                        <div className={styles.tncRow}>
                            {val}
                        </div>
                    ))
                }
            </div>
            <div className={styles.sectionHeader}>
                <div className={styles.priceHeaderTwo}>Features</div>
            </div>
            <div className={styles.priceSection}>
                {
                    pricingFeatures.map((feature, featureIdx) => (
                        <>
                            <div className={styles.featureRow}>
                                {
                                    Object.keys(feature).slice(1).map((key, idx) => (
                                        idx === 0 ?
                                            <div className={styles.featureDetailFirstCell}>
                                                <div className={styles.heading}>{feature[Object.keys(feature)[0]]}</div>
                                                <div className={styles.helperText}>{feature[key]}</div>
                                            </div>
                                            :
                                            key !== "tnc_text" ?
                                                <div className={`${styles.featureDetailCell}`}>
                                                    {
                                                        feature[key] === "present" ? <div style={{ color: "#2BA773" }}><RoundTickIcon /></div> : <div className={` ${feature[key] === "Not Available" ? styles.notAvailable : styles.normal}`}>{feature[key] === "Not Available" ? <div style={{ color: "#7C859C" }}><RoundCrossIcon /></div> : feature[key]}</div>
                                                    }
                                                </div>
                                                :
                                                <></>
                                    ))
                                }
                            </div>
                            {
                                feature["tnc_text"] ?
                                    <div className={styles.tncRow}>
                                        {
                                            feature["tnc_text"]
                                        }
                                    </div>
                                    :
                                    null
                            }

                        </>

                    ))
                }
            </div>
            <div className={styles.sectionHeader}>
                <div className={styles.priceHeaderTwo}>Product-Dependent Features</div>
            </div>
            <div className={styles.priceSection}>
                {
                    pricingFeaturesProd.map((feature, featureIdx) => (
                        <>
                            <div className={styles.featureRow}>
                                {
                                    Object.keys(feature).slice(1, 6).map((key, idx) => (
                                        idx === 0 ?
                                            <div className={styles.featureDetailFirstCell}>
                                                <div className={styles.heading}>{iconMapping[feature["product_dependency"]]}{feature[Object.keys(feature)[0]]}{feature["tooltip_text"] ? <Tooltip anchorComponent={<InfoIcon />} tooltipStyleClass={styles.tooltipStyle} tooltipContent={feature["tooltip_text"]} /> : <></>}</div>
                                                <div className={styles.helperText}>{feature[key]}</div>
                                            </div>
                                            :
                                            key !== "tnc_text" ?
                                                <div className={`${styles.featureDetailCell}`}>
                                                    {
                                                        feature[key] === "present" ? <div style={{ color: "#2BA773" }}><RoundTickIcon /></div> : <div className={` ${feature[key] === "Not Available" ? styles.notAvailable : styles.normal}`}>{feature[key] === "Not Available" ? <div style={{ color: "#7C859C" }}><RoundCrossIcon /></div> : feature[key]}</div>
                                                    }
                                                </div>
                                                :
                                                <></>
                                    ))
                                }
                            </div>
                            {
                                feature["tnc_text"] ?
                                    <div className={styles.tncRow}>
                                        {
                                            feature["tnc_text"]
                                        }
                                    </div>
                                    :
                                    null
                            }

                        </>

                    ))
                }
            </div>
        </div>
    )
}

export default PriceComparator