import React from 'react'

const RightArrowIcon = () => {
    return (
        <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6254 11.1539L8.25038 14.5289C8.09172 14.6875 8.01239 14.8894 8.01239 15.1346C8.01239 15.3798 8.09172 15.5817 8.25038 15.7404C8.40903 15.8991 8.61095 15.9784 8.85615 15.9784C9.10134 15.9784 9.30326 15.8991 9.46191 15.7404L13.4427 11.7596C13.5292 11.6731 13.5904 11.5793 13.6261 11.4784C13.6619 11.3774 13.6801 11.2692 13.6807 11.1539C13.6807 11.0385 13.6625 10.9303 13.6261 10.8293C13.5898 10.7284 13.5286 10.6346 13.4427 10.5481L9.46191 6.56733C9.30326 6.40867 9.10134 6.32935 8.85615 6.32935C8.61095 6.32935 8.40903 6.40867 8.25038 6.56733C8.09172 6.72598 8.01239 6.9279 8.01239 7.1731C8.01239 7.41829 8.09172 7.62021 8.25038 7.77887L11.6254 11.1539Z" fill="black" />
        </svg>

    )
}

export default RightArrowIcon