import React, { useRef, useState } from 'react'
import styles from "./enquiryFormV2.module.css"
import { toast } from 'react-toastify'
import { insertQueryInDynamoDb, sendEnquiry } from '../../Utils/utils'
import { ToastContainer } from 'react-toastify';
import PopUp from '../PopUp/PopUp';
import LeadForm from '../LeadForm/LeadForm';
// import EnquiryBg from './Enquiry-form-bg-vector/EnquiryBg';


const EnquiryFormV2 = (props) => {

    const formRef = useRef()
    const [leadFormTrigger, setLeadFormTrigger] = useState(false)

    const [leadSubmited, setLeadSubmited] = useState(false)
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")



    return (
        <div >
            <div className={styles.eForm_container}>
                {
                    leadFormTrigger ?  
                        <PopUp trigger={leadFormTrigger} setTrigger={setLeadFormTrigger}>
                            <LeadForm  {...{firstName,lastName,email,phone,setFirstName,setLastName,setPhone,message,setMessage,setEmail, setFormPopUpTrigger : setLeadFormTrigger}}/>
                        </PopUp>
                    : <></>
                }
                <div>
                    <h1>Scale your Tutoring Business 10X</h1>
                    <p>We help scale tutoring businesses with our All-in-one Tutoring Management Tool - Evallo. </p>
                    <button onClick={(e)=>setLeadFormTrigger(true)} className={styles.submit_btn}>Book a demo</button>
                </div>
            </div>
            {/* <div className=' form-bottom-strip'></div> */}
            <ToastContainer />
        </div>
    )
}

export default EnquiryFormV2

