import React, { useEffect, useState } from 'react'
import styles from "./carousel.module.css"
import PriceTableCard from '../PriceTableCard/PriceTableCard'
import RightArrowIcon from '../../assets/iconComponents/RightArrowIcon';
import LeftArrowIcon from '../../assets/iconComponents/LeftArrowIcon';

const Carousel = ({ yearly, yearlyProductBundle, monthlyProductBundle }) => {

    const [slide, setSlide] = useState(0);

    const nextSlide = () => {
        setSlide(slide === monthlyProductBundle.length - 1 ? 0 : slide + 1);
    };

    const prevSlide = () => {
        setSlide(slide === 0 ? monthlyProductBundle.length - 1 : slide - 1);
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setSlide(slide === monthlyProductBundle.length - 1 ? 0 : slide + 1);
        }, 3000)
        return () => clearInterval(timer);
    }, [slide])


    return (
        <div className={styles.responsive_bundle_product_container}>
            <h1>Pricing for product bundles</h1>
            <div>
                <div className={styles.responsive_bundle_product}>
                    <div>
                        {
                            yearly ?
                                yearlyProductBundle.map((el, i) => {
                                    return <>
                                        <PriceTableCard bundle={el} key={i} idx={i} currentSlide={slide} isYearly={yearly} />
                                    </>
                                })
                                :
                                monthlyProductBundle.map((el, i) => {
                                    return <>
                                        <PriceTableCard bundle={el} key={i} idx={i} currentSlide={slide} isYearly={yearly} />
                                    </>
                                })
                        }
                    </div>
                    <div className={styles.slide_btn}>
                        <div className={styles.left_btn} onClick={prevSlide}> <LeftArrowIcon /> </div>
                        <div className={styles.right_btn} onClick={nextSlide}> <RightArrowIcon /> </div>
                    </div>
                    <div className={styles.indicator}>
                        {
                            monthlyProductBundle.map((el, i) => {
                                return <div key={i} onClick={() => setSlide(i)} style={{ background: `${slide === i ? "#000" : "#D9D9D9"}` }}></div>
                            })
                        }
                    </div>
                </div>
            </div>
            <div>You can <span>SAVE 15%</span> with yearly billing.</div>
        </div>
    )
}

export default Carousel