import React, { useState } from 'react'
import styles from './simpleEnquiryForm.module.css'
import axios from "axios";
import { insertQueryInDynamoDb } from '../../Utils/utils';
import { toast } from 'react-toastify';

const SimpleEnquiryForm = ({ setActivePopup }) => {

    const [fullName, setFullName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [organization, setOrgnization] = useState("")
    const [website, setWebsite] = useState("")
    const [city, setCity] = useState("")
    const [loaderIcon, setLoaderIcon] = useState(false);
    const [success, setSuccess] = useState(false);

    const isEmail = (val) => {
        let regEmail =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEmail.test(val)) {
            return false;
        } else {
            return true;
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const firstName = fullName.split(" ")[0]
        let lastName = ""
        if (fullName.split(" ")[1]) {
            lastName = fullName.split(" ")[1]
        } else {
            lastName = "NA"
        }

        if (fullName === "" || email === "" || phone === "") {
            toast.warn("Please fill required fields.");
            return;
        }
        if (phone.length > 10) {
            toast.warn("Enter correct phone number");
            return;
        }
        if (isEmail(email) == false) {
            toast.warn("Enter correct email.");
            return;
        }

        setLoaderIcon(true);
        try {
            const result = await insertQueryInDynamoDb({
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone: phone,
                message: `Orgnization: ${organization} | Website: ${website} | City: ${city}`,
                queryFor: "sales",
            });
            setSuccess(true);
            window.history.pushState({}, "", `book-demo/call-scheduling`);
            // if (setLeadSubmited) {
            //     setLeadSubmited(true);
            // }
            toast.success("Thank you for entering the query.");

            //   if (setFormPopUpTrigger) setFormPopUpTrigger(false);
            setActivePopup(false)
            window.ApolloMeetings.submit({
                map: false,
                lead: { first_name: firstName, last_name: lastName, email: email, phone: phone, message: `Orgnization: ${organization} | Website: ${website} | City: ${city}` },
                onSuccess: () => {
                    toast.success(
                        "Meeting booked successfully. Looking forward to meet you."
                    );
                    setFullName('')
                    setPhone("")
                    setEmail("")
                    setCity("")
                    setOrgnization("")
                    setWebsite("")
                },
            });
        } catch (err) {
            setLoaderIcon(false);
            console.log(err);
            toast.error("Oops an error occured");
            //   if (setFormPopUpTrigger) setFormPopUpTrigger(false);
        }

        setLoaderIcon(false)
    };

    // const submitLead = async (e) => {
    //     e.preventDefault();

    //     const formData = new FormData();
    //     formData.append("fullName", fullName);
    //     formData.append("email", email);
    //     formData.append("phoneNumber", phoneNumber);
    //     formData.append("organization", organization);
    //     formData.append("website", website);
    //     formData.append("city", city);

    //     try {
    //         const response = await fetch("https://script.google.com/macros/s/AKfycbxQgefMFV850bSkJM7ROC0iRUUPCS_S8vWH31P-jMhMnZ5OMiIvPoDluLai-qo6jEaJ/exec", {
    //             method: 'POST',  // POST method

    //             body: formData  // Stringify the data
    //         });

    //         const responseData = await response.json();
    //         console.log("result:", response);

    //     } catch (error) {
    //         console.error("Error:", error);
    //     }
    // };



    return (
        <form className={styles.simpleForm} action="" onSubmit={(e) => handleFormSubmit(e)}>
            <h6>Get Your <span>Free Demo</span> Now</h6>
            <div className={styles.inputFieldContainer}>
                <input type="text" name='full_name' placeholder='Full Name*' onChange={(e) => setFullName(e.target.value)} />
                <input type="text" name='email' placeholder='Email*' onChange={(e) => setEmail(e.target.value)} />
                <input type="text" name='phone_number' placeholder='Phone Number*' onChange={(e) => setPhone(e.target.value)} />
                <input type="text" name='orgnization' placeholder='Orgnization' onChange={(e) => setOrgnization(e.target.value)} />
                <input type="text" name='website' placeholder='Website' onChange={(e) => setWebsite(e.target.value)} />
                <input type="text" name='city' placeholder='City' onChange={(e) => setCity(e.target.value)} />
            </div>
            <button className={styles.submitBtn} type="submit" disabled={loaderIcon} > <span>{loaderIcon ? "Submitting..." : "Submit"}</span>
            </button>
        </form>
    )
}

export default SimpleEnquiryForm