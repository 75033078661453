import { useState } from 'react';
import { ButtonHollow } from '../Buttons'
import styles from './FeaturesCard.module.css'
import Tooltip from '@mui/material/Tooltip';


export default function FeaturesCard({ rev = false, img, heading, description, buttonText, trigger, setTrigger, idx, setVidIdx, buttonDisabled }) {

    const [showMessage, setShowMessage] = useState(false)
    const [isSmallDevice, setIsSmallDevice] = useState(false)

    return (
        <div className={`testing d-flex flex-column gap-3 justify-content-between align-items-start ${styles.container} ${rev ? `flex-lg-row-reverse` : "flex-lg-row"} gap-lg-5 align-items-lg-center`}>
            <div className={styles.imgContainer}>
                <img src={img} className={styles.img} />
            </div>
            <div className={`d-flex flex-column gap-2 gap-lg-3 justify-content-start align-items-start ${styles.contentContainer}`}>
                <div className={styles.heading}>
                    {heading}
                </div>
                <div className={styles.description}>
                    {description}
                </div>
                <div className='d-none d-lg-block mt-lg-2'>
                    {
                        setTrigger ?
                            <ButtonHollow onClick={(e) => { if (setTrigger) { setTrigger(!trigger); setVidIdx(idx) } }} disabled={buttonDisabled === true}>{buttonText}</ButtonHollow>
                            :
                            <></>
                            // <Tooltip title="Coming Soon" open={showMessage && isSmallDevice === false}>
                            //     <ButtonHollow onClick={(e) => { setShowMessage(true); setIsSmallDevice(false) }} onMouseLeave={(e) => { setShowMessage(false); setIsSmallDevice(false) }} onMouseEnter={(e) => { setShowMessage(true); setIsSmallDevice(false) }}>{buttonText}</ButtonHollow>
                            // </Tooltip>
                    }

                </div>
            </div>
            <div className='d-lg-none'>
                {
                    setTrigger ?
                        <ButtonHollow onClick={(e) => { if (setTrigger) { setTrigger(!trigger); setVidIdx(idx) } }} disabled={buttonDisabled === true}>{buttonText}</ButtonHollow>
                        :
                        <></>
                        // <Tooltip title="Coming Soon" open={showMessage && isSmallDevice === true} placement='right'>
                        //     <ButtonHollow onClick={(e) => { setShowMessage(true); setIsSmallDevice(true) }} onMouseLeave={(e) => { setShowMessage(false); setIsSmallDevice(true) }} onMouseEnter={(e) => { setShowMessage(true); setIsSmallDevice(true) }}>{buttonText}</ButtonHollow>
                        // </Tooltip>
                }
            </div>

        </div>
    )
}