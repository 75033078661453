import React from 'react'
import "./titleTag.css"

const TitleTag = ({ title }) => {
    return (
        <div className='d-flex my-3 justify-content-center align-items-center'>
            <span className='line'></span>
            <span className='box-heading'>{title}</span>
            <span className='line' style={{ transform: "rotate(180deg)" }}>   </span>
        </div>
    )
}

export default TitleTag