import React from 'react'
import styles from './responsiveIcon.module.css'

const CompanyGroupIcon = () => {
    return (
        <svg className={styles.responsiveIconSize} viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="38" height="38" rx="4" fill="white" />
            <path d="M26.4375 22.1871H25.375V17.4058C25.375 17.2305 25.2847 17.0605 25.1359 16.9649L22.1875 14.9993V14.2236C22.1875 13.9314 21.9484 13.6924 21.6562 13.6924H21.125V12.0986C21.125 11.8064 20.8859 11.5674 20.5938 11.5674H17.4062C17.1141 11.5674 16.875 11.8064 16.875 12.0986V13.6924H16.3438C16.0516 13.6924 15.8125 13.9314 15.8125 14.2236V15.8174H14.75V15.2861H13.6875V15.8174H13.1562C12.8641 15.8174 12.625 16.0564 12.625 16.3486V22.1924H11.5625V23.2549H26.4375V22.1924V22.1871ZM15.2812 22.1871H14.2188V21.1246H15.2812V22.1871ZM21.125 22.1871H19.5312V20.5933H18.4688V22.1871H16.875V14.7496H21.125V22.1871ZM23.7812 22.1871H22.7188V21.1246H23.7812V22.1871Z" fill="#25335A" />
            <path d="M17.4062 15.2812H18.4688V16.3438H17.4062V15.2812Z" fill="#25335A" />
            <path d="M19.5312 15.2812H20.5938V16.3438H19.5312V15.2812Z" fill="#25335A" />
            <path d="M17.4062 16.875H18.4688V17.9375H17.4062V16.875Z" fill="#25335A" />
            <path d="M19.5312 16.875H20.5938V17.9375H19.5312V16.875Z" fill="#25335A" />
            <path d="M9.4375 12.625C11.7803 12.625 13.6875 10.7178 13.6875 8.375C13.6875 6.03219 11.7803 4.125 9.4375 4.125C7.09469 4.125 5.1875 6.03219 5.1875 8.375C5.1875 10.7178 7.09469 12.625 9.4375 12.625ZM9.4375 5.1875C11.1959 5.1875 12.625 6.61656 12.625 8.375C12.625 8.99656 12.4444 9.58094 12.1309 10.0697C11.435 9.33125 10.4734 8.90625 9.4375 8.90625C8.40156 8.90625 7.44 9.33656 6.74406 10.0697C6.43063 9.57562 6.25 8.99656 6.25 8.375C6.25 6.61656 7.67906 5.1875 9.4375 5.1875Z" fill="#25335A" />
            <path d="M9.41016 8.90625C10.2904 8.90625 11.0039 8.1927 11.0039 7.3125C11.0039 6.4323 10.2904 5.71875 9.41016 5.71875C8.52995 5.71875 7.81641 6.4323 7.81641 7.3125C7.81641 8.1927 8.52995 8.90625 9.41016 8.90625Z" fill="#25335A" />
            <path d="M32.8125 8.90625C32.8125 6.56344 30.9053 4.65625 28.5625 4.65625C26.2197 4.65625 24.3125 6.56344 24.3125 8.90625C24.3125 11.2491 26.2197 13.1562 28.5625 13.1562C30.9053 13.1562 32.8125 11.2491 32.8125 8.90625ZM25.375 8.90625C25.375 7.14781 26.8041 5.71875 28.5625 5.71875C30.3209 5.71875 31.75 7.14781 31.75 8.90625C31.75 9.52781 31.5694 10.1122 31.2559 10.6009C30.56 9.8625 29.5984 9.4375 28.5625 9.4375C27.5266 9.4375 26.565 9.86781 25.8691 10.6009C25.5556 10.1069 25.375 9.52781 25.375 8.90625Z" fill="#25335A" />
            <path d="M28.5352 9.4375C29.4154 9.4375 30.1289 8.72395 30.1289 7.84375C30.1289 6.96355 29.4154 6.25 28.5352 6.25C27.655 6.25 26.9414 6.96355 26.9414 7.84375C26.9414 8.72395 27.655 9.4375 28.5352 9.4375Z" fill="#25335A" />
            <path d="M5.1875 29.0938C5.1875 31.4366 7.09469 33.3438 9.4375 33.3438C11.7803 33.3438 13.6875 31.4366 13.6875 29.0938C13.6875 26.7509 11.7803 24.8438 9.4375 24.8438C7.09469 24.8438 5.1875 26.7509 5.1875 29.0938ZM12.625 29.0938C12.625 29.7153 12.4444 30.2997 12.1309 30.7884C11.435 30.05 10.4734 29.625 9.4375 29.625C8.40156 29.625 7.44 30.0553 6.74406 30.7884C6.43063 30.2944 6.25 29.7153 6.25 29.0938C6.25 27.3353 7.67906 25.9062 9.4375 25.9062C11.1959 25.9062 12.625 27.3353 12.625 29.0938Z" fill="#25335A" />
            <path d="M9.41016 29.625C10.2904 29.625 11.0039 28.9115 11.0039 28.0312C11.0039 27.151 10.2904 26.4375 9.41016 26.4375C8.52995 26.4375 7.81641 27.151 7.81641 28.0312C7.81641 28.9115 8.52995 29.625 9.41016 29.625Z" fill="#25335A" />
            <path d="M28.5625 25.375C26.2197 25.375 24.3125 27.2822 24.3125 29.625C24.3125 31.9678 26.2197 33.875 28.5625 33.875C30.9053 33.875 32.8125 31.9678 32.8125 29.625C32.8125 27.2822 30.9053 25.375 28.5625 25.375ZM31.2559 31.3197C30.56 30.5812 29.5984 30.1562 28.5625 30.1562C27.5266 30.1562 26.565 30.5866 25.8691 31.3197C25.5556 30.8256 25.375 30.2466 25.375 29.625C25.375 27.8666 26.8041 26.4375 28.5625 26.4375C30.3209 26.4375 31.75 27.8666 31.75 29.625C31.75 30.2466 31.5694 30.8309 31.2559 31.3197Z" fill="#25335A" />
            <path d="M28.5352 30.1562C29.4154 30.1562 30.1289 29.4427 30.1289 28.5625C30.1289 27.6823 29.4154 26.9688 28.5352 26.9688C27.655 26.9688 26.9414 27.6823 26.9414 28.5625C26.9414 29.4427 27.655 30.1562 28.5352 30.1562Z" fill="#25335A" />
            <path d="M14.1389 31.9258L13.7617 32.9192C15.4298 33.5514 17.1936 33.8702 18.9998 33.8702C20.8061 33.8702 22.5752 33.5514 24.238 32.9192L23.8608 31.9258C20.7689 33.0998 17.2255 33.0998 14.1336 31.9258H14.1389Z" fill="#25335A" />
            <path d="M6.65516 25.194C5.68297 23.2656 5.18891 21.1831 5.18891 18.9996C5.18891 16.8162 5.68297 14.7337 6.65516 12.8053L5.70422 12.3271C4.65234 14.4043 4.12109 16.6462 4.12109 18.9996C4.12109 21.3531 4.65234 23.595 5.70422 25.6721L6.65516 25.194Z" fill="#25335A" />
            <path d="M31.3477 12.8053C32.3198 14.7337 32.8139 16.8162 32.8139 18.9996C32.8139 21.1831 32.3198 23.2656 31.3477 25.194L32.2986 25.6721C33.3505 23.595 33.8817 21.3531 33.8817 18.9996C33.8817 16.6462 33.3505 14.4043 32.2986 12.3271L31.3477 12.8053Z" fill="#25335A" />
            <path d="M23.8622 6.07453L24.2394 5.08109C20.9084 3.81672 17.0887 3.81672 13.7578 5.08109L14.135 6.07453C17.2269 4.90047 20.7703 4.90047 23.8622 6.07453Z" fill="#25335A" />
        </svg>

    )
}

export default CompanyGroupIcon