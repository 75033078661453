import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { SignInUser } from "./SignInAction";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "../../../validations/loginValidations";
import "./SignIn.css";
import { getTokenCookie } from "../../../Utils/authTools";

const SignIn = ({setIsLoggedIn}) => {

  const navigate = useNavigate()
  const [loginError, setLoginError] = useState();


  useEffect(()=>{
    if(getTokenCookie()){
      navigate("/mirach/home")
    }
  },[])

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      if (data) {
        let response = await SignInUser(data);
        if (response) {
          setIsLoggedIn(true)
          navigate("/mirach/docs");
        }
      }
    } catch (err) {
      if (err.response) {
        setLoginError(err.response.data.message);
      } else {
        setLoginError(err.message);
      }
    }
  };

  return (
    <div className="row m-auto p-0 z-2 " style={{gridColumn : "1/3", width : "100%"}}>
      <div className="">
        <form className="z-2 signin-from" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <h2>Evallo SignIn</h2>
          </div>

          <label className="d-flex flex-column" htmlFor="email">
            Email
            <input
              type="text"
              placeholder="Johndoe@evallo.org"
              id="email"
              name="email"
              {...register("email")}
            />
          </label>
          {
            <div
              className="invalid-feedback  text-output-error text-xs px-2 pt-1"
              style={errors.email ? { display: "block" } : {}}
            >
              {errors.email?.message}
            </div>
          }

          <div className="d-flex flex-wrap flex-lg-nowrap gap-lg-3">
            <label className="d-flex flex-column" htmlFor="password">
              Password
              <input
                type="password"
                placeholder="*****"
                id="password"
                name="password"
                {...register("password")}
              />
            </label>
            <div
              className="invalid-feedback text-center text-output-error text-xs px-2 py-2 pt-1 "
              style={loginError ? { display: "block" } : {}}
            >
              {loginError ? loginError : null}
            </div>
          </div>

          <button className="submit-btn">Sign In</button>
        </form>
        <div className="formStrip"></div>
      </div>{" "}
    </div>
  );
};

export default SignIn;
