import React from 'react'
import styles from './responsiveIcon.module.css'

const CoachIcon = () => {
    return (
        <svg className={styles.responsiveIconSize} viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="38" height="38" rx="4" fill="white" />
            <path d="M9 9.5C9 8.119 10.119 7 11.5 7C12.881 7 14 8.119 14 9.5C14 10.881 12.881 12 11.5 12C10.119 12 9 10.881 9 9.5ZM22 13V15H15V21H7V16C7 14.346 8.346 13 10 13H22ZM28 7H15.24C15.719 7.715 16 8.575 16 9.5C16 10.026 15.908 10.531 15.742 11H24V17H17V21H25V19H29V21H31V10C31 8.343 29.657 7 28 7ZM10.5 27C11.605 27 12.5 26.105 12.5 25C12.5 23.895 11.605 23 10.5 23C9.395 23 8.5 23.895 8.5 25C8.5 26.105 9.395 27 10.5 27ZM19 27C20.105 27 21 26.105 21 25C21 23.895 20.105 23 19 23C17.895 23 17 23.895 17 25C17 26.105 17.895 27 19 27ZM27.5 27C28.605 27 29.5 26.105 29.5 25C29.5 23.895 28.605 23 27.5 23C26.395 23 25.5 23.895 25.5 25C25.5 26.105 26.395 27 27.5 27ZM14 30C14 28.897 13.103 28 12 28H9C7.897 28 7 28.897 7 30V31H14V30ZM31 30C31 28.897 30.103 28 29 28H26C24.897 28 24 28.897 24 30V31H31V30ZM22.5 30C22.5 28.897 21.603 28 20.5 28H17.5C16.397 28 15.5 28.897 15.5 30V31H22.5V30Z" fill="#25335A" />
        </svg>

    )
}

export default CoachIcon