import { useContext, useEffect, useRef, useState } from 'react';
import styles from './DocsSearchBar.module.css'
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import docsIcon from './docsIcon.svg'
import { Link } from 'react-router-dom';
import NavLinkContext from '../../contexts/NavLinkContext';
import { searchDocsForKeyword } from '../../Utils/utils';

const result = [
    {
        heading: "Test Admin Sign Up",
        link: "Admin get started > Sign up"
    },
    {
        heading: "Test Admin Sign Up",
        link: "Admin get started > Sign up"
    },
    {
        heading: "Test Admin Sign Up",
        link: "Admin get started > Sign up"
    },
    {
        heading: "Test Admin Sign Up",
        link: "Admin get started > Sign up"
    },
    {
        heading: "Test Admin Sign Up",
        link: "Admin get started > Sign up"
    }
]

const DocsSearchRes = ({ title, description, publishId, module, section, setSearchText,setShowMenu }) => {
    return (
        
        <div className={`${styles.container}`}>
            <div className={styles.docsIcon}><img src={docsIcon} /></div>
            <div className={styles.resultContainer}>
                <div>{title}</div>
                <Link to={`/docs/published/${module === "home" ? "home": module+"s"}/${section}/${publishId}`} onClick={(e)=>{setSearchText("");if(setShowMenu)setShowMenu(false);}} target='_top'>
                    {module === "home" ? "Home": module+"s"}{" >> "}{section}{" >> "}{title}
                </Link>
            </div>
        </div>
    )
}

const DocsSearchBar = (props) => {

    const searchFieldRef = useRef(null)
    const [searchText, setSearchText] = useState("")
    const {setIsInTheBackground} = useContext(NavLinkContext)
    const [matchingDocs, setMatchingDocs] = useState([])

    useEffect(()=>{
        if(searchText !== "" ){
            setIsInTheBackground(true)
        } else {
            setIsInTheBackground(false)
        }
    },[searchText])

    const searchTextChanged = async (e) => {
        console.log(e.target.value.trim())
        setSearchText(e.target.value.trim())
        const matchingDocs = await searchDocsForKeyword(e.target.value.trim())
        setMatchingDocs(matchingDocs)
    }

    const handleCloseClicked = (e) => {
        if (searchFieldRef.current) {
            searchFieldRef.current.value = ""
            setSearchText("")
        }
    }

    return (
        <>
            <div className={`input-group ${!searchText ? styles.searchBarNormal : styles.searchBarSelected}`}>
                <span className={`input-group-text ${styles.searchIcon}`}><SearchIcon /></span>
                <input ref={searchFieldRef} type="text" className={`form-control ${styles.searchField}`} placeholder='Search anything here' onChange={(e) => searchTextChanged(e)} />
                {
                    searchText ?
                        <button className={styles.closeButton} onClick={(e) => handleCloseClicked(e)}><CloseIcon style={{ pointerEvents: "none" }} /></button>
                        : <></>
                }
            </div>

            {
                searchText ?
                    <div className={styles.superContainer}>
                        {
                            matchingDocs.map((val, idx) => {
                                return (
                                    <DocsSearchRes {...val} setSearchText={setSearchText} setShowMenu={props.setShowMenu}/>
                                )
                            })
                        }
                    </div>
                    :
                    null
            }

        </>
    )
}


export default DocsSearchBar