import React, { useEffect, useState } from "react";
import styles from "./pricingV2.module.css";
import PriceCard from "../../components/PriceCardV3/PriceCard";
import PriceTableCard from "../../components/PriceTableCard/PriceTableCard";
import CalendarIcon from "../../assets/pricingPageIcons/CalendarIcon";
import AssignmentIcon from "../../assets/pricingPageIcons/AssignmentIcon";
import CustomContentIcon from "../../assets/pricingPageIcons/CustomContentIcon";
import EnquiryFormV2 from "../../components/EnquiryFormV2/EnquiryFormV2";
import PriceComparator from "../../components/PriceComparator/PriceComparator";
import explorerIcon from "./ExplorerIcon.svg";
import starterIcon from "./StarterIcon.svg";
import professionalIcon from "./ProfessionalIcon.svg";
import premiumIcon from "./PremiumIcon.svg";
import getRecommendedPackageWithProducts from "../../Utils/package_recommender";
import Carousel from "../../components/carosuel/Carousel";
import { useSearchParams } from "react-router-dom";

const PricingV2 = () => {
  const [selectedPriceIndex, setSelectedPriceIndex] = useState(0);
  const [selectedValue, setSelectedValue] = useState(0);
  const [priceFeature, setPriceFeatures] = useState("General");
  const [recommendedPlan, setRecommendedPlan] = useState("");
  const [yearly, setYearly] = useState(false);
  const [numberOfTutors, setNumberOfTutors] = useState("6");
  // const [params,setparams] = useSearchParams();

  const [monthlySubscriptionData, setMonthlySubscriptionData] = useState([
    {
      type: "Explorer",
      cost: "Free",
      desc: "Experience powerful features for free but with limited capabilities.",
    },
    {
      type: "Starter",
      cost: "10",
      desc: "Ideal for freelance tutors and small businesses.",
    },
    {
      type: "Professional",
      cost: "29",
      desc: "Ideal for small businesses  looking to scale up and take it to the next level.",
    },
    {
      type: "Premium",
      cost: "99",
      desc: "Ideal for larger businesses and enterprises.",
    },
  ]);

  const [yearlySubscriptionData, setYearlySubscriptionData] = useState([
    {
      type: "Explorer",
      cost: "Free",
      desc: "Experience powerful features for free but with limited capabilities.",
    },
    {
      type: "Starter",
      cost: "102",
      desc: "Ideal for freelance tutors and small businesses.",
    },
    {
      type: "Professional",
      cost: "296",
      desc: "Ideal for small businesses  looking to scale up and take it to the next level.",
    },
    {
      type: "Premium",
      cost: "1010",
      desc: "Ideal for larger businesses and enterprises.",
    },
  ]);

  const [monthlyProductBundle, setMonthlyProductBundle] = useState([
    {
      icon: <CalendarIcon />,
      title: "Calendar",
      desc: "Seamlessly schedule sessions for students and student groups.",
      limitTitle: "No. of Sessions",
      limits: [
        {
          quantity: "100",
          cost: "0",
        },
        {
          quantity: "250",
          cost: "4",
        },
        {
          quantity: "1500",
          cost: "10",
        },
        {
          quantity: "Unlimited",
          cost: "30",
        },
      ],
      additional:
        "Additional charge of 3¢/ session once you exhaust your usage limits.",
    },
    {
      icon: <AssignmentIcon />,
      title: "Assignments",
      desc: "Practice tests, drills, question banks, and more, added by Evallo or you.",
      limitTitle: "Assignment Credits",
      limits: [
        {
          quantity: "15",
          cost: "0",
        },
        {
          quantity: "100",
          cost: "10",
        },
        {
          quantity: "400",
          cost: "36",
        },
        {
          quantity: "1500",
          cost: "109",
        },
      ],
      additional:
        "Additional charge of 15¢/ session once you exhaust your usage limits.",
    },
    {
      icon: <CustomContentIcon />,
      title: "Custom Tests",
      desc: "Set up your brand identity and give your clients a premium experience.",
      limitTitle: "Test Uploads",
      limits: [
        {
          quantity: "2",
          cost: "0",
        },
        {
          quantity: "10",
          cost: "6",
        },
        {
          quantity: "30",
          cost: "9",
        },
        {
          quantity: "Unlimited",
          cost: "12",
        },
      ],
      additional:
        "You can upload custom tests up to the limits mentioned above.",
    },
  ]);
  const [yearlyProductBundle, setYearlyProductBundle] = useState([
    {
      icon: <CalendarIcon />,
      title: "Calendar",
      desc: "Seamlessly schedule sessions for students and student groups.",
      limitTitle: "No. of Sessions",
      limits: [
        {
          quantity: "1200",
          cost: "0",
        },
        {
          quantity: "3000",
          cost: "41",
        },
        {
          quantity: "18000",
          cost: "102",
        },
        {
          quantity: "Unlimited",
          cost: "260",
        },
      ],
      additional:
        "Additional charge of 3¢/ session once you exhaust your usage limits.",
    },
    {
      icon: <AssignmentIcon />,
      title: "Assignments",
      desc: "Practice tests, drills, question banks, and more, added by Evallo or you.",
      limitTitle: "Assignment Credits",
      limits: [
        {
          quantity: "180",
          cost: "0",
        },
        {
          quantity: "1200",
          cost: "102",
        },
        {
          quantity: "4800",
          cost: "367",
        },
        {
          quantity: "18000",
          cost: "1112",
        },
      ],
      additional:
        "Additional charge of 15¢/ session once you exhaust your usage limits.",
    },
    {
      icon: <CustomContentIcon />,
      title: "Custom Tests",
      desc: "Set up your brand identity and give your clients a premium experience.",
      limitTitle: "Test Uploads",
      limits: [
        {
          quantity: "2",
          cost: "0",
        },
        {
          quantity: "120",
          cost: "61",
        },
        {
          quantity: "360",
          cost: "92",
        },
        {
          quantity: "Unlimited",
          cost: "122",
        },
      ],
      additional:
        "You can upload custom tests up to the limits mentioned above.",
    },
  ]);

  const _monthlyPackages = [
    {
      summaryImg: explorerIcon,
      isRecommended: false,
      basePackageName: "Explorer",
      _CTAButtonText: "Get started",
      basePackageDescription: (
        <>
          Experience powerful features for free.
          <span className="text-[#0671E0]">
            &nbsp;Credit Card NOT required.
          </span>{" "}
        </>
      ),
      price: "Free",
      packageType: "annual",
      buttonContent: "Get started",
      additionalDetails: [
        {
          name: "basePackage",
          details: [
            "Includes 1 Tutor Licenses",
            "Unlimited students / parents",
            <>
              Student groups Allowed - <span className="font-[700]">10</span>
            </>,
            <>
              Calendar - <span className="font-[700]">1200</span> sessions
            </>,
            <>
              Assignments - <span className="font-[700]">180</span> credits
            </>,
            <>
              <span className="font-[700]">2</span>&nbsp;Full-length DSAT®
            </>,
          ],
          tooltipText: [
            "Your lifetime free subscription comes with the ability to create one tutor profile that can be mapped with the students you onboard.",
            "You can invite and/or store client profiles for students and parents without any restrictions.",
            "Club students within groups to save time with assignments, scheduling, invoicing, etc.",
            "Your free subscription comes with 1200 sessions that can be reconciled on the Calendar (you need to enable the product to use this feature).",
            'Your free subscription comes with 180 Assignments that can be sent to the student for practice. This includes practice tests, drills, question banks, and other such material that is either added by Evallo or created by you. Note that you need to enable the "Assignments" product to access this feature.',
            'Your free subscription comes with 2 Full-length DSAT® that can be assigned to the students (you need to enable the "Assignments" product to use this feature).',
          ],
        },
      ],
      selectedProducts: ["basePackage", "sessions"],
      normalPrice: "$58",
      onCTAButtonClick: () => {
        window.open("https://app.evallo.org/signup", "_blank");
      },
      showPAYG: false,
    },
    {
      summaryImg: starterIcon,
      isRecommended: false,
      basePackageName: "Starter",
      _CTAButtonText: "Start free trial",
      basePackageDescription:
        "Ideal for freelance tutors and small businesses. Credit Card required.",
      price: "$10",
      packageType: "monthly",
      buttonContent: "Start free trial",
      additionalDetails: [
        {
          name: "basePackage",
          details: [
            <>
              Tutors licenses included-<span className="font-[700]">1</span>
            </>,
            "Unlimited CRM features",
            <>
              <span className="font-[700]">5</span>&nbsp;Full-length DSAT®
            </>,
            "White labeling (limited)",
          ],
          tooltipText: [
            "Your subscription comes with the ability to create 1 tutor licenses that you can use to invite your team.",
            "Includes the following features: Unlimited student and parent accounts, Client management, Advanced profile filters, Student groups, Custom tags, Leads pipeline, Customer profiles, Dashboards, and more!",
            'Your subscription comes with 5 Full-length DSAT® that can be assigned to the students (you need to enable the "Assignments" product to use this feature).',
            "Set up your brand identity and give your clients a premium experience.",
          ],
        },
        {
          name: "Calendar",
          details: ["Calendar - sessions"],
          tooltipText: [
            "You have chosen {X} Calendar sessions to be included with your subscription.",
          ],
        },
        {
          name: "Assignments",
          details: ["Assignment - credits"],
          tooltipText: [
            "You have chosen {X} Assignment credits to be included with your subscription. This includes practice tests, drills, question banks, and other such material that is either added by Evallo or created by you.",
          ],
        },
      ],
      selectedProducts: ["basePackage", "Calendar", "Assignments"],
      normalPrice: "$58",
      onCTAButtonClick: () => {
        window.open("https://app.evallo.org/signup", "_blank");
      },
      showPAYG: true,
    },
    {
      summaryImg: professionalIcon,
      isRecommended: false,
      basePackageName: "Professional",
      _CTAButtonText: "Start free trial",
      basePackageDescription:
        "Ideal for SMBs looking to scale up. Credit Card required.",
      price: "$10",
      packageType: "monthly",
      buttonContent: "Start free trial",
      additionalDetails: [
        {
          name: "basePackage",
          details: [
            <>
              Tutors licenses included-<span className="font-[700]">10</span>
            </>,
            "Unlimited CRM features",
            <>
              <span className="font-[700]">10</span>&nbsp;Full-length DSAT®
            </>,
            "White labeling",
          ],
          tooltipText: [
            "Your subscription comes with the ability to create 10 tutor licenses that you can use to invite your team.",
            "Includes the following features: Unlimited student and parent accounts, Client management, Advanced profile filters, Student groups, Custom tags, Leads pipeline, Customer profiles, Dashboards, and more!",
            'Your subscription comes with 10 Full-length DSAT® that can be assigned to the students (you need to enable the "Assignments" product to use this feature).',
            "Set up your brand identity and give your clients a premium experience.",
          ],
        },
        {
          name: "Calendar",
          details: ["Calendar - sessions"],
          tooltipText: [
            "You have chosen {X} Calendar sessions to be included with your subscription.",
          ],
        },
        {
          name: "Assignments",
          details: ["Assignment - credits"],
          tooltipText: [
            "You have chosen {X} Assignment credits to be included with your subscription. This includes practice tests, drills, question banks, and other such material that is either added by Evallo or created by you.",
          ],
        },
      ],
      selectedProducts: ["basePackage", "Calendar", "Assignments"],
      normalPrice: "$58",
      onCTAButtonClick: () => {
        window.open("https://app.evallo.org/signup", "_blank");
      },
      showPAYG: true,
    },
    {
      summaryImg: premiumIcon,
      isRecommended: false,
      basePackageName: "Premium",
      _CTAButtonText: "Start free trial",
      basePackageDescription:
        "Ideal for larger businesses and enterprises. Credit Card required.",
      price: "$10",
      packageType: "monthly",
      buttonContent: "Start free trial",
      additionalDetails: [
        {
          name: "basePackage",
          details: [
            <>
              Tutors licenses included-<span className="font-[700]">30</span>
            </>,
            "Unlimited CRM features",
            <>
              <span className="font-[700]">15</span>&nbsp;Full-length DSAT®
            </>,
            "White labeling",
          ],
          tooltipText: [
            "Your subscription comes with the ability to create 30 tutor licenses that you can use to invite your team.",
            "Includes the following features: Unlimited student and parent accounts, Client management, Advanced profile filters, Student groups, Custom tags, Leads pipeline, Customer profiles, Dashboards, and more!",
            'Your subscription comes with 15 Full-length DSAT® that can be assigned to the students (you need to enable the "Assignments" product to use this feature).',
            "Set up your brand identity and give your clients a premium experience.",
          ],
        },
        {
          name: "Calendar",
          details: ["Calendar - sessions"],
          tooltipText: [
            "You have chosen {X} Calendar sessions to be included with your subscription.",
          ],
        },
        {
          name: "Assignments",
          details: ["Assignment - credits"],
          tooltipText: [
            "You have chosen {X} Assignment credits to be included with your subscription. This includes practice tests, drills, question banks, and other such material that is either added by Evallo or created by you.",
          ],
        },
      ],
      selectedProducts: ["basePackage", "Calendar", "Assignments"],
      normalPrice: "$58",
      onCTAButtonClick: () => {
        window.open("https://app.evallo.org/signup", "_blank");
      },
      showPAYG: true,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  useEffect(() => {
    (async () => {
      try {
        // const productPrices = await getRecommendedPackageWithProducts(numberOfTutors, yearly ? 1 : 0)
        // console.log(productPrices)

        if (numberOfTutors > 0 && numberOfTutors < 3)
          setRecommendedPlan("Starter");
        else if (numberOfTutors >= 3 && numberOfTutors < 13)
          setRecommendedPlan("Professional");
        else setRecommendedPlan("Premium");
      } catch (error) {
        console.log(error);
      }
    })();
  }, [numberOfTutors]);

  return (
    <div id={styles.page_container}>
      <div className={styles.heading_ontainer}>
        <h1>Simple, Transparent Pricing</h1>
        <p>
          Yes, we do offer Free Trials. <br /> No, we don’t have complicated
          pricing tables.
        </p>
      </div>
      <div className={styles.package_container}>
        <div className={styles.main2_container}>
          <div className={styles.utility_container}>
            <div className={styles.tutor_input}>
              <div>Total number of tutors</div>
              <input
                type="text"
                defaultValue={"6"}
                onChange={(e) => {
                  const regex = /^\d+\.?\d*$/;
                  if (regex.test(e.target.value)) {
                    setNumberOfTutors(parseInt(e.target.value));
                  }
                  // if (e.target.value.trim() === "" || parseInt(e.target.value) === 0) return;
                  // setNumberOfTutors(parseInt(e.target.value))
                }}
              />
            </div>
            <div className={styles.plan_switch}>
              <div>Monthly</div>
              <label className={styles.switch}>
                <input
                  className={styles.switch_input}
                  type="checkbox"
                  onChange={() => setYearly(!yearly)}
                />
                <span className={styles.slider}></span>
              </label>
              <div>
                <div>Yearly</div>
                <div className={styles.dis_tag}>Save 15%</div>
              </div>
            </div>
          </div>
          <div className={styles.card_container}>
            {!yearly
              ? monthlySubscriptionData.map((el, i) => {
                  return (
                    <PriceCard
                      planType={el.type}
                      idx={i}
                      cost={el.cost}
                      key={i}
                      recommendedPlan={recommendedPlan}
                      numberOfTutors={numberOfTutors}
                      desc={el.desc}
                      isYearly={yearly}
                      onCTAButtonClick={() => {
                        window.open("https://app.evallo.org/signup", "_blank");
                      }}
                    />
                  );
                })
              : yearlySubscriptionData.map((el, i) => {
                  return (
                    <PriceCard
                      planType={el.type}
                      idx={i}
                      cost={el.cost}
                      key={i}
                      recommendedPlan={recommendedPlan}
                      numberOfTutors={numberOfTutors}
                      desc={el.desc}
                      isYearly={yearly}
                      onCTAButtonClick={() => {
                        window.open("https://app.evallo.org/signup", "_blank");
                      }}
                    />
                  );
                })}
          </div>
          <a className={styles.all_feature_btn} href="#price_comparator_table">
            <div>See all features</div>
            <div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 0C5.3838 0 0 5.3838 0 12C0 18.6162 5.3838 24 12 24C18.6162 24 24 18.6162 24 12C24 5.3838 18.6162 0 12 0ZM16.6536 14.079L12.8862 17.2782C12.6306 17.4948 12.315 17.604 12 17.604C11.6844 17.604 11.3688 17.4948 11.1132 17.2782L7.3458 14.079C6.7704 13.5894 6.6996 12.7254 7.1886 12.1488C7.6776 11.5734 8.5416 11.502 9.1182 11.9916L10.6308 13.2762V7.7646C10.6308 7.0086 11.2434 6.396 12 6.396C12.756 6.396 13.3686 7.0086 13.3686 7.7646V13.2762L14.8812 11.9916C15.4584 11.5032 16.3218 11.5734 16.812 12.1488C17.3004 12.7254 17.2296 13.5888 16.6536 14.079Z"
                  fill="#0671E0"
                />
              </svg>
            </div>
          </a>
        </div>
        <div className={styles.bundle_product} id="prod_dep_feature">
          <h1>Pricing for product bundles</h1>
          <div>
            {yearly
              ? yearlyProductBundle.map((el, i) => {
                  return (
                    <>
                      {i !== 0 ? (
                        <div className={styles.verticle_devider}></div>
                      ) : (
                        ""
                      )}
                      <PriceTableCard bundle={el} key={i} isYearly={yearly} />
                    </>
                  );
                })
              : monthlyProductBundle.map((el, i) => {
                  return (
                    <>
                      {i !== 0 ? (
                        <div className={styles.verticle_devider}></div>
                      ) : (
                        ""
                      )}
                      <PriceTableCard bundle={el} key={i} isYearly={yearly} />
                    </>
                  );
                })}
          </div>

          <div>
            You can <span>SAVE 15%</span> with yearly billing.
          </div>
        </div>

        <Carousel
          monthlyProductBundle={monthlyProductBundle}
          yearlyProductBundle={yearlyProductBundle}
          yearly={yearly}
        />

        <div className={styles.enq_form}>
          <EnquiryFormV2 />
        </div>

        <div
          className="w-100 d-flex justify-content-start justify-content-lg-center align-items-center"
          style={{
            paddingTop: "104px",
            maxWidth: "calc(100vw - 36px)",
            overflowX: "scroll",
          }}
        >
          <PriceComparator
            packageInfo={_monthlyPackages}
            setAreCardsExpanded={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default PricingV2;
