import React from 'react'
import "./videoCard.css"

import playBtn from "../../../assets/icons/video-circle.svg"
import { Link } from 'react-router-dom'

const VideoCard = ({ title, desc, image, docUrl, videoUrl }) => {
    return (
        <div className=" col-lg-6 py-3 p-lg-3 ">
            <div className='video-card'>
                <div>
                    <img src={image} alt="" />
                </div>
                <div className='px-1'>
                    <h2>{title}</h2>
                    <p>{desc}</p>
                </div>
                <div className='d-flex gap-4 px-1'>
                    <Link to={videoUrl} target="_blank" rel="noopener noreferrer">
                        <button className='play-btn'><img src={playBtn} alt="" /><span>Video Tutorial</span></button>
                    </Link>
                    <Link to={docUrl} target="_blank" rel="noopener noreferrer">
                        <button className='doc-btn'>Documentation</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default VideoCard