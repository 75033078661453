import styles from './videoPopUp.module.css'

const VideoPopUp = ({ element, trigger, setTrigger }) => {
    return (
        <>
            {   
                trigger ?
                <div className={styles.outerContainer} onClick={(e) => setTrigger(false)}>
                    {
                        element
                    }
                </div>
                :
                null
            }
        </>
    )

}

export default VideoPopUp