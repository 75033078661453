import React, { useState } from 'react'
import TabSelector from '../TabSelector/TabSelector'
import styles from './customSolutionSection.module.css'
import SectionTitleAndDesc from '../SectionTitleAndDesc/SectionTitleAndDesc'
import AvatarIcon from '../../assets/images/CalendarV2/desktopIcons/AvatarIcon'
import CompanyGroupIcon from '../../assets/images/CalendarV2/desktopIcons/CompanyGroupIcon'
import CoachIcon from '../../assets/images/CalendarV2/desktopIcons/CoachIcon'



const tabs = [
    {
        image: <AvatarIcon />,
        heading: 'Individual Tutors'
    },
    {
        image: <CompanyGroupIcon />,
        heading: 'Tutoring Companies'
    },
    {
        image: <CoachIcon />,
        heading: 'Coaches & Consultants'
    },
]

// const tabs = ["Individual Tutors", "Tutoring Companies", "Coaches & Consultants"]

const individualTutors = [
    {
        title: "Custom Booking Pages",
        desc: "Individuals can create their own appointment and booking pages, allowing clients to schedule sessions based on the tutor's availability."
    },
    {
        title: "Analytics and Insights",
        desc: "Track completed sessions, no-shows, and projected revenue with robust calendar analytics."
    },
    {
        title: "Session Notes",
        desc: "Send detailed session notes to clients after each session to enhance communication and improve outcomes."
    },
    {
        title: "Seamless Integration",
        desc: "Integrate personal Zoom, Google Calendar, or Outlook calendars to manage sessions from anywhere with ease."
    },
    {
        title: "Effortless Coordination",
        desc: "Add time off for vacations or appointments, and the system will handle the rest. Enjoy the ease of scheduling without the hassle of manual mapping."
    },
    {
        title: "Automatic Reminders",
        desc: "Set automatic reminders for students to ensure they never miss a session."
    },
]
const tutoringCompanies = [
    {
        title: "Streamlined Operations",
        desc: "Use the Cumulative View, Schedule Mapper, and Time Blocks to visualize and manage availabilities. Easily set daily availability, add time off, and ensure smooth coordination."
    },
    {
        title: "Customization and Control",
        desc: "Create and manage custom booking pages for your organization. Filter calendar views by tutor, student, service, or location for precise scheduling."
    },
    {
        title: "Effortless Scheduling",
        desc: "Automatically generate and share meeting links with students and tutors, and set reminders to ensure no sessions are missed."
    },
    {
        title: "Data-Driven Insights",
        desc: "Access comprehensive calendar analytics to monitor revenue, costs, and session statuses. Filter data to track no-shows, completed sessions, and more, for informed decision-making."
    },
    {
        title: "Seamless Integration",
        desc: "Integrate effortlessly with Google Calendar, Outlook, and Zoom for seamless coordination of all your scheduling needs."
    },
]
const coachesConsultants = [
    {
        title: "Professional Scheduling",
        desc: "Create external booking pages for consultations and sessions, eliminating the need for third-party tools, categorizing events into Sessions, Consultations, Internal Meetings, and Office Work."
    },
    {
        title: "Seamless Integration",
        desc: "Sync with Google Calendar, Outlook, and Zoom to keep all your appointments in one place. Automatically populate meeting links and share them with clients."
    },
    {
        title: "Comprehensive Calendar View",
        desc: "Utilize the Cumulative View to see all your appointments in one unified dashboard. Filter by client, service, or session status to focus on key aspects of your schedule."
    },
    {
        title: "Advanced Analytics",
        desc: "Dive into detailed calendar analytics to track revenue, client engagement, and session outcomes. Use data insights to optimize your scheduling and improve client satisfaction."
    },
    {
        title: "Flexibility and Control",
        desc: "Easily manage your own availability and time off. Customize your scheduling preferences to fit your unique needs and workflow."
    },
]

const solutionItems = [individualTutors, tutoringCompanies, coachesConsultants]
const CustomSolutionSection = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    return (
        <div>
            <SectionTitleAndDesc title={"Custom Solutions for All"} desc={"Tailored to fit your unique tutoring needs, our solutions adapt to every challenge. Empowering tutors, students, and administrators alike!"} customWidth={"836px"} />

            <div className={styles.customSolutionContainer}>
                <TabSelector items={tabs}
                    onClick={(idx) => {
                        setSelectedTab(idx);
                    }}
                    selectedIdx={selectedTab}

                />
                <div className={styles.middleDevider}></div>
                <div className={styles.customSolutionCardContainer}>
                    {
                        solutionItems[selectedTab].map((el, idx) => {
                            return <div className={styles.customSolutionCard}>
                                <div>{el.title}</div>
                                <div>{el.desc}</div>
                            </div>
                        })
                    }

                </div>
            </div>
        </div>
    )
}

export default CustomSolutionSection