const InfoIcon = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clip-path="url(#clip0_29157_4630)">
                <path d="M8.00016 14.6663C11.6821 14.6663 14.6668 11.6816 14.6668 7.99967C14.6668 4.31778 11.6821 1.33301 8.00016 1.33301C4.31826 1.33301 1.3335 4.31778 1.3335 7.99967C1.3335 11.6816 4.31826 14.6663 8.00016 14.6663Z" stroke="#ADB2C0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8 10.6667V8" stroke="#ADB2C0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8 5.33301H8.00667" stroke="#ADB2C0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_29157_4630">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default InfoIcon