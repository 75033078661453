import React from 'react'
import styles from "./PrivacyPolicy.module.css"

const PrivacyPolicy = () => {
    return (
        <div className={styles.pp_container}>
            <div>
                <h1 className={styles.title} >Privacy Policy</h1>
            </div>
            <div>
                <p>This privacy policy <strong>(“Privacy Policy”)</strong> is applicable to and in respect of all services offered and provided by Evallo Digital Products Inc. <strong>(“Evallo”)</strong> through its website - <a href="https://evallo.org" target='_blank'>https://evallo.org</a> and all its subdomains <strong>(“Platforms”)</strong>. The Privacy Policy, inter alia, sets out the manner in which information is collected, processed and/or stored by Evallo through the Platform; the purpose, means and modes of usage of such information; and how, and to whom such information which has been collected will be disclosed.</p>
                <p>For the purpose of this Privacy Policy, and where the context so requires, <strong>“our”</strong>, <strong>“we”</strong> or <strong>“us”</strong> shall mean Evallo, and wherever the context so requires, the <strong>“you”</strong> or <strong>“your”</strong> will refer to a User.</p>
                <p>This Privacy Policy is effective as of the date you confirm your acceptance to this Privacy Policy through an online checkbox, “I agree” button or, any similar acceptance mode.</p>
                <p>By using the Platform, you are agreeing to this Privacy Policy. If you do not agree, please refrain from using the Platform. You can contact us anytime with queries about this Privacy Policy at support@evallo.org.</p>
            </div>
            <div>
                <h2>1. DEFINITIONS</h2>
                <ul>
                    <li>1.1. <strong>“Data”</strong> means the Personal Data that Evallo obtains when it collects information, in any manner, from the User (as defined below) when they use the Platform, or in the course of availing the Services via the Platform.</li>
                    <li>1.2. <strong>“Personal Data”</strong> means any information that relates to a natural person, which, either directly or indirectly, in combination with other information available or likely to be available with a body corporate, can identify such person.</li>
                    <li>1.3. <strong>“Terms of Use”</strong> means the <a href="https://www.evallo.org/tou">terms of use</a> of the Platform. </li>
                    <li>1.4. <strong>“User”</strong> as the context requires, refers to either the Parent, Student, Admin, Tutor, or the Contributor (as the case may be).</li>
                </ul>
            </div>
            <div>
                <h2>2. DATA COLLECTED BY EVALLO</h2>
                <ul>
                    <li>
                        2.1. You may voluntarily provide us Data when you register for creating an account to use the Platform. This may include some or all the following categories of Data:
                        <ul>
                            <li> (a) Name, Personal Information, and contact data: This can include your first name, last name, date of birth, email address, postal address, phone number, and personal description or any other similar data;</li>
                            <li>(b) Credentials: Passwords, password hints, and similar security information used for authentication;</li>
                            <li>(c) Content: Content of any files, images, and/or communications, you input, upload, receive, create and control;</li>
                            <li>(d) You may also provide information when you complete a form or survey on the Platform.</li>
                        </ul>
                    </li>
                    <li>
                        2.2. From the moment you first interact with the Platform, we may collect Personal Information. Sometimes we collect Personal Information automatically when you interact with our website/Platform and sometimes we collect the Personal Information directly from you. At times, we may collect Personal information about you from other sources and third parties, even before our first direct interaction.
                    </li>
                    <li>
                        2.3. Note that if you use the Platform to access a service or product, you may need to log in to make use of the functionality of the product.
                    </li>
                    <li>2.4. We collect Data about the pages your device’s browser visits and how your browser accesses and uses the Platform using cookies, tracking technologies and other third-party analytics tools. Specifically, we and our service providers use cookies and similar technologies such as local storage (HTML5), web beacons, JavaScript, and eTags on our Platform. This includes Data about the devices you use to access the Platform including, but not limited to, unique device identifiers, IP address, operating system, browser, and cookies. </li>

                </ul>
            </div>
            <div>
                <h2>3. PURPOSE OF COLLECTION</h2>
                <ul>
                    <li>
                        We use the collected data to provide services to you on the Platform. Set out below is an illustrative list of purposes for which we collected data:
                    </li>
                    <li>
                        3.1. to provide, maintain, and improve our Platform, including by using third-party tools to perform services for the purpose laid out in our Terms of Usage and assist us with providing services and communicating with you.
                    </li>
                    <li>
                        3.2. to communicate with you in any manner that you have requested;
                    </li>
                    <li>
                        3.3. to provide you with customer support including technical support and troubleshooting;
                    </li>
                    <li>
                        3.4. to fulfil Evallo’s obligations as outlined in the Terms of Use;
                    </li>
                    <li>
                        3.5. to receive payments, from you, in accordance with the Terms of Use;
                    </li>
                    <li>3.6 to protect you and conduct security investigations and fraud and abuse analysis;</li>
                    <li>3.7 to comply with Evallo’s legal obligations, for example when assisting governments and law enforcement agencies or regulators (as may be required by law);</li>
                    <li>3.8 to verify your identity;</li>
                    <li>3.9 to improve the safety and reliability of the Platforms. This includes detecting, preventing, and responding to fraud, abuse, security risks, and technical issues that could harm Evallo, you, or the general public;</li>
                    <li>3.10. to send notifications about activities and updates on your account;</li>
                    <li>3.11. to analyse usage information to investigate, prevent, and detect activities on our Platform that we believe may violate the law or applicable regulations.</li>
                </ul>
            </div>
            <div>
                <h2>4. CHILDREN’S PRIVACY</h2>
                <ul>
                    <li>4.1 <strong>Parental Consent:</strong> We do not knowingly collect or solicit Personal Information from children under the legal age of age of 13 years in the USA, 18 years in India or such age as applicable within your jurisdiction ("Legal Age”), without obtaining verifiable parental consent for the collection, use, or disclosure of personal information from such children. If we learn that we have collected Personal Information from a child under the Legal Age, we will delete that information as quickly as possible. If you believe that a child under the Legal Age may have provided us personal information, without the verifiable parental consent, please contact us at support@evallo.org.</li>
                    <li>4.2. Evallo has processes and procedures in place to protect the Personal Information of children under the <strong>Legal Age</strong>, as detailed below. We do not use such Data for any purpose other than to provide our services and for the specific uses set forth below, in accordance with contractual agreements with our customers and our Terms of Use.</li>
                    <li>4.3. <strong>Consent to Collect, Use, or Disclose Personal Information from Children Under the Legal Age :</strong> Children under Legal Age may only use the Platform with the prior consent of a parent. We will not collect, use, or disclose any Personal Information from children under Legal Age without such consent. Educational institutions may consent to the collection, use, or disclosure of Personal Information from children under Legal Age by agreeing to use the Platform.</li>
                    <li>
                        <ul>
                            <li>4.4. <strong>Personal Information from Children Under Legal Age Provided to Evallo: </strong> Evallo products may collect the following categories of Personal Information from children under Legal Age, with the appropriate consent:</li>
                            <ul>
                                <li>(a) <strong>Information provided via customers or through use of Platform: </strong> assessment data, conduct or behaviour data, demographic data, enrolment data, contact information, course schedule data, online communications, student identifiers (e.g., ID number), student-generated content, and transcript data.</li>
                                <li>(b) <strong>Usage, browser, and device information:</strong> Evallo does not allow Personal Information from children under Legal Age to be made publicly available via its Platform. Evallo does not share Personal Information from children under the Legal Age with third parties unless necessary under the law.</li>
                                <li>(c) <strong>Service providers, partners, and product integrations: </strong> Our service providers help us with things like data security, cloud hosting, information technology, customer support, usage and analytics, email delivery, application performance monitoring, and user identity and authentication.</li>
                                <li>(d) <strong>Administrative and legal reasons: </strong> When reasonably necessary to meet and comply with any applicable law, regulation, legal process, or enforceable governmental request; enforce applicable Terms of Use, including investigation of potential violations; detect, prevent, or otherwise address fraud, security, or technical issues; or protect against harm to the rights, property or safety of Evallo, our users or the public as required or permitted by law.</li>
                                <li>(e) <strong> Business transitions. </strong> In the event that Evallo goes through a business transition such as a merger, acquisition by another company, sale of all or a portion of its assets, bankruptcy, or other corporate change (including during the course of any due diligence process), this Personal Information may be shared or transferred based on consent of our customers.</li>
                            </ul>
                        </ul>
                    </li>
                    <li>4.5. Our customers may share Personal Information of students with Tutors as needed for educational purposes. Evallo does not control and is not responsible for this type of sharing. If you are a parent and have questions about this, please contact your child’s educational institution.</li>
                    <li>4.6. Evallo does not share Personal Information from children under Legal Age for advertising or marketing purposes.</li>
                </ul>
            </div>
            <div>
                <h2>5.	CALIFORNIA PRIVACY RIGHTS</h2>
                <p>California law requires us to disclose the following information with respect to our privacy practices. If you are a California resident, this section applies to you in addition to the rest of the Privacy Policy.</p>
                <ul>
                    <li>
                        <ul>
                            <li>
                                5.1<strong>	Categories of Personal Information Evallo Collects: </strong> The types of Personal Information we collect can include, but is not limited to -
                            </li>
                            <ul>
                                <li>(a)Basic identifying information (e.g., name, address, email, phone number)</li>
                                <li>(b)Demographic data (e.g., age, date of birth, gender)</li>
                                <li>(c)Device information and other unique identifiers (e.g., device ID, IP address, cookies)</li>
                                <li>(d)Network activity</li>
                                <li>(e)User-generated content, including student-generated content.</li>
                            </ul>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li>
                                5.2	<strong>Means of Collection of Personal Information by Evallo:</strong> We may collect Personal Information through the following means -
                            </li>
                            <ul>

                                <li>(a) Directly from you (e.g., upon creating an account)</li>
                                <li>(b) Automated data collection technologies (e.g., cookies, pixels)</li>
                                <li>(c) Other sources (e.g., data analytics providers)</li>
                            </ul>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li>
                                5.3 <strong> Purposes for Collection and Use of Personal Information:</strong> Evallo collects and uses Personal Information for various purposes, including, but not limited to the following -
                            </li>
                            <ul>

                                <li>(a) Administration of Platform</li>
                                <li>(b) Improvement of Platform </li>
                                <li>(c) Communications with you</li>
                                <li>(d) Legal obligations</li>
                                <li>(e) Security, fraud prevention, and troubleshooting</li>
                            </ul>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li>
                                5.4	Evallo does not share Personal Information with third parties unless necessary for the above-listed purposes. Evallo may disclose Personal Information to the following categories of third parties:
                            </li>
                            <ul>

                                <li>(a)<strong>	Service providers and partners.</strong> Our service providers help us provide services via the Platform. These service providers may support activities such as data security, cloud hosting, information technology, customer support, usage and analytics, email delivery, application performance monitoring, error and crash reporting, and user identity and authentication. In addition, we may engage in product partnerships and integrations that offer certain features to users.</li>
                                <li>(b)<strong>Administrative and legal reasons.</strong> We may share Personal Information with third parties based on a good-faith belief that access, use, or preserve the information as reasonably necessary to meet any applicable law, regulation, legal process or enforceable governmental request; enforce applicable Terms of Use, including investigation of potential violations; detect, prevent, or otherwise address fraud, security or technical issues; or protect against harm to the rights, property or safety of Evallo, Users or the public as required or permitted by law. </li>

                            </ul>
                        </ul>
                    </li>
                    <li>
                        5.5	<strong>Exercising California Privacy Rights</strong> Subject to certain limitations, including that Evallo cannot delete Data where it is acting as a data processor under legal agreements with the data controller, California residents have the right to request access to the categories and specific pieces of Personal Information we collect, use, and disclose; the right to request deletion of their Personal Information; and the right to opt out of any sales of Personal Information that may be occurring. These rights may be exercised by contacting us at support@evallo.org.
                    </li>
                    <li>
                        5.6 <strong>Access to Data and Deletion Requests </strong> For collected Data where Evallo is not acting as a data processor, but collects Personal Information directly from a User, you may submit a request for a copy of the information collected about you in the last 12-months, or request deletion of information, by contacting us at support@evallo.org.
                    </li>
                </ul>
            </div>
            <div>
                <h2>6.	TRANSFER/SHARING/DISCLOSURE OF DATA</h2>
                <p>We do not rent or sell your Personal Information in personally identifiable form to anyone, provided that certain Personal Information may be transferred in connection with business transfers, as described below. We may share your Personal Information with third parties as described in this section:</p>
                <ul>
                    <li>6.1.	<strong>Data that’s no longer personally identifiable: </strong> We may anonymize your Personal Information so that you are not individually identified and provide that Data to our partners. We may also provide aggregate usage information to our partners, who may use such Data to understand how often and in what ways people use our Services, so that they, too, can provide you with an optimal online experience. However, we never disclose aggregate usage information to a partner in a manner that would identify you personally, as an individual. You can opt-out of the automatic retention of data that is collected by Evallo through your browsers while on our Platform by contacting us in the manner provided by this Policy. You should be aware that if you get a new computer, install a new browser, erase, or otherwise alter your browser’s cookie file (including upgrading certain browsers), you may also clear the Evallo’s opt-out cookie. </li>
                    <li>6.2.	<strong>Affiliated Businesses:</strong> In certain situations, businesses, or third-party websites we are affiliated with may sell or provide products or services to you through or in connection with the Services (either alone or jointly with us).  You can recognize when an affiliated business is associated with such a transaction or service, and we will share your Personal Information with that affiliated business only to the extent that it is related to such transaction or service. However, we have no control over the policies and practices of third-party websites or businesses as to privacy or anything else, so if you choose to take part in any transaction or service relating to an affiliated website or business, please review all such businesses’ or websites’ policies.</li>
                    <li>6.3.	<strong>Agents/Service Provider:</strong> We employ other companies and people to perform tasks on our behalf and need to share your Data with them to provide products or Services to you; for example, we may use a payment processing company to receive and process your credit card transactions for us. Unless we tell you differently, our agents/service providers do not have any right to use the Personal Information we share with them beyond what is necessary to assist us.</li>
                    <li>6.4.	<strong>User Profiles and Submissions: </strong>  Certain User profile information, including your name, username, any video, or image content that such user has uploaded on the Platform, may be displayed to other users to facilitate user interaction within the Services or address your request for our Services.  Please remember that any content you upload to your public user profile, along with any Personal Information or content that you voluntarily disclose online in a manner other users can view may become publicly available and can be collected and used by anyone.</li>
                    <li>6.5.	<strong>Business Transfers:</strong> We may choose to buy or sell assets and may share and/or transfer your information in connection with the evaluation of and entry into such transactions. Also, if we (or our assets) are acquired, or if we go out of business, enter bankruptcy, or go through some other change of control, Personal Information could be one of the assets transferred to or acquired by a third party.</li>
                    <li>6.6.	<strong>Protection of Company and Others:</strong> We reserve the right to access, read, preserve, and disclose any Data that we reasonably believe is necessary to comply with law or court order; enforce or apply our Terms of Use and other agreements; or protect the rights, property, or safety of Company, our employees, our Users, or others.</li>
                </ul>
            </div>
            <div>
                <h2>7.	OTHER RIGHTS OF THE USER</h2>
                <ul>
                    <li>
                        7.1.	You may export a copy of your Data or delete your Data from the Platform using the functionalities provided on the Platform.
                    </li>
                    <li>
                        <ul>
                            <li>7.2.	Subject to the provisions of applicable law, you are entitled to the following rights:</li>
                            <ul>
                                <li>(a)	Request access to Personal Information: Unless prohibited by applicable law, you are entitled to know whether Evallo holds any Personal Information about you, and if Evallo do, you can request to obtain a copy of the same;</li>
                                <li>(b)	Request for rectification of Personal Information: You can ask Evallo to rectify any inaccurate or incomplete Personal Information;</li>
                                <li>(c)	Object to the processing of Information: You can request Evallo to stop processing any of your Personal Information. This may however impact your usage of the Platform;</li>
                                <li>(d)	Erase all Data: You can request Evallo for the deletion of all Personal Information. This may however impact your usage of the Platform.</li>
                            </ul>
                        </ul>
                    </li>
                </ul>
            </div>
            <div>
                <h2>8.	RETENTION OF DATA</h2>
                <p>Unless required by applicable law, Evallo shall retain all Data only for as long as necessary for Evallo to perform its obligations under the Terms of Use and under this Privacy Policy, or to comply with its legal obligations.</p>
            </div>
            <div>
                <h2>9.	DATA PROTECTION PRACTICES</h2>
                <ul>
                    <li>9.1.	We follow the latest, industry standards to protect your data. Evallo maintains organizational, physical, and technical security arrangements for all data Evallo holds. Some measures that Evallo may follow include use of highly secure, access-controlled data centres, data encryption in transit, and encryption data at rest etc. </li>
                    <li>9.2.	Despite these measures, in the event of a security breach, we will notify affected account holders within the amount of time required by the local law or by Evallo’s internal policy, whichever is more stringent, so that you can take steps to keep your data safe.</li>
                    <li>9.3.	Regarding use of Platform, you should understand that the open nature of the internet is such that information and Data flows over networks connecting user to our systems without security measures and may be accessed and used by people other than those for whom the data is. As a result, while Evallo tries to protect the Data it holds, it cannot guarantee the security of any Data, User transmits to Evallo and the User does so at their own risk.</li>
                </ul>
            </div>
            <div>
                <h2>10.	COOKIES AND DATA ANALYTICS</h2>
                <ul>
                    <li>10.1.	Evallo uses cookies, which are files that the web browser puts on a system when you use the Platform, to store certain Data. The Data collected through these cookies is used by Evallo for the technical administration of Evallo’s research and development, and to improve the quality of its Services.</li>
                    <li>10.2.	Evallo may use third party cookies to track visitor behaviour and to improve the quality of Platform and its Services. However, such cookies will not store any kind of Data, nor will such Data be disclosed to any third party.</li>
                    <li>10.3.	We use cookies and other similar services to keep you logged in to Platform, customize your Platform experience, understand how you use Platform, and promote Platform to relevant teachers and schools. You can remove or disable cookies via your browser settings, in which case your experience with the Platform will not be optimal.</li>
                    <li>10.4.	Notwithstanding anything mentioned in this section, Evallo will routinely analyse your Data to help improve the way Evallo runs its business and Platform. Evallo uses your Data for data analytics, particularly to understand how our services are used, but this Data is not Personal Information as it is.</li>

                </ul>
            </div>
            <div>
                <h2>11.	LINKS TO THIRD PARTY SITES</h2>
                <ul>
                    <li>11.1.	Platform may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of Evallo. Evallo is not responsible for the content of any Linked Site, including, without limitation to, any link contained in a Linked Site, or any changes or updates to a Linked Site.</li>
                    <li>11.2.	Evallo does not intend the Linked Sites to be referrals to, endorsements of, or affiliation with the linked entities.</li>
                    <li>11.3.	On accessing the Linked Sites, you shall be governed by the terms of use, privacy policy and such additional policies of the Linked Sites, as applicable. You further acknowledge and agree that Evallo shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, advertising, products, services or other materials available on or through any Linked Sites or for any errors, defamatory content, libel, slander, omissions, falsehoods, obscene content, pornographic material, or any profanity contained therein.</li>

                </ul>
            </div>
            <div>
                <h2>12.	CHANGES TO THE POLICY</h2>
                <p>We may update this Privacy Statement and its last updated date to reflect changes to our data governance practices. If we propose to make any material changes, we will notify you by means of a notice on our public facing webpage page and/or by way of an email  prior to the change becoming effective. If you continue to use our service after you receive notice of changes to this Privacy Policy, we will assume that you have accepted these changes. We encourage you to periodically review this page for the latest information on our privacy practices. </p>
                <p>Last updated on <span style={{fontWeight:"700"}} >Sept. 6, 2024</span>.</p>
            </div>
            <div>
                <h2>13.	GOVERNING LAW AND DISPUTE RESOLUTIONS</h2>
                <p>These Terms shall be governed by the laws of the United States and the parties hereto agree to submit all matters and/or disputes arises thereof, to the exclusive jurisdiction of the courts of Delaware, USA.</p>

            </div>
            <div>
                <h2>14.	CONTACT US</h2>
                <p>If you have any questions or concerns about your privacy or this Privacy Policy, please contact our Data Protection Officer at support@evallo.org. </p>
                <p>You may also write to us at: <br />
                    Attention: Kartik Sarda <br />
                    Address: 8 The Green, STE A, Dover, Delaware, 19901
                </p>
            </div>
        </div>
    )
}

export default PrivacyPolicy