import { Link } from 'react-router-dom'
import styles from './DocsContentHelp.module.css'
import { forwardRef, useEffect, useState } from 'react'
import VideoPopUp from '../videoPopUp/videoPopUp'
import playCircleIcon from './playCircleIcon.svg'

const DocsContentHelp = forwardRef(function (props,ref) {

    const {mediaId, links} = props
    const [vidPopUpTrigger, setvidPopUpTrigger] = useState(false)
    const [activeLinkid, setActiveLinkId] = useState(0)

    const onLinkClick = (e) => {
        console.log(e.target)
        setActiveLinkId(parseInt(e.target.id))
    }

    return (
       <div className={`${styles.container}`}>
            <VideoPopUp trigger={vidPopUpTrigger} setTrigger={setvidPopUpTrigger} element={<iframe src={`https://www.youtube.com/embed/${mediaId}`} height={"80%"} width={"80%"}/>}/>
            <div className={styles.imgContainer} style={{display : !mediaId ? "none" : "block"}} onClick={(e)=>setvidPopUpTrigger(true)}>
                <img src={playCircleIcon} style={{position : "absolute", left : "calc(50% - 15px)", top :  "calc(50% - 15px)", zIndex : 2}}/>
                <div className={styles.mask}>&nbsp;</div>
                <img src={`https://img.youtube.com/vi/${mediaId}/hqdefault.jpg`} className={`${styles.img}`} />
            </div>
            <div className={`${styles.linksContainer}`} ref={ref}>
                {   
                    links?.map((val,idx)=>{
                        return(
                            <a href={val.link} id={idx} onClick={(e)=>onLinkClick(e)} className={`${activeLinkid === idx ? styles.linksContainerLinkActive : ""} `}>{val.text}</a>
                        )
                    })
                }
            </div>
       </div>
    )
})

export default DocsContentHelp