import cookie from "react-cookies";

export const getTokenCookie = () => {
  cookie.load("mirach-token");
  console.log(cookie.loadAll())
}

export const removeTokenCookie = () => {
  let response = cookie.remove("mirach-token", { path: "/" });
  return response;
};

export const getAuthHeader = () => {
  return { headers: { authorization: `${getTokenCookie()}` } };
};

