import ReactQuill, { Quill } from 'react-quill';
import styles from './BlogsCreator.module.css'
import 'react-quill/dist/quill.snow.css';
import { ButtonFilled, ButtonHollow } from '../../../components/Buttons';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PublishIcon from '@mui/icons-material/Publish';
import PreviewIcon from '@mui/icons-material/Preview';
import SaveIcon from '@mui/icons-material/Save';
import { useEffect, useState } from 'react';
import { deleteArticle, editDoc, fetchArticle, generateLinksForHeadings, getImageBase64String, saveNewDoc, updateArticle, getYTVideoIdFromURL, getCategories, fetchBlogByid, saveNewBlog, editBlog, fetchAllBlogs } from '../../../Utils/utils';
import { ToastContainer, toast } from 'react-toastify';
import { useParams, useSearchParams, Link } from 'react-router-dom';
import MirachTopbar from '../../../components/MirachTopbar/MirachTopbar';
import FileInput from '../../../components/FileInput/FileInput'
import MultiSelectDropdown from '../../../components/Multi_Select_Dropdown/MultiSelectDropdown';

const BlogsCreator = (props) => {

    //Valid modes are ["create","edit"]
    const [mode, setMode] = useState("create")
    const [existingCategories, setExistingCategories] = useState(null)
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
                {
                    background: ["red", "blue", "yellow"]
                }
            ],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean'],
        ],
    }

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote', 'background', 'color',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]


    const [articleId, setArticleid] = useState(null)
    const [seoFocusKeyphrase, setSeoFocusKeyphrase] = useState(null) //
    const [editorValue, setEditorValue] = useState('') //
    const [customURL, setCustomURL] = useState('') //
    const [tags, setTags] = useState('') //
    const [title, setTitle] = useState('') //
    const [seoDescription, setSeoDescription] = useState('') //
    const [seoTitle,setSeoTitle] = useState('')
    const [img, setImg] = useState(null)
    const [showMessage, setShowMessage] = useState(false)
    const [categories, setCategories] = useState([])
    const [readTime, setReadTime] = useState("")
    const { docId } = useParams()

    const [isCustomURLUnique, setIsCustomURLUnique] = useState(false)

    useEffect(()=>{
        (async()=>{
            try {
                const categories = await getCategories()
                setExistingCategories(categories)
            }catch(err){
                
            }
        })()
    },[])

    useEffect(() => {
        (async () => {
            if (docId) {
                try {
                    const blog = await fetchBlogByid(docId)
                    setArticleid(docId)
                    setSeoFocusKeyphrase(blog.seoFocusKeyphrase)
                    setCustomURL(blog.customURL)
                    setEditorValue(blog.html)
                    setTitle(blog.title)
                    setSeoDescription(blog.seoDescription)
                    setImg(blog.img)
                    setTags(blog.tags)
                    setCategories(blog.categories)
                    setSeoTitle(blog.seoTitle)
                    setReadTime(blog.readTime)
                    //Left to set the categories
                } catch (err) {
                    toast.error("No blog found with this id.")
                }
            }
        })()

        window.onbeforeunload = () => true
        props.setIsInCreator(true)

        return (() => {
            window.onbeforeunload = null
            props.setIsInCreator(false)
        })
    }, [])

    useEffect(()=>{
        (async ()=>{
            try {
                if(!customURL || customURL.trim() === ""){
                    return
                }
                const allBlogs = await fetchAllBlogs()
                const allURLs = allBlogs.map((blog)=>{return blog.customURL})
                if(allURLs.includes(customURL)){
                    if(!docId){
                        setIsCustomURLUnique(false)
                    } else {
                        const currentBlog = allBlogs.find((val)=>val._id === docId)
                        if(currentBlog.customURL === customURL){
                            setIsCustomURLUnique(true)
                        } else {
                            setIsCustomURLUnique(false)
                        }
                    }
                }else {
                    setIsCustomURLUnique(true)
                }
            }catch(err){
                toast.error("Some error occured")
            }
        })()
    },[customURL])

    const onChangeValues = (e) => {
        const target = e.target
        const value = e.target.value
        switch (e.target.id) {
            case "focus_key": setSeoFocusKeyphrase(value); break;
            case "seo_title": setSeoTitle(value); break;
            case "title": setTitle(value); break;
            case "slug": setCustomURL(value); break;
            case "meta_desc": setSeoDescription(value); break;
            case "tags":setTags(value) ;break;
            case "read_time": setReadTime(value);break;
            case "featured_image": getImageBase64String(e.target.files[0], (result) => { setImg(result) }); break;
        }
    }

    const onPreviewClicked = (e) => {

    }


    const onPublishClicked = async (e) => {
        if (!articleId) {
            toast.error("Please save your work first.")
            return
        } else {
            const params = {
                id: articleId,
                publish: true,
            }
            try {
                await editBlog(params)
                toast.success("Blog published successfully")
            } catch (err) {
                toast.error("Error publishing article " + err?.response?.data?.message)
            }
        }
    }

    const onSaveClicked = async (e) => {

        if(isCustomURLUnique === false) {
            toast.warning("Please select a unique custom URL or slug for your blog.")
            return 
        }

        const editorHTMLWithdID = generateLinksForHeadings(editorValue).innerHTML
        const params = {
            ...(articleId) && {id : articleId},
            title : title,
            html : editorHTMLWithdID,
            customURL : customURL,
            categories : categories,
            ...(seoTitle) && {seoTitle : seoTitle},
            ...(seoDescription) && {seoDescription : seoDescription},
            ...(img) && {img : img},
            ...(readTime) && {readTime : readTime},
            ...(seoFocusKeyphrase) && {seoFocusKeyphrase : seoFocusKeyphrase},
            ...(tags) && {tags : tags}
        }


        //Check for enabling edit mode
        if (!docId) {
            try {
                const savedBlog = await saveNewBlog(params)
                setArticleid(savedBlog._id)
                toast.success("Blog saved successfully.")
            } catch (err) {
                toast.error(err.message, "Blog not saved, some error occured.")
            }
        } else {
            try {
                await editBlog(params)
                toast.success("Blog edited & saved successfully.")
            } catch (err) {
                toast.error("Error saving edited blog.")
            }
        }

    }

    // const onDeleteClicked = async (e) => {
    //     try {
    //         await deleteArticle("65e5af2cd653e7e14ae6ce47", articleId)
    //         toast.success("Document deleted successfully.")
    //     } catch (err) {
    //         toast.error("Error deleting document.")
    //     }
    // }


    // return (
    //     <>
    //         <div className={`container h-100 p-3 ${styles.contentContainer}`}>
    //             <ToastContainer />
    //         </div >
    //     </>
    // )

    return (
        <>
            <div className={styles.topBarContainer}>
                <MirachTopbar isHome={false} onPublishClicked={onPublishClicked} onSaveClicked={onSaveClicked} backLink={"/mirach/blogs"}/>
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.columnOne}>
                    <div className='container-fluid h-100'>

                        <div className='row'>
                            <div className={`col-12 mb-3`}>
                                <input type="text" className={`form-control ${styles.titleInput}`} id="title" placeholder="Title for new blog here" onChange={(e) => onChangeValues(e)} defaultValue={title ? title : ""} />
                            </div>
                        </div>

                        <div className='row gap-4'>
                            <div className={`col-12`}>
                                <ReactQuill id="editor" theme="snow" modules={modules} formats={formats} className={`${styles.editor}`} value={editorValue} onChange={setEditorValue}></ReactQuill>
                            </div>
                            <div className={`col-12`} hidden={true}>
                                <div className={`${styles.infoMessage}`}>
                                    Save your work to preview the article.
                                </div>
                            </div>
                            <div className={`col-12 d-flex justify-content-between align-items-center gap-3`}>
                                <div className='d-flex justify-content-start align-items-center gap-3'>
                                    {/* Add edit mode preview show later */}
                                    <Link to={!articleId ? "" : `/blogs/preview/${customURL}`} target='_blank' onMouseEnter={(e) => { if (true) setShowMessage(true); }} onMouseLeave={(e) => { if (true) setShowMessage(false); }}>
                                        <button className={styles.previewButton} disabled={!articleId} ><PreviewIcon />Preview</button>
                                    </Link>
                                    <div className={`${styles.infoMessage}`} hidden={!showMessage}>
                                        Coming soon ...
                                    </div>
                                    {/* <ButtonHollow className='d-flex justify-content-start align-items-center gap-2' onClick={(e)=>onPublishClicked(e)}><PublishIcon/>Publish</ButtonHollow>
                        <ButtonFilled className='d-flex justify-content-start align-items-center gap-2' onClick={(e)=>onSaveClicked(e)}><SaveIcon />Save</ButtonFilled> */}
                                </div>
                                <div className='d-flex justify-content-start align-items-center gap-3'>
                                    {/* <ButtonFilled className='d-flex justify-content-start align-items-center gap-2 bg-danger' onClick={(e) => onDeleteClicked(e)} disabled={!articleId}><DeleteForeverIcon />Delete</ButtonFilled> */}
                                </div>
                            </div >
                        </div>
                    </div>
                </div>
                <div className={styles.columnTwo}>
                    <div className='container-fluid h-100'>
                        <div className={styles.sidebar_container}>
                            <div className={styles.blog_sidebar}>
                                <div>
                                    <label htmlFor="focus_key">Focus Keyphrase</label>
                                    <input type="text" name="focus_key" id="focus_key" placeholder='Important for college' defaultValue={seoFocusKeyphrase ? seoFocusKeyphrase : ""} onChange={(e)=>onChangeValues(e)}/>
                                </div>
                                <div>
                                    <label htmlFor="seo_title">SEO Title</label>
                                    <textarea type="text" cols="30" rows="2" name="seo_title" id="seo_title" placeholder='SAT & ACT Essential - Everything You Need to Know' defaultValue={seoTitle ? seoTitle : ""} onChange={(e)=>onChangeValues(e)}/>
                                </div>
                                <div>
                                    <label htmlFor="slug">Slug {isCustomURLUnique ? "✅" : "❌"}</label>
                                    <input type="text" name="slug" id="slug" placeholder='Lorem Ipsum' defaultValue={customURL ? customURL : ""} onChange={(e)=>onChangeValues(e)}/>
                                </div>
                                <div>
                                    <label htmlFor="category">Category</label>
                                    <MultiSelectDropdown selected={categories} setSelected={setCategories} categories={existingCategories} onChange={(e)=>onChangeValues(e)}/>
                                </div>
                                <div>
                                    <label htmlFor="meta_desc">Meta Description</label>
                                    <textarea name="meta_desc" id="meta_desc" cols="30" rows="6" placeholder='You will spend 0 credits when downloading with Attribution of the control.' defaultValue={seoDescription ? seoDescription : ""} onChange={(e)=>onChangeValues(e)}></textarea>
                                </div>
                                <div>
                                    <label htmlFor="tags">Tags</label>
                                    <input type="text" name='tags' placeholder='#college, #SAT, #university' id="tags" defaultValue={tags ? tags : ""} onChange={(e)=>onChangeValues(e)}/>
                                </div>
                                <div>
                                    <label htmlFor="read_time">Read Time</label>
                                    <input type="text" name='read_time' placeholder='7 Mins' id="read_time" defaultValue={readTime ? readTime : ""} onChange={(e)=>onChangeValues(e)}/>
                                </div>

                                <div>
                                    <label htmlFor="featured_image">Featured Image</label>
                                    <span>Upload your featured image here, File shouldn’t exceed 5MB.</span>
                                    <input type='file' id="featured_image" className='form-control' onChange={(e)=>onChangeValues(e)}/>
                                </div>
                                <div>
                                    {   
                                        img ? 
                                        <>
                                        <label>Preview Featured Image</label>
                                        <img src={img} className={styles.previewImage}/>
                                        </>
                                        :null
                                    }
                                </div> 
                            </div>
                        </div>
                    </div >
                </div>
            </div>

        </>
    )
}

export default BlogsCreator