import React from "react";
import "./pinbanner.css";
import arrow from "../../../assets/images/blog-page-images/arrow.png";
import { Link } from "react-router-dom";

const PinBanner = ({
  id,
  category,
  heading,
  date,
  Reading_Time,
  Summary,
  link,
  image,
}) => {
  const DateModified = () => {
    const modDate = new Date(date);
    const month = modDate.toLocaleString("default", { month: "short" });
    const day = modDate.getDate();
    const year = modDate.getFullYear();
    return `${month} ${day} ${year}`;
  };
  return (
    <div className="pin_banner" style={{ backdropFilter: "blur(5px)" }}>
      <section className="pin_container ">
        <section className="d-flex gap-2 flex-wrap">
          {category.map((data, index) => (
            <button key={index} className="pin_button">
              {data}
            </button>
          ))}
        </section>
        <h3>{heading}</h3>

        <div className=" d-flex gap-0 p-0 flex-column pin_time  ">
          <p className="p-0 m-0"> {DateModified()} </p>
          <hr />
          <p className=" m-0">{Reading_Time}</p>
        </div>

        <section className=" d-flex  align-items-end gap-4  ">
          <p className=" mt-2 mb-0 summary ">{Summary}</p>

          <Link to={`${id}`} target="_blank">
            <button className=" refer ">
              <img src={arrow} alt="arrow" />
            </button>
          </Link>
        </section>
      </section>
    </div>
  );
};

export default PinBanner;
