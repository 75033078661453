import React from "react";
import "./blogBanner.css";
import article_img from "../../../assets/images/blog-page-images/article_img.png";
import clock from "../../../assets/images/blog-page-images/clock.png";
import { Link } from "react-router-dom";

const BlogBanner = ({ id, category, heading, date, Reading_Time, Summary, link, image }) => {
  const DateModified = () => {
    const modDate = new Date(date);
    const month = modDate.toLocaleString("default", { month: "short" });
    const day = modDate.getDate();
    const year = modDate.getFullYear();
    return `${month} ${day} ${year}`;
  };

  return (
    <>
      <div className="blog_banner z-2 " style={{backdropFilter :"blur(5px)"}} >
        <div className="blog_image">
          <img src={image} alt="blog-image" />
        </div>

        <section className="blog_desc">
          <section className="d-flex gap-2">
            {category.map((data, index) => (
              <button key={index} className="blog_button">
                {data}
              </button>
            ))}
          </section>

          <h2>{heading}</h2>
          <p className="blog_time">
            {DateModified()} | <img src={clock} alt="clock" /> {Reading_Time}
          </p>
          <p>{Summary}</p>
          <Link to={`${id}`} style={{ width: "fit-content", cursor: "default" }} target="_blank" >
            <button className="blog_button_2">Read more</button>
          </Link>
        </section>
      </div>

      {/* For mobile view */}
      <div className="mblog_banner z-3">
        <section className="d-flex gap-3">
          <div className="mblog_image">
            <img src={image} alt="blog-image" />
          </div>

          <section className="mblog_desc">
            <section className="d-flex gap-1 flex-wrap">
              {category.map((data,index) => (
                <button key={index} className="mblog_button">{data}</button>
              ))}
            </section>
            <h1>{heading}</h1>
          </section>
        </section>

        <section>
          <p className="mblog_time">
            {DateModified()} | <img src={clock} alt="clock" /> {Reading_Time}
          </p>

          <p className="msummary">{Summary}</p>

          <Link to={`${id}`} target="_blank">
            <button className="mblog_button_2">Read more</button>
          </Link>
        </section>
      </div>
    </>
  );
};

export default BlogBanner;
