import React, { useEffect } from "react";
import "./contact.css";
import EnquiryForm from "../../components/Enquiry-form/EnquiryForm";
import EnquiryFormV2 from "../../components/EnquiryFormV2/EnquiryFormV2";
import Background from "../../assets/backgrounds/Background-grid.svg";
import BannerTutor10x from "../../components/Blogs/BannerTutor10x/BannerTutor10x";
import { ToastContainer } from "react-toastify";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container-fluid h-100 d-flex flex-column align-items-center m-0 mt-5 mt-lg-4 pt-lg-5 px-0 overflow-hidden">
       <ToastContainer/>
      <div className="row  position-relative">
      <div className="w-100 position-absolute top-0 background-grid  ">
            <img src={Background} alt="" />
          </div>
          <div className="position-absolute top-0 start-50 translate-middle-x color-spread "></div>
        <div className="col-12 d-flex flex-column justify-content-center align-items-center pricing-heading-container ">
          <div className="pricing-heading" style={{ color: "#07151F" }}>
            Get in touch
          </div>
          <div className="pricing-desc" style={{ color: "#25335A" }}>
            Questions? We are here to help.
          </div>
        </div>

        {/* Additional details */}

        <div className="contact_add_details" style={{zIndex:"5", backdropFilter:"blur(2px)"}} >
          <section className="add_details_1 details_box ">
            24/7 Customer Support
          </section>

          <section className="add_details_2">
            <div className="details_box2">
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_7730_50333)">
                  <path
                    d="M17.4501 14.0363C16.282 13.0372 15.0965 12.432 13.9428 13.4296L13.2538 14.0325C12.7498 14.4702 11.8126 16.5151 8.18903 12.3468C4.56625 8.18372 6.72211 7.53553 7.22693 7.10164L7.91964 6.49797C9.06737 5.49814 8.63423 4.23949 7.80645 2.94387L7.30691 2.1591C6.47536 0.866487 5.56985 0.0175763 4.41911 1.0159L3.79733 1.5592C3.28874 1.9297 1.86709 3.13402 1.52225 5.42193C1.10722 8.16712 2.41643 11.3107 5.41592 14.76C8.41163 18.2107 11.3447 19.944 14.1231 19.9138C16.4321 19.8889 17.8266 18.6498 18.2628 18.1994L18.8868 17.6553C20.0345 16.6577 19.3215 15.6421 18.1526 14.6407L17.4501 14.0363Z"
                    fill="#07151F"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7730_50333">
                    <rect
                      width="19.3363"
                      height="19.3363"
                      fill="white"
                      transform="translate(0.789062 0.578125)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="details_box">+1 828-573-8985</div>
          </section>

          <section className="add_details_3">
            <div className="details_box2">
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_7730_50340)">
                  <path
                    d="M13.318 10.8359L11.6986 12.4609C11.0418 13.1199 9.88634 13.1341 9.21543 12.4609L7.5959 10.8359L1.7793 16.6714C1.99581 16.7715 2.23453 16.8313 2.48832 16.8313H18.4257C18.6795 16.8313 18.9181 16.7716 19.1345 16.6715L13.318 10.8359Z"
                    fill="#07151F"
                  />
                  <path
                    d="M18.4256 3.23828H2.48824C2.23446 3.23828 1.99574 3.29803 1.7793 3.39815L7.99475 9.63422C7.99516 9.63463 7.99565 9.63471 7.99607 9.63512C7.99648 9.63554 7.99656 9.63611 7.99656 9.63611L10.0175 11.6638C10.2322 11.8784 10.6817 11.8784 10.8964 11.6638L12.9169 9.63645C12.9169 9.63645 12.9174 9.63554 12.9178 9.63512C12.9178 9.63512 12.9187 9.63463 12.9192 9.63422L19.1345 3.39811C18.918 3.29795 18.6794 3.23828 18.4256 3.23828Z"
                    fill="#07151F"
                  />
                  <path
                    d="M0.969812 4.19141C0.857797 4.41793 0.789062 4.66938 0.789062 4.93872V15.1356C0.789062 15.405 0.857721 15.6564 0.969774 15.8829L6.79646 10.0374L0.969812 4.19141Z"
                    fill="#07151F"
                  />
                  <path
                    d="M19.9438 4.19141L14.1172 10.0374L19.9438 15.8831C20.0558 15.6566 20.1245 15.4051 20.1245 15.1357V4.9388C20.1245 4.66938 20.0558 4.41793 19.9438 4.19141Z"
                    fill="#07151F"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7730_50340">
                    <rect
                      width="19.3363"
                      height="19.3363"
                      fill="white"
                      transform="translate(0.789062 0.367188)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <a className="details_box" href="mailto:support@evallo.org">
              support@evallo.org
            </a>
          </section>
        </div>

        {/* Apollo enquiry form */}

        <section style={{ padding: "32px 0px" }}>
          <EnquiryForm cases="contact" />
        </section>

        <div className="enq_form" style={{zIndex:"5"}}>
          <BannerTutor10x />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
