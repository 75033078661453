import styles from "./index.module.css";
import { Route, Router, Routes } from "react-router-dom";
import Home from "./Home/Home";
import MirachNavbar from "../../components/MirachNavbar/MirachNavbar";
import Creator from "./Creator/Creator";
import Account from "./Account/Account";
import SignIn from "./SignIn/SignIn";
import SignUp from "./SignUp/SignUp";
import { getTokenCookie } from "../../Utils/authTools";
import { useEffect, useState } from "react";
import MirachTopbar from "../../components/MirachTopbar/MirachTopbar";
import { ToastContainer } from "react-toastify";
import BlogsHome from "./BlogsHome/BlogsHome";
import BlogsCreator from "./BlogsCreator/BlogsCreator";
import WhitelistUser from "./WhitelistUser/WhitelistUser";
import { myProfile } from "../../Utils/utils";

const Mirach = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isInCreator, setIsInCreator] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        await myProfile();
        setIsLoggedIn(true);
      } catch (err) {
        setIsLoggedIn(false);
      }
    })();
  }, []);

  return (
    <div className={styles.container}>
      <div
        className={
          isInCreator || !isLoggedIn
            ? styles.creatorNav
            : styles.navbarContainer
        }
      >
        <MirachNavbar isLoggedIn={isLoggedIn}/>
      </div>
      <div
        className={
          isInCreator ? styles.creatorContainer : styles.contentContainer
        }
      >
        <Routes>
          <Route
            path="signin"
            element={<SignIn setIsLoggedIn={setIsLoggedIn} />}
          ></Route>
          <Route path="signup" element={<SignUp />}></Route>
          <Route path="docs" element={<Home />}></Route>
          <Route path="blogs" element={<BlogsHome />}></Route>
          <Route
            path="account"
            element={<Account setIsLoggedIn={setIsLoggedIn} />}
          ></Route>
          <Route
            path="creator/:docId?"
            element={<Creator setIsInCreator={setIsInCreator} />}
          ></Route>
          <Route
            path="blogs-creator/:docId?"
            element={<BlogsCreator setIsInCreator={setIsInCreator} />}
          ></Route>
          <Route
            path="account"
            element={<Account setIsLoggedIn={setIsLoggedIn} />}
          ></Route>
          <Route path="whitelist" element={<WhitelistUser />}></Route>
        </Routes>
      </div>
    </div>
  );
};

export default Mirach;
