import React, { useState } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './calendarFeaturesSection.module.css'
import SectionTitleAndDesc from '../SectionTitleAndDesc/SectionTitleAndDesc'
import AvatarIcon from '../../assets/images/CalendarV2/desktopIcons/AvatarIcon';
import HistoryIcon from '../../assets/images/CalendarV2/desktopIcons/HistoryIcon';
import CalendarFeatureCard from '../CalendarFeatureCardV2/CalendarFeatureCard';

import CustomBooking from '../../assets/images/CalendarV2/Custom Booking.png'
import eventType from '../../assets/images/CalendarV2/EventType.png'
import integrations from '../../assets/images/CalendarV2/Integrations.png'
import calendarAnalitycs from '../../assets/images/CalendarV2/CalAnalitycxs.png'
import CalendarIcon from '../../assets/images/CalendarV2/desktopIcons/CalendarIcon';
import MultiEventIcon from '../../assets/images/CalendarV2/desktopIcons/MultiEventIcon';
import IntegrationIcon from '../../assets/images/CalendarV2/desktopIcons/IntegrationIcon';

const cardSummary = [
    "Provide your clients with a flexible scheduling experience via booking pages tailored to your brand. Easily manage session and consultation appointments on your calendar.",
    "No more counting hours manually! Use calendar analytics to track your events and revenue data as well as feedback from your clients.",
    "Use Sessions for tutoring, Consultations for initial calls, Internal Meetings for meeting with your team, and Office Hours to track administrative hours.",
    "Streamline scheduling with seamless two-way integration with Google Calendar, Zoom or Outlook Calendar to manage your schedule from anywhere.",
]


const ActiveCircle = () => {
    return <div className={styles.activeCircle}>
        &nbsp;
    </div>
}

const ActiveLine = () => {
    return <div className={styles.activeLine}>

    </div>
}

const CalendarFeaturesSection = ({ setActivePopup }) => {

    const [expanded, setExpanded] = useState('panel1');
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div>
            <SectionTitleAndDesc title={"Our Features"} desc={"Why is Evallo the ultimate calendar management tool? Just take a look at our comprehensive features!"} customWidth={"692px"} />

            <div className={styles.featuresContainer}>
                <div className={styles.featuresSectionLeft}>
                    <Accordion className={styles.expandCard} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        {
                            expanded === "panel1" && <ActiveLine />
                        }
                        <AccordionSummary
                            className={styles.expandCardTabContainer}
                            // expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >

                                <div className={expanded === 'panel1' ? styles.activeExpandCardTab : styles.expandCardTab}>

                                    {
                                        expanded === "panel1" ? <ActiveCircle /> : <HistoryIcon />
                                    }
                                    <div>White-labeled Booking Links</div>
                                </div>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={styles.accordionDeatils}>
                            <Typography >
                                <CalendarFeatureCard summary={cardSummary[0]} setActivePopup={setActivePopup} />
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className={styles.expandCard} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        {
                            expanded === "panel2" && <ActiveLine />
                        }
                        <AccordionSummary
                            className={styles.expandCardTabContainer}
                            // expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >
                                <div className={expanded === 'panel2' ? styles.activeExpandCardTab : styles.expandCardTab}>

                                    {
                                        expanded === "panel2" ? <ActiveCircle /> : <CalendarIcon />
                                    }
                                    <div>Tracking & Analytics</div>
                                </div>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={styles.accordionDeatils}>
                            <Typography >
                                <CalendarFeatureCard summary={cardSummary[1]} setActivePopup={setActivePopup} />
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className={styles.expandCard} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        {
                            expanded === "panel3" && <ActiveLine />
                        }
                        <AccordionSummary
                            className={styles.expandCardTabContainer}
                            // expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >
                                <div className={expanded === 'panel3' ? styles.activeExpandCardTab : styles.expandCardTab}>

                                    {
                                        expanded === "panel3" ? <ActiveCircle /> : <MultiEventIcon />
                                    }
                                    <div>Multiple Event Types</div>
                                </div>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={styles.accordionDeatils}>
                            <Typography >
                                <CalendarFeatureCard summary={cardSummary[2]} setActivePopup={setActivePopup} />
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className={styles.expandCard} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        {
                            expanded === "panel4" && <ActiveLine />
                        }
                        <AccordionSummary
                            className={styles.expandCardTabContainer}
                            // expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >
                                <div className={expanded === 'panel4' ? styles.activeExpandCardTab : styles.expandCardTab}>
                                    {
                                        expanded === "panel4" ? <ActiveCircle /> : <IntegrationIcon />
                                    }
                                    <div>Two Way Integrations</div>
                                </div>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={styles.accordionDeatils}>
                            <Typography>
                                <CalendarFeatureCard summary={cardSummary[3]} setActivePopup={setActivePopup} />
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className={styles.featuresSectionRight}>
                    <img src={expanded === "panel1" ? CustomBooking : expanded === "panel2" ? calendarAnalitycs : expanded === "panel3" ? eventType : expanded === "panel4" ? integrations : ""} alt="" />
                </div>
            </div>
        </div>
    )
}

export default CalendarFeaturesSection

