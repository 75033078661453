import { NavLink } from 'react-router-dom';
import { ButtonFilled } from '../../../components/Buttons'
import DocsSearchBar from '../../../components/DocsSearchBar/DocsSearchBar'
import MirachPreviewCard from '../../../components/MirachPreviewCard/MirachPreviewCard'
import styles from './BlogsHome.module.css'
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import { fetchAllArticlesForAuthor, fetchAllBlogs, searchBlogsForKeyword, searchDocsForKeyword } from '../../../Utils/utils';
import { ToastContainer, toast } from 'react-toastify';
import MirachTopbar from '../../../components/MirachTopbar/MirachTopbar';
import MirachTable from '../../../components/MirachTable/MirachTable';

const BlogsHome = (props) => {

    const [allBlogs, setAllBlogs] = useState(null)
    const [filteredBlogs, setFilteredBlogs] = useState(null)
    const [blogsFilter, setBlogsFilter] = useState("pub")
    const [searchKeywords, setSearchKeywords] = useState(null)


    const handleOnSearchKeywordChanged = (e) => {
        setSearchKeywords(e.target.value.trim())
    }


    const selectFilter = (e) => {
        setBlogsFilter(e.target.id)
        fetchAndSetAllBlogs(e.target.id)
    }


    useEffect(()=>{
        (async()=>{
            try{
                if(searchKeywords){
                    const  blogs = await searchBlogsForKeyword(searchKeywords)
                    if(blogsFilter && blogsFilter==="pub"){
                        const tempBlogs = blogs.filter((val)=> {if(val.isPublished === true){return val}})
                        setFilteredBlogs(tempBlogs)
                    } else if(blogsFilter && blogsFilter==="draft"){
                        const tempBlogs = blogs.filter((val)=> {if(val.isPublished === false){return val}})
                        setFilteredBlogs(tempBlogs)
                    }else {
                        setFilteredBlogs(blogs)
                    }
                } else {
                    setFilteredBlogs(null)
                }
                
            }catch(err){
                throw err
            }
        })()
    },[searchKeywords])


    const fetchAndSetAllBlogs = async (filter=null) => {
        const id = toast.loading('Fetching your blogs ...')
        try{
            const blogs = await fetchAllBlogs()
            if(filter && filter==="pub"){
                const tempBlogs = blogs.filter((val)=> {if(val.isPublished === true){return val}})
                setAllBlogs(tempBlogs)
            } else if(filter && filter==="draft"){
                const tempBlogs = blogs.filter((val)=> {if(val.isPublished===false){return val}})
                setAllBlogs(tempBlogs) 
            }else {
                setAllBlogs(blogs)
            }
            toast.update(id, {
                render: "Done",
                autoClose: 1500,
                isLoading: false,
                type: "success"
            })
        }catch(err){
            toast.update(id, {
                render: "Error fetching the blogs",
                autoClose: 1500,
                isLoading: false,
                type: "error"
            })
        }
}
 
    useEffect(()=>{
        (async()=>{
            const id = toast.loading('Fetching your blogs ...')
            try{
                const blogs = await fetchAllBlogs()
                if(blogsFilter && blogsFilter === "pub"){
                    const tempBlogs = blogs.filter((val)=> {if(val.isPublished){if(val.isPublished === true){return val}}})
                    setAllBlogs(tempBlogs)
                }
                toast.update(id, {
                    render: "Done",
                    autoClose: 1500,
                    isLoading: false,
                    type: "success"
                })
            }catch(err){
                toast.update(id, {
                    render: "Error fetching the blogs, if you are loggin in for the first time, its empty.",
                    autoClose: 1500,
                    isLoading: false,
                    type: "error"
                })
            }

        })()
    },[])

    return (
        <>
        <div className={styles.topBarContainer}>
            <MirachTopbar isHome = {true} onSearchKeywordChanged={handleOnSearchKeywordChanged}/>
        </div>
        <div className={`container-fluid h-100 px-3 ${styles.contentContainer}`}>
            <div className='row py-3 px-3'>
                <div className='col-12 d-flex justify-content-between align-items-center'>
                    <h2>Blogs</h2>
                    <NavLink to="/mirach/blogs-creator">
                        <button className={styles.createDocButton}>
                            <AddIcon /> New Blog
                        </button>
                    </NavLink>
                </div>
                <div className='col-12'>
                    <button className={`btn me-3 ${blogsFilter === "pub" ? styles.selectedFilterButton : ""}`} id="pub" onClick={(e)=>selectFilter(e)}>Published</button>
                    <button className={`btn ${blogsFilter === "draft" ? styles.selectedFilterButton : ""}`} id="draft" onClick={(e)=>selectFilter(e)}>Drafts</button>
                    <button className={`btn ${blogsFilter === "pin" ? styles.selectedFilterButton : ""}`} id="pin" onClick={(e)=>selectFilter(e)}>pinned</button>
                </div>  
            </div>
            <MirachTable type="blogs" blogsFilter= {blogsFilter}   data={searchKeywords ? filteredBlogs : allBlogs} fetchAndSetAllBlogs={fetchAndSetAllBlogs} selectedFilter={blogsFilter}/>
        </div>
        </>
    )
}

export default BlogsHome