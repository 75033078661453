import './PriceFeaturesDetails.css'
import checkIcon from './ci.svg'

const PriceFeaturesDetails = (props) => {
    return (
        <div className='price-details-container'>
            <ul className="pricing-details-list row px-0">

                <div className='col-12 col-lg-6 d-flex flex-column justify-content-top align-items-start'>
                    {
                        props.priceFeatures?.slice(0, 5).map((val, idx) => {
                            return (
                                <>
                                    <div className='price-features-li d-flex flex-row justify-content-start align-items-center gap-3'>
                                        <img src={checkIcon} /><span>{val}</span>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
                <div className='col-12 col-lg-6 d-flex flex-column justify-content-top align-items-start'>
                    {
                        props.priceFeatures?.slice(5, 10).map((val, idx) => {
                            return (
                                <>
                                    <div className='price-features-li d-flex flex-row justify-content-start align-items-center gap-3'>
                                        <img src={checkIcon} /><span>{val}</span>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
            </ul>
        </div>

    )
}

export default PriceFeaturesDetails