export default function EvalloLogo  (props) {
    return (
        <svg style={{display :"block", height : props.height, width : props.width}} viewBox={`0 0 ${132} ${24}`} fill={props.fill} xmlns="http://www.w3.org/2000/svg" >
            <path d="M2.30865 8.86716H21.025C21.1948 8.86716 21.3521 8.95623 21.4395 9.1018L22.2288 10.4173C22.3248 10.5774 22.3201 10.7786 22.2165 10.934L21.4422 12.0955C21.3525 12.2299 21.2016 12.3107 21.04 12.3107H6.84549C6.47475 12.3107 6.24206 12.7109 6.42546 13.0331L8.11052 15.9933C8.19646 16.1443 8.35681 16.2375 8.53054 16.2375H18.2893C18.6608 16.2375 18.8934 16.6391 18.7085 16.9613L17.1487 19.6799C17.0625 19.8301 16.9026 19.9227 16.7295 19.9227H6.71357C6.5423 19.9227 6.38383 19.832 6.29699 19.6844L1.14149 10.9201C1.05394 10.7712 1.05245 10.587 1.13758 10.4368L1.88817 9.11218C1.97398 8.96075 2.13459 8.86716 2.30865 8.86716Z" fill={props.fill} />
            <path d="M16.7318 1.37598H6.70692C6.53925 1.37598 6.38356 1.46288 6.29555 1.6056L4.65635 4.26376C4.45778 4.58576 4.68942 5.00074 5.06772 5.00074H18.2824C18.6554 5.00074 18.8878 4.59611 18.6998 4.27392L17.1492 1.61576C17.0626 1.46728 16.9037 1.37598 16.7318 1.37598Z" fill={props.fill} />
            <path d="M2.30865 8.86716H21.025C21.1948 8.86716 21.3521 8.95623 21.4395 9.1018L22.2288 10.4173C22.3248 10.5774 22.3201 10.7786 22.2165 10.934L21.4422 12.0955C21.3525 12.2299 21.2016 12.3107 21.04 12.3107H6.84549C6.47475 12.3107 6.24206 12.7109 6.42546 13.0331L8.11052 15.9933C8.19646 16.1443 8.35681 16.2375 8.53054 16.2375H18.2893C18.6608 16.2375 18.8934 16.6391 18.7085 16.9613L17.1487 19.6799C17.0625 19.8301 16.9026 19.9227 16.7295 19.9227H6.71357C6.5423 19.9227 6.38383 19.832 6.29699 19.6844L1.14149 10.9201C1.05394 10.7712 1.05245 10.587 1.13758 10.4368L1.88817 9.11218C1.97398 8.96075 2.13459 8.86716 2.30865 8.86716Z" stroke={props.stroke} stroke-width="1.2" />
            <path d="M16.7318 1.37598H6.70692C6.53925 1.37598 6.38356 1.46288 6.29555 1.6056L4.65635 4.26376C4.45778 4.58576 4.68942 5.00074 5.06772 5.00074H18.2824C18.6554 5.00074 18.8878 4.59611 18.6998 4.27392L17.1492 1.61576C17.0626 1.46728 16.9037 1.37598 16.7318 1.37598Z" stroke={props.stroke} stroke-width="1.2" />
            <path d="M30.4265 20.2245C30.2288 20.2245 30.0511 20.1042 29.9777 19.9207L22.6509 1.60357C22.5239 1.2861 22.7577 0.940771 23.0996 0.940771H26.9278C27.1288 0.940771 27.3088 1.06514 27.3799 1.25313L30.9808 10.7755C31.2012 11.3448 31.3848 11.8407 31.5317 12.2631C31.697 12.6855 31.8348 13.0803 31.945 13.4477C32.0735 13.815 32.1837 14.1915 32.2755 14.5771C32.3524 14.8332 32.4336 15.116 32.5194 15.4256C32.5778 15.6365 32.4185 15.8444 32.1997 15.8444C31.9852 15.8444 31.8266 15.6443 31.8783 15.4361C31.9924 14.977 32.1064 14.5621 32.2205 14.1915C32.3674 13.6956 32.5327 13.1905 32.7163 12.6763C32.9 12.1437 33.1295 11.5101 33.405 10.7755L36.8705 1.2587C36.94 1.06781 37.1215 0.940771 37.3247 0.940771H41.0639C41.4064 0.940771 41.6402 1.28705 41.5122 1.60467L34.133 19.9218C34.0593 20.1047 33.8819 20.2245 33.6847 20.2245H30.4265Z" fill={props.fill} />
            <path d="M39.673 20.2245C39.3319 20.2245 39.0981 19.8806 39.2236 19.5634L46.472 1.24624C46.545 1.06187 46.7231 0.940771 46.9214 0.940771H50.2304C50.4291 0.940771 50.6076 1.0624 50.6803 1.24735L57.8763 19.5645C58.0008 19.8815 57.7671 20.2245 57.4264 20.2245H53.7332C53.5312 20.2245 53.3506 20.0989 53.2802 19.9097L49.7609 10.4449C49.614 10.0592 49.4671 9.64601 49.3201 9.20523C49.1732 8.76446 49.0263 8.31451 48.8794 7.85537C48.7324 7.37787 48.5855 6.91873 48.4386 6.47796C48.3518 6.16792 48.2734 5.87462 48.2033 5.59808C48.1527 5.39826 48.3007 5.20432 48.5067 5.19745C48.7219 5.19028 48.8849 5.39004 48.8292 5.59805C48.7447 5.91418 48.6512 6.22585 48.5488 6.53306C48.4202 6.97383 48.2825 7.4146 48.1356 7.85537C48.007 8.27778 47.8601 8.70937 47.6948 9.15014C47.5295 9.57254 47.3734 10.0133 47.2265 10.4725L43.7074 19.9101C43.6369 20.0991 43.4563 20.2245 43.2545 20.2245H39.673ZM43.3216 16.5331C42.9772 16.5331 42.7433 16.1831 42.8751 15.8649L43.9349 13.3053C44.0097 13.1247 44.1859 13.0069 44.3814 13.0069H52.6036C52.8003 13.0069 52.9774 13.1261 53.0514 13.3083L54.0912 15.8679C54.2203 16.1857 53.9865 16.5331 53.6435 16.5331H43.3216Z" fill={props.fill} />
            <path d="M60.1984 20.2245C59.9315 20.2245 59.7151 20.0081 59.7151 19.7412V1.42407C59.7151 1.15715 59.9315 0.940771 60.1984 0.940771H63.915C64.1819 0.940771 64.3983 1.15715 64.3983 1.42407V15.609C64.3983 15.8759 64.6147 16.0923 64.8816 16.0923H72.51C72.7769 16.0923 72.9933 16.3087 72.9933 16.5756V19.7412C72.9933 20.0081 72.7769 20.2245 72.51 20.2245H60.1984Z" fill={props.fill} />
            <path d="M76.1591 20.2245C75.8922 20.2245 75.6758 20.0081 75.6758 19.7412V1.42407C75.6758 1.15715 75.8922 0.940771 76.1591 0.940771H79.8757C80.1426 0.940771 80.359 1.15715 80.359 1.42407V15.609C80.359 15.8759 80.5754 16.0923 80.8423 16.0923H88.4708C88.7377 16.0923 88.9541 16.3087 88.9541 16.5756V19.7412C88.9541 20.0081 88.7377 20.2245 88.4708 20.2245H76.1591Z" fill={props.fill} />
            <path d="M99.8864 20.5C98.4172 20.5 97.0765 20.2521 95.8644 19.7562C94.6522 19.2603 93.5962 18.5624 92.6963 17.6625C91.7964 16.7626 91.0985 15.7066 90.6026 14.4945C90.1068 13.2824 89.8588 11.9509 89.8588 10.5C89.8588 9.04913 90.1068 7.71763 90.6026 6.50551C91.0985 5.29339 91.7964 4.23737 92.6963 3.33747C93.5962 2.43756 94.6522 1.73967 95.8644 1.2438C97.0765 0.747934 98.408 0.5 99.8588 0.5C101.31 0.5 102.641 0.747934 103.853 1.2438C105.065 1.73967 106.121 2.43756 107.021 3.33747C107.921 4.23737 108.61 5.29339 109.087 6.50551C109.583 7.71763 109.831 9.04913 109.831 10.5C109.831 11.9325 109.583 13.264 109.087 14.4945C108.61 15.7066 107.921 16.7626 107.021 17.6625C106.121 18.5624 105.065 19.2603 103.853 19.7562C102.641 20.2521 101.319 20.5 99.8864 20.5ZM99.8588 16.0923C100.612 16.0923 101.301 15.9545 101.925 15.6791C102.568 15.4036 103.119 15.0179 103.578 14.522C104.055 14.0078 104.423 13.4109 104.68 12.7314C104.937 12.0519 105.065 11.3081 105.065 10.5C105.065 9.69192 104.937 8.94812 104.68 8.26859C104.423 7.58907 104.055 7.00138 103.578 6.50551C103.119 5.99128 102.568 5.59642 101.925 5.32094C101.301 5.04545 100.612 4.90771 99.8588 4.90771C99.1059 4.90771 98.408 5.04545 97.7652 5.32094C97.1408 5.59642 96.5898 5.99128 96.1123 6.50551C95.6348 7.00138 95.2675 7.58907 95.0104 8.26859C94.7716 8.94812 94.6522 9.69192 94.6522 10.5C94.6522 11.3081 94.7716 12.0611 95.0104 12.759C95.2675 13.4385 95.6348 14.0262 96.1123 14.522C96.5898 15.0179 97.1408 15.4036 97.7652 15.6791C98.408 15.9545 99.1059 16.0923 99.8588 16.0923Z" fill={props.fill} />
        </svg>
    )
}