import React from 'react'
import "./featureblog.css"
import clock from "../../../../assets/images/blog-page-images/clock.png";
import { Link } from "react-router-dom";

export const FeatureBlog = ({ id, category, heading, date, Reading_Time, Summary, link, image }) => {

    const DateModified = () => {
        const modDate = new Date(date);
        const month = modDate.toLocaleString("default", { month: "short" });
        const day = modDate.getDate();
        const year = modDate.getFullYear();
        return `${month} ${day} ${year}`;
      };
    
  return (
    <div className="fblog_banner z-1" style={{minWidth:"342px"}} >
        <section className="d-flex gap-3">
          <div className="mblog_image">
            <img src={image} alt="blog-image" />
          </div>

          <section className="mblog_desc">
            <section className="d-flex gap-1 flex-wrap">
              {category.map((data,index) => (
                <button key={index} className="mblog_button">{data}</button>
              ))}
            </section>
            <h1>{heading}</h1>
          </section>
        </section>

        <section>
          <p className="mblog_time">
            {DateModified()} | <img src={clock} alt="clock" /> {Reading_Time}
          </p>

          <p className="msummary">{Summary}</p>

          <Link to={`/blogs/${id}`} target="_blank">
            <button className="mblog_button_2">Read more</button>
          </Link>
        </section>
      </div>
  )
}
