import React from 'react'
import styles from './thankYouCard.module.css'

import mailSentIcon from "../../assets/images/CalendarV2/MailSentIcon.svg"

const ThankYouCard = () => {
    return (
        <div className={styles.thankYouCardContainer}>
            <h2>Thank You!</h2>
            <div>
                <img src={mailSentIcon} alt="" />
                <div>Your pricing guide has been delivered to your email inbox.</div>
            </div>
        </div>
    )
}

export default ThankYouCard