import React from "react";
import adminDocImg from "../../assets/images/support-page-images/Admin-video-thumbnail.svg";
import tutorDocImg from "../../assets/images/support-page-images/Tutors-video-thumbnail.svg";
import studentDocImg from "../../assets/images/support-page-images/Students-video-thumbnail.svg";
import parentDocImg from "../../assets/images/support-page-images/Parents-video-thumbnail.svg";
import DocsCard from "../DocsCard/DocsCard";
import styles from "./DocsContent.module.css";

const docsItemInfo = [
  {
    image: adminDocImg,
    title: "Administrators",
    description:
      "Clicking on Documentation will lead to the knowledge base page for that user’s documentation",
  },
  {
    image: tutorDocImg,
    title: "Tutors",
    description:
      "Clicking on Documentation will lead to the knowledge base page for that user’s documentation",
  },
  {
    image: studentDocImg,
    title: "Students",
    description:
      "Clicking on Documentation will lead to the knowledge base page for that user’s documentation",
  },
  {
    image: parentDocImg,
    title: "Parents",
    description:
      "Clicking on Documentation will lead to the knowledge base page for that user’s documentation",
  },
];

const DocsContent = () => {
  return (
    <div className={styles.docsContentContainer}>
      <div className={styles.docsContentBlock}>
        <div className="row">
          <div className="col h-100">
            <DocsCard
              title={docsItemInfo[0].title}
              desc={docsItemInfo[0].description}
              img={docsItemInfo[0].image}
              button={"Learn More"}
              navTab={"admins"}
            />
          </div>
          <div className="col h-100">
            <DocsCard
              title={docsItemInfo[1].title}
              desc={docsItemInfo[1].description}
              img={docsItemInfo[1].image}
              button={"Learn More"}
              navTab={"tutors"}
            />
          </div>
        </div>
        <div className="row">
          <div className="col h-100">
            <DocsCard
              title={docsItemInfo[2].title}
              desc={docsItemInfo[2].description}
              img={docsItemInfo[2].image}
              button={"Learn More"}
              navTab={"students"}
            />
          </div>
          <div className="col h-100">
            <DocsCard
              title={docsItemInfo[3].title}
              desc={docsItemInfo[3].description}
              img={docsItemInfo[3].image}
              button={"Learn More"}
              navTab={"parents"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocsContent;
