import styles from './DocsNavMenu.module.css'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useContext, useEffect } from 'react';
import NavLinkContext from '../../contexts/NavLinkContext';
import { useParams } from 'react-router-dom';

const DocsNavMenu = (props) => {

    const {navLink, setNavLink, setContentHelp, setNavLinkChangedOnClick} = useContext(NavLinkContext)
    const {module,section} = useParams()

    const handleLinkClicked = (e) =>{
       setNavLink(e.target.id.split("_")[0])
       if (props.setShowNavLinksMobile) {
            props.setShowNavLinksMobile(true)
       }
       setNavLinkChangedOnClick(true)
    }

    useEffect(()=>{
        setNavLink(module)
    },[])

    useEffect(()=>{
        console.log("Tstin",navLink)
    },[navLink])

    return (
        <div className={`${styles.links}`}>
            <div className={`${navLink === "home" ? styles.selectedNav : styles.nav}`} id="home_nav" onClick={(e)=>handleLinkClicked(e)}>
                Home
                <div className={styles.navForward} style={{pointerEvents : "none"}}>
                    <NavigateNextIcon/>
                </div>
            </div>
            <div className={`${navLink === "admins" ? styles.selectedNav : styles.nav}`} id="admins_nav" onClick={(e)=>handleLinkClicked(e)}>
                Admins
                <div className={styles.navForward} style={{pointerEvents : "none"}}>
                    <NavigateNextIcon/>
                </div>
            </div>
            <div className={`${navLink === "tutors" ? styles.selectedNav : styles.nav}`} id="tutors_nav" onClick={(e)=>handleLinkClicked(e)}>
                Tutors
                <div className={styles.navForward} style={{pointerEvents : "none"}}>
                    <NavigateNextIcon/>
                </div>
            </div>
            <div className={`${navLink === "parents" ? styles.selectedNav : styles.nav}`} id="parents_nav" onClick={(e)=>handleLinkClicked(e)}>
                Parents
                <div className={styles.navForward} style={{pointerEvents : "none"}}>
                    <NavigateNextIcon/>
                </div>
            </div>
            <div className={`${navLink === "students" ? styles.selectedNav : styles.nav}`} id="students_nav" onClick={(e)=>handleLinkClicked(e)}>
                Students
                <div className={styles.navForward} style={{pointerEvents : "none"}}>
                    <NavigateNextIcon/>
                </div>
            </div>
        </div>
    )
}

export default DocsNavMenu