import FeaturesCard from '../../../components/FeaturesCard/FeaturesCard'
import FeaturesHero from '../../../components/FeaturesHero/FeaturesHero'
import styles from './Invoicing.module.css'
import invoiceHero from './invoiceHero.svg'
import autoCycles from './autoCycles.svg'
import notifs from './notifs1.png'
import clientMgmt from './clientMgmt.svg'
import stripeInt from './stripeInt.svg'
import manInvoice from './manInvoicing.svg'
import customPrice from './customPricing.svg'
import FeatureCardSmall from '../../../components/FeaturesCardSmall/FeaturesCardSmall'
import Meteorites from '../../../components/metorites/meteorites'
import { useEffect } from 'react'
import useScrollAnimation from '../../../Hooks/useScrollAnimation'
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import ContactFrom from '../../../components/ContactForm/ContactFrom'
import { useState } from 'react'
import { ToastContainer } from 'react-toastify'


export default function Invoicing(props) {

    const watchDemoButtonText = <div className='d-none justify-content-between gap-2 align-items-center' style={{ pointerEvents: "none" }}><PlayCircleOutlineRoundedIcon style={{ pointerEvents: "none" }} />Watch Demo</div>
    const [vidTrigger, setVidTrigger] = useState(false)
    const [vidIdx, setVidIdx] = useState(0)
    const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useScrollAnimation(styles.fCard, styles.popIn, 0.85)


    return (
        <div className={`container-fluid h-100 ${styles.container} position-relative px-0`} id="top">
             <ToastContainer/>
            <div className={`row`}>
                <div className='col-12'>
                    <FeaturesHero img={invoiceHero} heading="Invoicing System" description="This feature is currently under construction, but you can take a sneak-peek here! Inquire for more details." buttonText="Get Started" btnUrl="https://app.evallo.org/signup" />
                </div>
            </div>
            <div className='row'>
                <div className={`col-12  ${styles.fCard}`}>
                    <FeaturesCard img={autoCycles} heading="Automation" description="Take control of your cash flow with automated billing cycles. Set up recurring invoices effortlessly, ensuring timely and predictable payments. Say goodbye to the hassle of manual invoicing and hello to a smoother financial workflow." buttonText={watchDemoButtonText} rev={true} />
                </div>
                <div className={`col-12  ${styles.fCard}`}>
                    <FeaturesCard img={notifs} heading="Smart Notifications" description="Promptly follow up on outstanding invoices, minimizing delays for a proactive approach to managing your tutoring business's finances. Set up customizable notifications for parents, tutors and admins!" buttonText={watchDemoButtonText} rev={false} />
                </div>
                <div className={`col-12  ${styles.fCard}`}>
                    <FeaturesCard img={clientMgmt} heading="Customizable Policies" description="Every business runs differently. Whether you charge pre-package, post-session, hourly, monthly, weekly, or even randomly - we got you covered! Let these tools adjust for you, not the other way around." buttonText={watchDemoButtonText} rev={true} />
                </div>
            </div>
            <div className={`row row-gap-3 ${styles.smallCard}`}>
                <div className={`col-12 col-lg-4  ${styles.fCard}`}>
                    <FeatureCardSmall heading="Integrations" description="Receive payments and even send refunds directly from Evallo when you link your Stripe account. QuickBooks & Zapier coming soon." img={stripeInt} />
                </div>
                <div className={`col-12 col-lg-4  ${styles.fCard}`}>
                    <FeatureCardSmall heading='Manual Invoicing' description="If you need to create an invoice not based on a scheduled session, you can still use it for invoicing." img={manInvoice} />
                </div>
                <div className={`col-12 col-lg-4  ${styles.fCard}`}>
                    <FeatureCardSmall heading="Custom Rates" description="Rates can be adjusted individually for each tutor and even for each family, so you can meet every person’s needs." img={customPrice} />
                </div>
            </div>
            <div className='row p-0 m-0'>
                <div className='col-12 p-0 m-0'>
                <ContactFrom  {...{
                firstName,
                lastName,
                email,
                phone,
                setFirstName,
                setLastName,
                setPhone,
                message,
                setMessage,
                setEmail,
                queryFor:"sales",
              }} />
                </div>
            </div>
            <Meteorites />
        </div>
    )
}