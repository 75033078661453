import './FAQSec.css'
import closeIcon from './close.svg'
import openIcon from './open.svg'

const FAQSec = (props) => {
    return (
        <>
            <div className={`d-flex flex-column justify-content-start align-items-start  faq-sec ${props.margin}`}>
                <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                    <div className='faq-question'>
                        {props.question}
                    </div>
                    <button className='btn' style={{ color: "#25335A", fontSize: "14px" }} onClick={(e) => props.handleFaqClick(e)} id={props.buttonId}><img src={props.isActive[parseInt(props.buttonId)] ? closeIcon : openIcon} style={{ pointerEvents: "none" }} /></button>
                </div>
                <div className={"faq-answer"}>
                    {
                        props.isActive[parseInt(props.buttonId)] ? props.answer : null
                    }
                </div>
            </div>
        </>
    )
}

export default FAQSec