import React from 'react'

const CalendarIcon = () => {
    return (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 13.3333H28.5M9.83333 4V6.66667M23.1667 4V6.66667M8.76667 28H24.2333C25.7268 28 26.4736 28 27.044 27.7093C27.5457 27.4537 27.9537 27.0457 28.2093 26.544C28.5 25.9736 28.5 25.2268 28.5 23.7333V10.9333C28.5 9.43985 28.5 8.69312 28.2093 8.12269C27.9537 7.62092 27.5457 7.21297 27.044 6.95732C26.4736 6.66667 25.7268 6.66667 24.2333 6.66667H8.76667C7.2732 6.66667 6.52645 6.66667 5.95603 6.95732C5.45425 7.21297 5.04631 7.62092 4.79065 8.12269C4.5 8.69312 4.5 9.43985 4.5 10.9333V23.7333C4.5 25.2268 4.5 25.9736 4.79065 26.544C5.04631 27.0457 5.45425 27.4537 5.95603 27.7093C6.52645 28 7.27319 28 8.76667 28Z" stroke="#EE778C" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default CalendarIcon