import React from 'react'
import "./stats.css"

import mapStats from "../../assets/images/support-page-images/map-stats.svg"

const Stats = () => {
  return (
    <div>
      <div className='map-container px-4 p-lg-4 p-lg-0 mb-4'>
        <img width={"100%"} src={mapStats} alt="" />
      </div>
      <div className='row w-100 stats-info '>
        <div className="col-7 col-lg">
          <div>
            <h1>100+</h1>
            <p>Businesses Using Evallo</p>
          </div>
        </div>
        <div className="col-5 col-lg">
          <div>
            <h1>5000+</h1>
            <p>Hours Saved</p>
          </div>
        </div>
        <div className="col-5 col-lg">
          <div>
            <h1>$45,000</h1>
            <p>Annual Savings</p>
          </div>
        </div>
        <div className="col-7 col-lg ">
          <div>
            <h1>100%</h1>
            <p>Accurate Score Reports</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Stats