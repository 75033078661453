import { useContext, useEffect, useState } from 'react';
import styles from './DocsNavLinks.module.css'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavLinkContext from '../../contexts/NavLinkContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DocsDropdown from '../DocsDropdown/DocsDropdown';
import { useNavigate, useParams } from 'react-router-dom';

const navLinkMapping = {
    home : "home",
    admins : "admin",
    tutors : "tutor",
    parents : "parent",
    students : "student"
}
const contentHelp = "test"
const dummyLinks = {
    admins : [
        {
            "Overview" : [
                {
                    name :  "What is Evallo ?",
                    link : "test"
                },
                {
                    name :  "Why Choose Evallo ?",
                    link : "test"
                },
                {
                    name :  "Core Functionalities",
                    link : "test"
                },{
                    name : "User Types & Roles",
                    children : [
                        {
                            name : "Admins",
                            link : "test"
                        },
                        {
                            name : "Students",
                            link : "test"
                        },
                        {
                            name : "Tutors",
                            link : "test"
                        },
                        {
                            name : "Parents",
                            children : [
                                {
                                    name : "Test recur"
                                },
                                {
                                    name : "Test recur 2"
                                }
                            ]
                        }
                    ]
                }
               
            ]
        },
        {
            "Getting Started" : []
        },
        {
            "Managing Users (CRM) " : []
        },
        {
            "Scheduling & Calendar Management" : []
        },
        {
            "Assignments" : []
        },
        {
            "Additional Support" : []
        }
    ]
}

const DocsNavLinks = (props) =>{

    const {navLink, setNavLink, links} = useContext(NavLinkContext)
    const [latestTriggered, setLatestTriggered] = useState(null)
    const {module,section,preview, docId } = useParams()
    const navigate = useNavigate()

    const handleBackClicked = (e) =>{
        // setNavLink(null)
        props.setShowNavLinksMobile(false)
    }

    useEffect(()=>{
        console.log("---->",module,section,preview,docId)
        setLatestTriggered(section)
    },[section])

    return (
        <>
        <div className={`container-fluid p-0 w-100 ${styles.container}`}>
            <div className={`d-lg-none ${styles.linksHeader}`} onClick={(e)=>handleBackClicked(e)}>
                <NavigateBeforeIcon style={{pointerEvents : "none"}}/>
                {navLink}
            </div>
            {/* <div className={`d-none d-lg-flex ${styles.linksHeader}`} onClick={(e)=>handleBackClicked(e)}>
                <ExpandMoreIcon style={{pointerEvents : "none"}}/>
                {navLink}
            </div> */}
            <div className=''>
                {
                    links[navLinkMapping[navLink]]?.map((val,idx)=>{
                        return (
                            <DocsDropdown header={Object.keys(val)[0]} links={val[Object.keys(val)[0]]} latestTriggered={latestTriggered} setLatestTriggered={setLatestTriggered} contentHelp={contentHelp} setShowMenu={props.setShowMenu}/>
                        )     
                    })
                }
            </div>
        </div>
        </>
    )
}

export default DocsNavLinks