import { useEffect, useState } from "react";
import "./BrandSection.css";
import stp from "./logos/stp.svg";
import admin from "./logos/admin.svg";
import invoice from "./logos/invoice.svg";
import ag from "./logos/ag.svg";
import crm from "./logos/crm.svg";
import ref_mgmt from "./logos/ref_mgmt.svg";
import ss from "./logos/ss.svg";
import ev_logo_new from "./logos/ev_logo_new.svg";
import Tooltip from "@mui/material/Tooltip";
import { useRef } from "react";
import carouse1 from "./logos/carousel1.png";
import carouse2 from "./logos/carousel2.png";
import carouse3 from "./logos/carousel3.png";
import carouse4 from "./logos/carousel4.png";
import carouse5 from "./logos/carousel5.png";
import carouse6 from "./logos/carousel6.png";
import carouse7 from "./logos/carousel7.png";
import clientbg from "./logos/clients_bg.png";
import clientbg2 from "./logos/clinet_bg2.png";

const BrandsPill = (props) => {
  const [show, setShow] = useState(false);

  return (
    <div
      className={
        props.type
          ? props.type
          : "" +
          " brands-pill d-flex flex-column justify-content-top  align-items-center flex-lg-row justify-content-lg-between align-items-lg-center text-center position-relative"
      }
      style={{ ...props.style }}
    >
      {props.type ? (
        <Tooltip
          title={props.content}
          open={show}
          arrow={true}
          placement={props.place ? props.place : "top-start"}
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: "#216A99",
                color: "#F0F9FE",
                fontFamily: "Lexend",
                fontWeight: "600",
                fontSize: "14px",
              },
            },
          }}
        >
          <img
            id={props._id}
            src={props.logo}
            className="ms-4 me-4 me-lg-3 mb-3"
            onClick={(e) => setShow(!show)}
            onMouseLeave={(e) => setShow(false)}
          />
        </Tooltip>
      ) : (
        <>
          <img
            id={props._id}
            src={props.logo}
            className="ms-4 me-4 me-lg-3 mb-3"
          />
          <div className="brands-pill-content text-center">{props.content}</div>
        </>
      )}
    </div>
  );
};

const BrandSection = (props) => {
  const imgRef = useRef(null);
  const [imgWidth, setImgWidth] = useState(null);

  useEffect(() => {
    if (imgRef.current) {
      setImgWidth(imgRef.current?.getBoundingClientRect().width);

      window.addEventListener("resize", () => {
        console.log(imgRef.current?.getBoundingClientRect().width);
        setImgWidth(imgRef.current?.getBoundingClientRect().width);
      });
    }

    return () => {
      window.removeEventListener("resize", () => { });
    };
  }, []);

  return (
    <>
      <div className="col-12 position-relative d-xl-none">
        <div className="d-flex w-100 flex-column justify-content-top align-items-center d-xl-none gap-3">
          <div className="d-flex w-100 flex-row justify-content-center align-items-top">
            <BrandsPill
              logo={crm}
              content={<>CRM & Customer Profiling</>}
              type="orbit"
            />
            <BrandsPill
              logo={ref_mgmt}
              content={<>Referral Management</>}
              type="orbit"
            />
          </div>
          <div
            className="d-flex w-100 flex-row justify-content-center align-items-top"
            style={{ gap: "120px" }}
          >
            <BrandsPill
              logo={ss}
              content={<>Session Scheduling</>}
              type="orbit"
            />
            <BrandsPill logo={invoice} content={<>Invoicing</>} type="orbit" />
          </div>
          <div className="d-flex w-100 flex-row justify-content-center align-items-bottom pt-3 ps-2">
            <BrandsPill
              logo={ag}
              content={
                <>
                  Assignments <br /> & Grading
                </>
              }
              type="orbit"
            />
            <BrandsPill
              logo={stp}
              content={
                <>
                  Student
                  <br /> Performance <br />
                  Tracking
                </>
              }
              type="orbit"
            />
            <BrandsPill logo={admin} content={<>Admin Tasks</>} type="orbit" />
          </div>
          {/* <div className='d-flex w-100 flex-row justify-content-center align-items-top ps-3'>
                        <BrandsPill logo={admin} content={<>Admin Tasks</>} type="orbit"/>
                    </div> */}
        </div>
        <div className="d-flex mt-3 flex-column justify-content-top align-items-center d-xl-none" >
          <div className="subheading-container-2-home-bs" style={{ zIndex: "5" }} >TRUSTED BY</div>

          <div className=" client_headbox  ">
            <div className="clients_container " style={{ zIndex: "3" }}>
              <img
                src={clientbg2}
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  zIndex: "0",
                  top: "0",
                  left: "0",
                  zIndex: "-1"
                }}
              />

              <section className="clients_heading">
                <h1>Trusted by over 200+ Tutoring Businesses</h1>
                <p>
                  We guarantee the highest level of service to our clients.
                </p>
              </section>
              <div className="clients_carousel">
                <div class="slider">
                  <div class="slide-track">
                    <div class="slide">
                      <img src={carouse1} alt="" width={100} className="" />
                    </div>
                    <div class="slide">
                      <img src={carouse2} alt="" />
                    </div>
                    <div class="slide">
                      <img src={carouse3} alt="" />
                    </div>

                    <div class="slide">
                      <img src={carouse4} alt="" />
                    </div>
                    <div class="slide">
                      <img src={carouse5} alt="" />
                    </div>
                    <div class="slide">
                      <img src={carouse6} alt="" />
                    </div>
                    <div class="slide">
                      <img src={carouse7} alt="" />
                    </div>
                    <div class="slide">
                      <img src={carouse1} alt="" />
                    </div>
                    <div class="slide">
                      <img src={carouse2} alt="" />
                    </div>
                    <div class="slide">
                      <img src={carouse3} alt="" />
                    </div>
                    <div class="slide">
                      <img src={carouse4} alt="" />
                    </div>

                    <div class="slide">
                      <img src={carouse5} alt="" />
                    </div>
                    <div class="slide">
                      <img src={carouse6} alt="" />
                    </div>
                    <div class="slide">
                      <img src={carouse7} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <img src={oct_sm} style={{ marginTop: "-5px" }}/> */}
        </div>
        <div
          id="logo-container"
          style={{
            position: "absolute",
            top: "62px",
            left: "calc(100vw/2 - 56px)",
          }}
        >
          <img src={ev_logo_new} alt="evallo logo" />
        </div>
      </div>

      <div id="outer">
        <div id="crm">
          <BrandsPill
            logo={crm}
            content={<>CRM & Customer Profiling</>}
            _id={"_crm"}
          />
        </div>
        <div id="ref">
          <BrandsPill logo={ref_mgmt} content={<>Referral Management</>} />
        </div>
        <div id="ss">
          <BrandsPill logo={ss} content={<>Session Scheduling</>} />
        </div>

        <div id="middle">
          <div id="invoice">
            <BrandsPill logo={invoice} content={<>Invoicing</>} />
          </div>
          <div id="ag">
            <BrandsPill logo={ag} content={<>Assignments & Grading</>} />
          </div>
          <div id="inner">
            <div id="logo-container">
              <img
                src={ev_logo_new}
                alt="evallo logo"
                style={{ height: "135px", width: "156px" }}
              />
            </div>

            <div id="stp">
              <BrandsPill
                logo={stp}
                content={<>Student Performance Tracking</>}
              />
            </div>
            <div id="admin">
              <BrandsPill logo={admin} content={<>Admin Tasks</>} />
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div
        className="d-flex flex-column justify-content-center align-items-center position-absolute clients d-none d-xl-flex"
        style={{ top: "calc(1220px/2 + 168px)" }}
      >
        <div className="subheading-container-2-home-bs">OUR CLIENTS</div>

        <div className=" client_headbox  ">
          <div className="clients_container" style={{ zIndex: "3" }}>
            <img
              src={clientbg}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                zIndex: "0",
                top: "0",
                left: "0",
              }}
            />
            <section className="clients_heading">
              <h1>Trusted by over 200+ Tutoring Businesses</h1>
              <p>
                Our all-in-one tutoring management app, built for tutors by
                tutors, guarantees the highest level of service to our clients.
              </p>
            </section>

            <div className="clients_carousel">
              <div class="slider">
                <div class="slide-track">
                  <div class="slide">
                    <img src={carouse1} alt="" width={100} className="" />
                  </div>
                  <div class="slide">
                    <img src={carouse2} alt="" />
                  </div>
                  <div class="slide">
                    <img src={carouse3} alt="" />
                  </div>
                  <div class="slide">
                    <img src={carouse4} alt="" />
                  </div>
                  <div class="slide">
                    <img src={carouse5} alt="" />
                  </div>
                  <div class="slide">
                    <img src={carouse6} alt="" />
                  </div>
                  <div class="slide">
                    <img src={carouse7} alt="" />
                  </div>
                  <div class="slide">
                    <img src={carouse1} alt="" />
                  </div>
                  <div class="slide">
                    <img src={carouse2} alt="" />
                  </div>
                  <div class="slide">
                    <img src={carouse3} alt="" />
                  </div>
                  <div class="slide">
                    <img src={carouse4} alt="" />
                  </div>
                  <div class="slide">
                    <img src={carouse5} alt="" />
                  </div>
                  <div class="slide">
                    <img src={carouse6} alt="" />
                  </div>
                  <div class="slide">
                    <img src={carouse7} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <img src={brandsImg} ref={imgRef} style={{marginTop : "-10px"}}/> */}
      </div>
    </>
  );
};

export default BrandSection;
