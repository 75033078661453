import React from 'react'

const CustomContentIcon = () => {
    return (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.4601 4.24963H21.6201C20.2062 4.24963 19.0601 5.39578 19.0601 6.80963V10.6496C19.0601 12.0635 20.2062 13.2096 21.6201 13.2096H25.4601C26.8739 13.2096 28.0201 12.0635 28.0201 10.6496V6.80963C28.0201 5.39578 26.8739 4.24963 25.4601 4.24963Z" stroke="#17A289" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.38 4.24963H7.53998C6.12613 4.24963 4.97998 5.39578 4.97998 6.80963V10.6496C4.97998 12.0635 6.12613 13.2096 7.53998 13.2096H11.38C12.7938 13.2096 13.94 12.0635 13.94 10.6496V6.80963C13.94 5.39578 12.7938 4.24963 11.38 4.24963Z" stroke="#17A289" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M25.4601 18.3296H21.6201C20.2062 18.3296 19.0601 19.4757 19.0601 20.8896V24.7296C19.0601 26.1435 20.2062 27.2896 21.6201 27.2896H25.4601C26.8739 27.2896 28.0201 26.1435 28.0201 24.7296V20.8896C28.0201 19.4757 26.8739 18.3296 25.4601 18.3296Z" stroke="#17A289" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.38 18.3296H7.53998C6.12613 18.3296 4.97998 19.4757 4.97998 20.8896V24.7296C4.97998 26.1435 6.12613 27.2896 7.53998 27.2896H11.38C12.7938 27.2896 13.94 26.1435 13.94 24.7296V20.8896C13.94 19.4757 12.7938 18.3296 11.38 18.3296Z" stroke="#17A289" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default CustomContentIcon