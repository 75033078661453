import React, { forwardRef } from 'react'
import "./videoButton.css"
import { Link } from 'react-router-dom'

import videoBtnIcon from "../../../assets/icons/video-color-icon.svg"

const VideoButton = forwardRef((props, ref) => {

  const { isVideo = true, btnName, onClick } = props
  return (
    <Link>
      <button {...props} ref={ref} onClick={onClick} className='video-btn'>
        {isVideo && <img src={videoBtnIcon} alt="" />}
        <span>{btnName}</span>
      </button>
    </Link>
  )
}
)

export default VideoButton