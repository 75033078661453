import React, { useState } from "react";
import "./blogs.css";
import LeadForm from "../../LeadForm/LeadForm";
import PopUp from "../../PopUp/PopUp";

const BannerTutor10x = () => {
  const [leadFormTrigger, setLeadFormTrigger] = useState(false);

  const [leadSubmited, setLeadSubmited] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  return (
    <div className="eForm_container z-2">
      {leadFormTrigger ? (
        <PopUp trigger={leadFormTrigger} setTrigger={setLeadFormTrigger}>
          <LeadForm
            {...{
              firstName,
              lastName,
              email,
              phone,
              setFirstName,
              setLastName,
              setPhone,
              message,
              setMessage,
              setEmail,
              setFormPopUpTrigger: setLeadFormTrigger,
            }}
          />
        </PopUp>
      ) : (
        <></>
      )}
      <div>
        <h1>
          Scale your <span>Tutoring Business 10X</span>
        </h1>
        <p>
          We help scale tutoring businesses with our All-in-one Tutoring
          Management Tool - Evallo.{" "}
        </p>
        <button onClick={(e)=>setLeadFormTrigger(true)} className="submit_btn">Book a demo</button>
      </div>
    </div>
  );
};

export default BannerTutor10x;
