import styles from './Tooltip.module.css'
import TooltipTriangle from './TooltipTriangle'
import { useEffect, useState } from 'react'

const Tooltip = ({anchorComponent,tooltipContent,open,tooltipStyleClass,full,tooltipTriangleStyling}) => {

    const [_open,_setOpen] = useState(false)


    return(
        <div className={`${styles.parent} ${full ? styles.w100 : ""}`} onMouseEnter={(e)=>_setOpen(true)} onMouseLeave={(e)=>_setOpen(false)} >
            {anchorComponent}
            {
                 _open ? 
                <div className={`${styles.tooltipComponent} ${tooltipStyleClass}`}>
                    {tooltipContent}
                    <TooltipTriangle className={`${styles.tooltipTriangle} ${tooltipTriangleStyling}`}/>
                </div> : 
                <></>
            }
        </div>
    )
}

export default Tooltip