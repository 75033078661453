import Tooltip from '@mui/material/Tooltip';
import './EvSlider.css'
import Slider from '@mui/material/Slider';
import React from 'react';

function ValueLabelComponent(props) {
    const { children, value } = props;
    let message = ""
    if(value >=0 && value<=30){
        message = "2-10 Tutors"
    } else if(value>30 && value <=60){
        message = "10-30 Tutors"
    } else if(value>60){
        message = "Unlimited tutors"
    }
    console.log(children)
    return (
      <Tooltip enterTouchDelay={0} placement="top" title={message}   componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: "#216A99",
            color: "#F0F9FE",
            fontFamily : "Lexend",
            fontWeight : "600"
          }
        }
      }}>
        {children}
      </Tooltip>
    );
}
  
function valuetextSubscriptions(value) {
    switch(value) {
        case 0 : return "1 Tutor";
        case 40 : return "2-10 Tutors";
        case 80 : return "10-30 Tutors";
        case 120 : return "Unlimited Tutors";
    }
}

function valueTextExtensions(value) {
    switch(value){
        case 0 : return <div className='assignmentTooltip'>100 Assignments</div>
        case 40 : return <div className='assignmentTooltip'>400 Assignments</div>
        case 80 : return <div className='assignmentTooltip'>1500 Assignments</div>
        case 120 : return <div className='assignmentTooltip'>Unlimited Assignments</div>
    }
}

const EvSlider = (props) =>{

    const subscriptionsMarks = [
        {
            value : 0.7,
            label : "1 Tutor"
        },
        {
            value : 40
            
        },
        {
            value : 80
        },
        {
            value : 118,
            label : "Unlimited tutors"
        }
    ]

    const extensionsMarks = [
        {
            value : 0.7,
            label : <>100 <br/> Assignments</>
        },
        {
            value : 40
            
        },
        {
            value : 80
        },
        {
            value : 118,
            label : <>Unlimited <br /> Assignments</>
        }
    ]

    const MIN = 0
    const MAX = 120
    return (

        <Slider
        key={props.selectedValue ? props.selectedValue : null}
        valueLabelDisplay="on"
        valueLabelFormat={valuetextSubscriptions}
        defaultValue={props.selectedValue}
        max={120}
        step = {40}
        size='medium'
        marks = {
            subscriptionsMarks
        }
        sx={{
            height : 12,
            color : "#4683AA",
            '& .MuiSlider-thumb': {
                color : "#E7F1F8",
                border : "4px solid #4683AA",
                height : "24px",
                width : "24px"

              },
            '& .MuiSlider-mark' : {
                height : 10,
                width : 10,
                borderRadius : "50%"
            },
            '& .MuiSlider-markLabel' : {
                color : "#4683AA",
                fontFamily : "Lexend",
                fontWeight : "600",
                ...(props.smallFont) && ({fontSize : "10px"})
            },
            '& .MuiSlider-valueLabel' : {
              backgroundColor: "#164766",
              color: "#F0F9FE",
              fontFamily : "Lexend",
              fontWeight : "500",
              borderRadius : "5px",
              padding : "5px",
              fontSize : "20px",
              ...(props.smallFont) && ({fontSize : "12px"})
          }
         }}
         
         onChangeCommitted={(e,val)=>props.handleOnChangeCommitted(e,val)}
         />
        
    )
}

export default EvSlider