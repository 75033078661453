import pricingStructureAnnual from './ps_annual.json'
import pricingStuctureMonthly from './ps_month.json'
import { fetchProductPrices } from './utils'

async function getRecommendedPackageWithProducts(noOfTutors, selectedPlanType) {
    try {

        const productPrices = await fetchProductPrices()

        const upperLimitTutors = {
            "Explorer": 1,
            "Starter": 1,
            "Professional": 10,
            "Premium": 30
        }
        const tiersMapping = {
            "T0": "Tier_0",
            "T1": "Tier_1",
            "T2": "Tier_2",
            "T3": "Tier_3"
        }
        const recommendedPackageWithProducts = {
            "CRM": { price: 0, value: "", selected: true },
            "Calendar": { price: "", value: "", selected: false, default: true },
            "Assignments": { price: "", value: "", selected: false, default: true },
            "Custom Tests": { price: "", value: "", selected: false, default: true },
            "basePackage": { name: "", price: "", value: "" },
            "tutors": { price: "", value: "", selected: false, extraTutors: "" }
        }

        const requiredRows = {
            "Calendar_Tier": "CalendarV2",
            "Assignments_Tier": "AssignmentsV2",
            "Custom_Content_Tier": "CustomContentV2"
        }

        const productMapping = {
            "CalendarV2": "Calendar",
            "AssignmentsV2": "Assignments",
            "CustomContentV2": "Custom Tests"
        }
        const pricingStructure = selectedPlanType === 0 ? pricingStuctureMonthly : pricingStructureAnnual

        const recommendedPackageWithProductsRow = pricingStructure[`${noOfTutors}`]
        const recommendedBasePackageName = recommendedPackageWithProductsRow["Base_Package"].includes("*") ? recommendedPackageWithProductsRow["Base_Package"].slice(0, -1) : recommendedPackageWithProductsRow["Base_Package"]
        const recommendedBasePackagePrice = productPrices.find((val) => val.product.name === "BasePackageV2").prices[selectedPlanType === 0 ? "monthly" : "annual"].find((val) => val.lookup_key.includes(recommendedBasePackageName))

        const extraTutors = upperLimitTutors[recommendedBasePackageName] < noOfTutors ? Math.abs(upperLimitTutors[recommendedBasePackageName] - noOfTutors) : 0
        const tutorPrice = productPrices.find((val) => val.product.name === "TutorsV2").prices[selectedPlanType === 0 ? "monthly" : "annual"].find((val) => val.lookup_key.includes(recommendedBasePackageName))

        Object.keys(requiredRows).forEach((key) => {
            const stripeProductName = requiredRows[key]
            const product = productPrices.find((val) => val.product.name === stripeProductName)
            const prices = selectedPlanType === 0 ? product.prices.monthly : product.prices.annual
            const recommendedPrice = prices.find((price) => price.lookup_key.includes(tiersMapping[recommendedPackageWithProductsRow[key]]))
            console.log(">>>RP", typeof (recommendedPackageWithProductsRow[key]))
            recommendedPackageWithProducts[productMapping[stripeProductName]] = {
                ...recommendedPackageWithProducts[productMapping[stripeProductName]],
                price: recommendedPrice.unit_amount / 100,
                value: recommendedPrice.id,
                selected: true,
                default: false
            }
        })

        recommendedPackageWithProducts["basePackage"] = {
            name: recommendedBasePackageName,
            price: recommendedBasePackagePrice.unit_amount / 100,
            value: recommendedBasePackagePrice.id
        }

        recommendedPackageWithProducts["tutors"] = {
            price: parseFloat(tutorPrice.unit_amount / 100) * parseInt(extraTutors),
            value: extraTutors === 0 ? null : tutorPrice.id,
            extraTutors: extraTutors,
            selected: true
        }

        return recommendedPackageWithProducts
    } catch (error) {
        console.log(error)
    }

}

export default getRecommendedPackageWithProducts