
import styles from './Tutoring.module.css'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { useEffect, useState } from 'react'
import { useTypewriter, Cursor } from 'react-simple-typewriter'
import useScrollAnimation from '../../../Hooks/useScrollAnimation'
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import ContactFrom from '../../../components/ContactForm/ContactFrom'
import { ToastContainer } from 'react-toastify'
import SimpleEnquiryForm from '../../../components/SimpleEnquiryForm/SimpleEnquiryForm'
import SectionTitleAndDesc from '../../../components/SectionTitleAndDesc/SectionTitleAndDesc'
import BrandCarousel from '../../../components/BrandCarousel/BrandCarousel'
import CalendarFeatureCard from '../../../components/CalendarFeatureCard/CalendarFeatureCard'

import scheduleMapper from '../../../assets/images/CalendarV2/scheduleMapper.png'
import availability from '../../../assets/images/CalendarV2/avalaiblity.png'
import robustFilter from '../../../assets/images/CalendarV2/searchAnd.png'
import TestomonialCard from '../../../components/TestomonialCard/TestomonialCard';
import CustomSolutionSection from '../../../components/CustomSolutionSection/CustomSolutionSection';
import CustomSolutionResponsiveSection from '../../../components/CustomSolutionResponsiveSection/CustomSolutionResponsiveSection';
import LockedPricingSection from '../../../components/LockedPricingSection/LockedPricingSection';
import CalendarFeaturesSection from '../../../components/CalendarFeaturesSection/CalendarFeaturesSection';
import CalendarFeaturesResponsiveSection from '../../../components/CalendarFeaturesSection/CalendarFeaturesResponsiveSection';
import SimpleEnquiryFormPopup from '../../../components/SimpleEnquiryFormPopup/SimpleEnquiryFormPopup';

const calendarFeatureCardInfo = [
    {
        title: "Schedule Mapper",
        desc: "Match your team's availability with your clients and eliminate potential scheduling conflicts.",
        image: scheduleMapper
    },
    {
        title: "Availability & Time-Off",
        desc: "Take control of your schedule by setting your availability and recording your vacations. Work-life balance matters!",
        image: availability
    },
    {
        title: "Search & Filters",
        desc: "Search (literally) for ANY user's calendar for your team or your clients! Advanced filters further ensure you find what you are looking for with ease.",
        image: robustFilter
    },
]

export default function Tutoring(props) {

    const watchDemoButtonText = <div className='d-flex justify-content-between gap-2 align-items-center'><PlayCircleOutlineRoundedIcon />Watch Demo</div>
    const [vidTrigger, setVidTrigger] = useState(false)
    const [vidIdx, setVidIdx] = useState(0)

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [activePopup, setActivePopup] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
        // document.querySelector(".navbar-container").styles.padding = 0
    }, [])
    useScrollAnimation(styles.fCard, styles.popIn, 0.5)

    const [text] = useTypewriter({
        words: ["Schedule Mapping", "Client Coordination", "Request Tracking"],
        loop: {},
        typeSpeed: 120,
        deleteSpeed: 60,
    })

    const testomonialsInfo = [
        {
            name: "Alex R.",
            position: "GMAT Prep Instructor",
            starts: 4,
            review: "I was skeptical about trying another scheduling tool, but this one surprised me. The analytics feature has given me insights into my tutoring business that I never had before.",
            image: "#3bb57a"
        },
        {
            name: "Emma S.",
            position: "SAT Specialist",
            starts: 4,
            review: "I appreciate being able to visually map out my availability alongside my students'. It's particularly handy for setting up recurring SAT prep sessions. ",
            image: "#fa7a3d"
        },
        {
            name: "David L.",
            position: "Math Tutor",
            starts: 4,
            review: "Some of my students have started using the online booking page. It just looks so professional. I really like how events are categorised as sessions, consultation calls, internal meeting and office work, make life easier.",
            image: "#4f46e5"
        },
    ]

    return (
        <div className={`${styles.container}`}>
            <ToastContainer />
            <div className={styles.heroSection}>
                <div>
                    <div>
                        <h1 className={styles.typeWriter}>Your One-Stop Solution <br /> for <span>{text}</span><span><Cursor cursorBlinking={false} cursorColor='#0671E0' /> </span></h1>
                        <h1 className={styles.typeWriterResponsive}>Your One-Stop Solution for <br /> <span>{text}</span><span><Cursor cursorBlinking={false} cursorColor='#0671E0' /> </span></h1>
                        <div className={styles.heroDesc}>White label your events, automate your reminders, share your session notes, and create personalized booking links!</div>
                        <div className={styles.heroBtn}>
                            <button className={styles.bookDemoBtn} onClick={() => setActivePopup(true)}>
                                <span>Book Demo</span>
                            </button>
                            <button className={styles.trialBtn} onClick={() => setActivePopup(true)}>
                                <span>Start Trial</span>
                            </button>
                        </div>
                    </div>
                    <div>
                        <SimpleEnquiryForm setActivePopup={setActivePopup} />
                    </div>
                </div>
            </div>
            <div className={styles.middleSection}>
                <div className={styles.videoSection}>
                    <SectionTitleAndDesc title={"Scheduling Made Simple"} desc={"Why choose Evallo? Here's a glimpse of some of the key features you get when you create an account."} />
                    <div className={styles.videoContainer}>
                        <iframe src="https://drive.google.com/file/d/1JSh5b7twlf7Aa1gUkjB9oDmbWIUMzkvn/preview" width="100%" height="100%" allow="autoplay"></iframe>
                    </div>
                </div>
            </div>
            <BrandCarousel />

            <div className={styles.discoverDiv}>
                <div>Discover <span>Seamless Scheduling</span> with Evallo</div>
                <button className={styles.bookDemoTwo} onClick={() => setActivePopup(true)}>
                    <span>Book Demo</span>
                </button>
            </div>

            <div className={styles.featureSlidesSection}>
                <CalendarFeaturesSection setActivePopup={setActivePopup} />
            </div>
            <div className={styles.featureSlidesRessponsiveSection}>
                <CalendarFeaturesResponsiveSection setActivePopup={setActivePopup} />
            </div>
            <div className={styles.calendarCardContainer}>
                <div>
                    {
                        calendarFeatureCardInfo.map((el, idx) => {
                            return <CalendarFeatureCard title={el.title} desc={el.desc} image={el.image} key={idx} />
                        })
                    }
                </div>
            </div>
            <div className={styles.testomonialsContainer}>
                <h2>What Our Customers Say</h2>
                <div className={styles.testomonialCardParent}>
                    <div className={styles.testomonialsChild}>

                        <div>
                            {
                                testomonialsInfo.map((el, idx) => {
                                    return <TestomonialCard name={el.name} position={el.position} review={el.review} image={el.image} stars={4} />
                                })
                            }

                        </div>
                        <div>
                            {
                                testomonialsInfo.map((el, idx) => {
                                    return <TestomonialCard name={el.name} position={el.position} review={el.review} stars={4} image={el.image} />
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.freeTrialContainer}>
                <div className={styles.freeTrial}>
                    <h2>Start your <span>FREE TRIAL</span> with Evallo</h2>
                    <div>No credit card required</div>
                </div>
                <button className={styles.bookDemoTwo} onClick={() => setActivePopup(true)}>
                    <span>Book Demo</span>
                </button>
            </div>

            <div className={styles.customSolutionContainer}>
                <CustomSolutionSection />
            </div>
            <div className={styles.customSolutionMobileContainer}>
                <CustomSolutionResponsiveSection />
            </div>

            <div className={styles.lockedPricingSectionContainer}>
                <LockedPricingSection />
            </div>

            <div className={styles.footerFormController}>
                <div className='row pt-0 m-0 mt-4'>
                    <div className='col-12 p-0 m-0'>
                        <ContactFrom  {...{
                            firstName,
                            lastName,
                            email,
                            phone,
                            setFirstName,
                            setLastName,
                            setPhone,
                            message,
                            setMessage,
                            setEmail,
                            queryFor: "sales",
                        }} />
                    </div>
                </div>
            </div>
            {activePopup && <SimpleEnquiryFormPopup activePopup={activePopup} setActivePopup={setActivePopup} />}
        </div>
    )

}