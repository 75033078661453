import styles from './DocsNav.module.css'
import MenuIcon from '@mui/icons-material/Menu';
import evDocsLogo from './docsLogo.svg'
import { Menu } from '@mui/material';
import { ButtonFilled } from '../Buttons';
import DocsSearchBar from '../DocsSearchBar/DocsSearchBar';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import DocsNavMenu from '../DocsNavMenu/DocsNavMenu';
import DocsNavLinks from '../DocsNavLinks/DocsNavLinks';
import { createContext } from 'react';
import NavLinkContext from '../../contexts/NavLinkContext';


const DocsNav = (props) => {

    const [showMenu, setShowMenu] = useState(false)
    const [showNavLinksMobile, setShowNavLinksMobile] = useState(true)
    const {navLink, setNavLink} = useContext(NavLinkContext)

    const handleShowMenu = (e) => {
        setShowMenu(!showMenu)
    }

    return (
        <div className={`container-fluid m-0 pb-0 px-0 ${showMenu ? styles.fullHeight : ""}`}>
            <div className='row p-3 m-0 p-lg-0'>

                <div className='col-6 col-lg-3 d-flex justify-content-lg-start align-items-center'>
                    <img src={evDocsLogo} className={styles.logo} />
                </div>

                <div className='col-6 col-lg-3 order-lg-3 d-flex justify-content-end align-itms-center'>
                    <div className='d-lg-none w-100 text-end'>
                        <button className='btn p-0 m-0 border-0' onClick={(e) => handleShowMenu(e)}>
                            {
                                showMenu ?
                                    <CloseIcon style={{ color: "#667085" }} />
                                    :
                                    <MenuIcon style={{ color: "#216A99" }} />
                            }

                        </button>
                    </div>
                    <div className='d-none d-lg-flex justify-content-end align-items-center'>
                        <Link to="/mirach/signin">  
                            <button className={styles.docsButton}>Log in</button>
                        </Link>
                    </div>
                </div>

                <div className='d-none d-lg-block col-lg-6 order-lg-2 p-0 d-flex justify-content-center align-items-center position-relative'>
                    <DocsSearchBar />
                </div>

            </div>
            <div className={`row d-lg-flex m-0 mt-0 mt-lg-4 mb-0 p-0 ${showMenu ? "d-flex" : "d-none"}`}>
                <div className='d-lg-none col-12 p-3 position-relative'>
                    <DocsSearchBar setShowMenu={setShowMenu}/>
                </div>
                <div className='col-lg-3 pb-0 mb-0'>

                </div>
                <div className='col-12 col-lg-6 pb-0 mb-0 h-100 mt-3 mt-lg-0 p-0 d-lg-none'>
                    {
                        showNavLinksMobile && navLink ?
                            <DocsNavLinks setShowMenu={setShowMenu} setShowNavLinksMobile={setShowNavLinksMobile}/>
                            :
                            <DocsNavMenu setShowNavLinksMobile={setShowNavLinksMobile}/>
                    }
                </div>
                <div className='col-lg-6 pb-0 mb-0 h-100 mt-lg-0 p-0 d-none d-lg-block'>
                    <DocsNavMenu />
                </div>
                <div className='col-lg-3 pb-0 mb-0'>
                </div>
            </div>
        </div>
    )
}

export default DocsNav