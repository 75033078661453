import React from 'react'

import "./hobbiesCard.css"

function HobbieCard({icon, title, desc}) {
    return (
        <div className='col-12 col-lg-6 row px-lg-5 py-lg-4 gap-lg-2 p-4  founder-hobbies'>
            <div className=' d-flex p-0 gap-2 gap-lg-3'>
                <div>
                    <img src={icon} alt="" />
                </div>
                <h5 className=''>
                    {title}
                </h5>
            </div>
            <div className=' p-0'>
                <p>{desc}</p>
            </div>
        </div>
    )
}

export default HobbieCard