import React from 'react'

const AssignmentIcon = () => {
    return (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5820_1498)">
                <path d="M15.625 30.75C15.625 31.4404 15.0654 32 14.375 32H7.1875C4.43042 32 2.1875 29.7571 2.1875 27V5C2.1875 2.24292 4.43042 0 7.1875 0H22.5557C25.3125 0 27.5557 2.24292 27.5557 5V18.25C27.5557 18.9404 26.9958 19.5 26.3057 19.5C25.6152 19.5 25.0557 18.9404 25.0557 18.25V5C25.0557 3.62158 23.9341 2.5 22.5557 2.5H7.1875C5.80908 2.5 4.6875 3.62158 4.6875 5V27C4.6875 28.3784 5.80908 29.5 7.1875 29.5H14.375C15.0654 29.5 15.625 30.0596 15.625 30.75ZM30.3525 20.3586C29.7913 19.9568 29.0105 20.0862 28.6086 20.6472L22.4473 29.2537C22.2791 29.4487 22.0681 29.4907 21.9553 29.4983C21.8386 29.5059 21.613 29.4912 21.4194 29.3069L17.4307 25.4768C16.9329 24.9988 16.1416 25.0146 15.6633 25.5127C15.1851 26.0107 15.2012 26.802 15.6992 27.28L19.6919 31.114C20.29 31.6831 21.0881 32 21.9094 32C21.98 32 22.0508 31.9976 22.1216 31.9929C23.0161 31.9333 23.8513 31.4983 24.4131 30.7991C24.4275 30.7812 24.4417 30.7627 24.4551 30.7439L30.6414 22.1025C31.0432 21.5413 30.9141 20.7605 30.3525 20.3586ZM21.3057 7.5H8.43066C7.74023 7.5 7.18066 8.05957 7.18066 8.75C7.18066 9.44043 7.74023 10 8.43066 10H21.3057C21.9958 10 22.5557 9.44043 22.5557 8.75C22.5557 8.05957 21.9958 7.5 21.3057 7.5ZM22.5557 13.75C22.5557 13.0596 21.9958 12.5 21.3057 12.5H8.43066C7.74023 12.5 7.18066 13.0596 7.18066 13.75C7.18066 14.4404 7.74023 15 8.43066 15H21.3057C21.9958 15 22.5557 14.4404 22.5557 13.75ZM8.43066 17.5C7.74023 17.5 7.18066 18.0596 7.18066 18.75C7.18066 19.4404 7.74023 20 8.43066 20H16.25C16.9404 20 17.5 19.4404 17.5 18.75C17.5 18.0596 16.9404 17.5 16.25 17.5H8.43066Z" fill="#8D54D7" />
            </g>
            <defs>
                <clipPath id="clip0_5820_1498">
                    <rect width="32" height="32" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default AssignmentIcon