import React from 'react'

const YellowWallerIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_430_13827)">
                <path d="M15.7031 13.7169C15.7031 14.795 16.5802 15.6721 17.6582 15.6721H23.9995V11.7617H17.6583C16.5802 11.7618 15.7031 12.6388 15.7031 13.7169ZM18.7275 13.7743C18.7275 14.2096 18.3746 14.5625 17.9393 14.5625C17.504 14.5625 17.1511 14.2096 17.1511 13.7743C17.1511 13.339 17.504 12.9862 17.9393 12.9862C18.3746 12.9862 18.7275 13.3391 18.7275 13.7743Z" fill="#F1BF5E" />
                <path d="M17.6587 17.1857C15.7458 17.1857 14.1895 15.6294 14.1895 13.7165C14.1895 11.8035 15.7458 10.2472 17.6587 10.2472H24V7.60529C24 5.81818 22.5461 4.36426 20.759 4.36426H3.24103C1.45392 4.36426 0 5.81818 0 7.60529V19.7335C0 21.5206 1.45392 22.9745 3.24103 22.9745H20.759C22.5461 22.9745 24 21.5206 24 19.7335V17.1857H17.6587Z" fill="#F1BF5E" />
                <path d="M3.24134 2.85068C22.2832 2.85068 20.9185 2.83497 21.3366 2.88579C20.8172 1.78734 19.6987 1.02539 18.4052 1.02539H3.24134C1.50117 1.02539 0.0775273 2.40408 0.00390625 4.12642C0.853388 3.33542 1.99172 2.85068 3.24134 2.85068Z" fill="#F1BF5E" />
            </g>
            <defs>
                <clipPath id="clip0_430_13827">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default YellowWallerIcon