import { NavLink } from 'react-router-dom';
import { ButtonFilled } from '../../../components/Buttons'
import DocsSearchBar from '../../../components/DocsSearchBar/DocsSearchBar'
import MirachPreviewCard from '../../../components/MirachPreviewCard/MirachPreviewCard'
import styles from './Home.module.css'
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import { fetchAllArticlesForAuthor, searchDocsForKeyword } from '../../../Utils/utils';
import { ToastContainer, toast } from 'react-toastify';
import MirachTopbar from '../../../components/MirachTopbar/MirachTopbar';
import MirachTable from '../../../components/MirachTable/MirachTable';

const Home = (props) => {

    const [allDocs, setAllDocs] = useState(null)
    const [filteredDocs, setFilteredDocs] = useState(null)
    const [docsFilter, setDocsFilter] = useState("pub")
    const [searchKeywords, setSearchKeywords] = useState(null)


    const handleOnSearchKeywordChanged = (e) => {
        setSearchKeywords(e.target.value.trim())
    }


    const selectFilter = (e) => {
        setDocsFilter(e.target.id)
        fetchAndSetAllDocs(e.target.id)
    }


    useEffect(()=>{
        (async()=>{
            try{
                if(searchKeywords){
                    const docs = await searchDocsForKeyword(searchKeywords)
                    if(docsFilter && docsFilter==="pub"){
                        const tempDocs = docs.filter((val)=> {if(val.publishId){if(val.pubslishdId === ""){;}else{return val}}})
                        setFilteredDocs(tempDocs)
                    } else if(docsFilter && docsFilter==="draft"){
                        const tempDocs = docs.filter((val)=> {if(!val.publishId || val.publishId===""){return val}})
                        setFilteredDocs(tempDocs) 
                    }else {
                        setFilteredDocs(docs)
                    }
                } else {
                    setFilteredDocs(null)
                }
                
            }catch(err){
                throw err
            }
        })()
    },[searchKeywords])

    const fetchAndSetAllDocs = async (filter=null) => {
            const id = toast.loading('Fetching your docs ...')
            try{
                const docs = await fetchAllArticlesForAuthor()
                if(filter && filter==="pub"){
                    const tempDocs = docs.filter((val)=> {if(val.publishId){if(val.pubslishdId === ""){;}else{return val}}})
                    setAllDocs(tempDocs)
                } else if(filter && filter==="draft"){
                    const tempDocs = docs.filter((val)=> {if(!val.publishId || val.publishId===""){return val}})
                    setAllDocs(tempDocs) 
                }else {
                    setAllDocs(docs)
                }
                toast.update(id, {
                    render: "Done",
                    autoClose: 1500,
                    isLoading: false,
                    type: "success"
                })
            }catch(err){
                toast.update(id, {
                    render: "Error fetching the docs",
                    autoClose: 1500,
                    isLoading: false,
                    type: "error"
                })
            }
    }
 
    useEffect(()=>{
        (async()=>{
            const id = toast.loading('Fetching your docs ...')
            try{
                const docs = await fetchAllArticlesForAuthor()
                if(docsFilter && docsFilter === "pub"){
                    const tempDocs = docs.filter((val)=> {if(val.publishId){if(val.pubslishdId === ""){;}else{return val}}})
                    setAllDocs(tempDocs)
                }
                toast.update(id, {
                    render: "Done",
                    autoClose: 1500,
                    isLoading: false,
                    type: "success"
                })
            }catch(err){
                toast.update(id, {
                    render: "Error fetching the docs, if you are logging for the first time, no docs are present.",
                    autoClose: 1500,
                    isLoading: false,
                    type: "error"
                })
            }

        })()
    },[])

    return (
        <>
        <div className={styles.topBarContainer}>
            <MirachTopbar isHome = {true} onSearchKeywordChanged={handleOnSearchKeywordChanged}/>
        </div>
        <div className={`container-fluid h-100 ${styles.contentContainer}`}>
            <div className='row py-3 px-3'>
                <div className='col-12 d-flex justify-content-between align-items-center'>
                    <h2>Docs</h2>
                    <NavLink to="/mirach/creator">
                        <button className={styles.createDocButton}>
                            <AddIcon /> New Doc
                        </button>
                    </NavLink>
                </div>
                <div className='col-12'>
                    <button className={`btn me-3 ${docsFilter === "pub" ? styles.selectedFilterButton : ""}`} id="pub" onClick={(e)=>selectFilter(e)}>Published</button>
                    <button className={`btn ${docsFilter === "draft" ? styles.selectedFilterButton : ""}`} id="draft" onClick={(e)=>selectFilter(e)}>Drafts</button>
                </div>
            </div>
            <MirachTable type="docs" data={searchKeywords ? filteredDocs : allDocs} fetchAndSetAllDocs={fetchAndSetAllDocs} selectedFilter={docsFilter}/>
        </div>
        </>
    )
}

export default Home