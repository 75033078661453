import React, { useState, useRef } from 'react'
// import { useRef } from "react-router-dom"
import styles from "./multiSelectDropdown.module.css"

const MultiSelectDropdown = ({ selected, setSelected, categories }) => {

    const [query, setQuery] = useState("");
    const [menuOpen, setMenuOpen] = useState(false);
    const inputRef = useRef(null);


    const filteredCategories = categories?.filter(
        (item) =>
            item?.toLocaleLowerCase()?.includes(query.toLocaleLowerCase()?.trim()) &&
            !selected.includes(item)
    );

    const isDisable =
        !query?.trim() ||
        selected.filter(
            (item) =>
                item?.toLocaleLowerCase()?.trim() === query?.toLocaleLowerCase()?.trim()
        )?.length;

    return (
        <div className={styles.multi_select}>
            <div className="">
                {selected?.length ? (
                    <div className={styles.tag_container}>
                        {selected.map((val,idx) => {
                            return (
                                <div
                                    key={`cat_${idx}`}
                                    className={styles.tag}
                                >
                                    <div>{val}</div>
                                    <div
                                        onMouseDown={(e) => e.preventDefault()}
                                        onClick={() =>
                                            setSelected(selected.filter((i) => i !== val))
                                        }
                                    >
                                        X
                                    </div>
                                </div>
                            );
                        })}
                        <div >
                            <span
                                className={styles.clear_all}
                                onClick={() => {
                                    setSelected([]);
                                    inputRef.current?.focus();
                                }}
                            >
                                Clear all
                            </span>
                        </div>
                    </div>
                ) : null}
                <div className={styles.input_container}>
                    {/* <Icons.Search /> */}
                    <input
                        ref={inputRef}
                        type="text"
                        value={query}
                        onChange={(e) => setQuery(e.target.value.trimStart())}
                        placeholder="Search or Create category"
                        className={styles.searchInput}
                        onFocus={() => setMenuOpen(true)}
                        onBlur={() => setMenuOpen(false)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" && !isDisable) {
                                setSelected((prev) => [...prev, query]);
                                setQuery("");
                                setMenuOpen(true);
                                console.log(selected)
                            }
                        }}
                    />
                    <button
                        className=""
                        style={{ cursor: isDisable ? null : "pointer" }}
                        disabled={isDisable}
                        onClick={() => {
                            if (isDisable) {
                                return;
                            }
                            setSelected((prev) => [...prev, query]);
                            setQuery("");
                            inputRef.current?.focus();
                            setMenuOpen(true);
                        }}
                    >
                        + Add
                    </button>
                </div>

                {/* Menu's */}
                {menuOpen ? (
                    <div className={styles.list_container}>
                        <ul className="w-full">
                            {filteredCategories?.length ? (
                                filteredCategories.map((tag, i) => (
                                    <li
                                        key={tag}
                                        className="p-2 cursor-pointer hover:bg-rose-50 hover:text-rose-500 rounded-md w-full"
                                        onMouseDown={(e) => e.preventDefault()}
                                        onClick={() => {
                                            setMenuOpen(true);
                                            setSelected((prev) => [...prev, tag]);
                                            setQuery("");
                                        }}
                                    >
                                        {tag}
                                    </li>
                                ))
                            ) : (
                                <li className="p-2 text-gray-500">No options available</li>
                            )}
                        </ul>
                    </div>
                ) : null}
            </div>
        </div >
    )
}

export default MultiSelectDropdown