import React from 'react'
import "./teamProfile.css"

import avatar from "../../assets/images/about-us/Avatar.svg"

const TeamProfile = ({name, position, image}) => {
    return (
        <div className='col-6 col-lg-3 p-1 team-profile'>
            <div className=' d-flex flex-column align-items-center gap-3'>
                <div>
                    <img width={"100%"} src={image} alt="" />
                </div>
                <div>
                    <h6>{name}</h6>
                    <p>{position}</p>
                </div>
            </div>
        </div>
    )
}

export default TeamProfile