import React from 'react'
import Pagination from '@mui/material/Pagination';
const PaginationComp = ({total,page, handleChange}) => {

  return (

    <div style={{ display:"flex", alignItems:"center", justifyContent:"center", width:"100%" }}>
      <Pagination count={ Math.round((total+1) /4)} color='primary'  size="large" boundaryCount={2}  onChange={handleChange}  />
    
    </div>
  )
}

export default PaginationComp