import axios from "axios";
import { axiosInstance } from "../../../Utils/axiosHelper";
import { getAuthHeader } from "../../../Utils/authTools";

axios.defaults.headers.post["Content-Type"] = "application/json";

export const CreateUser = async (values) => {
  console.log(values);
  let data = { email: values.email, role: values.role };
  const userInfo = await axiosInstance.post(
    "/api/auth/whiteListEmail",
    { data, withCredentials: true },
    getAuthHeader()
  );

  return userInfo.data;
};

export const DeleteUser = async (id) => {
  let data = { id: id };
  const userInfo = await axiosInstance.post(
    "/api/auth/deleteWhiteListEmail",
    {
      data,
      withCredentials: true,
    },
    getAuthHeader()
  );

  return userInfo.data;
};

export const GetAllUsers = async () => {
  const userInfo = await axiosInstance.get(
    "/api/auth/getWhiteList",
    { withCredentials: true },
    getAuthHeader()
  );

  return userInfo.data;
};
