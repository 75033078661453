import React, { useEffect, useRef, useState } from 'react'
// import styles from "./slideShow.module.css"
import "./slideShow.css"

import calendar from "../../assets/images/slideShow/Group.svg"
import robins from "../../assets/images/slideShow/robins.svg"
import dsat from "../../assets/images/features-page-images/DSAT-feature.svg"
import invoicing from "../../assets/images/features-page-images/invoicing.svg"
import tutor from "../../assets/images/features-page-images/Frame 1321315006.svg"

const   SlideShow = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const data = [
        {
            image: calendar,
            title: 'Calendar & Scheduling',
            desc: 'Evallo offers a seamless and intuitive experience to help you organize, track, and optimize your tutoring sessions. Go ahead and explore!',
        },
        {
            image: tutor,
            title: 'User Management',
            desc: 'Keep track of your relationships with tutors, students, and parents in one place with our in-built CRM. Use simple tools to track your sales pipeline, student profiles, tutor performance, and more!',
        },
        {
            image: dsat,
            title: 'Full-length Tests',
            desc: 'From paper-based tests to the new Digital SAT® portal, you get all your academic material in one place. Generate detailed score reports for tests, worksheets, drills, question banks, and more!',
        },
        {
            image: invoicing,
            title: 'Automated Invoices',
            desc: 'Automatically create and send invoices based on the schedule you and your tutors manage. Choose from various billing cycles that match your business policies.',
        },
        {
            image: robins,
            title: 'Performance Reports',
            desc: 'Track student performance and time management with automatic reports that contain breakdowns by question type and strategy type for each test. Student progress over time is graphed automatically.',
        },
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
        }, 4000); // Change the duration as per your preference

        return () => clearInterval(interval);
    }, [data]);

    return (
        <div className='slide-container'>
            <div className="data-container">
                {data.map((item, index) => (
                    <div
                        key={index}
                        className={`data-item ${index === currentIndex ? 'active' : ''
                            }`}
                    >
                        <div className='slide-image-container'>
                            <img src={item.image} alt={item.title} />
                        </div>
                        <h2>{item.title}</h2>
                        <p>{item.desc}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};



export default SlideShow