import React from 'react'
import styles from './sectionTitleAndDesc.module.css'

const SectionTitleAndDesc = ({ title, desc, customWidth }) => {
    return (
        <div className={styles.titleAndDescContainer}>
            <h2>{title}</h2>
            <div style={{ width: customWidth ? customWidth : "80%" }}>{desc}</div>
        </div>
    )
}

export default SectionTitleAndDesc