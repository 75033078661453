import * as yup from "yup";

export const whiteListSchema = yup.object().shape({
  email: yup
    .string()
    .email("Enter a valid Email")
    .required("Email is required"),
  role: yup.string().required("Role is Required"),
});

export const deleteUserSchema = yup.object().shape({
  Id: yup.string().required("User ID id required"),
});
