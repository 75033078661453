import React, { useEffect, useState } from 'react'
import "./support.css"

import Background from "../../assets/backgrounds/Background-grid.svg"
import adminVideoThumb from "../../assets/images/support-page-images/Admin-video-thumbnail.svg"
import tutorVideoThumb from "../../assets/images/support-page-images/Tutors-video-thumbnail.svg"
import studentVideoThumb from "../../assets/images/support-page-images/Students-video-thumbnail.svg"
import parentVideoThumb from "../../assets/images/support-page-images/Parents-video-thumbnail.svg"
import HeroTitleAndDesc from '../../components/hero-title-description/HeroTitleAndDesc'
import VideoCard from '../../components/cards/video-card/VideoCard'
import EnquiryForm from '../../components/Enquiry-form/EnquiryForm'
import TitleTag from '../../components/titleTagBox/TitleTag'
import FAQSec from '../../components/FAQSec/FAQSec'
import { ToastContainer } from 'react-toastify'






const Support = () => {

    const [isActive, setIsActive] = useState([true, false, false, false, false, false])

    useEffect(() => {
        setIsActive([true, false, false, false, false, false])
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleFaqClick = (e) => {
        const id = parseInt(e.target.id)
        let _isActive = isActive.map((val, idx) => false)
        console.log('Before', _isActive)
        _isActive[id] = !isActive[id]
        console.log("after", _isActive)
        setIsActive(_isActive)
    }


    const faqs = [
        {
            "question": "Do you offer unlimited student and parent accounts?",
            "answer": "Yes, we do! Nothing too complicated here - just go to the CRM and start adding students and parents via the “Bulk Upload” feature or the “Add New User” button. You can also directly share with your clients the unique sign-up link that comes with your account. To access that, simply visit your admin account Settings and click on the “Client Sign-Up” tab, under which you will find a link that students and parents can use to join your organization.",
        },
        {
            "question": "Do you have curated content like practice DSAT® or Math drills?",
            "answer": "Yes, we do! After signing up as a tutoring business administrator, you will have the option to select your subscription type and to add any extensions, which include “Assignments”. You can choose to start your free trial and gain access to hundreds of question banks, worksheets, practice tests, and official answer keys - all available to directly assign to your students.",
        },
        {
            "question": "What can I expect when I start using Evallo",
            "answer": `When you start using Evallo to run your tutoring business, you can expect the following:
            <br/> 1. About 3-4 hours saved daily due to automations in score reporting and invoicing, along with advanced filters to quickly manage your users (students, parents, and tutors) and their schedules.
            <br/> 2. Flexibility in managing your tutoring business policies through the “Organization Defaults” settings.
            <br/> 3. About $45,000 saved yearly (for businesses in the United States) by reducing your Ops team size.
            <br/> 4. Better referral tracking through our Referral Codes feature.
            <br/> 5. 100% accurate instant score reports that enhance your client’s service experience.
            <br/> 6. Top-notch customer support with guaranteed response within 24 hours of raising a query.`,
            "list": true
        },
        {
            "question": "Can Evallo serve individual tutors as well as large-scale tutoring companies?",
            "answer": "Yes, we can service tutoring businesses of all sizes. We understand that enterprises carry a lot of momentum, so we provide additional support to large businesses in transferring to Evallo. For individual tutors and SMBs, we provide a faster onboarding experience and a complete step-by-step process on how they can use Evallo to scale up.",
        },
        {
            "question": "Why is Evallo the better than anything else out there?",
            "answer": ` Evallo is the best tutoring management platform for the following reasons:
            1. Any tutoring business requires three key verticals to run efficiently - Operations, Content, and Marketing. While most platforms are limited to one, Evallo is a comprehensive solution that addresses all three verticals efficiently. 
            <br/> 2. Our user interface is friendly, easy to learn, and scalable. 
            <br/> 3. Our customer support and onboarding tutorials is top-notch.
            <br/> 4. Our platform is fast, secure, and simple.
            <br/> 5. Our pricing is competitive, especially if you consider content.`,
        },
        {
            "question": "How can I trust my business with Evallo?",
            "answer": " Evallo was built by tutors, for tutors. If you are facing a pain point right now in running your business, there is a good chance that the founders of Evallo have gone through something similar as well. Thousands of hours of research, hundreds of customer interviews, multiple rounds of peer review, and months of careful development has led to Evallo’s robust offerings. With us, you are guaranteed to receive top-class service, support, and pampering. We are collaborative by nature.",
        }
    ]

    const supportInfo = [
        {
            "title": "Administrators",
            "description": "Clicking on Documentation will lead to the knowledge base page for that user’s documentation, and clicking on Tutorials will lead to the playlist prepared.",
            "image": adminVideoThumb,
            "video": "https://www.youtube.com/playlist?list=PLfBtUFeRMAF__fNqpgzS1YwgjLJTN1P-m",
            "doc": "https://docs.google.com/document/d/1qUEe6w4FN7ArOoTbpAMH66aBDLfzAGyYP2NFzmHkp9A/edit"
        },
        {
            "title": "Tutors",
            "description": "Clicking on Documentation will lead to the knowledge base page for that user’s documentation, and clicking on Tutorials will lead to the playlist prepared.",
            "image": tutorVideoThumb,
            "video": "https://www.youtube.com/playlist?list=PLfBtUFeRMAF_4_t9-0C26E6x00JTH0t0y",
            "doc": "https://docs.google.com/document/d/1cS7wMBaPIO5LGWVAM1WyzECzB__XRCOabw9kjSCNdgA/edit"

        },
        {
            "title": "Students",
            "description": "Clicking on Documentation will lead to the knowledge base page for that user’s documentation, and clicking on Tutorials will lead to the playlist prepared.",
            "image": studentVideoThumb,
            "video": "https://www.youtube.com/playlist?list=PLfBtUFeRMAF8xg7ZBD6nvhPX5zPy86TBr",
            "doc": "https://docs.google.com/document/d/1kl_iNotF_LZ9FSlN38VlckWZCBzyv-KhAcMoV7j3lWo/edit"
        },
        {
            "title": "Parents",
            "description": "Clicking on Documentation will lead to the knowledge base page for that user’s documentation, and clicking on Tutorials will lead to the playlist prepared.",
            "image": parentVideoThumb,
            "video": "https://www.youtube.com/playlist?list=PLfBtUFeRMAF9WMvGqWMhbODvQToHFgZhc",
            "doc": "https://docs.google.com/document/d/1UpKf0pNFVyaIdGOPCcyLCs-67fJitj0eCokMZvdmFr8/edit"

        },
    ]

    const heroSection = {
        "title": "Support & FAQs",
        "description": "Welcome to the Evallo Support Dock, your go-to station for guidance and troubleshooting in the Evallo-verse"
    }

    return (
        <>
            <div className='container-fluid overflow-hidden'>
            <ToastContainer/>
                {/* <div className='row'>Navbar</div> */}
                <div className='row'>
                    <div className='w-100'>
                        <HeroTitleAndDesc title={heroSection.title} desc={heroSection.description} />
                        <div className=' row position-relative '>
                            <div className='w-100 mt-5 px-xxl-5 z-2'>
                                <div className='row p-1 m-lg-2 p-xl-5 justify-content-center'>
                                    {
                                        // Video Cards are appending here
                                        supportInfo.map((el, i) => {
                                            return <VideoCard key={i} title={el.title} desc={el.description} image={el.image} videoUrl={el.video} docUrl={el.doc} />
                                        })
                                    }
                                </div>
                            </div>
                            <div className='w-100 position-absolute top-0 background-grid'>
                                <img src={Background} alt="" />
                            </div>
                            <div className='position-absolute top-0 start-50 translate-middle-x color-spread'></div>
                            {/* Enquiry Form is here */}

                            <TitleTag title={"FAQs"} />
                            <div className='position-relative Z-2 mb-5 px-3 faq-section'>
                                <div className='home-faq-title-container col-12 d-flex flex-column justfy-content-between flex-lg-row justify-content-lg-center align-items-center '>
                                    <div className='faq-title-2'>Still Curious?</div>
                                    <div className='faq-title-1'>&nbsp;These might help...</div>
                                </div>

                                <div className='col-12 mt-5'>
                                    <FAQSec question={"Do you offer unlimited student and parent accounts?"}
                                        answer={"Yes, we do! Nothing too complicated here - just go to the CRM and start adding students and parents via the “Bulk Upload” feature or the “Add New User” button. You can also directly share with your clients the unique sign-up link that comes with your account. To access that, simply visit your admin account Settings and click on the “Client Sign-Up” tab, under which you will find a link that students and parents can use to join your organization."}
                                        isActive={isActive} buttonId={0} handleFaqClick={handleFaqClick} />
                                </div>
                                <div className='col-12 mt-4'>
                                    <FAQSec question={"Do you have curated content like practice DSAT® or Math drills?"}
                                        answer={"Yes, we do! After signing up as a tutoring business administrator, you will have the option to select your subscription type and to add any extensions, which include “Assignments”. You can choose to start your free trial and gain access to hundreds of question banks, worksheets, practice tests, and official answer keys - all available to directly assign to your students."}
                                        isActive={isActive} buttonId={1} handleFaqClick={handleFaqClick} />
                                </div>
                                <div className='col-12 mt-4'>
                                    <FAQSec question={"What can I expect when I start using Evallo?"}
                                        answer=
                                        {<>
                                            When you start using Evallo to run your tutoring business, you can expect the following:
                                            <ul>
                                                <li>About 3-4 hours saved daily due to automations in score reporting and invoicing, along with advanced filters to quickly manage your users (students, parents, and tutors) and their schedules.</li>
                                                <li>Flexibility in managing your tutoring business policies through the “Organization Defaults” settings.</li>
                                                <li>About $45,000 saved yearly (for businesses in the United States) by reducing your Ops team size.</li>
                                                <li>Better referral tracking through our Referral Codes feature.</li>
                                                <li>100% accurate instant score reports that enhance your client’s service experience.</li>
                                                <li>Top-notch customer support with guaranteed response within 24 hours of raising a query</li>
                                            </ul>
                                        </>}
                                        isActive={isActive} buttonId={2} handleFaqClick={handleFaqClick} />
                                </div>
                                <div className='col-12 mt-4'>
                                    <FAQSec question={"What advantage do Evallo’s dashboards offer?"}
                                        answer={"Evallo’s CRM functionality allows for detailed customer profiles, aiding in understanding clients better. This, in turn, facilitates personalized tutoring experiences, ultimately enhancing customer interactions."}
                                        isActive={isActive} buttonId={3} handleFaqClick={handleFaqClick} />
                                </div>
                                <div className='col-12 mt-4'>
                                    <FAQSec question={"Can Evallo serve individual tutors as well as large-scale tutoring companies?"}
                                        answer={"Yes, we can service tutoring businesses of all sizes. We understand that enterprises carry a lot of momentum, so we provide additional support to large businesses in transferring to Evallo. For individual tutors and SMBs, we provide a faster onboarding experience and a complete step-by-step process on how they can use Evallo to scale up."}
                                        isActive={isActive} buttonId={4} handleFaqClick={handleFaqClick} />
                                </div>
                                <div className='col-12 mt-4'>
                                    <FAQSec question={"Why is Evallo better than anything else out there?"}
                                        answer={<>
                                            Evallo is the best tutoring management platform for the following reasons:
                                            <ul>
                                                <li>Any tutoring business requires three key verticals to run efficiently - Operations, Content, and Marketing. While most platforms are limited to one, Evallo is a comprehensive solution that addresses all three verticals efficiently. </li>
                                                <li>Our user interface is friendly, easy to learn, and scalable. </li>
                                                <li>Our customer support and onboarding tutorials is top-notch.</li>
                                                <li>Our platform is fast, secure, and simple.</li>
                                                <li>Our pricing is competitive, especially if you consider content.</li>
                                            </ul>
                                        </>}
                                        isActive={isActive} buttonId={5} handleFaqClick={handleFaqClick} />
                                </div>
                                <div className='col-12 mt-4'>
                                    <FAQSec question={"How can I trust my business with Evallo?"}
                                        answer={`Evallo was built by tutors, for tutors. If you are facing a pain point right now in running your business, there is a good chance that the founders of Evallo have gone through something similar as well. Thousands of hours of research, hundreds of customer interviews, multiple rounds of peer review, and months of careful development has led to Evallo’s robust offerings. With us, you are guaranteed to receive top-class service, support, and pampering. We are collaborative by nature.`}
                                        isActive={isActive} buttonId={6} handleFaqClick={handleFaqClick} />
                                </div>

                            </div>
                            <EnquiryForm queryFor="support" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Support