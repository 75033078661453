import React from "react";
import "./article.css";
import art_image from "../../../assets/images/blog-page-images/article_img.png";
import clock from "../../../assets/images/blog-page-images/clock.png";
import { Link } from "react-router-dom";
import apiURL from "../../../apiURL";

const ArticleBanner = ({ BlogsBannerData }) => {
  const DateModified = (date) => {
    const modDate = new Date(date);
    const month = modDate.toLocaleString("default", { month: "short" });
    const day = modDate.getDate();
    const year = modDate.getFullYear();
    return `${month} ${day} ${year}`;
  };

  return (
    <div className="banner z-2">
      <div className="artice_banner">
        <div className="article_image">
          <img src={BlogsBannerData?.img} alt="artice-image" />
        </div>

        <section className="art_desc ">
          <section className="d-flex gap-2">
            {BlogsBannerData?.categories.map((data, index) => (
              <button key={index} className="art_button">
                {data}
              </button>
            ))}
          </section>
          <h2>{BlogsBannerData.title}</h2>

          <div className="art_time">
            {DateModified(BlogsBannerData.date)} |{" "}
            <img src={clock} alt="clock" /> {BlogsBannerData.readTime}
          </div>

          <p>{BlogsBannerData.seoDescription}</p>

          <Link to={`${BlogsBannerData._id}`} target="_blank">
            <button className="art_button_2">Read more</button>
          </Link>
        </section>
      </div>
    </div>
  );
};

export default ArticleBanner;
