import React, { useEffect, useState } from "react";
import "./description.css";
import BannerTutor10x from "../../components/Blogs/BannerTutor10x/BannerTutor10x";
import ArticleBanner from "../../components/BlogDescription/ArticleBanner/ArticleBanner.jsx";
import { fetchBlogByid } from "../../Utils/utils.js";
import Background from "../../assets/backgrounds/Background-grid.svg";
import FeaturedComponent from "../../components/BlogDescription/ArticleBanner/Featured Blog/FeaturedComponent.jsx";
import { toast } from "react-toastify";

const PageDescription = () => {
  const [blog, setBlog] = useState(null);
  const [headings, setHeadings] = useState([]);

  useEffect(() => {
    const fetchBlog = async () => {
      const url = window.location.href;
      const id = url.substring(url.lastIndexOf("/") + 1);
      try {
        const response = await fetchBlogByid(id);
        setBlog(response);
      } catch (err) {
        console.error(err);
      }
    };

    fetchBlog();
  }, []);

  useEffect(() => {
    if (blog?.html) {
      // Parse the HTML content to find all heading tags
      const parser = new DOMParser();
      const doc = parser.parseFromString(blog?.html, "text/html");
      const headings = Array.from(
        doc.querySelectorAll("h1, h2, h3, h4, h5, h6")
      );
      setHeadings(headings);
    }
  }, [blog]);

  // Handler to scroll to the original heading
  const handleScrollToHeading = (index) => {
    const heading = headings[index];
    if (heading) {
      const element = document.getElementById(heading.id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const scrollToRead = () => {
    const element = document.querySelector(".blog_brief");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const share = (name) => {
    const currentUrl = window.location.href;
    const text = encodeURIComponent('Check out this page: ');

    let Url;
    if(name == "twitter"){
       Url = `https://twitter.com/intent/tweet?text=${text}&url=${encodeURIComponent(currentUrl)}`;
      }
      else if(name == "facebook"){
        
        Url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`;
    }
    else if(name == "linkedin"){
       Url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(currentUrl)}`;

    }
    
    window.open(Url, '_blank', 'noopener,noreferrer');
};
 

  return (
    <div className="container-fluid px-0 overflow-hidden ">
      <div className="row  position-relative ">
        <div
          className="w-100 position-absolute top-0 background-grid  "
          style={{ marginTop: "20rem" }}
        >
          <img src={Background} alt="" />
        </div>
        <div className="position-absolute top-0 start-50 translate-middle-x color-spread "></div>

        {blog && (
          <ArticleBanner BlogsBannerData={blog} scrollToRead={scrollToRead} />
        )}

        <div className="main_desc z-2 " style={{ backdropFilter: "blur(2px)" }}>
          <div className="main_desc2">
            <div className="main_desc3">
              {blog?._id && (
                <div className="desc_first">
                  <h3>Table of contents</h3>

                  <div className="headings-list">
                    {headings.map((h, index) => (
                      <p
                        className="heading_item"
                        key={index}
                        onClick={() => handleScrollToHeading(index)}
                      >
                        {h.id}
                      </p>
                    ))}
                  </div>
                  <section className=" desc_second ">
                    <h3>Don’t forget to share this post</h3>

                    <div className="desc_links">
                      <button  onClick={()=> { navigator.clipboard.writeText(window.location.href); toast.success("Link copied to clipboard !") } }>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.33448 10.8332C8.69235 11.3117 9.14894 11.7075 9.67327 11.994C10.1976 12.2805 10.7774 12.4508 11.3734 12.4935C11.9693 12.5362 12.5675 12.4502 13.1273 12.2414C13.6871 12.0326 14.1954 11.7058 14.6178 11.2832L17.1178 8.78322C17.8768 7.99738 18.2968 6.94487 18.2873 5.85238C18.2778 4.7599 17.8396 3.71485 17.0671 2.94231C16.2945 2.16978 15.2495 1.73157 14.157 1.72208C13.0645 1.71259 12.012 2.13256 11.2261 2.89156L9.79281 4.31656M11.6678 9.16656C11.3099 8.68811 10.8533 8.29224 10.329 8.00577C9.80469 7.71931 9.22489 7.54896 8.62893 7.50628C8.03298 7.4636 7.43482 7.54958 6.87502 7.7584C6.31522 7.96723 5.80688 8.294 5.38448 8.71656L2.88448 11.2166C2.12548 12.0024 1.70551 13.0549 1.715 14.1474C1.72449 15.2399 2.1627 16.2849 2.93523 17.0575C3.70777 17.83 4.75282 18.2682 5.8453 18.2777C6.93779 18.2872 7.9903 17.8672 8.77614 17.1082L10.2011 15.6832"
                            stroke="#344054"
                            stroke-width="1.67"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>

                      <button onClick={() => share("twitter") } >
                        <svg
                          width="20"
                          height="18"
                          viewBox="0 0 20 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.2918 17.1251C13.8371 17.1251 17.9652 10.8724 17.9652 5.45167C17.9652 5.27589 17.9613 5.0962 17.9535 4.92042C18.7566 4.33967 19.4496 3.62033 20 2.7962C19.2521 3.12896 18.458 3.34627 17.6449 3.44074C18.5011 2.92755 19.1421 2.12135 19.4492 1.17159C18.6438 1.64892 17.763 1.98563 16.8445 2.1673C16.2257 1.50976 15.4075 1.07439 14.5164 0.928498C13.6253 0.78261 12.711 0.934331 11.9148 1.3602C11.1186 1.78607 10.4848 2.46238 10.1115 3.28455C9.73825 4.10672 9.64619 5.02897 9.84961 5.9087C8.21874 5.82686 6.62328 5.40321 5.16665 4.6652C3.71002 3.9272 2.42474 2.89132 1.39414 1.62472C0.870333 2.52782 0.710047 3.59649 0.945859 4.61353C1.18167 5.63057 1.79589 6.51966 2.66367 7.10011C2.01219 7.07943 1.37498 6.90402 0.804688 6.58839V6.63917C0.804104 7.58691 1.13175 8.50561 1.73192 9.2391C2.3321 9.97258 3.16777 10.4756 4.09687 10.6626C3.49338 10.8277 2.85999 10.8518 2.2457 10.7329C2.50788 11.548 3.01798 12.2609 3.70481 12.7721C4.39164 13.2833 5.22093 13.5673 6.07695 13.5845C4.62369 14.726 2.82848 15.3452 0.980469 15.3423C0.652739 15.3418 0.325333 15.3217 0 15.2821C1.87738 16.4866 4.06128 17.1263 6.2918 17.1251Z"
                            fill="#0B2333"
                          />
                        </svg>
                      </button>

                      <button onClick={() => share("facebook") }>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_7363_15961)">
                            <path
                              d="M20 10C20 4.47715 15.5229 0 10 0C4.47715 0 0 4.47715 0 10C0 14.9912 3.65684 19.1283 8.4375 19.8785V12.8906H5.89844V10H8.4375V7.79688C8.4375 5.29063 9.93047 3.90625 12.2146 3.90625C13.3084 3.90625 14.4531 4.10156 14.4531 4.10156V6.5625H13.1922C11.95 6.5625 11.5625 7.3334 11.5625 8.125V10H14.3359L13.8926 12.8906H11.5625V19.8785C16.3432 19.1283 20 14.9912 20 10Z"
                              fill="#0B2333"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_7363_15961">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>

                      <button onClick={() => share("linkedin") }>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.5195 0H1.47656C0.660156 0 0 0.644531 0 1.44141V18.5547C0 19.3516 0.660156 20 1.47656 20H18.5195C19.3359 20 20 19.3516 20 18.5586V1.44141C20 0.644531 19.3359 0 18.5195 0ZM5.93359 17.043H2.96484V7.49609H5.93359V17.043ZM4.44922 6.19531C3.49609 6.19531 2.72656 5.42578 2.72656 4.47656C2.72656 3.52734 3.49609 2.75781 4.44922 2.75781C5.39844 2.75781 6.16797 3.52734 6.16797 4.47656C6.16797 5.42188 5.39844 6.19531 4.44922 6.19531ZM17.043 17.043H14.0781V12.4023C14.0781 11.2969 14.0586 9.87109 12.5352 9.87109C10.9922 9.87109 10.7578 11.0781 10.7578 12.3242V17.043H7.79688V7.49609H10.6406V8.80078H10.6797C11.0742 8.05078 12.043 7.25781 13.4844 7.25781C16.4883 7.25781 17.043 9.23438 17.043 11.8047V17.043Z"
                            fill="#0B2333"
                          />
                        </svg>
                      </button>
                    </div>
                  </section>
                </div>
              )}

              <div className="blog_brief">
                <div dangerouslySetInnerHTML={{ __html: blog?.html }} />
              </div>
            </div>
          </div>
        </div>

         {blog && <FeaturedComponent id={blog?._id} /> }

        <BannerTutor10x />
      </div>
    </div>
  );
};

export default PageDescription;
