import React from 'react'

const ThreeArrowIcon = () => {
    return (
        <svg width="35" height="20" viewBox="0 0 35 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.7559 19.1457L11.9167 18.3062C11.3611 17.7504 11.3612 16.8493 11.9171 16.2937L18.2135 9.99905L11.917 3.70435C11.3611 3.14865 11.361 2.24757 11.9167 1.69173L12.756 0.852195C13.3117 0.296351 14.2127 0.296257 14.7686 0.851914L22.9116 8.99255C23.4676 9.54835 23.4675 10.4497 22.9116 11.0055L14.7685 19.146C14.2126 19.7017 13.3115 19.7015 12.7559 19.1457Z" fill="white" />
            <path d="M23.7559 19.1457L22.9167 18.3062C22.3611 17.7504 22.3612 16.8493 22.9171 16.2937L29.2135 9.99905L22.917 3.70435C22.3611 3.14865 22.361 2.24757 22.9167 1.69173L23.756 0.852195C24.3117 0.296351 25.2127 0.296257 25.7686 0.851914L33.9116 8.99255C34.4676 9.54835 34.4675 10.4497 33.9116 11.0055L25.7685 19.146C25.2126 19.7017 24.3115 19.7015 23.7559 19.1457Z" fill="white" />
            <path d="M1.75589 19.1457L0.916736 18.3062C0.36108 17.7504 0.361221 16.8493 0.917064 16.2937L7.21355 9.99905L0.916971 3.70435C0.361127 3.14865 0.360986 2.24757 0.91669 1.69173L1.75599 0.852195C2.31169 0.296351 3.21272 0.296257 3.76856 0.851914L11.9116 8.99255C12.4676 9.54835 12.4675 10.4497 11.9116 11.0055L3.76852 19.146C3.21263 19.7017 2.31155 19.7015 1.75589 19.1457Z" fill="white" />
        </svg>

    )
}

export default ThreeArrowIcon