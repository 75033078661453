import React, { useEffect, useState } from "react";
import styles from "./leadForm.module.css";
import { insertQueryInDynamoDb } from "../../Utils/utils";
import { toast, ToastContainer } from "react-toastify";
import LoaderIcon from "./LoaderIcon";

const LeadForm = ({
  setLeadSubmited,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phone,
  setPhone,
  message,
  setMessage,
  setFormPopUpTrigger,
}) => {
  const [loaderIcon, setLoaderIcon] = useState(false);
  const [success, setSuccess] = useState(false);


  console.log(firstName, lastName, email, message)

  const isEmail = (val) => {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      return false;
    } else {
      return true;
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (firstName === "" || email === "" || message === "" || lastName === "" || phone === "" ) {
      toast.warn("Please fill required fields.");
      return;
    }
    if(phone.length >10){
      toast.warn("Enter correct phone number");
      return;
    }
    if (isEmail(email) == false) {
      toast.warn("Enter correct email.");
      return;
    }

    setLoaderIcon(true);
    try {
      const result = await insertQueryInDynamoDb({
        firstName: firstName,
        lastName: lastName,
        email: email,
        message: message,
        phone:  phone,
        queryFor: "sales",
      });
      setSuccess(true);
      window.history.pushState({}, "", `book-demo/call-scheduling`);
      if (setLeadSubmited) {
        setLeadSubmited(true);
      }
      toast.success("Thank you for entering the query.");
      if (setFormPopUpTrigger) setFormPopUpTrigger(false);
      window.ApolloMeetings.submit({
        onSuccess: () => {
          toast.success(
            "Meeting booked successfully. Looking forward to meet you."
          );
          setFirstName("")
          setLastName("")
          setPhone("")
          setEmail("")
          setMessage("")
        },
      });
    } catch (err) {
      setLoaderIcon(false);
      console.log(err);
      toast.error("Oops an error occured");
    //   if (setFormPopUpTrigger) setFormPopUpTrigger(false);
    }

    setLoaderIcon(false)
  };

  return (
    <div>
      <div className={styles.lead_form} style={{ zIndex: "2" }}>
        <form
          className="position-relative z-2 question-from"
          action=""
          id="book-demo-form"
        >
          <div className="d-flex flex-wrap flex-md-nowrap gap-2 gap-lg-3">
            <label className="d-flex flex-column" htmlFor="first_name">
              <input
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                value={firstName}
                placeholder="First Name*"
                id="first_name"
                name="first_name"
              />
            </label>
            <label className="d-flex flex-column" htmlFor="last_name">
              <input
                onChange={(e) => setLastName(e.target.value)}
                type="text"
                value={lastName}
                placeholder="Last Name*"
                id="last_name"
                name="last_name"
              />
            </label>
          </div>
          <div className="d-flex flex-wrap flex-lg-nowrap gap-2 gap-lg-3">
            <label className="d-flex flex-column" htmlFor="email">
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                value={email}
                placeholder="Email*"
                id="email"
                name="email"
              />
            </label>
            <label
              className="d-flex w-100 w-md-100 flex-column"
              htmlFor="phone"
            >
              <input
                onChange={(e) => setPhone(e.target.value)}
                type="text"
                value={phone}
                placeholder="Phone Number*"
                id="phone"
                name="phone"
              />
            </label>
          </div>
          <div>
            <label className="d-flex flex-column" htmlFor="message">
              <textarea
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Message*"
                value={message}
                name="message"
                id="message"
                cols="30"
                rows="5"
              ></textarea>
            </label>
          </div>

          <button
            disabled={loaderIcon}
            onClick={(e) => {
              handleFormSubmit(e);
            }}
            className={styles.submit_btn}
          >
            {loaderIcon && success ? (
              "Query submitted"
            ) : loaderIcon ? (
              <LoaderIcon />
            ) : (
              "Book A Demo"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LeadForm;
