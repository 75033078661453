import React, { useState } from 'react'
import "./feature.css"
// import "../../components/components.css"
import crm from "../../../assets/images/features-page-images/Frame 1321315006.svg"
import invoic from "../../../assets/images/features-page-images/invoicing.svg"
import ssn from "../../../assets/images/features-page-images/SSN.svg"
import dsat from "../../../assets/images/features-page-images/DSAT-feature.svg"
import feedback from "../../../assets/images/features-page-images/student-feedback.svg"
import tstProgress from "../../../assets/images/features-page-images/test progress.svg"
import evalloTw from "../../../assets/images/features-page-images/evello tw.svg"
import HeroTitleAndDesc from '../../../components/hero-title-description/HeroTitleAndDesc'
import ReverseBanner from '../../../components/banner/ReverseBanner'
import Banner from '../../../components/banner/Banner'
import CardWithPadding from '../../../components/cards/cards-with-padding/CardWithPadding'
import NormalButton from '../../../components/Buttons/NormalButton/NormalButton'
import Meteorites from '../../../components/metorites/meteorites'
import PackageFeaturesList from '../../../components/PackageFeaturesList/PackageFeaturesList'
import PriceFeaturesDetails from '../../../components/PriceFeaturesDetails/PriceFeaturesDetails'
import PackageFeaturesDropDown from '../../../components/PackageFeaturesDropDown/PackageFeaturesDropDown'
import ContactFrom from '../../../components/ContactForm/ContactFrom'
import { useEffect } from 'react'
import { ToastContainer } from 'react-toastify'

const Features = () => {
    const [priceFeature, setPriceFeatures] = useState("General")

    const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

    const priceFeatures = {
        "General": [
            "Client-Facing Portals",
            "Unique Accounts for Every User Type",
            "Customizable Client Sign-Up Form",
            "Unique Referral Codes",
            "Announcement Board",
            "Advanced Permissions Control",
            "Branding & Personalization",
            "Custom Shareable Sign-Up Form Link",
            "Modular Extensions Selection"
        ],
        "User Management": [
            "Bulk Upload / Bulk Edit Users",
            "Lead & Contact Management",
            "Customizable & Detailed User Profiles",
            "Export User Data",
            "Online Document Storage",
            "Advanced Filters for Lead Segmentation",
            "Contact Mapping",
            "Customizable Tags and Statuses",
            "Student Feedback"
        ],
        "Calendar & Scheduling": [
            "Bulk Updates",
            "Weekly Repeating Sessions",
            "Automatic Reminders",
            "Session Notes - Client & Internal",
            "Advanced Session Reconciliation",
            "Customizable Tags",
            "Yearly, Monthly and Weekly Views",
            "Universal Time Zones",
            "User-Specific Calendar Analytics",
            "Rescheduling & Partial Sessions"
        ],
        "Analytics": [
            "Centralized Dashboard",
            "Business Performance Reporting",
            "User Activity & Action Log",
            "Tutor Performance Management",
            "Client Success Reporting",
            "Student Performance Analytics"
        ],
        "Invoicing": ["Manual Invoice Generation", "Automated Invoices", "Customizable Invoicing Settings for Each Family", "Billing Plan-Based", "Invoicing", "Overdue Invoice Reminders", "Invoice Approval Workflow", "Advanced Filters to Manage Invoices", "Auto-Generated Payment Links", "Advanced Options to Manage Missed & Cancelled Session"]
    }

    const featureDetails = [
        {
            "title": "Assignments & Grading",
            "description": "From paper-based tests to the new Digital SAT® portal, you get all your academic material in one place. Generate detailed score reports for tests, worksheets, drills, question banks, and more! Use our curated content or add your own.",
            "image": dsat,
            "button": "View Details",
            "featureUrl": "dsat"
        },
        {
            "title": "CRM & Profiles",
            "description": "Keep track of your relationships with tutors, students, and parents in one place with our in-built CRM (customer relationship manager). Use simple tools to track your sales pipeline, student profiles, tutor performance, and more!",
            "image": crm,
            "button": "View Details",
            "featureUrl": "crm"
        },
        {
            "title": `Scheduling &
            Session Notes`,
            "description": "Take control of your time with a powerful calendar that is integrated session notes and instant tags. Schedule sessions yourself or give tutors the ability to schedule their own sessions in the same system.",
            "image": ssn,
            "button": "View Details",
            "featureUrl": "calendar"
        },
        {
            "title": "Invoicing",
            "description": "Automatically create and send invoices based on the schedule you and your tutors manage. Decide between pre-/post-session billing, manual or scheduled invoicing, and more options built with tutors in mind.",
            "image": invoic,
            "button": "View Details",
            "featureUrl": "invoicing"
        },
    ]
    const featureCardDetails = [

        {
            "title": "Student Feedback",
            "description": "Gather feedback directly from students after every single session right after a session is marked as Completed. Only admins are able to access the ratings.",
            "image": feedback
        },
        {
            "title": "Score Reports",
            "description": "With the optional Assignments extension, students can complete tests and drills directly in Evallo for automatic grading and tracking.",
            "image": tstProgress
        },
        {
            "title": "Free Training",
            "description": "Don’t be overwhelmed by the switch to a new platform–get unlimited onboarding training for yourself and your team.",
            "image": evalloTw
        },

    ]

    const heroSection = {
        "title": "Features",
        "description": "Our comprehensiveness is what sets us apart. Below, you will find the evidence to this statement, though this is only the beginning! There’s so much more to come..."
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="container-fluid px-0 overflow-hidden">
            <ToastContainer/>

                {/* Hero section Title and Description here */}

                <HeroTitleAndDesc title={heroSection.title} desc={heroSection.description} grid={true} />

                {/* Mapping banner fist banner image right side thne second image left side */}
                <div className='row gap-0 row-gap-4 gap-lg-5 featured-items'>

                    {
                        featureDetails.map((el, i) => {
                            if (i % 2 !== 0) return <ReverseBanner key={i} title={el.title} desc={el.description} image={el.image} button={<NormalButton btnName={el.button} btnUrl={el.featureUrl} />} featureUrl={el?.featureUrl} note={ i==3 ? "Note: this feature is currently under development." : null}/>
                            return <Banner key={i} title={el.title} desc={el.description} image={el.image} button={<NormalButton btnName={el.button} btnUrl={el.featureUrl} />} featureUrl={el?.featureUrl} />
                        })
                    }
                    {/* {
                        featureDetails.map((el, i) => {
                            if (i % 2 !== 0) return <div className='col-12 '> <ReverseBanner key={i} title={el.title} desc={el.description} image={el.image} button={<NormalButton btnName={el.button} btnUrl={el.featureUrl} />} featureUrl={el?.featureUrl} /></div>
                            return <div className='col-12 '> <Banner key={i} title={el.title} desc={el.description} image={el.image} button={<NormalButton btnName={el.button} btnUrl={el.featureUrl} />} featureUrl={el?.featureUrl} /> </div>
                        })
                    } */}


                    {/* Bottom cards mapping here */}
                    <div className='row m-0 p-1 gap-4'>

                        {
                            featureCardDetails.map((el, i) => {
                                return <CardWithPadding key={i} title={el.title} desc={el.description} image={el.image} />
                            })
                        }

                    </div>

                </div>

                <div className='row p-4 px-lg-5 mx-lg-5' id="features-section">
                    <div className='col-12 d-flex flex-column justify-content-center align-items-center pricing-heading-container-2'>
                        <div className='pricing-heading px-0 py-3' id='features-section-heading'>All Packages Include</div>
                    </div>
                    <div className='col-12 col-lg-4 d-none d-lg-block'>
                        <PackageFeaturesList setSelected={setPriceFeatures} selected={priceFeature} />
                    </div>
                    <div className='col-12 col-lg-4 d-lg-none'>
                        <PackageFeaturesDropDown setSelected={setPriceFeatures} />
                    </div>
                    <div className='col-12 col-lg-8 px-lg-3 p-0' style={{ backgroundColor: "#E7F1F8" }}>
                        <PriceFeaturesDetails priceFeatures={priceFeatures[priceFeature]} />
                    </div>
                </div>
                <div className='row p-0 m-0'>
                    <div className='col-12 p-0 m-0'>
                    <ContactFrom  {...{
                firstName,
                lastName,
                email,
                phone,
                setFirstName,
                setLastName,
                setPhone,
                message,
                setMessage,
                setEmail,
                queryFor:"sales",
              }} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Features