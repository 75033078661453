import React from 'react'
import styles from './responsiveIcon.module.css'

const MultiEventIcon = () => {
    return (
        <svg className={styles.calendarSectionIcons} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="40" height="40" rx="12" fill="#0671E0" />
            <rect x="1" y="1" width="40" height="40" rx="12" stroke="#0671E0" />
            <path d="M31.4981 16.099C31.4969 17.5838 30.9068 19.0076 29.8572 20.0579C28.8076 21.1082 27.3842 21.6993 25.8994 21.7015H20.6719C20.5725 21.7012 20.4773 21.6616 20.407 21.5913C20.3368 21.5211 20.2972 21.4258 20.2969 21.3265V16.099C20.605 8.67087 31.1909 8.6746 31.4981 16.099Z" fill="white" />
            <path d="M29.0681 27.1162C28.8188 32.9329 20.5448 32.9347 20.2969 27.1161V23.1074C20.2967 23.0581 20.3063 23.0093 20.3251 22.9637C20.3439 22.9181 20.3715 22.8767 20.4063 22.8419C20.4412 22.807 20.4826 22.7794 20.5282 22.7606C20.5737 22.7418 20.6226 22.7323 20.6719 22.7324H24.6844C25.8466 22.7339 26.9608 23.1962 27.7826 24.018C28.6044 24.8398 29.0667 25.954 29.0681 27.1162Z" fill="white" />
            <path d="M19.2675 17.3138V21.3263C19.2677 21.3756 19.2581 21.4244 19.2393 21.47C19.2205 21.5156 19.1929 21.557 19.158 21.5919C19.1232 21.6267 19.0818 21.6543 19.0362 21.6731C18.9906 21.6919 18.9418 21.7015 18.8925 21.7013H14.8837C13.7215 21.6999 12.6074 21.2376 11.7855 20.4158C10.9637 19.594 10.5014 18.4798 10.5 17.3176C10.7397 11.4979 19.0262 11.4986 19.2675 17.3138Z" fill="white" />
            <path d="M19.2675 23.1074V27.1162C19.0294 32.9309 10.7404 32.9312 10.5 27.1162C10.5008 25.9538 10.9629 24.8392 11.7849 24.0173C12.6068 23.1953 13.7214 22.7332 14.8837 22.7324H18.8925C18.9419 22.732 18.9909 22.7413 19.0366 22.76C19.0823 22.7787 19.1238 22.8063 19.1587 22.8412C19.1937 22.8761 19.2213 22.9176 19.2399 22.9634C19.2586 23.0091 19.268 23.0581 19.2675 23.1074Z" fill="white" />
        </svg>

    )
}

export default MultiEventIcon