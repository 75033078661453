import React from 'react'
import styles from './calendarFeatureCard.module.css'

const CalendarFeatureCard = ({ title, desc, image }) => {
    return (
        <div className={styles.cardContainer}>
            <div>
                <div className={styles.cardTitle}>{title}</div>
                <div className={styles.cardDesc}>{desc}</div>
            </div>
            <div className={styles.cardImage}>
                <img src={image} alt="" />
            </div>
        </div>
    )
}

export default CalendarFeatureCard