import './PackageFeaturesDropDown.css'
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import GridViewIcon from '@mui/icons-material/GridView';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import selected from './selected.svg'
import book from './book.svg'
import analytics from './analytics.svg'
import apps from './apps.svg'
import cal from './cal.svg'
import { useState } from 'react';



const PackageFeaturesDropDown = (props) => {
    const features = [
        <><img src={book} /><span>General</span></>,
        <> <img src={apps} /><span>User Management</span></>,
        <> <img src={cal} /><span>Calendar & Scheduling</span></>,
        <> <img src={analytics} /><span>Analytics</span></>,
        <><MonetizationOnIcon /><span>Invoicing</span></>
    ]

    const fets = [
        "General",
        "User Management",
        "Calendar & Scheduling",
        "Analytics",
        "Invoicing"
    ]
    const [selectedFeature, setSelectedFeature] = useState(<><img src={book} /><span>General</span></>)
    const [selectedFeatureIdx, setSelectedFeatureIdx] = useState(0)

    const handleSelectedFeature = (e) => {
        setSelectedFeature(features[parseInt(e.target.id)])
        setSelectedFeatureIdx(parseInt(e.target.id))
        props.setSelected(fets[parseInt(e.target.id)])
    }

    return (

        <div className='dropdown text-center pt-4 pfl-dd'>
            <button className="btn dropdown-toggle w-100 package-dropdown-btn" data-bs-toggle="dropdown" type='button'>
                {selectedFeature}
            </button>

            <ul className="package-features-dd pfl-dropdown dropdown-menu dropdown-menu-center">
                <li onClick={(e) => handleSelectedFeature(e)} id={0} style={{ display: selectedFeatureIdx === 0 ? "none" : "block" }}>
                    <div className='d-flex flex-row justify-content-start align-items-center gap-3' style={{ pointerEvents: "none" }}>
                        <img src={book} /><span>General</span>
                    </div>
                </li>
                <li onClick={(e) => handleSelectedFeature(e)} id={1} style={{ display: selectedFeatureIdx === 1 ? "none" : "block" }}>
                    <div className='d-flex flex-row justify-content-start align-items-center gap-3' style={{ pointerEvents: "none" }}>
                        <img src={apps} /><span>User Management</span>
                    </div>
                </li>
                <li onClick={(e) => handleSelectedFeature(e)} id={2} style={{ display: selectedFeatureIdx === 2 ? "none" : "block" }}>
                    <div className='d-flex flex-row justify-content-start align-items-center gap-3' style={{ pointerEvents: "none" }}>
                        <img src={cal} /><span>Calendar & Scheduling</span>
                    </div>
                </li>
                <li onClick={(e) => handleSelectedFeature(e)} id={3} style={{ display: selectedFeatureIdx === 3 ? "none" : "block" }}>
                    <div className='d-flex flex-row justify-content-start align-items-center gap-3' style={{ pointerEvents: "none" }}>
                        <img src={analytics} /><span>Analytics</span>
                    </div>
                </li>
                <li onClick={(e) => handleSelectedFeature(e)} id={4} style={{ display: selectedFeatureIdx === 4 ? "none" : "block" }}>
                    <div className='d-flex flex-row justify-content-start align-items-center gap-3' style={{ pointerEvents: "none" }}>
                        <MonetizationOnIcon /><span>Invoicing</span>
                    </div>
                </li>

            </ul>
        </div>
    )

}

export default PackageFeaturesDropDown