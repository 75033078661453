import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";

import Banner from "../../../components/banner/Banner";
import NormalButton from "../../../components/Buttons/NormalButton/NormalButton";
import ReverseBanner from "../../../components/banner/ReverseBanner";
import VideoButton from "../../../components/Buttons/VideoButton/VideoButton";
import CardWithPadding from "../../../components/cards/cards-with-padding/CardWithPadding";
import FeaturesHeroSection from "../../../components/FeaturesHeroSection/FeaturesHeroSection";

import crmHeroBg from "../../../assets/backgrounds/featureHeroCRM.svg";
import userManagement from "../../../assets/images/crm-page-images/usermanage.png";
import userProfiles from "../../../assets/images/crm-page-images/userProfile.svg";
import tracking from "../../../assets/images/crm-page-images/performanceTracking.svg";
import bulkUpload from "../../../assets/images/crm-page-images/bulkUpload.svg";
import autoMapping from "../../../assets/images/crm-page-images/autoMapping.svg";
import feedback from "../../../assets/images/crm-page-images/tutorReview.svg";
import Meteorites from "../../../components/metorites/meteorites";
import testUm from "./test.svg";
import PopUp from "../../../components/PopUp/PopUp";
import ContactFrom from "../../../components/ContactForm/ContactFrom";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";

const CrmProfile = () => {
  const [isSmallDevice, setIsSmallDevice] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showMessage2, setShowMessage2] = useState(false);
  const [formTrigger, setFormTrigger] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const crmInfo = {
    image: crmHeroBg,
    title: "CRM & Profiles",
    description:
      "Add your parents, students, tutors, partners, and staff to manage your entire tutoring business from a single Evallo administrator account.",
    buttonText: "Get Started",
    buttonUrl: "https://app.evallo.org/signin",
  };

  const crmItemInfo = [
    {
      image: userManagement,
      title: "User Management",
      description:
        "Manage various pipelines through Evallo’s integrated CRM. From bulk import/export to mass editing and sorting, convenience is now at your fingertips!",
    },
    {
      image: userProfiles,
      title: "Robust User Profiles",
      description:
        "Record every little detail about your users, including their personal info, relationships with other users, tags, links, documents, personality, progression, notes & much more!",
    },
    {
      image: tracking,
      title: "Performance Tracking",
      description:
        "Keep a track of tutor performance via the rating system, and monitor individual student progress directly through student profiles.",
    },
  ];

  const crmCardInfo = [
    {
      image: bulkUpload,
      title: "Bulk Upload",
      description:
        "With 50+ fields that can be mapped in bulk, this feature makes your transition from your current product to Evallo easy.",
    },
    {
      image: autoMapping,
      title: "Auto-Mapping",
      description:
        "All mapping happens automatically via bulk upload: any-to-many relationships between tutors and clients and one-to-many relationships between parents and students.",
    },
    {
      image: feedback,
      title: "Collect Feedback",
      description:
        "Students can leave ratings and reviews for their tutors from their dashboard. Admins can access these from tutor profiles.",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className="container-fluid position-relative px-0"
      style={{ overflow: "clip" }}
    >
       <ToastContainer/>
      <PopUp trigger={formTrigger} setTrigger={setFormTrigger}>
        <ContactFrom
         {...{
          firstName,
          lastName,
          email,
          phone,
          setFirstName,
          setLastName,
          setPhone,
          message,
          setMessage,
          setEmail,
          queryFor: "sales",
          cases:"popup",
          setFormTrigger: setFormTrigger,
        }}
        />
      </PopUp>
      <Meteorites />
      <FeaturesHeroSection
        image={crmInfo.image}
        title={crmInfo.title}
        desc={crmInfo.description}
        buttonText={crmInfo.buttonText}
        buttonUrl={crmInfo.buttonUrl}
        page ="crm"
      />

      <div className="row gap-0 row-gap-4 gap-lg-5 featured-items">
        <Banner
          title={crmItemInfo[0].title}
          desc={crmItemInfo[0].description}
          image={crmItemInfo[0].image}
          button={
            <Tooltip
              title="Coming Soon"
              open={showMessage && isSmallDevice === false}
            >
              {" "}
              <VideoButton
                onClick={(e) => {
                  setShowMessage(true);
                  setIsSmallDevice(false);
                }}
                onMouseLeave={(e) => {
                  setShowMessage(false);
                  setIsSmallDevice(false);
                }}
                onMouseEnter={(e) => {
                  setShowMessage(true);
                  setIsSmallDevice(false);
                }}
                btnName={"Watch Video"}
              />{" "}
            </Tooltip>
          }
        />
        <ReverseBanner
          title={crmItemInfo[1].title}
          desc={crmItemInfo[1].description}
          image={crmItemInfo[1].image}
          button={
            <Tooltip
              title="Coming Soon"
              open={showMessage2 && isSmallDevice === false}
            >
              {" "}
              <VideoButton
                onClick={(e) => {
                  setShowMessage2(true);
                  setIsSmallDevice(false);
                }}
                onMouseLeave={(e) => {
                  setShowMessage2(false);
                  setIsSmallDevice(false);
                }}
                onMouseEnter={(e) => {
                  setShowMessage2(true);
                  setIsSmallDevice(false);
                }}
                btnName={"Watch Video"}
              />{" "}
            </Tooltip>
          }
        />
        <Banner
          title={crmItemInfo[2].title}
          desc={crmItemInfo[2].description}
          image={crmItemInfo[2].image}
          button={
            <button onClick={(e)=>setFormTrigger(true)} className="normal-btn"> Inquire For Details </button>
            // <NormalButton
            //   btnUrl={"mailto:support@evallo.org"}
            //   btnName={"Inquire For Details"}
            // />
          }
        />

        <div className="row m-0 p-1 gap-4">
          {crmCardInfo.map((el, i) => {
            return (
              <CardWithPadding
                key={i}
                title={el.title}
                desc={el.description}
                image={el.image}
              />
            );
          })}
        </div>
      </div>
      <div className="row p-0 m-0">
        <div className="col-12 p-0 m-0">
        <ContactFrom  {...{
                firstName,
                lastName,
                email,
                phone,
                setFirstName,
                setLastName,
                setPhone,
                message,
                setMessage,
                setEmail,
                queryFor:"sales",
              }} />
        </div>
      </div>
    </div>
  );
};

export default CrmProfile;
