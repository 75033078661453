import React from 'react'

const RightIcon = () => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.27637 2L15.8097 8.06667L9.27637 14.1333" stroke="currentColor" stroke-width="2" stroke-linecap="square" stroke-linejoin="bevel" />
            <path d="M1.80957 7.81669H14.8762" stroke="currentColor" stroke-width="2" stroke-linecap="square" stroke-linejoin="bevel" />
        </svg>

    )
}

export default RightIcon