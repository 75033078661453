import React from "react";
import "./pinsection.css";
import PinBanner from "../PinBanner/PinBanner";

const PinSection = ({ PinPostData }) => {
  return (
    <div className="pinsection">
      <h2>Pinned Posts</h2>

      <section className="pinlist">
        {PinPostData.map((blog) => (
          <PinBanner key={blog._id} category={blog.categories} heading={blog.title} date={blog.date} Reading_Time={blog.readTime} Summary={blog.seoDescription} id={blog._id}  />
        ))}
      </section>
    </div>
  );
};

export default PinSection;
