import React from 'react'
import styles from "./priceCard.module.css"
import RightIcon from './RightIcon'

const PriceCard = ({ planType, cost, desc, isYearly, recommendedPlan, numberOfTutors, idx, onCTAButtonClick }) => {
    return (
        <div className={styles.card}>
            <div className={styles.recommend} style={{ display: `${recommendedPlan === planType ? "block" : "none"}` }}>
                BEST FOR {numberOfTutors} TUTORS
            </div>
            <div style={{ border: `${recommendedPlan === planType ? "4px solid #0671E0" : "1px solid #ADB2C0"}` }}>
                <div className={styles.plan_type}>{planType}</div>
                <div className={styles.price_amt}>
                    {
                        cost !== "Free" ?
                            <>
                                <h1><span className={styles.dollar}>$</span>{isYearly ? (Math.round(cost / 12)).toFixed(0) : cost}<span className={styles.plan_day}>/month</span></h1>
                                {
                                    !isYearly ? <div>billed monthly</div> : <div>billed yearly</div>
                                }
                            </>
                            :
                            <>
                                <h1>FREE</h1>
                                <div>now & forever</div>
                            </>
                    }
                </div>
                <div className={styles.plan_desc} style={{ paddingRight: `${idx === 1 || idx === 3 ? "16px" : ""}` }} > {desc} </div>
                <div className={`${styles.btn_div}`}>
                    <button className={`${recommendedPlan === planType ? styles.recommendedButton : ""}`}  onClick={()=>onCTAButtonClick()}>
                        <div>Get Started</div>
                        <RightIcon />
                    </button>
                    {
                        cost !== "Free" ? <div>Credit card required</div> : <div>No credit card required</div>
                    }
                </div>
            </div>
        </div>
    )
}

export default PriceCard