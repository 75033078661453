import React, { useState } from 'react'
import styles from './lockedPricingSection.module.css'
import ThreeArrowIcon from '../../assets/images/CalendarV2/desktopIcons/ThreeArrowIcon'
import CreditsEarnedPopup from '../CreditsEarnedPopup/CreditsEarnedPopup'
import ThankYouCard from '../ThankYouCard/ThankYouCard'
import { insertQueryInDynamoDb } from '../../Utils/utils'
import { toast } from 'react-toastify'

const UnlockPriceBtn = ({ setPopupActive }) => {
    return (
        <div className={styles.unlockPriceBtnContainer}>
            <div>Join 1,000+ satisfied customers</div>
            <button onClick={() => setPopupActive(true)}>
                Unlock Pricing <ThreeArrowIcon />
            </button>
        </div>
    )
}

const LockedPricingSection = () => {
    const [popupActive, setPopupActive] = useState(false)
    const [success, setSuccess] = useState(false);


    return (
        <div className={styles.LockedPricingContainer}>
            <div className={styles.lockedPricingSection}>
            </div>
            <div className={styles.LockedPricing}>
                {
                    success ? <ThankYouCard /> : popupActive ? <CreditsEarnedPopup setSuccess={setSuccess} /> : <UnlockPriceBtn setPopupActive={setPopupActive} />
                }
            </div>
        </div>
    )
}

export default LockedPricingSection