import React, { useEffect, useRef } from 'react'
import styles from './simpleEnquiryFormPopup.module.css'
import SimpleEnquiryForm from '../SimpleEnquiryForm/SimpleEnquiryForm'

const SimpleEnquiryFormPopup = ({ activePopup, setActivePopup }) => {

    const modalRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setActivePopup(false)
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setActivePopup]);

    if (!activePopup) {
        return null;
    }

    return (
        <div className={styles.simpleEnquiryFormPopup}>
            <div ref={modalRef}>
                <SimpleEnquiryForm setActivePopup={setActivePopup} />
            </div>
        </div>
    )
}

export default SimpleEnquiryFormPopup