import './NotFound.css'

const NotFound = () =>{
    return (
        <div className='nf-container'>
            &nbsp;
            &nbsp;            &nbsp;
            &nbsp;            &nbsp;
            &nbsp;

            &nbsp;            &nbsp;            &nbsp;

            &nbsp;            &nbsp;            &nbsp;

            &nbsp;
        </div>
    )
}

export default NotFound