import { useEffect, useRef, useState } from 'react'
import DocsNav from '../../components/DocsNav/DocsNav'
import styles from './index.module.css'
import NavLinkContext from '../../contexts/NavLinkContext'
import DocsNavLinks from '../../components/DocsNavLinks/DocsNavLinks'
import testImg from './testImg.svg'
import DocsContentHelp from '../../components/DocsContentHelp/DocsContentHelp'
import { useNavigate, useParams } from 'react-router-dom'
import { createLinkTagsForHeading, fetchArticle, fetchArticleById, fetchArticleByPublishedId, fetchArticlesByModule } from '../../Utils/utils'
import { toast } from 'react-toastify'
import DocsContent from '../../components/DocsContent/DocsContent'

const navLinkMapping = {
    home : "home",
    admins : "admin",
    tutors : "tutor",
    parents : "parent",
    students : "student"
}

const Docs = (props) => {

    const contentContainerRef = useRef(null)
    const contentHelpRef = useRef(null)

    const [navLink, setNavLink] = useState(null)
    const [links,setLinks] = useState([])
    const [navLinkChangedOnClick,setNavLinkChangedOnClick] = useState(false)

    const [contentHelp, setContentHelp] = useState(null)
    const [thumbnailImgId, setThumbnailImgId] = useState(null)
    const [isInTheBackground, setIsInTheBackground] = useState(false)
    const [internalLinks,setInternalLinks] = useState([])
    const [doc,setDoc] = useState(null)
    const navigate = useNavigate()

    const {module,section,preview,docId} = useParams()

    useEffect(()=>{
        (async()=>{
            if(preview === "preview") {
                if(docId){
                    try{
                        const _doc = await fetchArticleById(docId)
                        if(contentContainerRef.current){
                            if(_doc.ytVideoId){
                                setThumbnailImgId(_doc.ytVideoId)
                            }
                            
                            contentContainerRef.current.innerHTML = _doc.html
                            // contentHelpRef.current.innerHTML = createLinkTagsForHeading(_doc.html).innerHTML
                            setInternalLinks(createLinkTagsForHeading(_doc.html))
                        }
                    }catch(err){
                        toast.error("Error generating preview.")
                    }
                }
            }
        })()
    },[])


    useEffect(()=>{
        (async ()=>{
            if (!preview || preview === "published"){
                try{

                    console.log("Nav Link changed")
                    if(!module && !navLinkChangedOnClick){
                        setNavLink("home")
                    } else {
                        // setNavLink(module)
                    }
                    const linksForModule = await fetchArticlesByModule(navLinkMapping[navLink ?? module])
                    setLinks(linksForModule)
                    if((navLinkChangedOnClick === false && docId)){
                        return
                    }

                    
                    const firstSection = linksForModule[navLinkMapping[navLink]][0]
                    console.log(">>>FS",navLink,firstSection, linksForModule[navLinkMapping[navLink]])
                    const firstSectionHeader = Object.keys(firstSection)[0]
                    const firstLink = firstSection[Object.keys(firstSection)[0]][0]
                    navigate(`/docs/published/${navLink}/${firstSectionHeader}/${firstLink.publishId}`)

                    if(!docId){
                        setThumbnailImgId(null)
                        setInternalLinks(null)
                    }
                }catch(err){
                    console.log(err)
                }
                
            }
        })()
    },[navLink])

    useEffect(()=>{
        (async ()=>{
            if (!preview || preview === "published"){
                try{
                    if(docId) {
                        const _doc = await fetchArticleByPublishedId(docId)
                        if(contentContainerRef.current){
                            window.scrollTo({
                                behavior : "smooth",
                                top : 0
                            })
                            contentContainerRef.current.scrollTo({
                                behavior : "smooth",
                                top : 0
                            })
                            setThumbnailImgId(_doc.ytVideoId)
                            contentContainerRef.current.innerHTML = `<h1 class=${styles.title}>${_doc.title}</h1>`+_doc.html
                            // contentHelpRef.current.innerHTML = createLinkTagsForHeading(_doc.html).innerHTML
                            setInternalLinks(createLinkTagsForHeading(_doc.html))
                        }
                    }
                }catch(err){
                    console.log(err)
                }
                
            }
        })()
    },[docId])

    return (
        <NavLinkContext.Provider value={{navLink, setNavLink, setContentHelp, setIsInTheBackground, links, setNavLinkChangedOnClick}}>
        {
            isInTheBackground ?  <div className={styles.makeBackgroundDiv}>&nbsp;</div> : <></>
        }
       
        <div className={styles.container} >
            <div className={styles.navContainer} style={{display : preview === "preview" ? "none" : "false"}}>
                    <DocsNav />
            </div>
            <div className={styles.contentNav} >
                {
                    navLink && navLink !== "home"?
                    <DocsNavLinks /> :
                    <></>
                }
            </div>
            <div className={styles.contentContainer} style={{display : navLink === "home" ? "block" : "none"}}>
                <DocsContent />
            </div>
            <div className={styles.contentContainer} ref={contentContainerRef} style={{display : navLink === "home" ? "none" : "block"}}>
            </div>
            <div className={styles.contentHelp}>
                {
                navLink && navLink !== "home" ?
                <DocsContentHelp mediaId={thumbnailImgId} links={internalLinks} ref={contentHelpRef}/> :
                null
                }
                
            </div>
        </div>
        </NavLinkContext.Provider>
    )
}

export default Docs